import React, { useState } from "react";
import {
  FLEX_ROW_CENTER,
  BORDER_LIGHT,
  SHADOW_LIGHT,
  TEXT_WRAP_CENTER,
} from "../constants/style";
import {
  View,
  Image,
  TextMD,
  TouchableOpacity,
  TextL,
} from "../../seperated/react-native";
import { navigateBack } from "../../seperated/components/Router";
import { WebDiv } from "../../seperated/web-only";
import { SwipeableDrawer } from "@material-ui/core"
import AvatarImage from "../avatar/AvatarImage"
import ScopeStore from "../../stores/ScopeStore"
import LeftPaneNavItem from "../LeftPane/LeftPaneNavItem"
import { navigate } from "../../seperated/components/Router"
import { performLogout } from "../../pages/Login";
import { navigateToScanQr } from "../../pages/ScanQr";

function MobileDrawer({open, onOpen, onClose}) {
  return (
    <SwipeableDrawer
      anchor={"left"}
      open={open}
      onOpen={onOpen}
      onClose={onClose}
    >
      <View style={{paddingTop: 16, minWidth: 275}}>
        <View
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: 28,
          }}
        >
          <AvatarImage src={ScopeStore.user?.profile.avatar} style={{marginBottom: 12}} size={150}></AvatarImage>
          <TextL style={{...TEXT_WRAP_CENTER}}>{ScopeStore.user?.profile.name}</TextL>
        </View>

        <LeftPaneNavItem
          icon={require("../../img/icons/home.png")}
          label="หน้าแรก"
          to="dashboard"
        ></LeftPaneNavItem>

        <LeftPaneNavItem
          icon={require("../../img/icons/power.png")}
          label="ควบคุมอุปกรณ์"
          onPress={()=>navigate("control_air_list", {type: "building", id: ScopeStore.activeBuilding})}
        ></LeftPaneNavItem>

        <LeftPaneNavItem
          icon={require("../../img/icons/energy.png")}
          label="พลังงาน"
          onPress={()=>navigate("energy", {type: "building", id: ScopeStore.activeBuilding})}
        ></LeftPaneNavItem>

        {/* <LeftPaneNavItem
          icon={require("../../img/icons/home.png")}
          label="รายการ"
          to="list"
        ></LeftPaneNavItem> */}

        <LeftPaneNavItem
          icon={require("../../img/icons/option.png")}
          label="ตั้งค่า"
          to="settings"
        ></LeftPaneNavItem>

        <LeftPaneNavItem
          icon={require("../../img/icons/option.png")}
          label="ผู้ใช้"
          to="permissions"
        ></LeftPaneNavItem>

        <LeftPaneNavItem
          icon={require("../../img/icons/option.png")}
          label="Scan QR Code"
          onPress={() => navigateToScanQr()}
        ></LeftPaneNavItem>

        <LeftPaneNavItem
          icon={require("../../img/icons/option.png")}
          label="ออกจากระบบ"
          onPress={()=>performLogout()}
        ></LeftPaneNavItem>
      </View>
    </SwipeableDrawer>
  )
}

export default function MobileHeader({
  menu = false,
  back = true,
  onBack = () => navigateBack(),
  title,
  ...props
}) {
  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <View
      style={{
        ...FLEX_ROW_CENTER,
        justifyContent: "space-between",
        ...BORDER_LIGHT,
        ...SHADOW_LIGHT,
      }}
    >
      <View style={{
        padding: 12,
        paddingHorizontal: 16,
      }}>
        {back && (
          <TouchableOpacity onPress={onBack}>
            <Image
              src={require("../../img/icons/arrow-left-outline-black.png")}
              height="16"
            ></Image>
          </TouchableOpacity>
        )}

        {menu && (
          <TouchableOpacity onPress={() => setOpenDrawer(true)}>
            <Image
              src={require("../../img/icons/arrow-left-outline-black.png")}
              height="16"
            ></Image>
          </TouchableOpacity>
        )}
      </View>

      <TouchableOpacity onPress={() => navigate('dashboard')} style={{
        padding: 8,
      }}>
        <TextMD style={{fontSize: 18}}>{title}</TextMD>
      </TouchableOpacity>

      <View style={{minWidth: 36}}>{props.children}</View>

      <MobileDrawer
        open={openDrawer}
        onOpen={() => setOpenDrawer(true)}
        onClose={() => setOpenDrawer(false)}
      ></MobileDrawer>
    </View>
  );
}
