import React, { useState, useEffect } from "react";

import Layout, { LeftPane, CenterPane } from "../../components/Layout";
import {
  View,
  Text,
  Image,
  TextInputSolid,
  TouchableOpacity,
  TextMD,
  FlatList,
  TextL,
} from "../../seperated/react-native";
import TextFieldComplete from "../../components/input/TextFieldComplete";
import { IS_MOBILE } from "../../seperated/screen-mode";
import {
  SHADOW_LIGHT,
  BORDER_LIGHT,
  FLEX_ROW_CENTER,
  FLEX_LR_CENTER,
  FLEX_ROW_CENTER_CENTER,
  TEXT_WRAP,
  BORDER_LINE_BOTTOM,
  BORDER_LINE_BOTTOM_HARD,
  BORDER_LINE_RIGHT_HARD,
  FLEX_COLUMN_CENTER_CENTER,
  FLEX_COLUMN,
} from "../../components/constants/style";
import {
  Link,
  navigate,
  getRouteParam,
  navigateBack,
  navigateSameParams,
} from "../../seperated/components/Router";
import LeftPaneControlPanel from "../../components/LeftPane/LeftPaneControlPanel";
import Panel from "../../components/Panel";
import PanelInnerGrid from "../../components/PanelInnerGrid";
import PanelInner from "../../components/PanelInner";
import { globalLoadingFn } from "../../utils/loading";
import { api } from "../../config";
import { useFormState } from "../../hooks/FormState";
import ControlTempSlider from "../../components/control/ControlTempSlider";
import ScheduleStore, { submitSchedule, loadSchedule } from "../../stores/ScheduleStore";
import ControlAirPanel from "../../components/control/air/ControlAirPanel";
import ControlAirSchedulePanel from "../../components/control/air/ControlAirSchedulePanel";
import { observer } from "mobx-react-lite";
import TextWithIcon from "../../components/text/TextWithIcon";
import CommonButton from "../../components/buttons/CommonButton";
import IconInline from "../../components/icon/IconInline";
import ControlPlaceAddDeviceDialog from "../../components/control/ControlPlaceAddDeviceDialog";
import DatePickerMulti from "../../seperated/components/DatePickerMulti/index.js";
import { formatLongDate, time2date } from "../../utils/date";
import TextWithBack from "../../components/text/TextWithBack";
import { alertErrorComplete } from "../../seperated/toast";
import DisabledContext, { InsistDisabledContext, DisabledOpacity } from "../../components/input/DisabledContext";
import ScheduleAir from "./Air/ScheduleAir";
import CardButtonMini from "../../components/buttons/CardButtonMini";
import MobileLayout from "../../components/MobileLayout";
import DatePickerComplete from "../../components/input/DatePickerComplete";
import TimePickerComplete from "../../components/input/TimePickerComplete";
import CheckboxComplete from "../../components/input/CheckboxComplete";

function navigateToListPage(type, id, entity) {
  navigate("schedule_list", {type, id, ...(entity ? {entity} : {})})
}

const DAY_LABEL = ["อา.", "จ.", "อ.", "พ.", "พฤ.", "ศ.", "ส."];

const ScheduleDay = observer<any>(({ day }) => {
  const active = ScheduleStore.repeat_day.indexOf(day) != -1;

  console.log(ScheduleStore.repeat_day)

  const SCALE = IS_MOBILE ? 0.8 : 1;

  return (
    <TouchableOpacity
      style={{
        ...FLEX_ROW_CENTER_CENTER,
        borderRadius: 10000,
        borderColor: active ? "#49c5f1" : "#b1b1b1",
        borderWidth: IS_MOBILE ? 3 : 4,
        padding: 8,
        width: 56 * SCALE,
        height: 56 * SCALE,
      }}

      onPress={()=>ScheduleStore.toggleRepeatDay(day)}
    >
      {IS_MOBILE ? 
        <Text style={{color: active ? "black" : "#aaaaaa"}}>{DAY_LABEL[day]}</Text> :
        <TextMD style={{color: active ? "black" : "#aaaaaa"}}>{DAY_LABEL[day]}</TextMD>
      }
    </TouchableOpacity>
  );
});

const ScheduleCalendar = observer<any>(() => {
  const [showIncludeDatePicker, setShowIncludeDatePicker] = useState(false);
  const [showExceptDatePicker, setShowExceptDatePicker] = useState(false);
  const model = useFormState({
    start_date: new Date(),
    start_time: time2date("00:00:00"),
    expire_date: null,
    expire_time: time2date("23:59:59"),
    has_expire: false,
  });

  return (
    <View>
      <View
        style={{
          paddingHorizontal: IS_MOBILE ? 8 : 48,
          ...FLEX_ROW_CENTER,
          justifyContent: "space-around",
          ...BORDER_LINE_BOTTOM_HARD,
          borderBottomWidth: 3,
          paddingBottom: 16
        }}
      >
        {[0, 1, 2, 3, 4, 5, 6].map((day) => (
          <ScheduleDay day={day}></ScheduleDay>
        ))}
      </View>

      {false && 
        <View style={{...(IS_MOBILE ? FLEX_COLUMN : FLEX_LR_CENTER), alignItems: "flex-start", marginVertical: 16, marginHorizontal: IS_MOBILE ? 0 : 24}}>
          <View style={{...FLEX_ROW_CENTER}}>
            <View style={{marginRight: 16}}>
              <Text>วันที่เริ่มต้น</Text>
            </View>

            <DatePickerComplete model={model.$("start_date")} style={{width: 160, marginRight: 8}}></DatePickerComplete>
            {/* <TimePickerComplete model={model.$("start_time")} style={{width: 110, marginRight: 8}}></TimePickerComplete> */}
          </View>

          <View>
            <View style={{...FLEX_ROW_CENTER, marginTop: IS_MOBILE ? 12 : 0}}>
              <CheckboxComplete model={model.$("has_expire")}></CheckboxComplete>
              <View>
                <Text>มีวันที่สิ้นสุด</Text>
              </View>

              {model.get().has_expire && <View style={{marginLeft: 16, ...FLEX_ROW_CENTER}}>
                <DatePickerComplete model={model.$("expire_date")} style={{width: 160, marginRight: 8}}></DatePickerComplete>
                {/* <TimePickerComplete model={model.$("expire_time")} style={{width: 110, marginRight: 8}}></TimePickerComplete> */}
              </View>}
            </View>
          </View>
        </View>
      }

      <View
        style={{paddingTop: 16, display: "flex", flexDirection: IS_MOBILE ? "column" : "row"}}
      >
        <View style={{flexBasis: "50%", paddingLeft: IS_MOBILE ? 8 : 48, paddingRight: IS_MOBILE ? 8 : 24, ...(IS_MOBILE ? {...BORDER_LINE_BOTTOM_HARD, borderBottomWidth: 3, marginBottom: 16, paddingBottom: 12} : {...BORDER_LINE_RIGHT_HARD, borderRightWidth: 3})}}>
          <View style={{...FLEX_LR_CENTER}}>
            <TextMD>+ รายการเพิ่ม</TextMD>
            <TouchableOpacity onPress={() => setShowIncludeDatePicker(true)}>
              <IconInline src={require('../../img/icons/calendar.png')} size={24}></IconInline>
            </TouchableOpacity>
          </View>

          <View style={{paddingTop: 8, paddingLeft: 36}}>
            {ScheduleStore.include_date.map(item => 
              <View>
                <Text>+ {formatLongDate(item)}</Text>
              </View>
            )}
          </View>
        </View>

        <View style={{flexBasis: "50%", paddingLeft: IS_MOBILE ? 8 : 48, paddingRight: IS_MOBILE ? 8 : 24}}>
          <View style={{...FLEX_LR_CENTER}}>
            <TextMD>- รายการลด</TextMD>
            <TouchableOpacity onPress={() => setShowExceptDatePicker(true)}>
              <IconInline src={require('../../img/icons/calendar.png')} size={24}></IconInline>
            </TouchableOpacity>
          </View>

          <View style={{paddingTop: 8, paddingLeft: 36}}>
            {ScheduleStore.except_date.map(item => 
              <View>
                <Text>- {formatLongDate(item)}</Text>
              </View>
            )}
          </View>
        </View>
      </View>

      <DatePickerMulti
        open={showIncludeDatePicker}
        selectedDates={ScheduleStore.include_date.map(x => new Date(x))}
        onCancel={() => setShowIncludeDatePicker(false)}
        onSubmit={dates => { ScheduleStore.setIncludeDate(dates); setShowIncludeDatePicker(false); }}
      />

      <DatePickerMulti
        open={showExceptDatePicker}
        selectedDates={ScheduleStore.except_date.map(x => new Date(x))}
        onCancel={() => setShowExceptDatePicker(false)}
        onSubmit={dates => { ScheduleStore.setExceptDate(dates); setShowExceptDatePicker(false); }}
      />
    </View>
  )
})

function ScheduleCalendarWithPanel(props) {
  if (IS_MOBILE) {
    return (
      <CardButtonMini
        title="เลือกปฏิทินการทำงาน"
        shadowRoot
        defaultShowChild
        hasArrow={false}
        style={{marginBottom: 24}}
      >
        <View style={{padding: 16, paddingTop: 24}}>
          <ScheduleCalendar></ScheduleCalendar>
        </View>
      </CardButtonMini>
    )
  }

  return (
    <PanelInner
      header={
        <View
          style={{
            ...FLEX_LR_CENTER,
          }}
        >
          <View style={{ flexGrow: 1 }}>
            <TextMD>เลือกปฏิทินการทำงาน</TextMD>
          </View>
        </View>
      }

      style={{ marginBottom: 24 }}
    >
      <ScheduleCalendar></ScheduleCalendar>
    </PanelInner>
  )
}

function ScheduleReasonWithPanel({model}) {
  if (IS_MOBILE) {
    return (
      <CardButtonMini
        title="สาเหตุที่ขอเปิดแอร์"
        shadowRoot
        defaultShowChild
        hasArrow={false}
        style={{marginBottom: 24}}
      >
        <View style={{padding: 16}}>
          <TextFieldComplete variant="material" model={model.$("request_reason")}></TextFieldComplete>
        </View>
      </CardButtonMini>
    )
  }

  return (
    <PanelInner
      header={
        <View
          style={{
            ...FLEX_LR_CENTER,
          }}
        >
          <View style={{ flexGrow: 1 }}>
            <TextMD>สาเหตุ / คำอธิบาย</TextMD>
          </View>
        </View>
      }

      style={{ marginBottom: 24 }}
    >
      <TextFieldComplete variant="material" model={model.$("request_reason")}></TextFieldComplete>
    </PanelInner>
  )
}

const ScheduleScopeList = observer(function () {
  return (
    <View style={{ marginTop: -8 }}>
      {ScheduleStore.scopes.map(scope => (
        <ScheduleScope place={scope}></ScheduleScope>
      ))}
    </View>
  )
})

const ScheduleScopeListWithPanel = observer(function() {
  const [showAddScopeModal, setShowAddScopeModal] = useState(false);

  const AddDeviceDialog = (
    <ControlPlaceAddDeviceDialog 
      show={showAddScopeModal} 
      onClose={()=>setShowAddScopeModal(false)} 
      type="all"
      onChange={scopes => ScheduleStore.setScopes(scopes)}
      selected={ScheduleStore.scopes}
      includeBuilding={true}
      includeGroup={true}
    ></ControlPlaceAddDeviceDialog>
  )

  if (IS_MOBILE) {
    return (
      <CardButtonMini
        title="รายการสถานที่"
        shadowRoot
        defaultShowChild
        rightIcon={require('../../img/icons/add.png')}
        onPress={()=>setShowAddScopeModal(true)}
        style={{marginBottom: 24}}
      >
        <View style={{padding: 16}}>
          <ScheduleScopeList></ScheduleScopeList>
        </View>

        {AddDeviceDialog}
      </CardButtonMini>
    )
  }

  return (
    <PanelInner
      header={
        <View
          style={{
            ...FLEX_LR_CENTER,
          }}
        >
          <View style={{ flexGrow: 1 }}>
            <TextMD>รายการสถานที่</TextMD>
          </View>

          <TouchableOpacity onPress={()=>setShowAddScopeModal(true)}>
            <TextWithIcon icon={require('../../img/icons/add.png')} iconRight>เพิ่มรายการ</TextWithIcon>
          </TouchableOpacity>
        </View>
      }

      style={{ marginBottom: 24 }}
    >
      <ScheduleScopeList></ScheduleScopeList>

      {AddDeviceDialog}
    </PanelInner>
  )
});

function ScheduleEditModeSeperator() {
  return <TextL style={{marginRight: 16, marginLeft: 8, color: '#999'}}>|</TextL>
}

function ScheduleEditMode({children, active, onPress, hasSeperator=true}) {
  return <>
    <TouchableOpacity onPress={onPress}>
      <TextMD style={{color: active ? 'black' : '#9B9B9B', textDecorationLine: active ? "underline" : "none"}}>{children}</TextMD>
    </TouchableOpacity>

    {hasSeperator && <ScheduleEditModeSeperator />}
  </>
}

function ScheduleScope({place, hasLine=true}) {
  const ICON_SIZE = 48;

  return (
    <View style={{...FLEX_ROW_CENTER, ...(hasLine ? BORDER_LINE_BOTTOM : {})}}>
      <View style={{ width: ICON_SIZE, flexBasis: ICON_SIZE, marginRight: 24 }}>
        <Image src={require("../../img/icons/air.png")} style={{ height: ICON_SIZE }} resizeMode="contain" />
      </View>

      <View style={{flexGrow: 1}}>
        <Text>{place.name}</Text>
      </View>

      <View style={{...FLEX_ROW_CENTER}}>
        <View style={{marginRight: 12}}>
          <Text style={{textAlign: "right"}}>{place.total_count || 1} เครื่อง</Text>
        </View>

        <TouchableOpacity onPress={()=>ScheduleStore.removeScopes(place.scope_type, place.scope_id)}>
          <IconInline src={require('../../img/icons/cancel.png')}></IconInline>
        </TouchableOpacity>
      </View>
    </View>
  )
}

export default observer<any>(function ScheduleDetailPage(props) {
  const model = useFormState();
  const [refreshKey, setRefreshKey] = useState(Math.random());
  const [showAddScopeModal, setShowAddScopeModal] = useState(false);

  const [place, setPlace] = useState<any>();

  const type = getRouteParam(props, "type");
  const id = parseInt(getRouteParam(props, "id") || "0");
  const entity = getRouteParam(props, "entity"); // This field use in list page
  const control_entity = getRouteParam(props, "control_entity"); // This field use in this page
  const pending = getRouteParam(props, "pending"); // This field use in list page

  const scheduleId = getRouteParam(props, "schedule_id");
  const [editMode, _setEditMode] = useState(scheduleId ? "view" : "new");
  const disabled = editMode == "view"

  async function initSchedule() {
    let place = (await api.get("/" + type + "s/" + id)).data;
    setPlace(place)

    if (scheduleId) {
      await globalLoadingFn(() => loadSchedule(scheduleId, control_entity));
      model.dataState.set({
        name: ScheduleStore.name,
        request_reason: ScheduleStore.request_reason
      })
    } else {
      let airData = await globalLoadingFn(()=>api.get("/devices/airs/scope?scope_type=" + type + "&scope_id=" + id), "", {performBack404: true});
      ScheduleStore.airNew(airData.data.device_control_air);

      ScheduleStore.setScopes([place])
    }

    setRefreshKey(Math.random())
  }

  useEffect(() => {
    globalLoadingFn(() => initSchedule());
  }, []);

  function setEditMode(mode) {
    switch (mode) {
      case 'duplicate':
        ScheduleStore.setId(0)
      break;

      default:
        ScheduleStore.setId(scheduleId)
      break;
    }

    _setEditMode(mode)
  }

  // console.log(ScheduleStore.controls.get("common_on"))
  // console.log(ScheduleStore.include_date)

  const SubmitButton = <CommonButton variant="primary" style={{flexGrow: IS_MOBILE ? 1 : 0, minWidth: IS_MOBILE ? undefined : 150}} onPress={async ()=>{
    try {
      ScheduleStore.setName(model.$("name").get())
      ScheduleStore.setRequestReason(model.$("request_reason").get())

      let successText = "แก้ไขตารางการทำงานสำเร็จ"

      switch (editMode) {
        case 'new': successText = "สร้างตารางการทำงานสำเร็จ"; break;
        case 'duplicate': successText = "ทำซ้ำตารางการทำงานสำเร็จ"; break;
      }

      let response = await submitSchedule({control_entity, successText})

      navigate("schedule_detail_view", {type, id, entity, control_entity, pending, schedule_id: response.data.id})
    } catch (err) {
      alertErrorComplete(err)
    }
  }}>
    {scheduleId ? (ScheduleStore.status == "pending" ? "อนุมัติ" : (editMode == "duplicate" ? "ทำซ้ำ" : "บันทึก")) : 'สร้าง / ส่งคำขออนุมัติ'}
  </CommonButton>

  const DeleteBottomButton = <CommonButton variant="danger" style={{flexGrow: IS_MOBILE ? 1 : 0, minWidth: IS_MOBILE ? undefined : 150, marginTop: 16}} onPress={async () => {
    if (window.confirm("ยืนยันการลบตารางการทำงาน ?")) {
      await globalLoadingFn(()=>api.delete("/schedules/" + scheduleId));
      navigateSameParams("schedule_list", props);
    }
  }}>{ScheduleStore.status == "pending" ? "ปฏิเสธ" : "ลบ"}</CommonButton>

  const BottomButtons = (
    IS_MOBILE ? (
      <View style={{...FLEX_COLUMN}}>
        {SubmitButton}
        {DeleteBottomButton}
      </View>
    ) : (
      <View style={{...FLEX_LR_CENTER}}>
        {DeleteBottomButton}
        {SubmitButton}
      </View>
    )
  )

  const DeleteButton = (
    <TouchableOpacity>
      <TextWithIcon icon={require('../../img/icons/delete.png')} Component={TextMD} onPress={async ()=>{
        if (window.confirm('ยืนยันลบตารางการทำงาน')) {
          await globalLoadingFn(()=>api.delete("/schedules/" + scheduleId));
          navigateToListPage(type, id, entity);
        }
      }} iconProps={{size: IS_MOBILE ? 20 : 16}}>{IS_MOBILE ? "" : "ลบตารางการทำงาน"}</TextWithIcon>
    </TouchableOpacity>
  )

  const TopEditModeControl = (
    <View style={{...(IS_MOBILE ? FLEX_COLUMN_CENTER_CENTER : FLEX_LR_CENTER), marginBottom: IS_MOBILE ? 18 : 24}}>
      <View style={{...FLEX_ROW_CENTER_CENTER}}>
        <ScheduleEditMode active={editMode=="view"} onPress={() => setEditMode('view')}>View {!IS_MOBILE && "schedule"}</ScheduleEditMode>
        <ScheduleEditMode active={editMode=="edit"} onPress={() => setEditMode('edit')}>Edit {!IS_MOBILE && "schedule"}</ScheduleEditMode>
        <ScheduleEditMode active={editMode=="duplicate"} onPress={() => setEditMode('duplicate')} hasSeperator={false}>Duplicate</ScheduleEditMode>
      </View>

      {!IS_MOBILE && DeleteButton}
    </View>
  )

  if (IS_MOBILE) {
    return (
      <MobileLayout title="ตารางการทำงาน" onBack={() => navigateToListPage(type, id, entity)}>
        {scheduleId && TopEditModeControl}

        <View style={{...FLEX_LR_CENTER, marginBottom: 24}}>
          <View style={{...FLEX_ROW_CENTER}}>
            <TextFieldComplete variant="material" model={model.$("name")} placeholder="ชื่อตารางการทำงาน"></TextFieldComplete>
            <IconInline src={require("../../img/icons/edit.png")} style={{marginLeft: 8}}></IconInline>
          </View>

          {scheduleId && DeleteButton}
        </View>
        
        <DisabledOpacity value={disabled} opacityOnly>
          <ScheduleAir refreshKey={refreshKey} model={model}></ScheduleAir>

          {/* Calendar */}
          <ScheduleCalendarWithPanel></ScheduleCalendarWithPanel>

          {/* Scopes */}
          <ScheduleScopeListWithPanel></ScheduleScopeListWithPanel>

          {/* Request reason */}
          <ScheduleReasonWithPanel model={model}></ScheduleReasonWithPanel>

          {(editMode != "view" || ScheduleStore.status == "pending") && BottomButtons}
        </DisabledOpacity>
      </MobileLayout>
    )
  }

  return (
    <Layout webGrid>
      <LeftPane>
        <LeftPaneControlPanel
          active="schedule"
          data={place}
        ></LeftPaneControlPanel>
      </LeftPane>

      <CenterPane>
        <Panel>
          <View style={{marginBottom: scheduleId ? 12 : 20}}>
            <TextWithBack onPress={()=>navigateToListPage(type, id, entity)}>ตารางการทำงาน</TextWithBack>
          </View>

          {scheduleId && TopEditModeControl}
          
          <DisabledOpacity value={disabled} opacityOnly>
            <ScheduleAir refreshKey={refreshKey} model={model}></ScheduleAir>
  
            {/* Calendar */}
            <ScheduleCalendarWithPanel></ScheduleCalendarWithPanel>
  
            {/* Scopes */}
            <ScheduleScopeListWithPanel></ScheduleScopeListWithPanel>
  
            {/* Request reason */}
            <ScheduleReasonWithPanel model={model}></ScheduleReasonWithPanel>
  
            {(editMode != "view" || ScheduleStore.status == "pending") && BottomButtons}
          </DisabledOpacity>
        </Panel>
      </CenterPane>
    </Layout>
  );
});
