import React, { useState, useEffect } from "react";

import Layout, { LeftPane, CenterPane } from "../../components/Layout";
import { View, Text, Image, TextInputSolid, TouchableOpacity, TextMD, TextXL } from "../../seperated/react-native";
import TextFieldComplete from "../../components/input/TextFieldComplete";
import { IS_MOBILE } from "../../seperated/screen-mode";
import { SHADOW_LIGHT, BORDER_LIGHT, FLEX_ROW_CENTER, FLEX_LR_CENTER, FLEX_COLUMN, FLEX_COLUMN_CENTER_CENTER, BORDER_LINE_BOTTOM, FLEX_ROW_CENTER_CENTER } from "../../components/constants/style";
import { Link, navigate, getRouteParam } from "../../seperated/components/Router";
import LeftPaneControlPanel from "../../components/LeftPane/LeftPaneControlPanel";
import Panel from "../../components/Panel";
import PanelInnerGrid from "../../components/PanelInnerGrid";
import PanelInner from "../../components/PanelInner";
import MobileLayout from "../../components/MobileLayout";
import LeftPaneRootPanel from "../../components/LeftPane/LeftPaneRootPanel";
import { api } from "../../config";
import { globalLoadingFn } from "../../utils/loading";
import { cloneDeep } from "lodash"
import { useFormState } from "../../hooks/FormState";
import EnergyConsumptionSummary from "../../components/energy/EnergyConsumptionSummary";
import CommonButton from "../../components/buttons/CommonButton";
import SelectCustom from "../../seperated/components/SelectCustom";
import DropdownMenu from "../../components/DropdownMenu";
import { ListItemText, MenuItem, Button } from "@material-ui/core";
import IconInline from "../../components/icon/IconInline";
import Dialog from "../../components/dialog/Dialog";
import ProgressCircle from "../../components/energy/ProgressCircle";
import TextWithCircleDot from "../../components/text/TextWithCircleDot";
import Plot from "react-plotly.js";
import performanceValue from "../../performance_value.json"

const DEFAULT_RELAY_THRESHOLD_TEMPLATE: any[] = [];

for (let i = 0; i < 12; i++) {
  DEFAULT_RELAY_THRESHOLD_TEMPLATE.push([null, null, null]);
}

const MONTH_LABEL = [
  "มกราคม",
  "กุมภาพันธ์",
  "มีนาคม",
  "เมษายน",
  "พฤษภาคม",
  "มิถุนายน",
  "กรกฎาคม",
  "สิงหาคม",
  "กันยายน",
  "ตุลาคม",
  "พฤศจิกายน",
  "ธันวาคม",
];

function RelayInput({monthi, monthLabel, data, onChange}) {
  const model = useFormState({
    relay1: data[0] || "",
    relay2: data[1] || "",
    relay3: data[2] || "",
  })

  useEffect(() => {
    onChange(0, parseFloat(model.get().relay1) || null);
  }, [model.get().relay1])

  useEffect(() => {
    onChange(1, parseFloat(model.get().relay2) || null);
  }, [model.get().relay2])

  useEffect(() => {
    onChange(2, parseFloat(model.get().relay3) || null);
  }, [model.get().relay3])

  useEffect(() => {
    model.dataState.set({
      relay1: data[0] || "",
      relay2: data[1] || "",
      relay3: data[2] || "",
    })
  }, [data])

  return (
    <View style={{...FLEX_ROW_CENTER}}>
      <Text style={{flexBasis: 28}}>{monthi + 1}</Text>
      <Text style={{flexBasis: 110}}>{monthLabel}</Text>
      <TextFieldComplete model={model.$('relay1')} variant="material" inputStyle={{width: 60}} style={{marginRight: 8}}></TextFieldComplete>
      <TextFieldComplete model={model.$('relay2')} variant="material" inputStyle={{width: 60}} style={{marginRight: 8}}></TextFieldComplete>
      <TextFieldComplete model={model.$('relay3')} variant="material" inputStyle={{width: 60}} style={{marginRight: 8}}></TextFieldComplete>
    </View>
  )
}

export default function EnergyManagementPage(props) {
  const scopeType = getRouteParam(props, "type") || "building";
  const scopeId = parseInt(getRouteParam(props, "id") || "0");
  const isBruno = parseInt(getRouteParam(props, "bruno") || "0");

  const [relays, setRelays] = useState<any[]>([]);
  const [selectedRelay, setSelectedRelay] = useState<any>(null);

  const [airs, setAirs] = useState<any[]>([]);
  const [performanceTableIndex, setPerformanceTableIndex] = useState<any[]>([]);
  const [sumOfVrv, setSumOfVrv] = useState<number[]>([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);

  const [showChooseDelayDialog, setShowChooseDelayDialog] = useState(false);

  const [energyData, setEnergyData] = useState<any>(null);

  const energyPercentage = energyData?.total_energy ? (energyData?.vrv_energy / energyData?.total_energy) : 0;

  const VALUE_ROW_STYLE = {
    ...FLEX_LR_CENTER, 
    ...BORDER_LINE_BOTTOM, 
    padding: 12,
    paddingHorizontal: 24,
  }

  async function reloadAirs() {
    let response = await globalLoadingFn(() => api.get("/devices?building_id="+scopeId+"&device_type_id=1&deep=1"))
    setAirs(response.data)
    console.log(response.data)

    // Accumulate table data
    let productGenerationCount = {};
    for (let air of response.data) {
      if (air.device_control_air.device_air_product_generation && performanceValue[air.device_control_air.device_air_product_generation]) {
        if (!productGenerationCount[air.device_control_air.device_air_product_generation]) {
          productGenerationCount[air.device_control_air.device_air_product_generation] = 0;
        }

        productGenerationCount[air.device_control_air.device_air_product_generation]++;
      }
    }

    let tableIndex: any[] = []
    let sumOfVrvLocal = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    for (let key in productGenerationCount) {
      tableIndex.push({
        generation: key,
        quantity: productGenerationCount[key]
      });

      for (let i = 0; i < 11; i++) {
        sumOfVrvLocal[i] += performanceValue[key][i] * productGenerationCount[key];
      }
    }

    setPerformanceTableIndex(tableIndex);
    setSumOfVrv(sumOfVrvLocal);
  }

  async function reloadRelays(useLoading = false) {
    let fn = () => api.get("/sensors/relay?building_id=" + scopeId);
    let response;

    if (useLoading) {
      response = await globalLoadingFn(fn);
    } else {
      response = await fn();
    }

    let data = response.data;

    for (let relay of data) {
      if (!relay.relay_threshold) relay.relay_threshold = cloneDeep(DEFAULT_RELAY_THRESHOLD_TEMPLATE);
      else relay.relay_threshold = cloneDeep(relay.relay_threshold);
    }

    setRelays(data);

    if (data.length > 0) {
      setSelectedRelay(data[0])
    }

    console.log(data)
  }

  async function reloadEnergy(useLoading = false) {
    let response = await globalLoadingFn(() => api.get("/sensors/energy_management_detail?scope_type=building&scope_id=" + scopeId));
    setEnergyData(response.data)
    console.log(response.data)
  }

  function updateRelayThreshold(monthi, i, value) {
    if (selectedRelay) {
      console.log(selectedRelay.relay_threshold)
      selectedRelay.relay_threshold[monthi][i] = value;
    }
  }

  useEffect(() => {
    reloadAirs();
  }, []);

  useEffect(() => {
    reloadRelays(true);
  }, []);

  useEffect(() => {
    reloadEnergy(true);
  }, []);

  const RelaySelect = (true ? 
    <View style={{...FLEX_ROW_CENTER_CENTER}}>
      <Button
        onClick={() => setShowChooseDelayDialog(true)}
      >
        <Text style={{marginRight: 10}}>{selectedRelay ? selectedRelay.device.name : "ไม่พบ Relay"}</Text>
        <IconInline src={require("../../img/icons/arrow-down-outline-black.png")} style={{height: 8}}></IconInline>
      </Button>

      <Dialog show={showChooseDelayDialog} title="เลือก Relay" hasClose onClose={() => setShowChooseDelayDialog(false)}>
        {relays.map(relay => (
          <View style={{...BORDER_LINE_BOTTOM}}>
            <MenuItem
              onClick={() => {
                setSelectedRelay(relay);
                setShowChooseDelayDialog(false);
              }}
            ><ListItemText>{relay.device.name}</ListItemText></MenuItem>
          </View>
        ))}
      </Dialog>
    </View> 
    : 
    <View>
      <DropdownMenu text={selectedRelay ? selectedRelay.device.name : "ไม่พบ Relay"} right>
        {relays.map(relay => (
          <MenuItem onClick={() => setSelectedRelay(relay)}><ListItemText>{relay.device.name}</ListItemText></MenuItem>
        ))}
      </DropdownMenu>
    </View> 
  )

  const EnergyBody = <>
    <PanelInner
      header={
        <View
          style={{
            ...FLEX_LR_CENTER,
          }}
        >
          <View style={{ flexGrow: 1 }}>
            <TextMD>Total KW Building</TextMD>
          </View>
        </View>
      }

      style={{
        marginBottom: 24,
      }}
    >
      <View style={{...(IS_MOBILE ? FLEX_COLUMN_CENTER_CENTER : FLEX_ROW_CENTER)}}>
        <View style={{
          ...FLEX_ROW_CENTER_CENTER,
          flexBasis: IS_MOBILE ? "100%" : "50%", 
          marginBottom: IS_MOBILE ? 16 : 0,
          width: "100%"
        }}>
          <ProgressCircle color="#49c5f1" progress={energyPercentage} radius={75} strokeWidth={16}>
            <TextXL style={{fontSize: 32}}>{(energyPercentage * 100).toFixed(0)}%</TextXL>
          </ProgressCircle>

          <View style={{...FLEX_COLUMN, marginLeft: 36}}>
            <View style={{marginVertical: 4}}>
              <TextWithCircleDot circleColor="#49c5f1">VRV</TextWithCircleDot>
            </View>

            <View style={{marginVertical: 4}}>
              <TextWithCircleDot circleColor="#d7eaf7">Other load</TextWithCircleDot>
            </View>
          </View>
        </View>

        <View style={{...SHADOW_LIGHT, ...BORDER_LIGHT, flexBasis: IS_MOBILE ? "100%" : "50%", width: "100%"}}>
          <View style={VALUE_ROW_STYLE}>
            <Text>Total KW Building</Text>
            <Text style={{fontWeight: "bold"}}>{energyData?.total_energy.toFixed(2) || 0} kW</Text>
          </View>

          <View style={VALUE_ROW_STYLE}>
            <Text>Total KW VRV</Text>
            <Text style={{fontWeight: "bold"}}>{energyData?.vrv_energy.toFixed(2) || 0} kW</Text>
          </View>

          <View style={VALUE_ROW_STYLE}>
            <Text>Total KW Other Load</Text>
            <Text style={{fontWeight: "bold"}}>{(energyData?.total_energy - energyData?.vrv_energy).toFixed(2) || 0} kW</Text>
          </View>
        </View>
      </View>
    </PanelInner>

    <PanelInner
      header={
        <View
          style={{
            ...FLEX_LR_CENTER,
          }}
        >
          <View style={{ flexGrow: 1 }}>
            <TextMD>SUM of VRV</TextMD>
          </View>

          {!IS_MOBILE && RelaySelect}
        </View>
      }

      style={{
        marginBottom: 24,
      }}
    >
      <View style={{...(IS_MOBILE ? FLEX_COLUMN_CENTER_CENTER : FLEX_ROW_CENTER)}}>
        <View style={{flexBasis: IS_MOBILE ? "100%" : "50%"}}>
          <Plot
            data={[
              {
                name: "kW",
                type: "bar",
                x: ["VRV", "Other"],
                y: [energyData?.vrv_energy || 0, (energyData?.total_energy - energyData?.vrv_energy).toFixed(2) || 0]
              }
            ]}
            layout={{
              height: 300,
              showlegend: false,
              margin: {
                l: 24,
                r: 24,
                t: 24,
                b: 24,
              },
            }}
          />
        </View>

        <View style={{flexBasis: IS_MOBILE ? "100%" : "50%", width: "100%"}}>
          {IS_MOBILE && <View style={{marginBottom: 12}}>{RelaySelect}</View>}

          <View style={{...SHADOW_LIGHT, ...BORDER_LIGHT, width: "100%"}}>
            <View style={VALUE_ROW_STYLE}>
              <Text>Relay 1 threshold</Text>
              <Text style={{fontWeight: "bold"}}>{selectedRelay?.relay1_threshold || "-"} kW</Text>
            </View>
  
            <View style={VALUE_ROW_STYLE}>
              <Text>Relay 2 threshold</Text>
              <Text style={{fontWeight: "bold"}}>{selectedRelay?.relay2_threshold || "-"} kW</Text>
            </View>
  
            <View style={VALUE_ROW_STYLE}>
              <Text>Relay 3 threshold</Text>
              <Text style={{fontWeight: "bold"}}>{selectedRelay?.relay3_threshold || "-"} kW</Text>
            </View>
          </View>
        </View>
      </View>
    </PanelInner>

    <PanelInner
      header={
        <View
          style={{
            ...FLEX_LR_CENTER,
          }}
        >
          <View style={{ flexGrow: 1 }}>
            <TextMD>Energy Management</TextMD>
          </View>
        </View>
      }

      style={{
        marginBottom: 24,
      }}
    >
      <EnergyConsumptionSummary
        scopeType={scopeType}
        scopeId={scopeId}
        controlEntity={["total", "vrv"]}
        chartOnly
      ></EnergyConsumptionSummary>
    </PanelInner>

    <PanelInner
      header={
        <View
          style={{
            ...FLEX_LR_CENTER,
          }}
        >
          <View style={{ flexGrow: 1 }}>
            <TextMD>Setting Relay Threshold</TextMD>
          </View>

          {!IS_MOBILE && RelaySelect}
        </View>
      }

      style={{
        marginBottom: 24,
      }}
    >
      {IS_MOBILE && <View style={{marginBottom: 12}}>{RelaySelect}</View>}

      <View style={{...(IS_MOBILE ? FLEX_COLUMN_CENTER_CENTER : FLEX_ROW_CENTER)}}>
        <View style={{flexBasis: IS_MOBILE ? "100%" : "50%", width: "100%"}}>
          {[0,1,2,3,4,5].map(monthi => (
            <View>
              <RelayInput 
                monthi={monthi}
                monthLabel={MONTH_LABEL[monthi]}
                data={selectedRelay?.relay_threshold[monthi] || [null, null, null]}
                onChange={(i, value) => updateRelayThreshold(monthi, i, value)}
              ></RelayInput>
            </View>
          ))}
        </View>

        <View style={{flexBasis: IS_MOBILE ? "100%" : "50%", width: "100%"}}>
          {[6,7,8,9,10,11].map(monthi => (
            <View>
              <RelayInput 
                monthi={monthi}
                monthLabel={MONTH_LABEL[monthi]}
                data={selectedRelay?.relay_threshold[monthi] || [null, null, null]}
                onChange={(i, value) => updateRelayThreshold(monthi, i, value)}
              ></RelayInput>
            </View>
          ))}
        </View>
      </View>

      <View style={{marginTop: 24}}>
        <CommonButton 
          variant="primary"
          onPress={async () => {
            await globalLoadingFn(() => api.put('/sensors/relay/' + selectedRelay.device_id, selectedRelay.relay_threshold));
            await reloadRelays(true);
          }}
        >บันทึก</CommonButton>
      </View>
    </PanelInner>

    <PanelInner
      header={
        <View
          style={{
            ...FLEX_LR_CENTER,
          }}
        >
          <View style={{ flexGrow: 1 }}>
            <TextMD>Demand</TextMD>
          </View>
        </View>
      }

      style={{
        marginBottom: 24,
      }}
    >
      <View>
        <View>
          <Plot
            data={energyData?.demand.data}
            layout={{
              height: 300,
              showlegend: true,
              margin: {
                l: 24,
                r: 24,
                t: 24,
                b: 24,
              },
            }}
          />
        </View>
      </View>
    </PanelInner>

    <PanelInner
      header={
        <View
          style={{
            ...FLEX_LR_CENTER,
          }}
        >
          <View style={{ flexGrow: 1 }}>
            <TextMD>Accumulated Power</TextMD>
          </View>
        </View>
      }

      style={{
        marginBottom: 24,
      }}
    >
      <View>
        <View>
          <Plot
            data={energyData?.acc.data}
            layout={{
              height: 300,
              showlegend: true,
              margin: {
                l: 24,
                r: 24,
                t: 24,
                b: 24,
              },
            }}
          />
        </View>
      </View>
    </PanelInner>

    <PanelInner
      header={
        <View
          style={{
            ...FLEX_LR_CENTER,
          }}
        >
          <View style={{ flexGrow: 1 }}>
            <TextMD>Table of PI(kW) in VRT mode</TextMD>
          </View>
        </View>
      }

      style={{
        marginBottom: 24,
      }}
    >
      <table className="td-border-bottom">
        <thead>
          <tr style={{lineHeight: "36px"}}>
            <th style={{textAlign: "left", paddingLeft: 10, backgroundColor: "#04009A", color: "white"}}>MODEL</th>
            <th style={{backgroundColor: "#04009A", color: "white"}}>100%</th>
            <th style={{backgroundColor: "#77ACF1", color: "black"}}>90%</th>
            <th style={{backgroundColor: "#77ACF1", color: "black"}}>80%</th>
            <th style={{backgroundColor: "#3EDBF0", color: "black"}}>70%</th>
            <th style={{backgroundColor: "#3EDBF0", color: "black"}}>60%</th>
            <th style={{backgroundColor: "#F0EBCC", color: "black"}}>50%</th>
            <th style={{backgroundColor: "#F0EBCC", color: "black"}}>40%</th>
          </tr>
        </thead>

        <tbody>
          {performanceTableIndex.map(index => (
            <tr key={index.generation}>
              <td style={{paddingLeft: 10}}>
                <div style={{}}>{index.generation}</div>
                <div style={{color: "gray"}}>Quantity: {index.quantity}</div>
              </td>

              {[3,4,5,6,7,8,9].map(i => (
                <td style={{textAlign: "center"}} key={i}>
                  {(performanceValue[index.generation][i] * index.quantity).toFixed(2)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </PanelInner>

    <PanelInner
      header={
        <View
          style={{
            ...FLEX_LR_CENTER,
          }}
        >
          <View style={{ flexGrow: 1 }}>
            <TextMD>Forecast energy consumption</TextMD>
          </View>
        </View>
      }

      style={{
        marginBottom: 24,
      }}
    >
      <View style={{...FLEX_ROW_CENTER, justifyContent: "flex-end", marginBottom: 8}}>
        <View style={{marginRight: 48}}>
          <TextWithCircleDot circleColor="#009C9D">PI (kW)</TextWithCircleDot>
        </View>

        <View>
          <TextWithCircleDot circleColor="#0C71E0">TC (BTUH)</TextWithCircleDot>
        </View>
      </View>

      <table className="td-border-bottom">
        <thead>
          <tr style={{lineHeight: "36px", backgroundColor: "darkblue", color: "white"}}>
            <th style={{textAlign: "left", paddingLeft: 10}}>MODEL</th>
            <th>QTY</th>
            <th style={{backgroundColor: "#009C9D"}}>100%</th>
            <th style={{backgroundColor: "#009C9D"}}>60%</th>
            <th style={{backgroundColor: "#009C9D"}}>50%</th>
            <th style={{backgroundColor: "#0C71E0"}}>100%</th>
            <th style={{backgroundColor: "#0C71E0"}}>60%</th>
            <th style={{backgroundColor: "#0C71E0"}}>50%</th>
          </tr>
        </thead>

        <tbody>
          {performanceTableIndex.map(index => (
            <tr key={index.generation}>
              <td style={{paddingLeft: 10}}>
                <div style={{}}>{index.generation}</div>
              </td>

              <td style={{textAlign: "center"}}>{index.quantity}</td>

              {[3,7,8].map(i => (
                <td style={{textAlign: "center"}} key={i}>
                  {(performanceValue[index.generation][i] * index.quantity).toFixed(2)}
                </td>
              ))}

              {[3,7,8].map(i => (
                <td style={{textAlign: "center"}} key={i}>
                  {(performanceValue[index.generation][i] * index.quantity * 3412.14).toFixed(0)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>

        <tfoot>
          <tr>
            <td colSpan={2} style={{textAlign: "center"}}>
              SUM of VRV
            </td>

            {[3,7,8].map(i => (
              <td style={{textAlign: "center", color: "#009C9D"}} key={i}>
                {sumOfVrv[i].toFixed(2)}
              </td>
            ))}

            {[3,7,8].map(i => (
              <td style={{textAlign: "center", color: "#0C71E0"}} key={i}>
                {(sumOfVrv[i] * 3412.14).toFixed(0)}
              </td>
            ))}
          </tr>

          <tr>
            <td colSpan={2} style={{textAlign: "center"}}>
            Other Load
            </td>

            {[3,7,8].map(i => (
              <td style={{textAlign: "center"}} key={i}>
                {((1 - energyPercentage) * sumOfVrv[i]).toFixed(2)}
              </td>
            ))}

            {[3,7,8].map(i => (
              <td style={{textAlign: "center"}} key={i}>
                <br/>
              </td>
            ))}
          </tr>
        </tfoot>
      </table>
    </PanelInner>
  </>;

  if (IS_MOBILE) {
    return (
      <MobileLayout title="Energy Management">
        {EnergyBody}
      </MobileLayout>
    )
  }

  return (
    <Layout webGrid>
      <LeftPane>
        <LeftPaneRootPanel active="energy"></LeftPaneRootPanel>
      </LeftPane>

      <CenterPane>
        <Panel>{EnergyBody}</Panel>
      </CenterPane>
    </Layout>
  );
}