import React from "react"
import ButtonWithIcon from "./ButtonWithIcon"
import { navigate } from "../../seperated/components/Router"

export default function ManagePlaceButton(props) {
  return (
    <ButtonWithIcon
      backgroundColor="#ff9191"
      icon={require("../../img/icons/home-white.png")}
      style={{ marginRight: 8, paddingHorizontal: 24 }}
      onPress={()=>navigate("control_air_map", {type: props.type, id: props.id, edit: 1, control_entity: props.controlEntity})}
    >
      จัดการข้อมูล
    </ButtonWithIcon>
  )
}