import React, { useState, useEffect } from "react"
import ControlAirButton from "./ControlAirButton"
import { View } from "../../../seperated/react-native"
import { IS_MOBILE } from "../../../seperated/screen-mode"
import { observer } from "mobx-react-lite"
import AirStore, { refreshAir } from "../../../stores/AirStore"
import { globalLoadingFn } from "../../../utils/loading"
import { api } from "../../../config"
import ScopeStore from "../../../stores/ScopeStore"

const ON_ICON = require("../../../img/controls/control/timer-active.png")
const OFF_ICON = require("../../../img/controls/control/timer.png")

function getTimerText(timer_schedule_due) {
  let seconds = Math.floor((timer_schedule_due.getTime() - Date.now()) / 1000);

  if (seconds < 0) return "00 : 00"

  let hours = Math.floor(seconds/3600);
  seconds %= 3600;
  let minutes = Math.floor(seconds/60);
  seconds %= 60;

  let hoursStr = hours.toString().padStart(2, '0')
  let minutesStr = minutes.toString().padStart(2, '0')
  let secondsStr = seconds.toString().padStart(2, '0')

  if (!hours) return minutesStr + " : " + secondsStr;

  return "ปิดใน " + hoursStr + " : " + minutesStr + " : " + secondsStr;
}

export default observer<any>(function ControlAirTimerButton(props) {
  let timer_schedule_due = AirStore.device_control_air.timer_schedule_due;
  // console.log(timer_schedule_due)

  if (timer_schedule_due && timer_schedule_due.getTime() <= Date.now()) {
    timer_schedule_due = undefined;
  }

  const [text, setText] = useState(timer_schedule_due ? getTimerText(timer_schedule_due) : "Off");
  const [refreshKey, setRefreshKey] = useState(Math.random())

  useEffect(() => {
    setTimeout(()=>{
      let timer_schedule_due = AirStore.device_control_air.timer_schedule_due;
      if (timer_schedule_due && timer_schedule_due.getTime() > Date.now()) setText(getTimerText(timer_schedule_due))
      else setText("TIMER OFF")
      setRefreshKey(Math.random());
    }, 1000)
  }, [timer_schedule_due, text, refreshKey])

  useEffect(() => {
    if (timer_schedule_due && timer_schedule_due.getTime() > Date.now()) setText(getTimerText(timer_schedule_due))
    else setText("TIMER OFF")
  }, [timer_schedule_due])

  return <>
    <View style={IS_MOBILE ? {flexBasis: "33.33%", paddingHorizontal: 4, marginBottom: 8} : {}}>
      <ControlAirButton icon={timer_schedule_due ? ON_ICON : OFF_ICON} text={text} onPress={async () => {
        let new_duration: any = prompt("กรุณาใส่จำนวนนาที (ใส่ 0 เพื่อยกเลิกการตั้งเวลา)");
        if (new_duration !== null) {
          new_duration = parseFloat(new_duration) * 60;
          await globalLoadingFn(() => api.post("/devices/device_control_air/timer?scope_type=" + ScopeStore.controlActiveType + "&scope_id=" + ScopeStore.controlActiveId, {
            new_duration
          }))
        }
        refreshAir();
      }}></ControlAirButton>
    </View>
  </>
})