import React from "react"
import QrReader from 'react-qr-reader'
import { View } from "../seperated/react-native"
import MobileLayout from "../components/MobileLayout";
import Dialog from "../components/dialog/Dialog";
import { FLEX_ROW_CENTER_CENTER } from "../components/constants/style";
import { acceptRoleQr } from "./RoleAcceptQr";
import { navigate } from "../seperated/components/Router";

let lastScan = null;

function handleError(err) {
  console.error(err);
  alert("เกิดข้อผิดพลาดในการ Scan โปรดตรวจสอบดูความพร้อมของกล้อง และตรวจสอบว่า browser ที่คุณใช้รองรับหรือไม่ (ใน ios รองรับแค่ safari เท่านั้น)")
}

function handleScan(data) {
  if (data) {
    if (data == lastScan) return;

    console.log(data);
    // alert(data);

    if (data.indexOf("/roleqr?s=") != -1) {
      acceptRoleQr(data.substr(data.indexOf("/roleqr?s=") + "/roleqr?s=".length))
    }

    lastScan = data;
  }
}

function ScanQrView() {
  return (
    <View style={{...FLEX_ROW_CENTER_CENTER}}>
      <View style={{maxWidth: 600, width: "100%"}}>
        <QrReader
          delay={300}
          onError={handleError}
          onScan={handleScan}
          facingMode="environment"
          style={{ width: '100%' }}
        />
      </View>
    </View>
  )
}

export function ScanQrPage() {
  return (
    <MobileLayout title="Scan QR Code">
      <ScanQrView></ScanQrView>
    </MobileLayout>
  )
}

export function ScanQrDialog({show}) {
  return (
    <Dialog title="Scan QR Code" hasClose show={show}>
      <ScanQrView></ScanQrView>
    </Dialog>
  )
}

export function navigateToScanQr() {
  if (window.NativeScan) {
    window.NativeScan.postMessage("");
    return;
  }

  navigate("scan_qr");
}