import React from "react";
import Select from "react-select";
import SelectMat from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { MOCK_OPTIONS } from "../../components/constants/options";

function generateTextStyle(justifyContent, fontSize = 20) {
  return {
    control(style) {
      return {
        display: "flex",
        flexDirection: "row",

        "&:hover": {
          cursor: "pointer",
        },
      };
    },
    valueContainer(style) {
      return {
        ...style,
        justifyContent: justifyContent,
        padding: 0,
        paddingRight: (fontSize / 16) * 4,
        //minWidth: 300
      };
    },
    indicatorSeparator(style) {
      return {
        display: "none",
      };
    },
    dropdownIndicator(style) {
      return {
        ...style,
        padding: 0,
      };
    },
    container(style) {
      return {
        ...style,
        zIndex: 100,
      };
    },
    menu(style) {
      return {
        ...style,
        minWidth: 250,
        ...(justifyContent == "flex-end" ? { right: 0 } : { left: 0 }),
      };
    },
    singleValue(style) {
      return { fontSize };
    },
  };
}

const VARIANT_STYLE = {
  text_right: generateTextStyle("flex-end", 20),
  text_left: generateTextStyle("flex-start", 20),
  text_right_md: generateTextStyle("flex-end", 24),
  text_left_md: generateTextStyle("flex-start", 24),
};

export default function SelectCustom({
  variant = "text_right",
  options = MOCK_OPTIONS,
  ...props
}) {
  if (variant == "material") {
    return (
      <SelectMat
        {...props}
        onChange={(event) => {
          props.onChange && props.onChange(event.target.value);
        }}
      >
        {options.map((option, i) => (
          <MenuItem value={option.value} key={i}>
            {option.label}
          </MenuItem>
        ))}
      </SelectMat>
    );
  }

  return (
    <Select
      options={options}
      isSearchable={false}
      styles={VARIANT_STYLE[variant]}
      {...props}
      value={props.value ? (typeof props.value !== "object" ? options.find(opt => opt.value == props.value) : props.value) : undefined}
    />
  );
}
