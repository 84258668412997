import React from "react"
import PanelInner from "../PanelInner"
import { FLEX_ROW_CENTER, FLEX_COLUMN, FLEX_COLUMN_CENTER_CENTER, TEXT_WRAP } from "../constants/style"
import { TextMD, TextSM, View, TouchableOpacity, Text, TextXL } from "../../seperated/react-native"
import ProgressCircle from "./ProgressCircle"
import { navigateSameParams } from "../../seperated/components/Router"
import { toFixed2 } from "../../utils/number"
import { IS_MOBILE } from "../../seperated/screen-mode"

const MOBILE_SCALE = IS_MOBILE ? 0.75 : 1

export default function EnergyUsageSummary(props) {
  return (
    <PanelInner>
    <View style={{ ...FLEX_ROW_CENTER }}>
      {!IS_MOBILE &&
        <View
          style={{
            ...FLEX_COLUMN,
            alignSelf: "flex-start",
            flexBasis: 200,
          }}
        >
          <View style={{ marginBottom: 28 }}>
            <TextMD>Energy Usage</TextMD>
          </View>
          <View
            style={{
              alignSelf: "flex-end",
              ...FLEX_COLUMN,
              alignItems: "flex-end",
            }}
          >
            <Text>Average Energy Usage</Text>
            <TextSM>Daily (kW)</TextSM>
          </View>
        </View>
      }

      <View style={{ flexGrow: 1, ...FLEX_ROW_CENTER }}>
        <View style={{ flexGrow: 1, ...FLEX_COLUMN_CENTER_CENTER }}>
        <TextSM style={{ marginBottom: 4 }}>Average</TextSM>
          <ProgressCircle subtext="kW" color="#49c5f1" progress={props.data?.daily_energy.whtotal || 0}>
            <TextXL style={{fontSize: 32 * MOBILE_SCALE}}>{toFixed2(props.data?.daily_energy.whtotal) || "---"}</TextXL>
          </ProgressCircle>
        </View>

        <View style={{ flexGrow: 1, ...FLEX_COLUMN_CENTER_CENTER }}>
          <TextSM style={{ marginBottom: 4 }}>Air Condition</TextSM>
          <ProgressCircle color="#49c5f1" progress={1.00}>
            <TextXL style={{fontSize: 32 * MOBILE_SCALE}}>100%</TextXL>
          </ProgressCircle>
          {/* <TextSM style={{ marginTop: 6, fontSize: 10 }}>
            Operation BTU per Total BTU
          </TextSM> */}
        </View>

        <View style={{ flexGrow: 1, ...FLEX_COLUMN_CENTER_CENTER }}>
          <TextSM style={{ marginBottom: 4 }}>Lighting</TextSM>
          <ProgressCircle color="#49c5f1" progress={0.00}>
            <TextXL style={{fontSize: 32 * MOBILE_SCALE}}>0%</TextXL>
          </ProgressCircle>
          {/* <TextSM style={{ marginTop: 6, fontSize: 10 }}>
            Operation BTU per Total BTU
          </TextSM> */}
        </View>
      </View>

      <View style={{ ...FLEX_COLUMN_CENTER_CENTER }}>
        <TouchableOpacity onPress={() => props.moreClick()}>
          <TextSM style={{ marginBottom: 8, textDecorationLine: "underline" }}>See more &gt;</TextSM>
        </TouchableOpacity>

        {/* <TextSM style={{ marginBottom: 4 }}>Max Usage</TextSM> */}

        <View
          style={{ marginVertical: 4, ...FLEX_COLUMN_CENTER_CENTER }}
        >
          <TextSM>Air Condition</TextSM>
          <Text style={{ fontWeight: "bold" }}>{toFixed2(props.data?.daily_energy.whtotal) || "---"} kW</Text>
        </View>

        <View
          style={{ marginVertical: 4, ...FLEX_COLUMN_CENTER_CENTER }}
        >
          <TextSM>Lighting</TextSM>
          <Text style={{ fontWeight: "bold" }}>0.00 kW</Text>
        </View>
      </View>
    </View>
  </PanelInner>
  )
}