import React from "react"
import { TouchableOpacity, View, TextMD, Image } from "../../../seperated/react-native"
import { SHADOW_LIGHT, BORDER_LIGHT, FLEX_ROW_CENTER_CENTER } from "../../constants/style"
import { ControlAirPowerButtonOnPress } from "./ControlAirPowerButton"
import AirStore from "../../../stores/AirStore"
import { observer } from "mobx-react-lite"

const ON_ICON = require("../../../img/controls/control/power-on.png")
const OFF_ICON = require("../../../img/controls/control/power-off.png")

export default observer(function ControlAirPowerPanel(props) {
  const on = AirStore.device_control_air.device_status == "1";
  
  return (
    <TouchableOpacity style={{...SHADOW_LIGHT, ...BORDER_LIGHT, padding: 12, ...FLEX_ROW_CENTER_CENTER}} onPress={()=>ControlAirPowerButtonOnPress(on)}>
      <View style={{marginRight: 12}}>
        <Image src={on ? ON_ICON : OFF_ICON} height="40"></Image>
      </View>

      <View style={{marginRight: 36}}>
        <TextMD>{on ? "On" : "Off"}</TextMD>
      </View>
    </TouchableOpacity>
  )
})