import { debounce } from "lodash";
import { FormState } from "../../../hooks/FormState";
import { DEV } from "../../../config";

export const inputOnChange = (props, model: FormState, value: any, ...args) => {
  if (props.errorFunc) {
    try {
      let err = props.errorFunc(value);
      if (err) {
        model.errorState.set(err);
      } else {
        model.errorState.set("");
      }
    } catch (err) {
      console.error(err);
      if (DEV) model.errorState.set(err.message || "Unknow error");
      else model.errorState.set("มีข้อผิดพลาดที่ไม่ทราบสาเหตุ")
    }
  } else {
    model.errorState.set("");
  }

  if (props.lock) value = props.lock(value);

  model.dataState.set(value);

  if (props.onChange) props.onChange(...args);
};