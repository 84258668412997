import * as React from "react";
import { Container } from "reactstrap";
import Header from "./header/Header";
import { SafeAreaView } from "../seperated/react-native";
import ContainerArea from "./ContainerArea";
import { WebDiv } from "../seperated/web-only";
import { IS_MOBILE } from "../seperated/screen-mode";
import MobileHeader from "./header/MobileHeader";

export function LeftPane(props) {
  return <WebDiv {...props} style={{
    height: "100%",
    ...(props.style || {})
  }}>{props.children}</WebDiv>
}

export function CenterPane({...props}) {
  return <WebDiv {...props} style={{
    height: "100%",
    ...(props.style || {})
  }}>{props.children}</WebDiv>
}

export default ({load=true, ...props}) => (
  <SafeAreaView style={{flex: 1, height: "100%"}}>
    {!IS_MOBILE ? <Header {...props} /> : (props.title && <MobileHeader title={props.title} />)}
    {props.fluid ? props.children :
      <ContainerArea>
        <WebDiv className={(props.webGrid ? "web-grid-container" : "") + " body-container"} style={{width: "100%", height: "100%"}}>
          {load && props.children}
        </WebDiv>
      </ContainerArea>
    }
  </SafeAreaView>
);
