import React, { useState, useEffect } from "react";

import Layout, { LeftPane, CenterPane } from "../components/Layout";
import { View, Text, Image, TextInputSolid, TouchableOpacity, TextMD } from "../seperated/react-native";
import TextFieldComplete from "../components/input/TextFieldComplete";
import { IS_MOBILE } from "../seperated/screen-mode";
import { SHADOW_LIGHT, BORDER_LIGHT, FLEX_ROW_CENTER, FLEX_LR_CENTER, FLEX_ROW_CENTER_CENTER, LINK_COLOR, LINK_COLOR_DARK } from "../components/constants/style";
import { Link, navigate, getRouteParam } from "../seperated/components/Router";
import LeftPaneControlPanel from "../components/LeftPane/LeftPaneControlPanel";
import Panel from "../components/Panel";
import PanelInnerGrid from "../components/PanelInnerGrid";
import PanelInner from "../components/PanelInner";
import LeftPaneControlAirPanel from "../components/LeftPane/LeftPaneControlAirPanel";
import { globalLoadingFn } from "../utils/loading";
import { api } from "../config";
import { toThaiDateString, date2time } from "../utils/date";
import AirStore from "../stores/AirStore";

export default function ErrorLogPage(props) {
  const [errors, setErrors] = useState<any[]>([]);

  const type = getRouteParam(props, "type") || "device"
  const id = parseInt(getRouteParam(props, "id") || "") || 0

  useEffect(() => {
    async function f() {
      let response = await globalLoadingFn(() => api.get("/places/error_log?scope_type=" + type +"&scope_id=" + id));
      setErrors(response.data);
    }
    f();
  }, [])

  return (
    <Layout webGrid>
      <LeftPane>
        <LeftPaneControlAirPanel active="error_log" data={AirStore.scope}></LeftPaneControlAirPanel>
      </LeftPane>

      <CenterPane>
        <Panel>
          <PanelInner
            header={
              <View style={{
                ...FLEX_LR_CENTER,
              }}>
                <View style={{flexGrow: 1}}>
                  <TextMD>รายงาน Error ที่กำลังเกิดขึ้น</TextMD>
                </View>
              </View>
            }

            style={{marginBottom: 28}}
          >
            <View style={{marginBottom: 8}}>
              <table>
                <thead>
                  <tr className="th-left">
                    <th>ตั้งแต่เวลา</th>
                    <th>ถึงเวลา</th>
                    <th>ตำแหน่ง</th>
                    <th>Error Code</th>
                  </tr>
                </thead>
  
                <tbody>
                  {errors.filter(c => c.is_error).map(error => error.device && (
                    <tr key={error.id}>
                      <td>{toThaiDateString(error.start_error_at)} {date2time(error.start_error_at)}</td>
                      <td>{toThaiDateString(error.last_error_at)} {date2time(error.last_error_at)}</td>
                      <td>{error.room?.name} - {error.device?.name}</td>
                      <td>{error.code}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </View>

            <View style={{...FLEX_ROW_CENTER_CENTER}}>
              <a href="https://www.daikin.co.th/service-error-code/" style={{textDecoration: "underline", color: LINK_COLOR_DARK}} target="blank">กดเพื่อดูความหมายของ Error Code (Daikin)</a>
            </View>
          </PanelInner>

          <PanelInner
            header={
              <View style={{
                ...FLEX_LR_CENTER,
              }}>
                <View style={{flexGrow: 1}}>
                  <TextMD>ประวัติการเกิด Error</TextMD>
                </View>
              </View>
            }
          >
            <View style={{marginBottom: 8}}>
              <table>
                <thead>
                  <tr className="th-left">
                    <th>ตั้งแต่เวลา</th>
                    <th>ถึงเวลา</th>
                    <th>ตำแหน่ง</th>
                    <th>Error Code</th>
                  </tr>
                </thead>
  
                <tbody>
                  {errors.filter(c => !c.is_error).map(error => error.device && (
                    <tr key={error.id}>
                      <td>{toThaiDateString(error.start_error_at)} {date2time(error.start_error_at)}</td>
                      <td>{toThaiDateString(error.last_error_at)} {date2time(error.last_error_at)}</td>
                      <td>{error.room?.name} - {error.device?.name}</td>
                      <td>{error.code}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </View>

            <View style={{...FLEX_ROW_CENTER_CENTER}}>
              <a href="https://www.daikin.co.th/service-error-code/" style={{textDecoration: "underline", color: LINK_COLOR_DARK}} target="blank">กดเพื่อดูความหมายของ Error Code (Daikin)</a>
            </View>
          </PanelInner>

        </Panel>
      </CenterPane>

    </Layout>
  )
}