import React from "react"
import GoogleLogin from 'react-google-login';
import { GOOGLE_CLIENT_ID, FACEBOOK_CLIENT_ID } from "../../config";
import { performLoginSocial } from "../../pages/Login";
import LoginWhiteButton from "./LoginWhiteButton";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'

export default function FacebookLoginButton({prefix = "เข้าสู่ระบบ"}) {
  return (
    <FacebookLogin
      appId={FACEBOOK_CLIENT_ID}
      callback={async (response) => {
        let token = response.accessToken;
        console.log(response, token)

        await performLoginSocial("facebook", token)
      }}
      render={renderProps => (
        <LoginWhiteButton label={prefix + "ด้วย Facebook"} icon={require('../../img/icons/facebook.png')} onPress={renderProps.onClick}></LoginWhiteButton>
      )}
    />
  )
}