import React, { useState, useEffect } from "react";

import Layout, { LeftPane, CenterPane } from "../../../components/Layout";
import { View, Text, Image, TextInputSolid, TouchableOpacity, TextMD, TextSM } from "../../../seperated/react-native";
import TextFieldComplete from "../../../components/input/TextFieldComplete";
import { IS_MOBILE } from "../../../seperated/screen-mode";
import { SHADOW_LIGHT, BORDER_LIGHT, FLEX_ROW_CENTER, FLEX_LR_CENTER, BORDER_LINE_BOTTOM_HARD, FLEX_ROW_CENTER_CENTER, FLEX_COLUMN } from "../../../components/constants/style";
import { Link, navigate, getRouteParam } from "../../../seperated/components/Router";
import LeftPaneControlPanel from "../../../components/LeftPane/LeftPaneControlPanel";
import Panel from "../../../components/Panel";
import PanelInnerGrid from "../../../components/PanelInnerGrid";
import PanelInner from "../../../components/PanelInner";
import ControlAirPanel from "../../../components/control/air/ControlAirPanel";
import LeftPaneControlAirPanel from "../../../components/LeftPane/LeftPaneControlAirPanel";
import CircularSlider from "../../../components/circular_slider/CircularSlider";
import ControlTempSlider from "../../../components/control/ControlTempSlider";
import MobileLayout from "../../../components/MobileLayout";
import ControlAirPowerPanel from "../../../components/control/air/ControlAirPowerPanel";
import { ControlAirModePanel } from "../../../components/control/air/ControlAirModeButton";
import AirStore, { refreshAir } from "../../../stores/AirStore";
import ScopeStore from "../../../stores/ScopeStore";
import { observer } from "mobx-react-lite";
import { InviteDialog } from "../../Membership/InvitePages";
import IconInline from "../../../components/icon/IconInline";
import ControlAirStoreModeContext from "../../../components/control/air/ControlAirStoreModeContext";

export default observer(function ControlAirManual(props) {
  const type = getRouteParam(props, "type") || "device"
  const id = parseInt(getRouteParam(props, "id") || "") || 0

  const [showInviteDialog, setShowInviteDialog] = useState(false);

  useEffect(()=>{
    ScopeStore.setActivePlace("control", type, id);
    refreshAir()
    // setInterval(() => refreshAir({quiet: true}), 5000);
  }, []);

  if (IS_MOBILE) {
    return (
      <ControlAirStoreModeContext.Provider value="air_manual">
        <MobileLayout title="แผงควบคุมอุปกรณ์">
          {/* <View style={{...FLEX_ROW_CENTER_CENTER, marginBottom: 8}}>
            <TextSM style={{color: "#888"}}>Last update at 23:40</TextSM>
          </View> */}

          <View style={{...FLEX_LR_CENTER, marginBottom: 12, paddingHorizontal: 12}}>
            <Text>{AirStore.scope.name}</Text>
            <Text>{AirStore.scope.total_count || 1} เครื่อง</Text>
          </View>

          <View style={{marginBottom: 16}}>
            <ControlAirPowerPanel></ControlAirPowerPanel>
          </View>

          <View style={{marginBottom: 16, paddingHorizontal: 28}}>
            <ControlAirModePanel></ControlAirModePanel>
          </View>

          <View style={{...FLEX_ROW_CENTER_CENTER, marginBottom: 24}}>
            <ControlTempSlider defaultTemp={AirStore.device_control_air.device_temp} refreshKey={AirStore.device_control_air.updated_at}></ControlTempSlider>
          </View>

          <View style={{marginBottom: 12}}>
            <ControlAirPanel></ControlAirPanel>
          </View>

          <TouchableOpacity style={{...FLEX_ROW_CENTER_CENTER}} onPress={() => navigate("invite", {type, id})}>
            <Text style={{textDecorationLine: "underline"}}>ขอสิทธิ์​ / ทำ OT / ชวนเพื่อน</Text>
          </TouchableOpacity>
        </MobileLayout>
      </ControlAirStoreModeContext.Provider>
    )
  }

  return (
    <Layout webGrid>
      <LeftPane>
        <LeftPaneControlAirPanel {...props} active="control_air_manual" data={AirStore.scope}></LeftPaneControlAirPanel>
      </LeftPane>

      <CenterPane>
        <Panel>
          <PanelInnerGrid>

            <PanelInner
              header={
                <View style={{
                  ...FLEX_LR_CENTER,
                }}>
                  <View style={{flexGrow: 1}}>
                    <TextMD>แผงควบคุม</TextMD>
                  </View>

                  <View>
                    <IconInline src={require("../../../img/icons/share.png")} size={24} onPress={()=>setShowInviteDialog(true)} />
                  </View>
                </View>
              }
            >
              
              <View style={{...FLEX_COLUMN, justifyContent: "space-between", flexGrow: 1}}>
                <View>
                  <View style={{...FLEX_LR_CENTER, ...BORDER_LINE_BOTTOM_HARD, paddingBottom: 16}}>
                    <TextMD>{AirStore.scope.name}</TextMD>
                    <TextMD>{AirStore.scope.total_count || 1} เครื่อง</TextMD>
                  </View>
    
                  <View style={{...FLEX_LR_CENTER, paddingTop: 16}}>
                    <View>
                      <View style={{...FLEX_ROW_CENTER, marginBottom: 8}}>
                        <View style={{flexBasis: 100}}>
                          <Text style={{color: "#393939"}}>Temp</Text>
                        </View>
                        <View style={{flexBasis: 32}}>
                          <Text style={{fontWeight: "bold"}}>---</Text>
                        </View>
                        <View>
                          <Text style={{color: "#393939"}}>c</Text>
                        </View>
                      </View>
    
                      <View style={{...FLEX_ROW_CENTER}}>
                        <View style={{flexBasis: 100}}>
                          <Text style={{color: "#393939"}}>Humidity</Text>
                        </View>
                        <View style={{flexBasis: 32}}>
                          <Text style={{fontWeight: "bold"}}>---</Text>
                        </View>
                        <View>
                          <Text style={{color: "#393939"}}>%</Text>
                        </View>
                      </View>
                    </View>
    
                    <View>
                      <View style={{...FLEX_ROW_CENTER, marginBottom: 8, width: 140}}>
                        <View style={{flexBasis: 65}}>
                          <Text style={{color: "#393939"}}>PM2.5</Text>
                        </View>
                        <View style={{flexBasis: 60}}>
                          <Text style={{fontWeight: "bold"}}>---</Text>
                        </View>
                        <View>
                          <Text style={{color: "#393939"}}></Text>
                        </View>
                      </View>
    
                      <View style={{...FLEX_ROW_CENTER}}>
                        <View style={{flexBasis: 65}}>
                          <Text style={{color: "#393939"}}>CO2</Text>
                        </View>
                        <View style={{flexBasis: 60}}>
                          <Text style={{fontWeight: "bold"}}>---</Text>
                        </View>
                        <View>
                          <Text style={{color: "#393939"}}>kg</Text>
                        </View>
                      </View>
                    </View>
                  </View>
                
                </View>


                <View style={{...FLEX_ROW_CENTER_CENTER}}>
                  <ControlTempSlider defaultTemp={AirStore.device_control_air.device_temp} refreshKey={AirStore.device_control_air.updated_at}></ControlTempSlider>
                </View>


                <View>
                  <View>
                    <Text>{AirStore.device_control_air.priority >= 100 ? "First Priority" : ""}</Text>
                  </View>
                </View>
              </View>

            </PanelInner>

            <PanelInner
              header={
                <View style={{
                  ...FLEX_LR_CENTER,
                }}>
                  <View style={{flexGrow: 1}}>
                    <TextMD>รายการ</TextMD>
                  </View>
                </View>
              }
            >
              <ControlAirPanel></ControlAirPanel>
            </PanelInner>

          </PanelInnerGrid>
        </Panel>
      </CenterPane>

      <InviteDialog show={showInviteDialog} onClose={()=>setShowInviteDialog(false)} place={AirStore.scope}></InviteDialog>

    </Layout>
  )
})