import React from "react"
import { TouchableOpacity, View, Text, Image } from "../../seperated/react-native"
import { SHADOW_LIGHT, BORDER_LIGHT } from "../constants/style"

export default function LoginWhiteButton({label, icon, ...rest}) {
  return (
    <TouchableOpacity {...rest}>
      <View style={{padding: 12, paddingLeft: 36, display: "flex", flexDirection: "row", alignItems: "center", ...SHADOW_LIGHT, ...BORDER_LIGHT}}>
        <View style={{width: 28, flexBasis: 36, marginRight: 48}}>
          <Image src={icon} style={{height: 28}} resizeMode="contain" />
        </View>
        <Text style={{}}>{label}</Text>
      </View>
    </TouchableOpacity>
  )
}