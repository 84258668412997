import * as React from "react";

import { FormState, useFormStateAgain } from "../../hooks/FormState";
import classNames from "classnames";
import { DEV } from "../../config";
import { debounce } from "lodash";
import { inputOnChange } from "./core_logic/onChange";
import {
  TextInput,
  Image,
  TouchableOpacity,
} from "../../seperated/react-native";
import InputCompleteBase from "./core_logic/InputCompleteBase";

import { TextField } from "@material-ui/core";
import IconInline from "../icon/IconInline";
import Checkbox from "./Checkbox";

export default function CheckboxComplete({ variant = "circle", ...props }) {
  let model = useFormStateAgain(props.model, props.defaultValue || "");

  return (
    <InputCompleteBase {...props} variant="line-hori-reverse" model={model}>
      <Checkbox
        active={model.dataState.get()}
        onChange={(val) => inputOnChange(props, model, val, val)}
        {...props}
        style={{ marginRight: 12, ...(props.style || {}) }}
      />
    </InputCompleteBase>
  );
}
