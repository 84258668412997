import React, { useState } from "react"
import { SafeAreaView, View, TextL } from "../seperated/react-native"
import MobileHeader from "./header/MobileHeader"
import { FLEX_COLUMN, TEXT_WRAP_CENTER } from "./constants/style"
import { WebDiv } from "../seperated/web-only"

export default function MobileLayout({title, nopadding=false, load=true, ...props}) {

  return (
    <SafeAreaView style={{flex: 1, height: "100%"}}>
      <View style={{...FLEX_COLUMN}}>
        <View style={{flexGrow: 1}}>
          <MobileHeader title={title} {...props}>
            {props.headerRight}
          </MobileHeader>

          <View style={{flex: 1, padding: nopadding ? 0 : 16, paddingTop: nopadding ? 0 : 20}}>
            <WebDiv className="body-container">{load && props.children}</WebDiv>
          </View>
        </View>

        {props.bottom && <View style={{padding: nopadding ? 0 : 16}}>{props.bottom}</View>}
      </View>

    </SafeAreaView>
  )
}