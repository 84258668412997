import React, { useState, useEffect } from "react";
import Dialog from "./dialog/Dialog";
import {
  FlatList,
  View,
  Text,
  TouchableOpacity,
} from "../seperated/react-native";
import { api } from "../config";
import { globalLoadingFn } from "../utils/loading";
import {
  BORDER_LINE_BOTTOM,
  FLEX_ROW_CENTER,
  TEXT_WRAP,
  BORDER_LIGHT,
} from "./constants/style";
import ScopeStore from "../stores/ScopeStore";
import AvatarImage from "./avatar/AvatarImage";

export default function MyBuildingsDialog({ show, onClose, onAdd }) {
  const [buildings, setBuildings] = useState<any>([]);

  useEffect(() => {
    async function f() {
      setBuildings((await globalLoadingFn(() => api.get("/buildings"))).data);
    }
    f();
  }, []);

  return (
    <Dialog title="อาคารของฉัน" show={show} hasClose onClose={onClose}>
      <FlatList
        data={buildings}
        renderItem={({ item }) => (
          <TouchableOpacity
            style={{
              ...BORDER_LINE_BOTTOM,
              padding: 12,
              paddingHorizontal: 24,
            }}
            onPress={() => {
              ScopeStore.setActiveBuilding(item.id);
              onClose();
            }}
          >
            <View style={{ ...FLEX_ROW_CENTER }}>
              <View style={{ flexBasis: 80 }}>
                <AvatarImage src={item.avatar} size={54} />
              </View>
              <View style={{ flexGrow: 1, flexShrink: 1 }}>
                <Text style={{ ...TEXT_WRAP }}>{item.name}</Text>
              </View>
              <View
                style={{
                  flexBasis: 24,
                  height: 24,
                  borderWidth: 3,
                  borderColor: "#dedede",
                  borderRadius: 10000,
                  backgroundColor:
                    ScopeStore.activeBuilding == item.id ? "#49c5f1" : "white",
                }}
              ></View>
            </View>
          </TouchableOpacity>
        )}
        keyExtractor={(item) => item.id}
      ></FlatList>

      <TouchableOpacity
        style={{ ...BORDER_LINE_BOTTOM, padding: 24 }}
        onPress={() => {
          onAdd();
        }}
      >
        <Text>+ เพิ่มอาคาร</Text>
      </TouchableOpacity>
    </Dialog>
  );
}
