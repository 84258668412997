import React, { useState, useEffect } from "react";

import Layout, { LeftPane, CenterPane } from "../../components/Layout";
import {
  View,
  Text,
  Image,
  TextInputSolid,
  TouchableOpacity,
  TextMD,
  TextL,
} from "../../seperated/react-native";
import TextFieldComplete from "../../components/input/TextFieldComplete";
import { IS_MOBILE } from "../../seperated/screen-mode";
import {
  SHADOW_LIGHT,
  BORDER_LIGHT,
  FLEX_ROW_CENTER,
  FLEX_LR_CENTER,
  BORDER_LINE_BOTTOM,
} from "../../components/constants/style";
import { Link, navigate, getRouteParam } from "../../seperated/components/Router";
import LeftPaneControlPanel from "../../components/LeftPane/LeftPaneControlPanel";
import Panel from "../../components/Panel";
import PanelInnerGrid from "../../components/PanelInnerGrid";
import PanelInner from "../../components/PanelInner";
import Plot from "react-plotly.js";
import TextWithCircleDot from "../../components/text/TextWithCircleDot";
import LeftPaneRootPanel from "../../components/LeftPane/LeftPaneRootPanel";
import EnergyChart, { activeDateText } from "./EnergyChart";
import { toDateString, date2time, toThaiDateString } from "../../utils/date";
import { toFixed2 } from "../../utils/number";
import MobileLayout from "../../components/MobileLayout";

const CHART_NAME = {
  temperature_humidity: "Temperature & Humidity",
  device_operation: "Device Operation",
  energy_consumption: "Energy Consumption",
  performance_value: "Performance Value",
  pv_power: "PV Power",
  solarcell_power: "Solarcell Power",
  energy_saving: "Energy Saving",
  energy_usage: "Energy Usage",
}

function lineName2Unit(name, chartType) {
  let unitFromChart = '';

  switch (chartType) {
    case 'performance_value':
      unitFromChart = '%';
      break;
      
    case 'energy_consumption':
    case 'pv_power':
    case 'solarcell_power':
    case 'energy_usage':
      unitFromChart = 'kW';
      break;

    case 'energy_saving':
      unitFromChart = '฿'
  }

  switch (name) {
    case 'Temperature':
      return '℃';

    case 'Humidity':
      return '%';

    default: return unitFromChart;
  }
}

export default function EnergyChartPage(props) {
  const activeIntervalMode = getRouteParam(props, "interval") || "d";
  const chartType = getRouteParam(props, "chart_type") || "";
  const isBruno = parseInt(getRouteParam(props, "bruno") || "0");

  const scopeType = getRouteParam(props, "type");
  const scopeId = getRouteParam(props, "id");

  const [rightDate, setRightDate] = useState(toThaiDateString(new Date()));
  // const [rightTime, setRightTime] = useState(date2time(new Date(), true));
  const [rightTime, setRightTime] = useState("เฉลี่ย");
  const [chartData, setChartData] = useState([])
  const [rightData, setRightData] = useState<any[]>([])

  function onDataChange({activeIntervalMode, activeDate, chartData}) {
    setRightDate(activeDateText(activeDate, activeIntervalMode));

    setChartData(chartData);

    let rightData: any[] = [];

    // let total = 0;

    for (let line of chartData.data) {
      let value = line.y.length > 0 ? line.y.reduce((acc, curr) => acc + curr, 0) / line.y.length : 0;
      // let value = line.y.length > 0 ? line.y.reduce((acc, curr) => Math.max(acc, curr), 0) : 0;
      // let value = line.y.length > 0 ? line.y[line.y.length - 1] : 0;
      // total += value;

      rightData.push({
        name: line.name,
        value,
      })
    }

    // if (chartType != 'temperature_humidity' && chartType != 'energy_saving') {
    //   rightData.unshift({
    //     name: "Total",
    //     value: total,
    //   })    
    // }

    setRightData(rightData);
  }

  const ChartBody = <>
    <PanelInner
      header={
        <View
          style={{
            ...FLEX_LR_CENTER,
          }}
        >
          <View style={{ flexGrow: 1 }}>
            <TextMD>{CHART_NAME[chartType]}</TextMD>
          </View>
        </View>
      }

      style={{
        marginBottom: IS_MOBILE ? 24 : 0,
      }}
    >
      <EnergyChart 
        chartType={chartType} 
        activeIntervalMode={activeIntervalMode}
        scopeType={scopeType}
        scopeId={scopeId}
        onDataChange={onDataChange}
      ></EnergyChart>
    </PanelInner>

    <PanelInner
      // header={
      //   <View
      //     style={{
      //       ...FLEX_LR_CENTER,
      //     }}
      //   >
      //     <View style={{ flexGrow: 1 }}>
      //       <TextMD></TextMD>
      //     </View>
      //   </View>
      // }
    >
      <View
        style={{
          ...FLEX_LR_CENTER,
          ...BORDER_LINE_BOTTOM,
          paddingBottom: 12,
          marginBottom: 8,
        }}
      >
        <TextMD>{rightDate}</TextMD>
        <TextMD style={{ color: "#49c5f1" }}>{rightTime}</TextMD>
      </View>

      {rightData.map(data => (
        <View style={{ ...FLEX_LR_CENTER, marginVertical: 4 }}>
          <View>
            <TextWithCircleDot circleColor="#49c5f1">
              {data.name}
            </TextWithCircleDot>
          </View>

          <View
            style={{ ...FLEX_ROW_CENTER, justifyContent: "flex-end" }}
          >
            <Text style={{ fontWeight: "bold", marginRight: 16 }}>
              {toFixed2(data.value)}
            </Text>
            <Text>{lineName2Unit(data.name, chartType)}</Text>
          </View>
        </View>
      ))}
    </PanelInner>
  </>

  if (isBruno) {
    return (
      <div className="bruno">
        {ChartBody}
      </div>
    )
  }

  if (IS_MOBILE) {
    return (
      <MobileLayout title={CHART_NAME[chartType]}>
        {ChartBody}
      </MobileLayout>
    )
  }

  return (
    <Layout webGrid>
      <LeftPane>
        <LeftPaneRootPanel active="energy"></LeftPaneRootPanel>
      </LeftPane>

      <CenterPane>
        <Panel>
          <PanelInnerGrid>
            {ChartBody}
          </PanelInnerGrid>
        </Panel>
      </CenterPane>
    </Layout>
  );
}
