import { alert_error, alert_success, alertErrorComplete } from "../seperated/toast";
import { globalLoading } from "../seperated/loading";

export async function globalLoadingFn(fn, success_message?: string, {performBack404 = false} = {}) {
  try {
    globalLoading(true)

    let response = await fn();

    if (success_message) {
      alert_success(success_message)
    }

    return response
  } catch (err) {
    alertErrorComplete(err, {performBack404})

    throw err;
  } finally {
    globalLoading(false)
  }
}