import React from "react"
import DropdownMenuIcon from "../DropdownMenuIcon"
import AddIcon from '@material-ui/icons/Add';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MenuItem from "@material-ui/core/MenuItem";
import { ListItemText } from "@material-ui/core";
import { navigate } from "../../seperated/components/Router";
import { navigateToScanQr } from "../../pages/ScanQr";

export default function ControlAddHeaderMenu(props) {
  return (
    <DropdownMenuIcon iconChildren={<MoreVertIcon></MoreVertIcon>}>
      <MenuItem onClick={()=>navigate("control_air_add_building_type")}>
        <ListItemText>เพิ่มอุปกรณ์</ListItemText>
      </MenuItem>

      <MenuItem onClick={()=>navigateToScanQr()}>
        <ListItemText>Scan QRcode</ListItemText>
      </MenuItem>

      {/* <MenuItem onClick={()=>navigate("control_air_add_building_type")}>
        <ListItemText>ชวนเพื่อน</ListItemText>
      </MenuItem> */}
    </DropdownMenuIcon>
  )
}