import React, { useState, useEffect } from "react";

import Layout, { LeftPane, CenterPane } from "../components/Layout";
import {
  View,
  Text,
  Image,
  TextInputSolid,
  TouchableOpacity,
  TextL,
  TextMD,
  TextXL,
  TextSM,
} from "../seperated/react-native";
import TextFieldComplete from "../components/input/TextFieldComplete";
import { IS_MOBILE } from "../seperated/screen-mode";
import { SHADOW_LIGHT, BORDER_LIGHT, FLEX_ROW_CENTER, FLEX_COLUMN_CENTER_CENTER, BORDER_LINE_RIGHT_HARD, BORDER_LINE_BOTTOM, BORDER_LINE_BOTTOM_HARD, FLEX_ROW_CENTER_CENTER, FLEX_LR_CENTER, TEXT_WRAP_CENTER, TEXT_WRAP } from "../components/constants/style";
import { Link, navigate } from "../seperated/components/Router";
import Panel from "../components/Panel";
import LeftPaneRootPanel from "../components/LeftPane/LeftPaneRootPanel";
import PanelInner from "../components/PanelInner";
import PanelInnerGrid from "../components/PanelInnerGrid";
import LinkText from "../components/text/LinkText";
import Select from "react-select";
import SelectCustom from "../seperated/components/SelectCustom";
import DownArrowIcon from "../components/icon/DownArrowIcon";
import TextWithCircleDot from "../components/text/TextWithCircleDot";
import IconSelectComplete from "../components/input/IconSelectComplete";
import EnergyCircle from "../components/energy/EnergyCircle";
import Weather from "../components/energy/Weather";
import MobileLayout from "../components/MobileLayout";
import RightArrowIcon from "../components/icon/RightArrowIcon";
import UpArrowIcon from "../components/icon/UpArrowIcon";
import { observer } from "mobx-react-lite";
import { globalLoadingFn } from "../utils/loading";
import { api } from "../config";
import ScopeStore from "../stores/ScopeStore";
import MyBuildingsDialog from "../components/MyBuildingsDialog";
import ControlAirAddBuilding from "./Control/Air/Add/ControlAirAddBuilding";
import AvatarImage from "../components/avatar/AvatarImage";
import SensorTable from "../components/energy/SensorTable";
import useActiveBuilding from "../hooks/ActiveBuilding";
import CommonButton from "../components/buttons/CommonButton";
import ProgressCircle from "../components/energy/ProgressCircle";
import { toFixed2 } from "../utils/number";
import TextWithIcon from "../components/text/TextWithIcon";
import { useScheduleCount } from "../hooks/ScheduleCount";

const MOBILE_SCALE = IS_MOBILE ? 0.6 : 1.2

function DashboardInner1(props) {
  const energyData = props.energyData;
  const electricity_formula = window.eval((energyData?.electricity_formula.code || "(x) => x"))
  const schedulePendingCount = props.schedulePendingCount;

  return (
    <View style={{
      display: "flex",
      flexDirection: "column",
      //justifyContent: "space-around",
      flexGrow: 1
    }}>

      {/* Online Error Offline Total */}
      <View style={{display: "flex", flexDirection: "row", paddingBottom: 36, marginTop: 16, ...BORDER_LINE_BOTTOM_HARD}}>

        <View style={{flexGrow: 1.75, ...FLEX_COLUMN_CENTER_CENTER}}>
          <View>
            <TextL style={{color: "#0cbf4a"}}>Online</TextL>
          </View>
          <View>
            <TextXL style={{fontSize: 78 * MOBILE_SCALE, lineHeight: 96 * MOBILE_SCALE, color: "#716c6c"}}>{props.building?.online_count}</TextXL>
          </View>
        </View>

        <View style={{flexGrow: 3}}>

          {/* ERROR ONLINE TOTAL */}
          <View style={{display: "flex", flexDirection: "row"}}>
            <View style={{flexGrow: 1, ...FLEX_COLUMN_CENTER_CENTER, ...BORDER_LINE_RIGHT_HARD}}>
              <View>
                <Text style={{color: "#c42c2c"}}>Error</Text>
              </View>
              <View>
                <Text style={{fontSize: 48 * MOBILE_SCALE, lineHeight: 64 * MOBILE_SCALE, color: "#c42c2c"}}>{props.building?.error_count}</Text>
              </View>

            </View>
    
            <View style={{flexGrow: 1, ...FLEX_COLUMN_CENTER_CENTER, ...BORDER_LINE_RIGHT_HARD}}>
              <View>
                <Text style={{color: "#b1b1b1"}}>Offline</Text>
              </View>
              <View>
                <Text style={{fontSize: 48 * MOBILE_SCALE, lineHeight: 64 * MOBILE_SCALE, color: "#b1b1b1"}}>{props.building?.total_count - props.building?.online_count}</Text>
              </View>
            </View>
    
            <View style={{flexGrow: 1, ...FLEX_COLUMN_CENTER_CENTER}}>
              <View>
                <Text style={{color: "#95a9ff"}}>Total</Text>
              </View>
              <View>
                <Text style={{fontSize: 48 * MOBILE_SCALE, lineHeight: 64 * MOBILE_SCALE, color: "#95a9ff"}}>{props.building?.total_count}</Text>
              </View>
            </View>
          </View>

          {/* MINI DEVICE STATUS */}
          <View style={{display: "flex", flexDirection: "row", justifyContent: "space-around", marginTop: 8}}>
            <View>
              <TextWithIcon icon={require('../img/SVCH/S/active/blue.png')} style={{fontSize: IS_MOBILE ? 12 : 16}}>
                {props.building?.S_online_count}/{props.building?.S_total_count}
              </TextWithIcon>
            </View>

            <View style={{paddingLeft: 0}}>
              <TextWithIcon icon={require('../img/SVCH/V/active/blue.png')} style={{fontSize: IS_MOBILE ? 12 : 16}}>
                {props.building?.V_online_count}/{props.building?.V_total_count}
              </TextWithIcon>
            </View>

            <View>
              <TextWithIcon icon={require('../img/SVCH/C/active/blue.png')} style={{fontSize: IS_MOBILE ? 12 : 16}}>
                {props.building?.C_online_count}/{props.building?.C_total_count}
              </TextWithIcon>
            </View>
          </View>
        
          {!IS_MOBILE && schedulePendingCount > 0 ?
            <View style={{marginTop: 16, ...FLEX_COLUMN_CENTER_CENTER}}>
              <View>
                <Text style={{color: "red"}}>มี {schedulePendingCount} รายการคำขอยังไม่อนุมัติ</Text>
              </View>
            </View> : <></>
          }
        </View>

      </View>

      {/* Energy used Watts */}
      <View style={{marginTop: 48 * MOBILE_SCALE}}>
        <View style={{display: "flex", flexDirection: "row", justifyContent: "space-between", paddingHorizontal: IS_MOBILE ? 4 : 24}}>
          <View>
            <TextL>Today</TextL>
            <TextXL style={{fontSize: IS_MOBILE ? 28 : 36, marginTop: 12}}>{toFixed2(energyData?.daily_energy.whtotal) || "---"} kW</TextXL>
            <TextL style={{color: "#9b9a9a", marginTop: 12}}>{toFixed2(electricity_formula(energyData?.daily_energy.whtotal || 0))} ฿ /hr</TextL>
          </View>

          <View>
            <ProgressCircle progress={0.00} radius={100}>
              <View style={{...FLEX_COLUMN_CENTER_CENTER}}>
                <TextXL style={{fontSize: 48 * MOBILE_SCALE}}>{toFixed2(energyData?.daily_energy.whtotal) || "---"}</TextXL>
                <TextL style={{}}>K.W/h</TextL>
              </View>
            </ProgressCircle>
          </View>
        </View>
      </View>

      {/* Just For Test */}
      {/* <View>
        <SelectCustom variant="material"></SelectCustom>
        <IconSelectComplete options={[...ICON_BUILDING_BASE]}></IconSelectComplete>
      </View> */}

    </View>

  )
}


export default observer(function Dashboard(props) {
  const [dashboardShow, setDashboardShow] = useState(true)
  const [buildingsModalShow, setBuildingsModalShow] = useState(false)
  const [addBuildingModalShow, setAddBuildingModalShow] = useState(false)

  const [building, setBuilding] = useActiveBuilding();
  const [energyData, setEnergyData] = useState<any>();

  const {scheduleActiveCount, schedulePendingCount} = useScheduleCount("building", ScopeStore.activeBuilding);

  useEffect(() => {
    async function f() {
      try {
        const {data} = await globalLoadingFn(() => api.get('/sensors/energy_page_detail?scope_type=building&scope_id=' + ScopeStore.activeBuilding));
        setEnergyData(data);
        console.log(data)
      } catch (err) {
        console.error(err)
      }
    }
    f();
  }, [ScopeStore.activeBuilding])

  const ModalRender = <>
    <MyBuildingsDialog 
      show={buildingsModalShow} 
      onClose={()=>setBuildingsModalShow(false)}
      onAdd={()=>{
        if (IS_MOBILE) {
          navigate("control_air_add_building_top", {add_building_only: 1})
        } else {
          setAddBuildingModalShow(true);
        }
      }}
    ></MyBuildingsDialog>

    <ControlAirAddBuilding addBuildingOnly={true} show={addBuildingModalShow} onClose={()=>setAddBuildingModalShow(false)}></ControlAirAddBuilding>
  </>

  if (IS_MOBILE) {
    return (
      <MobileLayout title="AirIn1" load={(building && energyData) || false} menu back={false}>
        <TouchableOpacity style={{...FLEX_ROW_CENTER_CENTER, marginBottom: 16}} onPress={()=>setBuildingsModalShow(true)}>
          <TextMD style={{marginRight: 12, ...TEXT_WRAP_CENTER}}>{building?.name}</TextMD>
          <DownArrowIcon></DownArrowIcon>
        </TouchableOpacity>

        <View style={{paddingHorizontal: 16}}>
          <View style={{...FLEX_ROW_CENTER}}>
            <View>
              <Image src={require("../img/graphics/building.png")} width="175" style={{marginBottom: 28}}></Image>
            </View>

            <View style={{flexGrow: 1, ...FLEX_COLUMN_CENTER_CENTER}}>
              {energyData && <Weather data={energyData}></Weather>}
            </View>
          </View>

          <View style={{...FLEX_ROW_CENTER, justifyContent: "space-between", marginBottom: 24}}>
            {/* <TouchableOpacity style={{...FLEX_COLUMN_CENTER_CENTER}}>
              <Image src={require("../img/icons/mobiledash-air.png")} height="36" style={{marginBottom: 12}} />
              <TextMD>ซื้อแอร์</TextMD>
            </TouchableOpacity>

            <TouchableOpacity style={{...FLEX_COLUMN_CENTER_CENTER}}>
              <Image src={require("../img/icons/mobiledash-maintenance.png")} height="36" style={{marginBottom: 12}} />
              <TextMD>ล้าง/ซ่อม</TextMD>
            </TouchableOpacity>

            <TouchableOpacity style={{...FLEX_COLUMN_CENTER_CENTER}}>
              <Image src={require("../img/icons/mobiledash-tool.png")} height="36" style={{marginBottom: 12}} />
              <TextMD>อุปกรณ์</TextMD>
            </TouchableOpacity> */}

            <TouchableOpacity style={{...FLEX_COLUMN_CENTER_CENTER}} onPress={()=>navigate("control_landing")}>
              <Image src={require("../img/icons/mobiledash-power.png")} height="36" style={{marginBottom: 12}} />
              <TextMD>ควบคุม</TextMD>
            </TouchableOpacity>
          </View>
        </View>

        {schedulePendingCount > 0 ?
          <View style={{marginBottom: 16, ...FLEX_COLUMN_CENTER_CENTER}}>
            <View>
              <Text style={{color: "red"}}>มี {schedulePendingCount} รายการคำขอยังไม่อนุมัติ</Text>
            </View>
          </View> : <></>
        }

        <View style={{...SHADOW_LIGHT, ...BORDER_LIGHT, padding: 16}}>
          <TouchableOpacity style={{...FLEX_LR_CENTER}} onPress={()=>setDashboardShow(!dashboardShow)}>
            <TextL>Dashboard</TextL>
            {dashboardShow ? <UpArrowIcon></UpArrowIcon> : <RightArrowIcon></RightArrowIcon>}
          </TouchableOpacity>

          {dashboardShow && energyData &&
            <DashboardInner1 {...props} building={building} energyData={energyData}></DashboardInner1>
          }
        </View>

        {/* <View>
          <CommonButton onPress={() => window.alert("This is alert")}>Alert</CommonButton>
          <CommonButton onPress={() => window.confirm("Confirm something")}>Confirm</CommonButton>
        </View> */}

        {ModalRender}
      </MobileLayout>
    )
  }

  return (
    <Layout webGrid load={building || false}>
      <LeftPane>
        <LeftPaneRootPanel active="dashboard"></LeftPaneRootPanel>
      </LeftPane>

      <CenterPane>
        <Panel>
          <PanelInnerGrid>
            <PanelInner
              header={
                <View style={{
                  ...FLEX_ROW_CENTER,
                }}>
                  <TextMD style={{flexGrow: 1}}>Dashboard</TextMD>
                  {/* <TouchableOpacity onPress={() => navigate("energy", {type: "building", id: ScopeStore.activeBuilding})}>
                    <LinkText icon={require("../img/icons/energy.png")} iconProps={{style: {height: 22, width: 16}}}>พลังงานทั้งหมด</LinkText>
                  </TouchableOpacity> */}
                </View>
              }
              h100
            >
              {energyData && <DashboardInner1 {...props} building={building} schedulePendingCount={schedulePendingCount} energyData={energyData}></DashboardInner1>}
            </PanelInner>

            {/* ====== RIGHT SECTION ====== */}
            <PanelInner
              header={
                <TouchableOpacity 
                  style={{
                    ...FLEX_ROW_CENTER
                  }}
                  onPress={()=>setBuildingsModalShow(true)}
                >
                  <TextMD style={{flexGrow: 1, ...TEXT_WRAP}}>{building?.name}</TextMD>
                  <DownArrowIcon></DownArrowIcon>
                </TouchableOpacity>
              }
              h100
            >
              {energyData && <View>
                <Weather data={energyData}></Weather>
                <View style={{...FLEX_ROW_CENTER_CENTER, marginVertical: 24}}>
                  <AvatarImage src={building?.avatar} />
                </View>
                <SensorTable data={energyData}></SensorTable>
              </View>}
            </PanelInner>
          </PanelInnerGrid>

        </Panel>
      </CenterPane>

      {ModalRender}
    </Layout>
  );
})
