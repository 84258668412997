import { useState, useMemo } from "react";
import { get, set, cloneDeep } from "lodash";

export class StateProvider {
  state: any
  setState: React.Dispatch<React.SetStateAction<any>>
  path: string[]
  childDefaultValue

  constructor(state: any, setState: React.Dispatch<React.SetStateAction<any>>, childDefaultValue, path: string[] = []) {
    this.state = state
    this.setState = setState

    this.path = path;
    this.childDefaultValue = childDefaultValue;
  }

  $(path) {
    return new StateProvider(this.state, this.setState, this.childDefaultValue, [...this.path, path]);
  }

  get() {
    if (this.path.length == 0) return this.state;
    let res = get(this.state, this.path);
    if (typeof res === "undefined") {
      return this.childDefaultValue;
    } else {
      return res;
    }
  }

  set(value) {
    if (this.path.length == 0) {
      this.setState(value);
      return;
    }

    let state = cloneDeep(this.state);
    set(state, this.path, value)
    this.setState(state);
  }

  patch(value) {
    if (this.path.length == 0) {
      this.setState({...this.state, ...value});
      return;
    }

    let state = cloneDeep(this.state);
    let atThisPath = get(state, this.path);
    set(state, this.path, {...atThisPath, ...value})
    this.setState(state);
  }
}

export function useStateProvider(defaultValue: any = {}, childDefaultValue: any = "") {
  let [state, setState] = useState(defaultValue);

  const provider = useMemo(()=>new StateProvider(state, setState, childDefaultValue), [state]);

  return provider;
}

// For fix code hell in duplicating useState, and make full flexible
export function useStateProviderAgain(stateProvider: StateProvider | undefined, defaultValue: any = "", childDefaultValue: any = "") {
  let backup = useStateProvider(defaultValue, childDefaultValue);
  if (stateProvider) return stateProvider;
  return backup;
}