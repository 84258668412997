import React from "react"
import { View } from "../seperated/react-native"
import { FLEX_ROW_CENTER, FLEX_COLUMN } from "./constants/style"
import { IS_MOBILE } from "../seperated/screen-mode"

export default function HoriTwo({leftSize=200, left, mobilecol=false, ...props}) {
  return (
    <View {...props} style={{...(IS_MOBILE && mobilecol ? FLEX_COLUMN : FLEX_ROW_CENTER), ...(props.style || {})}}>
      <View style={{flexBasis: IS_MOBILE ? (mobilecol ? undefined : leftSize / 1.1) : leftSize, marginBottom: IS_MOBILE ? 8 : 0}}>
        {left}
      </View>

      <View style={{flexGrow: 1}}>
        {props.children}
      </View>
    </View>
  )
}