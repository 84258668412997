import React, { useState } from "react"
import { navigate } from "../../seperated/components/Router";
import { globalLoadingFn } from "../../utils/loading";
import { api } from "../../config";
import { IS_MOBILE } from "../../seperated/screen-mode";
import { View } from "../../seperated/react-native";
import ControlListItem from "./ControlListItem";
import { deviceNotThisType } from "../../utils/deviceUtils";

export default function ControlListItemList({data, level=-1, controlEntity, ...props}) {
  const [children, setChildren] = useState(data.children);

  async function loadChildren() {
    if (data.type == "device") {
      navigate("control_air_manual", {id: data.id})
      return
    }

    if (IS_MOBILE) {
      navigate("control_air_list_"+data.type, {type: data.type, id: data.id, control_entity: controlEntity});
      return
    }

    if (!children) {
      let response = await globalLoadingFn(()=>api.get("/"+data.type+"s/"+data.id));
      setChildren(response.data.children);
    }
  }

  const childRender = (
    <View>
      {children && children.map(child => (
        <ControlListItemList data={child} level={level+1} key={child.id} controlEntity={controlEntity}></ControlListItemList>
      ))}
    </View>
  )

  if (deviceNotThisType(data, controlEntity)) {
    return <></>;
  }

  if (level == -1) {
    return childRender;
  }

  return <ControlListItem text={data.name} online={data.online_count} total={data.total_count} error={data.error_count} level={level} onPress={loadChildren} type={data.type} id={data.id}>
    {childRender}
  </ControlListItem>
}