import React, { useContext } from "react"
import { FLEX_COLUMN_CENTER_CENTER } from "../../constants/style"
import { View, Image, TouchableOpacity } from "../../../seperated/react-native"
import { IS_MOBILE } from "../../../seperated/screen-mode"
import { globalLoadingFn } from "../../../utils/loading"
import { api } from "../../../config"
import AirStore, { refreshAir } from "../../../stores/AirStore"
import { applySnapshotAuto } from "../../../utils/mobx"
import ScopeStore from "../../../stores/ScopeStore"
import ControlAirStoreModeContext from "./ControlAirStoreModeContext"
import ScheduleStore from "../../../stores/ScheduleStore"

const DEFAULT_HEIGHT = IS_MOBILE ? 40 : 60

export async function ControlAirModalButtonPress(field, value, storeMode) {
  if (storeMode == 'air_manual') {
    await globalLoadingFn(async ()=>{
      let response = await api.patch("/devices/device_control_air?scope_type="+ScopeStore.controlActiveType+"&scope_id="+ScopeStore.controlActiveId, {[field]: value})
      applySnapshotAuto(AirStore, response.data);
    })
    return;
  }

  if (storeMode.startsWith('schedule')) {
    let controlKey = storeMode.split(':')[1];
    ScheduleStore.controls.get(controlKey)?.setControlDataByField(field, value);
  }
}

export default function ControlAirModalButton({icon, height=DEFAULT_HEIGHT, field, value, ...props}) {
  const storeMode = useContext(ControlAirStoreModeContext);

  return (
    <TouchableOpacity style={{...FLEX_COLUMN_CENTER_CENTER}} onPress={()=>ControlAirModalButtonPress(field, value, storeMode)}>
      <Image src={icon} style={{height: height}}></Image>
      {props.children}
    </TouchableOpacity>
  )
}