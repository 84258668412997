import AirAdderStore from "../../../../stores/AirAdderStore";

export function controlAddFirstPage(device_type_id: number) {
  switch (device_type_id) {
    case 1: return 'split_type_1'
    case 2: return 'sensor';
    case 3: return 'light';
    default: return 'light';
  }
}

export function controlAddActiveFirstPage(device_type_id: number) {
  AirAdderStore.setActivePage(controlAddFirstPage(device_type_id));
}