import React, { useState, useEffect } from "react";

import Layout, { LeftPane, CenterPane } from "../../../components/Layout";
import {
  View,
  Text,
  Image,
  TextInputSolid,
  TouchableOpacity,
  TextL,
  TextMD,
} from "../../../seperated/react-native";
import TextFieldComplete from "../../../components/input/TextFieldComplete";
import { IS_MOBILE } from "../../../seperated/screen-mode";
import {
  SHADOW_LIGHT,
  BORDER_LIGHT,
  FLEX_ROW_CENTER,
  FLEX_LR_CENTER,
  FLEX_ROW_CENTER_CENTER,
  BORDER_LINE_BOTTOM,
  LINK_COLOR,
  FLEX_COLUMN_CENTER_CENTER,
  TEXT_WRAP_CENTER,
} from "../../../components/constants/style";
import {
  Link,
  navigate,
  getRouteParam,
} from "../../../seperated/components/Router";
import Panel from "../../../components/Panel";
import LeftPaneRootPanel from "../../../components/LeftPane/LeftPaneRootPanel";
import PanelInner from "../../../components/PanelInner";
import PanelInnerGrid from "../../../components/PanelInnerGrid";
import LinkText from "../../../components/text/LinkText";
import UserAvatar from "../../../components/avatar/UserAvatar";
import LRList from "../../../components/list/LRList";
import EditableText from "../../../components/text/EditableText";
import TextWithIcon from "../../../components/text/TextWithIcon";
import SelectCustom from "../../../seperated/components/SelectCustom";
import RightArrowIcon from "../../../components/icon/RightArrowIcon";
import SwitchCustom from "../../../seperated/components/SwitchCustom";
import { observer } from "mobx-react-lite";
import ScopeStore, { loadUser } from "../../../stores/ScopeStore";
import { globalLoadingFn } from "../../../utils/loading";
import { api } from "../../../config";
import FilePickerTouchableOpacity from "../../../seperated/components/FilePickerTouchableOpacity";
import AvatarImage from "../../../components/avatar/AvatarImage";
import AirStore, { refreshAir } from "../../../stores/AirStore";
import LeftPaneControlAirPanel from "../../../components/LeftPane/LeftPaneControlAirPanel";
import { DEIVCE_AIR_LOCK_REMOTE_OPTIONS } from "../../../components/constants/options";
import ControlPlaceEditBasicDialog from "../../../components/control/ControlPlaceEditBasicDialog";
import IconInline from "../../../components/icon/IconInline";
import TextWithBack from "../../../components/text/TextWithBack";

export default observer(function ControlAirSettings(props) {
  const type = getRouteParam(props, "type") || "device";
  const id = parseInt(getRouteParam(props, "id") || "") || 0;

  const [place, setPlace] = useState();
  const [devices, setDevices] = useState<any[]>([]);
  const [showPlaceEditDialog, setShowPlaceEditDialog] = useState(false);

  async function loadDevices() {
    let response = await globalLoadingFn(() => api.get("/devices/list?scope_type=" + type + "&scope_id=" + id));
    setDevices(response.data);
  }

  useEffect(() => {
    ScopeStore.setActivePlace("control", type, id);
    refreshAir();

    api.get("/" + type + "s/" + id).then((response) => setPlace(response.data));

    if (type != "device") {
      loadDevices();
    }
  }, []);

  async function patch(to_patch) {
    await globalLoadingFn(() =>
      api.patch(
        "/devices/device_control_air?scope_type=" + type + "&scope_id=" + id,
        to_patch
      )
    );
    refreshAir();
  }

  const LeftSetting = (
    <View>
      <TextMD>ควบคุมอุปกรณ์</TextMD>

      {/* <LRList
        left={<Text>เปิด/ปิด{devices.length > 1 ? "ทุกตัว" : ""}</Text>}
        right={
          <SwitchCustom
            checked={AirStore.device_control_air.priority >= 100}
            onChange={(checked) =>
              patch({ priority: checked ? 100 : 0 })
            }
          ></SwitchCustom>
        }
      /> */}

      <LRList
        left={<Text>First Priority</Text>}
        right={
          <SwitchCustom
            checked={AirStore.device_control_air.priority >= 100}
            onChange={(checked) =>
              patch({ priority: checked ? 100 : 0 })
            }
          ></SwitchCustom>
        }
      />

      <LRList
        left={<Text>การทำงานของรีโมท</Text>}
        right={
          <SelectCustom
            variant="text_right"
            options={DEIVCE_AIR_LOCK_REMOTE_OPTIONS}
            value={AirStore.device_control_air.device_is_locked_remote}
            onChange={({value}) => patch({device_is_locked_remote: value})}
          ></SelectCustom>
        }
        style={{ zIndex: 101 }}
      />

      <LRList
        left={<Text>ล็อคอุณหภูมิไว้ที่</Text>}
        right={
          <EditableText onChange={async (val)=>{
            patch({device_is_locked_temp: val})
          }} inputStyle={{width: 100}}>{AirStore.device_control_air.device_is_locked_temp}</EditableText>
        }
        style={{}}
      />

      <LRList left={<Text>ถ้าหากไม่ต้องการล็อคอุณหภูมิให้ใส่ 0</Text>} right={<View></View>}></LRList> 
    </View>
  )

  const RightSettings = (
    <View>
      <View style={{...FLEX_COLUMN_CENTER_CENTER, justifyContent: "space-around", marginBottom: 24}}>
        <View>
          <TouchableOpacity onPress={()=>setShowPlaceEditDialog(true)} style={{...FLEX_COLUMN_CENTER_CENTER}}>
            <AvatarImage src={AirStore.avatar} size={150} style={{marginBottom: 8}} />

            <TextWithIcon
              icon={require("../../../img/icons/edit.png")}
              iconRight
              containerStyle={{justifyContent: "center"}}
              style={{...TEXT_WRAP_CENTER}}
            >
              {AirStore.name}
            </TextWithIcon>
          </TouchableOpacity>
        </View>

        <ControlPlaceEditBasicDialog show={showPlaceEditDialog} place={{
          type: "device",
          id: AirStore.id,
          name: AirStore.name,
          avatar: AirStore.avatar,
        }} onClose={()=>{
          refreshAir();
          setShowPlaceEditDialog(false);
        }}></ControlPlaceEditBasicDialog>
      </View>

      <LRList
        left={<Text>ลบแอร์</Text>}
        right={
          <TouchableOpacity onPress={async () => {
            if (window.confirm("ยืนยันลบแอร์ " + AirStore.name + "?")) {
              await globalLoadingFn(() => api.delete("/devices/" + AirStore.id))
              await loadDevices();
            }
          }}>
            <Text style={{color: "red"}}>ลบ</Text>
          </TouchableOpacity>
        }
      />
    </View>
  )

  const Devices = (
    <View>
      {devices.map(device => (
        <View key={device.id}>
          <LRList
            left={
              <View>
                <Text>{device.name}</Text>
              </View>
            }

            right={
              <View style={{...FLEX_ROW_CENTER}}>
                <View style={{marginRight: 12}}>
                  <SwitchCustom
                    checked={device.status == "on"}
                    onChange={async (checked) => {
                      await globalLoadingFn(() => api.post("/devices/device_control_air/turn_"+(checked ? "on" : "off")+"?scope_type=device&scope_id=" + device.id));
                      await loadDevices();
                    }}
                  ></SwitchCustom>
                </View>

                <TouchableOpacity onPress={() => {
                  // navigate("control_air_settings", {
                  //   type: "device",
                  //   id: device.id
                  // })

                  // Navigate bugged because not reload air of new scope
                  window.location.href = "/control_air_settings?type=device&id=" + device.id
                }} style={{marginRight: 12}}>
                  <IconInline src={require("../../../img/icons/option.png")}></IconInline>
                </TouchableOpacity>

                <TouchableOpacity onPress={async () => {
                  if (window.confirm("ยืนยันลบแอร์ " + device.name + "?")) {
                    await globalLoadingFn(() => api.delete("/devices/" + device.id))
                    await loadDevices();
                  }
                }}>
                  <IconInline src={require("../../../img/icons/delete-red.png")}></IconInline>
                </TouchableOpacity>
              </View>
            }
          ></LRList>
        </View>
      ))}
    </View>
  )

  return (
    <Layout webGrid>
      <LeftPane>
        <LeftPaneControlAirPanel
          active="control_air_settings"
          data={place}
        ></LeftPaneControlAirPanel>
      </LeftPane>

      <CenterPane>
        <Panel>
          <PanelInnerGrid>
            {type == "device" ? <>
              <PanelInner header={(
                <View style={{...FLEX_ROW_CENTER}}>
                  <TextWithBack color="black">ตั้งค่า</TextWithBack>
                </View>
              )} h100>
                {LeftSetting}
              </PanelInner>

              <PanelInner header={<TextMD>&nbsp;</TextMD>} h100>
                {RightSettings}
              </PanelInner>
            </> : <>
              <PanelInner header={<TextMD>เลือกแอร์</TextMD>} h100>
                {Devices}
              </PanelInner>

              <PanelInner header={<TextMD>ตั้งค่า</TextMD>} h100>
                {LeftSetting}
              </PanelInner>
            </>}
          </PanelInnerGrid>
        </Panel>
      </CenterPane>
    </Layout>
  );
});
