import React, { useState, useEffect } from "react";
import TextWithIcon from "./TextWithIcon";
import { TouchableOpacity, View } from "../../seperated/react-native";
import { FLEX_ROW_CENTER, TEXT_WRAP } from "../constants/style";
import TextFieldComplete from "../input/TextFieldComplete";
import IconInline from "../icon/IconInline";
import { useFormState } from "../../hooks/FormState";
import { alertErrorComplete } from "../../seperated/toast";

export default function EditableText(props) {
  const [editMode, setEditMode] = useState(false);
  const childrenModel = useFormState(props.children || "");

  useEffect(()=>{
    childrenModel.dataState.set(props.children)
  }, [props.children])

  const text = props.model ? props.model.get() : childrenModel.get();

  return editMode ? (
    <View style={{ ...FLEX_ROW_CENTER }}>
      <TextFieldComplete
        model={props.model || childrenModel}
        lock={props.lock}
        validator={props.validator}
        variant="material"
        inputStyle={props.inputStyle}
      ></TextFieldComplete>
      <TouchableOpacity onPress={() => {
        if (!props.optional && !text) {
          alertErrorComplete({message: "โปรดตรวจสอบว่าคุณได้กรอกข้อมูลครบถ้วนแล้วหรือไม่"})
          return;
        } 

        if (props.onChange) props.onChange(text);
        setEditMode(false);
      }}>
        <IconInline src={require("../../img/icons/save.png")} />
      </TouchableOpacity>
    </View>
  ) : (
    <TouchableOpacity onPress={() => setEditMode(true)}>
      <TextWithIcon
        icon={require("../../img/icons/edit.png")}
        iconRight
        {...props}
        style={{...TEXT_WRAP, ...(props.style || {})}}
      >
        {text} {props.suffix || ""}
      </TextWithIcon>
    </TouchableOpacity>
  );
}
