import React from "react";
import { View, Text } from "../../seperated/react-native";
import { FLEX_LR_CENTER, BORDER_LINE_BOTTOM } from "../constants/style";

export default function LRList({ left, right, ...props }) {
  return (
    <View
      {...props}
      style={{
        ...FLEX_LR_CENTER,
        ...BORDER_LINE_BOTTOM,
        paddingTop: 12,
        paddingBottom: 12,
        ...(props.style || {})
      }}
    >
      {left}
      {right}
    </View>
  );
}
