import React from 'react';
import Plot from 'react-plotly.js';
import { countDevices } from '../../utils/deviceUtils';

export default function DeviceCountChart({data}) {
  const {
    online: airOn,
    offline: airOff,
    error: airErr,
    total: airTotal,
  } = countDevices(data || {}, 1);

  const {
    online: lightOn,
    offline: lightOff,
    error: lightErr,
    total: lightTotal,
  } = countDevices(data || {}, 3);

  const {
    online: curtainOn,
    offline: curtainOff,
    error: curtainErr,
    total: curtainTotal,
  } = countDevices(data || {}, 4);

  const {
    online: doorOn,
    offline: doorOff,
    error: doorErr,
    total: doorTotal,
  } = countDevices(data || {}, 5);

  return (
    <Plot
      data={[
        {
          type: 'scatterpolar',
          r: [airTotal, lightTotal, curtainTotal, doorTotal],
          theta: ['Air', 'Light', 'Curtain', 'Door'],
          fill: 'toself',
          line: {width: 5},
        }
      ]}
      layout={ {
        margin: {
          l: 24,
          r: 24,
          t: 24,
          b: 24,
        }
      } }
    />
  )
}