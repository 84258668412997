import React from "react";
import ReactDOM from 'react-dom';
import Modal from 'react-modal';

const modalStyles = {
  content : {
    background: 'transparent',
    border: 'none'
  },
  overlay: {
    background: 'transparent',
  }
};

Modal.setAppElement('#root')

export default ({
  show,
  style,
  position,
  children,
  centerOnly=false,
  onBackdropClick,
  noWrapper=false
}: {
  show: boolean;
  style?: React.CSSProperties;
  position?: string;
  children?: React.ReactNode;
  centerOnly?: boolean;
  onBackdropClick?: ()=>any;
  noWrapper?: boolean;
}) => {

  const Inner = (
    <div className={"modal " + (centerOnly ? "modal-center-only" : "")} onClick={onBackdropClick}>
      <div className="modal-content" style={style}>
        {children}
      </div>
    </div>
  )

  if (noWrapper) if (show) return Inner; else return <></>;

  return (
    <Modal isOpen={show} style={modalStyles}>
      {Inner}
    </Modal>
  );
}
