import React from 'react';
import { IS_MOBILE } from './screen-mode';
import { View } from './react-native';

export function WebDiv(props) {
  return <div {...props}>{props.children}</div>

  // if (IS_MOBILE) {
  //   return <View {...props}>{props.children}</View>
  // } else {
  //   return <div {...props}>{props.children}</div>
  // }
}

export function WebSpan(props) {
  return <span {...props}>{props.children}</span>

  // if (IS_MOBILE) {
  //   return <View {...props}>{props.children}</View>
  // } else {
  //   return <span {...props}>{props.children}</span>
  // }
}

export function WebOnly(props) {
  if (IS_MOBILE) {
    return <></>
  } else {
    return props.children;
  }
}