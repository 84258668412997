import React, { useState, useEffect } from "react";

import Layout, { LeftPane, CenterPane } from "../components/Layout";
import { View, Text, Image, TextInputSolid, TouchableOpacity, TextMD } from "../seperated/react-native";
import TextFieldComplete from "../components/input/TextFieldComplete";
import { IS_MOBILE } from "../seperated/screen-mode";
import { SHADOW_LIGHT, BORDER_LIGHT, FLEX_ROW_CENTER, FLEX_LR_CENTER } from "../components/constants/style";
import { Link, navigate } from "../seperated/components/Router";
import LeftPaneControlPanel from "../components/LeftPane/LeftPaneControlPanel";
import Panel from "../components/Panel";
import PanelInnerGrid from "../components/PanelInnerGrid";
import PanelInner from "../components/PanelInner";

export default function TOS(props) {
  return (
    <Layout>
      <iframe src="https://csb-w296y.netlify.app" style={{width: "100%", border: 0, height: 7000}}></iframe>
    </Layout>
  )
}