import React from "react";
import { View } from "../seperated/react-native";

export default function CircleDot({ color, ...props }) {
  return (
    <View
      style={{
        borderRadius: 10000,
        backgroundColor: color,
        width: 18,
        height: 18,
        ...(props.style || {}),
      }}
    ></View>
  );
}
