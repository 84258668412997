import React from "react"
import { View } from "../../seperated/react-native"
import { FLEX_ROW_CENTER } from "../constants/style"
import TextWithCircleDot from "../text/TextWithCircleDot"
import IconInline from "../icon/IconInline"

export default function OnlineErrorCount({online=0, total=0, error=0, gap=24, ...props}) {
  return (
    <View {...props} style={{...FLEX_ROW_CENTER, ...(props.style || {})}}>
      {props.icon && <View style={{marginRight: 8}}>
        <IconInline src={props.icon}></IconInline>
      </View>}

      <View style={{ marginRight: gap }}>
        <TextWithCircleDot circleColor="#84eaa7">{online.toString().padStart(2, '0')}/{total.toString().padStart(2, '0')}</TextWithCircleDot>
      </View>

      <View style={{ marginRight: 0 }}>
        <TextWithCircleDot circleColor="#ffbcc6">{error.toString().padStart(2, '0')}</TextWithCircleDot>
      </View>
    </View>
  )
}