import React from "react";
import {
  View,
  TouchableOpacity,
  Image,
  TextSM,
} from "../../../seperated/react-native";
import { FLEX_COLUMN_CENTER_CENTER } from "../../constants/style";
import { navigate } from "../../../seperated/components/Router";

export default function ControlAirVertButton({
  type,
  id,
  disableControl = false,
  disableEnergy = false,
  disablePlant = true,
}) {
  return (
    <View style={{ ...FLEX_COLUMN_CENTER_CENTER }}>
      {!disableControl && (
        <TouchableOpacity
          onPress={() => navigate("control_air_manual", {type, id})}
          style={{ marginBottom: 24, ...FLEX_COLUMN_CENTER_CENTER }}
        >
          <Image
            src={require("../../../img/icons/power-border.png")}
            height="30"
          ></Image>
          <TextSM>ควบคุม</TextSM>
        </TouchableOpacity>
      )}

      {!disableEnergy && (
        <TouchableOpacity
          style={{ marginBottom: 24, ...FLEX_COLUMN_CENTER_CENTER }}
        >
          <Image
            src={require("../../../img/icons/energy-border.png")}
            height="30"
          ></Image>
          <TextSM>พลังงาน</TextSM>
        </TouchableOpacity>
      )}

      {/* {!disablePlant && (
        <TouchableOpacity
          style={{ marginBottom: 24, ...FLEX_COLUMN_CENTER_CENTER }}
        >
          <Image
            src={require("../../../img/icons/plant-border.png")}
            height="30"
          ></Image>
          <TextSM>Plants</TextSM>
        </TouchableOpacity>
      )} */}
    </View>
  );
}
