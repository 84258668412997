import React from "react"
import { Link as LinkRaw } from 'react-router-dom';
import { Route as RouteRaw, Router } from "react-router";
import { createBrowserHistory } from "history";
import { TouchableOpacity } from "../react-native";

const history = createBrowserHistory();

function buildQueryString(params?: object) {
  if (!params) return "";

  var query: string[] = [];

  for(let k in params) {
    if (typeof params[k] != "undefined" && params[k] !== null) {
      query.push(encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
    }
  }

  return query.join('&');
}

function $_GET(name, url?) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

function makeTo(to, params) {
  return "/" + to + (params ? "?" + buildQueryString(params) : "");
}

export function getRouteParams(props): any {
  let url = window.location.href;
  var params = {};
	var parser = document.createElement('a');
	parser.href = url;
	var query = parser.search.substring(1);
	var vars = query.split('&');
	for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=');
    if (!pair[0]) continue;
		params[pair[0]] = decodeURIComponent(pair[1]);
	}
	return params;
}

export function getRouteName() {
  let parts = window.location.pathname.split("/");
  if (parts.length == 0) return "";
  return parts[parts.length - 1];
}

export function getRouteParam(props, param: string) {
  return $_GET(param);
}

export function navigate(to: string, params?: object) {
  // console.log("navigate to", makeTo(to, params))
  if (to == getRouteName()) {
    navigate("loading");
    setTimeout(()=>history.push(makeTo(to, params)), 500)
  } else {
    history.push(makeTo(to, params));
  }
}

export function navigateSameRoute(params?: object) {
  let routeName = getRouteName();
  setTimeout(()=>navigate(routeName, params), 500);
}

export function navigateSameParams(to: string, props) {
  console.log(getRouteParams(props));
  return navigate(to, getRouteParams(props))
}

export function navigateBack() {
  history.goBack();
}

export function Link({to, params, children, ...props} : {to: string, params?: object, children?: React.ReactNode, [x: string]: any}) {
  return <TouchableOpacity {...props}><LinkRaw to={makeTo(to, params)}>{children}</LinkRaw></TouchableOpacity>
}

export function RouteContainer(props) {
  return (
    <Router history={history}>
      {props.children}
    </Router>
  );
}

export function Route({name, component}) {
  return <RouteRaw exact path={"/"+name} component={component}></RouteRaw>
}
