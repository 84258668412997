import React from "react"
import { View, TouchableOpacity, TextSM } from "../seperated/react-native"
import { navigateSameRoute } from "../seperated/components/Router"
import { TEXT_WRAP_CENTER } from "./constants/style"

export default function PathBreadcrumb({path}) {
  if (!path) return <View></View>

  let pathReversed = path.reverse();

  if (path.length > 0) {
    return (
      <div
        style={{
          marginBottom: 12,
          textAlign: 'center',
        }}
        className="hover-pointer"
      >
        {pathReversed.map((path, i) => (
          <span key={i}>
            {i != 0 &&
              <span style={{marginRight: 4, marginLeft: 4, ...TEXT_WRAP_CENTER}}>
                <span>&gt;</span>
              </span>
            }

            <span style={{marginRight: 4, marginLeft: 4}} onClick={() => navigateSameRoute({
              type: path.type,
              id: path.id,
            })}>
              <span style={{textDecorationLine: "underline", ...TEXT_WRAP_CENTER}}>{path.name}</span>
            </span>
          </span>
        ))}
      </div>
    )
  }

  return <View></View>
}