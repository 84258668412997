import React, { useState } from "react";
import { TouchableOpacity, View, Image, Text, TextSM } from "../../seperated/react-native";
import { SHADOW_LIGHT, BORDER_LIGHT, BORDER_LINE_TOP, FLEX_ROW_CENTER } from "../constants/style";
import { Link } from "../../seperated/components/Router";
import LinkOrTouchableOpacity from "../LinkOrTouchableOpacity";
import IconInline from "../icon/IconInline";
import DownArrowIcon from "../icon/DownArrowIcon";
import RightArrowIcon from "../icon/RightArrowIcon";
import { IS_MOBILE } from "../../seperated/screen-mode";

export default function LeftPaneNavItem({ label, icon, isChild = false, hasChild = false, active = false, to = "", defaultShow = false, noLine = false, number = 0, ...rest }) {
  const [showChild, setShowChild] = useState(defaultShow)

  const touchProps = to ? {to} : (hasChild ? {
    onPress() {
      setShowChild(!showChild);
    }
  } : {onPress: rest.onPress});

  const ICON_SIZE = IS_MOBILE ? 20 : 24;
  
  return (
    <>
      <LinkOrTouchableOpacity {...touchProps}>
        <View
          style={{
            padding: IS_MOBILE ? 10 : 12,
            paddingLeft: (isChild ? ICON_SIZE * 2 : ICON_SIZE) - (active ? 5 : 0),
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            ...(noLine ? {} : BORDER_LINE_TOP),
            backgroundColor: active ? "#fafafa" : "transparent",
            borderLeftWidth: active ? 5 : 0,
            borderLeftColor: active ? "#49c5f1" : "transparent",
          }}
        >
          <View style={{ width: ICON_SIZE, flexBasis: ICON_SIZE, marginRight: ICON_SIZE }}>
            <Image src={icon} style={{ height: ICON_SIZE }} resizeMode="contain" />
          </View>

          <View style={{...FLEX_ROW_CENTER, flexGrow: 1}}>
            {IS_MOBILE ? 
              <TextSM style={{ fontWeight: active ? "bold" : "normal" }}>{label}</TextSM>
            :
              <Text style={{ fontWeight: active ? "bold" : "normal" }}>{label}</Text>
            }

            {number ? <View style={{backgroundColor: "red", borderRadius: 10000, padding: 2, paddingHorizontal: 6, marginLeft: 8}}>
              <TextSM style={{color: "white"}}>{number}</TextSM>
            </View> : <></>}
          </View>

          {hasChild ? (showChild ? <DownArrowIcon></DownArrowIcon> : <RightArrowIcon></RightArrowIcon> ) : (IS_MOBILE ? <RightArrowIcon></RightArrowIcon> : <></>) }
        </View>
      </LinkOrTouchableOpacity>

      {showChild ? rest.children : <></>}
    </>
  );
}
