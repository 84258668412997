import React from "react"
import Modal from "../../seperated/components/Modal"
import { View, TextL, TouchableOpacity, TextMD } from "../../seperated/react-native"
import { FLEX_LR_CENTER, FLEX_ROW_CENTER_CENTER } from "../constants/style"
import { WebDiv } from "../../seperated/web-only";
import { IS_MOBILE } from "../../seperated/screen-mode";

const BORDER_RADIUS = 8;

export function DialogTitle({hasClose, onClose, children}) {
  return (
    <View style={{...FLEX_LR_CENTER, paddingLeft: 20, borderTopLeftRadius: BORDER_RADIUS, borderTopRightRadius: BORDER_RADIUS, backgroundColor: "#9ce2f8"}}>
      <View style={{padding: 12}}>
        {typeof children === "string" ? <TextMD style={{fontWeight: "bold"}}>{children}</TextMD> : {children}}
      </View>

      {hasClose ? <TouchableOpacity style={{...FLEX_ROW_CENTER_CENTER, padding: 12, paddingRight: 20}} onPress={onClose}>
        <TextMD>X</TextMD>
      </TouchableOpacity> : <></>}
    </View>
  )
}

export default function Dialog({show, title, hasClose=false, onClose=()=>{}, ...props}) {
  return (
    <Modal show={show} {...props} style={{width: IS_MOBILE ? undefined : 825, borderRadius: 8, ...(props.style || {})}}>
      <DialogTitle hasClose={hasClose} onClose={onClose}>
        {title}
      </DialogTitle>

      <WebDiv className="dialog-content">
        <View style={{backgroundColor: "#ffffff", borderBottomLeftRadius: BORDER_RADIUS, borderBottomRightRadius: BORDER_RADIUS}}>
          {props.children}
        </View>
      </WebDiv>

    </Modal>
  )
}