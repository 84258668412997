import React, { useState, useEffect } from "react";
import MobileLayout from "../../components/MobileLayout";
import { TextL, View } from "../../seperated/react-native";
import CardButtonMini from "../../components/buttons/CardButtonMini";
import OnlineErrorCount from "../../components/control/OnlineErrorCount";
import LeftPaneNavItem from "../../components/LeftPane/LeftPaneNavItem";
import { BORDER_LIGHT, SHADOW_LIGHT } from "../../components/constants/style";
import { navigate } from "../../seperated/components/Router";
import DropdownMenuIcon from "../../components/DropdownMenuIcon";
import ControlAddHeaderMenu from "../../components/mobile_menu/ControlAddHeaderMenu";
import { globalLoadingFn } from "../../utils/loading";
import { api } from "../../config";
import ScopeStore from "../../stores/ScopeStore";


export default function ControlLandingPage(props) {
  let [building, setBuilding] = useState<any>();

  useEffect(()=>{
    async function f() {
      let response = await globalLoadingFn(()=>api.get("/buildings/"+ScopeStore.activeBuilding));
      setBuilding(response.data);
    }
    f();
  }, [])

  return (
    <MobileLayout title="ควบคุมอุปกรณ์" headerRight={
      <ControlAddHeaderMenu {...props}></ControlAddHeaderMenu>
    } load={building || false}>
      {/* <View style={{marginBottom: 16}}>
        <TextL>อาคารสำนักงานใหญ่</TextL>
      </View> */}

      <CardButtonMini
        title={building?.name}
        hasChild
        hasArrow={false}
        defaultShowChild={true}
        subtitle={
          <OnlineErrorCount online={building?.online_count} total={building?.total_count} error={building?.error_count}></OnlineErrorCount>
        }
      >
        <View style={{ padding: 16, ...BORDER_LIGHT, ...SHADOW_LIGHT }}>
          <LeftPaneNavItem
            icon={require("../../img/icons/power.png")}
            label="ควบคุมอุปกรณ์"
            onPress={() => navigate("control_select")}
            noLine
          ></LeftPaneNavItem>

          <LeftPaneNavItem
            icon={require("../../img/icons/calendar.png")}
            label="ตารางการทำงาน"
            onPress={() => navigate("schedule_list", {type: "building", id: ScopeStore.activeBuilding})}
          ></LeftPaneNavItem>

          <LeftPaneNavItem
            icon={require("../../img/icons/envelope.png")}
            label="รายการคำขอ"
            onPress={() => navigate("schedule_list", {type: "building", id: ScopeStore.activeBuilding, pending: 1})}
          ></LeftPaneNavItem>
        </View>
      </CardButtonMini>
    </MobileLayout>
  );
}
