import React, { useState, useEffect } from "react";

import Layout from "../components/Layout";
import { View, Text, Image, TextInputSolid, TouchableOpacity } from "../seperated/react-native";
import TextFieldComplete from "../components/input/TextFieldComplete";
import { IS_MOBILE } from "../seperated/screen-mode";
import { SHADOW_LIGHT, BORDER_LIGHT, FLEX_ROW_CENTER_CENTER } from "../components/constants/style";
import { Link, navigate } from "../seperated/components/Router";
import FacebookLoginButton from "../components/login/FacebookLoginButton";
import GoogleLoginButton from "../components/login/GoogleLoginButton";
import LoginWhiteButton from "../components/login/LoginWhiteButton";

export default function Register(props) {
  return (
    <Layout>
      <View style={{
        display: "flex",
        flexDirection: IS_MOBILE ? "column" : "row",
        justifyContent: "space-around",
        minHeight: "100%",
      }}>
        {!IS_MOBILE &&
          <View style={{flexGrow: 1, flexBasis: "60%", justifyContent: "center", alignItems: "center"}}>
            <Image src={require('../img/working1.png')} style={{width: "75%"}} />
          </View>
        }

        <View style={{flexGrow: 1, flexBasis: IS_MOBILE ? undefined : "40%", flexDirection: "column", justifyContent: IS_MOBILE ? "space-around" : "center"}}>
          <View style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
            <Image src={require('../img/airin1_caption.png')} style={{width: "40%", height: "100%"}} />
          </View>

          <View>
            <View style={{marginVertical: 8, marginTop: 24}}>
              <FacebookLoginButton prefix="ลงทะเบียน" />
            </View>
  
            <View style={{marginVertical: 8}}>
              <GoogleLoginButton prefix="ลงทะเบียน" />
            </View>
  
            <View style={{marginVertical: 8}}>
              <LoginWhiteButton label="ลงทะเบียนด้วย Email" icon={require('../img/icons/email.png')} onPress={()=>navigate("register_detail")}></LoginWhiteButton>
            </View>
  
            {/* <View style={{marginVertical: 16}}>
              <Link to="login">
                <View style={{padding: 12, ...SHADOW_LIGHT, ...BORDER_LIGHT, backgroundColor: "#9ce2f8"}}>
                  <Text style={{textAlign: "center", fontWeight: "bold"}}>เข้าสู่ระบบ</Text>
                </View>
              </Link>
            </View> */}

            <View style={{...FLEX_ROW_CENTER_CENTER, marginTop: 8}}>
              <Text>เป็นสมาชิกอยู่แล้ว ?</Text>
              <Link to="login" style={{marginLeft: 8}}>
                <Text style={{color: "#41b0e8"}}>เข้าสู่ระบบ</Text>
              </Link>
            </View>
          </View>

        </View>

        {IS_MOBILE &&
          <View style={{flexGrow: 1, justifyContent: "center", alignItems: "center"}}>
            <Image src={require('../img/working1.png')} style={{width: "75%"}} />
          </View>
        }
        
      </View>
    </Layout>
  )
}
