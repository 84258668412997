import React, { useState } from "react";
import {
  TouchableOpacity,
  View,
  Image,
  Text,
  TextMD,
} from "../../seperated/react-native";
import {
  FLEX_ROW_CENTER,
  SHADOW_LIGHT,
  BORDER_LIGHT,
  FLEX_COLUMN,
  TEXT_WRAP,
} from "../constants/style";
import DownArrowIcon from "../icon/DownArrowIcon";
import RightArrowIcon from "../icon/RightArrowIcon";
import IconInline from "../icon/IconInline";
import { IS_MOBILE } from "../../seperated/screen-mode";

export default function CardButtonMini({
  icon,
  title,
  subtitle,
  hasChild = false,
  hasArrow = true,
  defaultShowChild = false,
  onPress = undefined,
  rightIcon = undefined,
  shadowRoot = false,
  ...props
}: {
  icon?: any;
  title: string;
  subtitle?: any;
  hasChild?: boolean;
  hasArrow?: boolean;
  defaultShowChild?: boolean;
  onPress?: () => void;
  rightIcon?: any;
  [x: string]: any;
}) {
  const [showChild, setShowChild] = useState(defaultShowChild);

  const onPressToggleChild = () => setShowChild(!showChild);

  const onPressInner = onPress
    ? onPress
    : hasChild
    ? () => {
        onPressToggleChild();
      }
    : () => {};

  const ICON_SIZE = (subtitle ? 36 : 28) * (IS_MOBILE ? 1 : 1.5)

  return (
    <View {...props} style={!shadowRoot ? props.style : {
      ...SHADOW_LIGHT,
      ...BORDER_LIGHT,
      ...(props.style || {}),
    }}>
      <View
        style={
          shadowRoot ? {...FLEX_ROW_CENTER, paddingHorizontal: 20, paddingTop: 12} : 
          {
            ...FLEX_ROW_CENTER,
            ...SHADOW_LIGHT,
            ...BORDER_LIGHT,
            padding: 12,
            paddingHorizontal: 20,
          }
       }
      >
        <TouchableOpacity
          onPress={onPressInner}
          style={{ flexGrow: 1, ...FLEX_ROW_CENTER, maxWidth: "100%" }}
        >
          {icon && (
            <View style={{ width: ICON_SIZE, flexBasis: ICON_SIZE, marginRight: 24 }}>
              <Image src={icon} style={{ height: ICON_SIZE }} resizeMode="contain" />
            </View>
          )}

          <View style={{ ...FLEX_COLUMN, flexGrow: 1, maxWidth: "100%" }}>
            <TextMD style={{ marginBottom: subtitle ? 4 : 0, ...TEXT_WRAP }}>{title}</TextMD>
            {subtitle && <View>{subtitle}</View>}
          </View>
        </TouchableOpacity>

        {(hasArrow || rightIcon) && (
          <TouchableOpacity
            onPress={hasChild ? onPressToggleChild : onPressInner}
          >
            {rightIcon ? <IconInline src={rightIcon} size={18}></IconInline> :
              hasChild ? (
                showChild ? (
                  <DownArrowIcon></DownArrowIcon>
                ) : (
                  <RightArrowIcon></RightArrowIcon>
                )
              ) : (
                <RightArrowIcon></RightArrowIcon>
              )
            }
          </TouchableOpacity>
        )}
      </View>

      {showChild ? props.children : <></>}
    </View>
  );
}
