import React from "react"
import { View } from "../../seperated/react-native"
import Weather from "./Weather"
import SensorTable from "./SensorTable"

export default function WeatherAndSensorTable({data}) {
  return (<>
    <View style={{ marginBottom: 12 }}>
      <Weather data={data}></Weather>
    </View>
    <SensorTable data={data}></SensorTable>
  </>)
}