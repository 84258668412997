import React, { useState, useEffect } from "react";

import Layout from "../components/Layout";
import { View, Text, Image, TextInputSolid, TouchableOpacity } from "../seperated/react-native";
import TextFieldComplete from "../components/input/TextFieldComplete";
import { IS_MOBILE } from "../seperated/screen-mode";
import { SHADOW_LIGHT, BORDER_LIGHT } from "../components/constants/style";
import { Link, navigate } from "../seperated/components/Router";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import GoogleLogin from 'react-google-login';
import { GOOGLE_CLIENT_ID, FACEBOOK_CLIENT_ID } from "../config";
import { performLoginSocial } from "./Login";
import GoogleLoginButton from "../components/login/GoogleLoginButton";
import FacebookLoginButton from "../components/login/FacebookLoginButton";
import LoginWhiteButton from "../components/login/LoginWhiteButton";

export default function LoginLanding(props) {
  return (
    <Layout>
      <View style={{
        display: "flex",
        flexDirection: IS_MOBILE ? "column" : "row",
        justifyContent: "space-around",
        minHeight: "100%",
      }}>
        {!IS_MOBILE &&
          <View style={{flexGrow: 1, flexBasis: "60%", justifyContent: "center", alignItems: "center"}}>
            <Image src={require('../img/working1.png')} style={{width: "75%"}} />
          </View>
        }

        <View style={{flexGrow: 1, flexBasis: IS_MOBILE ? undefined : "40%", flexDirection: "column", justifyContent: IS_MOBILE ? "space-around" : "center"}}>
          <View style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
            <Image src={require('../img/favicon-lowpadding.jpg')} style={{height: 48, marginRight: 8}} />
            <Image src={require('../img/airin1_caption.png')} style={{width: "40%", height: "100%"}} />
          </View>

          <View>
            <View style={{marginVertical: 8, marginTop: 24}}>
              <FacebookLoginButton prefix="เข้าสู่ระบบ" />
            </View>
  
            <View style={{marginVertical: 8}}>
              <GoogleLoginButton prefix="เข้าสู่ระบบ" />
            </View>
  
            <View style={{marginVertical: 8}}>
              <LoginWhiteButton label="เข้าสู่ระบบด้วย Email" icon={require('../img/icons/email.png')} onPress={()=>navigate("login_email")}></LoginWhiteButton>
            </View>
  
            {/* <View style={{marginVertical: 16}}>
              <Link to="register">
                <View style={{padding: 12, ...SHADOW_LIGHT, ...BORDER_LIGHT, backgroundColor: "#9ce2f8"}}>
                  <Text style={{textAlign: "center", fontWeight: "bold"}}>ลงทะเบียน</Text>
                </View>
              </Link>
            </View> */}

            <View style={{display: "flex", flexDirection: "row", justifyContent: "space-between", marginVertical: 8}}>
              <Link to="register">
                <Text style={{textDecorationLine: "underline"}}>สมัครสมาชิก</Text>
              </Link>
              <Link to="reset_password">
                <Text style={{textDecorationLine: "underline"}}>ลืมรหัสผ่าน</Text>
              </Link>
            </View>
          </View>

        </View>

        {IS_MOBILE &&
          <View style={{flexGrow: 1, justifyContent: "center", alignItems: "center"}}>
            <Image src={require('../img/working1.png')} style={{width: "75%"}} />
          </View>
        }
        
      </View>
    </Layout>
  )
}
