import React from "react"
import AirStore from "../../../stores/AirStore";
import ScheduleStore from "../../../stores/ScheduleStore";

export default React.createContext("manual");

export function getControlFieldValue(storeMode, field) {
  if (storeMode == 'air_manual') return AirStore.device_control_air[field] || "Loading...";
  if (storeMode.startsWith('schedule')) {
    let controlKey = storeMode.split(':')[1];
    return ScheduleStore.controls.get(controlKey)?.control_data[field] || "Loading...";
  }

  return "Loading...";
}