import React, { useEffect } from "react"
import { View, Image } from "../../seperated/react-native"
import { FLEX_ROW_CENTER_CENTER } from "../../components/constants/style"
import Dialog from "../../components/dialog/Dialog"
import MobileLayout from "../../components/MobileLayout"

export function SuccessPageInner({onNavigate}) {
  useEffect(()=>{
    setTimeout(()=>{
      onNavigate();
    }, 2000)
  }, [])

  return (
    <View style={{...FLEX_ROW_CENTER_CENTER, height: "100%"}}>
      <Image src={require("../../img/graphics/success.png")} width="325" />
    </View>
  )
}

export function SuccessDialog({title, onNavigate}) {
  return (
    <Dialog title={title} show={true}>
      <View style={{padding: 24, paddingVertical: 64}}>
        <SuccessPageInner onNavigate={onNavigate}></SuccessPageInner>
      </View>
    </Dialog>
  )
}

export function SuccessPage({title, onNavigate}) {
  return (
    <MobileLayout title={title} back={false}>
      <SuccessPageInner onNavigate={onNavigate}></SuccessPageInner>
    </MobileLayout>
  )
}