import React, { useState, useContext } from "react"
import ControlAirButton from "./ControlAirButton"
import Dialog from "../../dialog/Dialog"
import { View, TextL, Text } from "../../../seperated/react-native"
import ControlAirModalButton from "./ControlAirModalButton"
import { FLEX_ROW_CENTER_CENTER, FLEX_COLUMN, FLEX_COLUMN_CENTER_CENTER } from "../../constants/style"
import { IS_MOBILE } from "../../../seperated/screen-mode"
import AirStore from "../../../stores/AirStore"
import { observer } from "mobx-react-lite"
import ControlAirStoreModeContext, { getControlFieldValue } from "./ControlAirStoreModeContext"

const ICONS = {
  "3": require("../../../img/controls/swing/3d.png"),
  "2": require("../../../img/controls/swing/horizontal.png"),
  "1": require("../../../img/controls/swing/vertical.png"),
  "0": require("../../../img/controls/swing/none.png"),
}

const ICONS_ACTIVE = {
  "3": require("../../../img/controls/swing/3d-active.png"),
  "2": require("../../../img/controls/swing/horizontal-active.png"),
  "1": require("../../../img/controls/swing/vertical-active.png"),
  "0": require("../../../img/controls/swing/none-active.png"),
}

export const TEXT2LABEL = {
  3: "3D Swing",
  2: "Left-Right",
  1: "Up-Down",
  0: "No Swing",
  "Loading...": "Loading...",
}

function capitalize(str) {
  return str[0].toUpperCase() + str.slice(1)
}

export let ControlAirSwingModal = observer<any>((props) => {
  const storeMode = useContext(ControlAirStoreModeContext);
  let mode = getControlFieldValue(storeMode, 'device_swing_mode');
  
  return (
    <Dialog {...props} title="Air flow direction" hasClose>
      <View style={{...FLEX_COLUMN_CENTER_CENTER}}>
        <View style={{padding: 24, marginTop: 12, ...FLEX_COLUMN, width: IS_MOBILE ? 450 : 600}}>
          <View style={{paddingBottom: 24, borderBottomColor: "#ededed", borderBottomWidth: 2}}>
            <TextL style={{textAlign: "center"}}>{TEXT2LABEL[mode]}</TextL>
          </View>
  
          <View style={{...FLEX_ROW_CENTER_CENTER, justifyContent: "space-around", padding: 30}}>
            {Object.keys(ICONS).map(key => (
              <ControlAirModalButton icon={mode == key ? ICONS_ACTIVE[key] : ICONS[key]} key={key} field="device_swing_mode" value={key}>

              </ControlAirModalButton>
            ))}
          </View>
        </View>
      </View>
    </Dialog>
  )
})

export default observer(function ControlAirSwingButton() {
  const [showModal, setShowModal] = useState(false)
  const storeMode = useContext(ControlAirStoreModeContext);
  let mode = getControlFieldValue(storeMode, 'device_swing_mode');
  
  return <>
    <View style={IS_MOBILE ? {flexBasis: "33.33%", paddingHorizontal: 4, marginBottom: 8} : {}}>
      <ControlAirButton icon={ICONS_ACTIVE[mode]} text={TEXT2LABEL[mode]} onPress={()=>setShowModal(true)} iconSize={90}></ControlAirButton>
    </View>
    <ControlAirSwingModal show={showModal} onClose={()=>setShowModal(false)}></ControlAirSwingModal>
  </>
})