import React, { useState, useEffect } from "react"
import Dialog from "../dialog/Dialog"
import { View, Text, TouchableOpacity, FlatList, ScrollView } from "../../seperated/react-native"
import { FLEX_ROW_CENTER, BORDER_LIGHT, FLEX_LR_CENTER, TEXT_WRAP } from "../constants/style"
import CommonButton from "../buttons/CommonButton"
import IconInline from "../icon/IconInline"
import TextFieldComplete from "../input/TextFieldComplete"
import { useFormState } from "../../hooks/FormState"
import { globalLoadingFn } from "../../utils/loading"
import { api } from "../../config"
import MapListItem from "../list/MapListItem"
import ScopeStore from "../../stores/ScopeStore"
import { IS_MOBILE } from "../../seperated/screen-mode"
import { deviceNotThisType } from "../../utils/deviceUtils"

function SelectedBadge({text, onDelete}) {
  return (
    <View style={{...FLEX_ROW_CENTER, ...BORDER_LIGHT, borderColor: "#9ce2f8", padding: 4, marginRight: 8, marginBottom: 8, backgroundColor: "#f0f9ff", maxWidth: "100%"}}>
      <Text style={{marginRight: 4, ...TEXT_WRAP}}>{text}</Text>
      <TouchableOpacity onPress={onDelete}>
        <IconInline src={require("../../img/icons/cancel-solid.png")}></IconInline>
      </TouchableOpacity>
    </View>
  )
}

export function ControlPlaceAddDevice(props) {
  const [selected, setSelected] = useState<any[]>(props.selected || []);
  const [airs, setAirs] = useState<any[]>([]);
  const searchForm = useFormState()

  async function performSearch(search?) {
    let response;

    let baseUrl = props.type == "all" ? 
      "/places/buildings/"+ScopeStore.activeBuilding+"?_=_"+(props.includeBuilding ? "&include_building=1" : "")+(props.includeGroup ? "&include_group=1" : "") : 
      "/devices?building_id="+ScopeStore.activeBuilding 

    if (search) {
      response = await globalLoadingFn(()=>api.get(baseUrl+"&search="+encodeURIComponent(search.trim())))
    } else {
      response = await globalLoadingFn(()=>api.get(baseUrl))
    }

    // let places = response.data.filter(place => !deviceNotThisType(place, props.controlEntity));
    let places = response.data.filter(place => place.device_type_id);

    // console.log(places)

    setAirs(places);
  }

  async function refreshSelected() {
    if (props.selected) {
      setSelected([...props.selected])
    } else {
      let response = (await globalLoadingFn(()=>api.get("/"+props.type+"s/"+props.id))).data;
      setSelected(response.children);
    }
  }

  async function refreshInit() {
    await refreshSelected();
    await performSearch()
  }

  useEffect(()=>{
    if (props.show) {
      searchForm.clear();
      refreshInit();
    }
  }, [props.show])

  return (<>
    <View style={{display: "flex", flexDirection: "row", marginBottom: 24, flexGrow: IS_MOBILE ? 1 : undefined}}>

      {/* LEFT */}
      <View style={{flexBasis: IS_MOBILE ? "100%" : "50.5%", paddingRight: IS_MOBILE ? 0 : 12}}>
        <View style={{...(IS_MOBILE ? {} : {...BORDER_LIGHT, borderColor: "#9ce2f8", padding: 8}), height: "100%"}}>
          <View style={{...FLEX_ROW_CENTER, marginBottom: 8}}>
            <IconInline src={require("../../img/icons/search.png")} style={{marginRight: 8}}></IconInline>
            <TextFieldComplete variant="material" model={searchForm.$("search")} style={{marginRight: 8, flexGrow: 1}}></TextFieldComplete>
            <CommonButton variant="primary" onPress={()=>{
              let searchFormData = searchForm.dataState.get();
              if (searchFormData.search) {
                performSearch(searchFormData.search)
              } else {
                performSearch()
              }
            }} padding={8} Component={Text}>ค้นหา</CommonButton>
          </View>

          <View style={{...FLEX_LR_CENTER, marginBottom: 8}}>
            <Text>รายการ</Text>
            <Text>{airs.length}</Text>
          </View>

          <ScrollView style={{maxHeight: 275}}>
            <FlatList
              data={airs}
              renderItem={({item}) => (
                <MapListItem
                  text={item.name}
                  editable={false}
                  selected={selected.find(x => x.id == item.id && x.type == item.type)}
                  hasCheckbox={true}
                  onSingleTap={()=>{
                    let index = selected.findIndex(x => x.id == item.id && x.type == item.type);

                    if (index == -1) {
                      selected.push(item)
                    } else {
                      selected.splice(index, 1);
                    }

                    setSelected([...selected])
                  }}
                ></MapListItem>
              )}
              keyExtractor={item => item.id}
              extraData={selected}
            ></FlatList>
          </ScrollView>
        </View>
      </View>

      {/* RIGHT */}
      {!IS_MOBILE && 
        <View style={{flexBasis: "49.5%", paddingLeft: 12}}>
          <View style={{...BORDER_LIGHT, borderColor: "#9ce2f8", padding: 8, height: "100%"}}>
            <View style={{display: "flex", flexDirection: "row", flexWrap: "wrap", alignItems: "flex-start"}}>
              {selected.filter(place => !deviceNotThisType(place, props.controlEntity)).map((item, i) => (
                <SelectedBadge text={item.name} onDelete={()=>{
                  selected.splice(i, 1)
                  setSelected([...selected])
                }} key={item.id}></SelectedBadge>
              ))}
            </View>
          </View>
        </View>
      }

    </View>

    <View style={{display: "flex", flexDirection: "row", justifyContent: "flex-end"}}>
      {!IS_MOBILE && <CommonButton variant="default" style={{marginRight: 12, flexGrow: 1}} onPress={()=>props.onClose()}>ยกเลิก</CommonButton>}

      <CommonButton variant="primary" style={{marginLeft: IS_MOBILE ? 0 : 12, flexGrow: 1}} onPress={async ()=>{
        props.onChange(selected.map(x => ({ ...x, scope_type: x.type, scope_id: x.id })))
        props.onClose()
      }}>ยืนยัน</CommonButton>
    </View>
  </>)
}

export default function ControlPlaceAddDeviceDialog(props) {
  return (
    <Dialog title="เลือกรายการ" hasClose {...props}>
      <View style={{padding: 24}}>
        <ControlPlaceAddDevice {...props}></ControlPlaceAddDevice>
      </View>
    </Dialog>
  )
}