import React, { useState, useEffect } from "react";
import MobileLayout from "../../components/MobileLayout";
import { TextL, View, Text, Image, TextMD, TouchableOpacity, FlatList } from "../../seperated/react-native";
import CardButtonMini from "../../components/buttons/CardButtonMini";
import OnlineErrorCount from "../../components/control/OnlineErrorCount";
import { navigate, getRouteParam } from "../../seperated/components/Router";
import { BORDER_LIGHT, SHADOW_LIGHT, FLEX_ROW_CENTER, BORDER_LINE_BOTTOM, FLEX_LR_CENTER, FLEX_COLUMN_CENTER_CENTER, FLEX_ROW_CENTER_CENTER } from "../../components/constants/style";
import ControlAddHeaderMenu from "../../components/mobile_menu/ControlAddHeaderMenu";
import { IS_MOBILE } from "../../seperated/screen-mode";
import Layout, { LeftPane, CenterPane } from "../../components/Layout";
import Panel from "../../components/Panel";
import DropdownLink from "../../components/DropdownLink";
import PanelInner from "../../components/PanelInner";
import LeftPaneControlPanel from "../../components/LeftPane/LeftPaneControlPanel";
import PanelInnerGrid from "../../components/PanelInnerGrid";
import CommonButton from "../../components/buttons/CommonButton";
import RightArrowIcon from "../../components/icon/RightArrowIcon";
import {groupBy, map} from "lodash"
import { globalLoadingFn } from "../../utils/loading";
import { api } from "../../config";
import ScheduleStore, { scheduleShouldRunOn } from "../../stores/ScheduleStore";
import LeftPaneControlAirPanel from "../../components/LeftPane/LeftPaneControlAirPanel";
import ControlAddScheduleMenu from "../../components/mobile_menu/ControlAddScheduleMenu";
import IconInline from "../../components/icon/IconInline";
import { useFormState } from "../../hooks/FormState";
import DatePickerComplete from "../../components/input/DatePickerComplete";

function formatLimitTwoPart(time) {
  if (!time) return time
  let parts = time.split(":")
  return parts[0] + ":" + parts[1]
}

function getTimeLabel(schedule) {
  if (schedule.time_on && schedule.time_off) {
    return formatLimitTwoPart(schedule.time_on) + " - " + formatLimitTwoPart(schedule.time_off)
  }

  if (schedule.time_on) return formatLimitTwoPart(schedule.time_on);
  if (schedule.time_off) return "ปิด " + formatLimitTwoPart(schedule.time_off);

  return "ไม่ทราบเวลา"
}

function airGetTemperature(schedule) {
  return schedule.device_schedules.find(c => c.schedule_wrapper_type == "common_on")?.control_data.device_temp;
}

function airIsPrecool(schedule) {
  return schedule.device_schedules.find(c => c.schedule_wrapper_type == "precool_on");
}

const DAY_LABEL = ["อา.", "จ.", "อ.", "พ.", "พฤ.", "ศ.", "ส."];

function DayListLabel({schedule}) {
  return <Text>{schedule.repeat_day.map(day => DAY_LABEL[day]).join(" ")}</Text>
}

function ScheduleListItem({schedule, onPress, hasLine=true}) {
  const ICON_SIZE = 32;

  return (
    <TouchableOpacity 
      style={{...FLEX_LR_CENTER, paddingVertical: 12, ...(hasLine ? BORDER_LINE_BOTTOM : {})}}
      onPress={onPress}
    >
      <View style={{...FLEX_ROW_CENTER, flexGrow: 1}}>
        {/* <View style={{ width: ICON_SIZE, flexBasis: ICON_SIZE, marginRight: 24 }}>
          
        </View> */}
  
        <View style={{flexGrow: 1}}>
          <View>
            <DayListLabel schedule={schedule}></DayListLabel>
          </View>

          <View>
            <Text>{getTimeLabel(schedule)}</Text>
          </View>

          {airIsPrecool(schedule) && <View>
            <Text style={{color: "grey", fontSize: 14}}>Precool</Text>
          </View>}
        </View>

        <View style={{marginRight: 16}}>
          {airGetTemperature(schedule) && <Text>{airGetTemperature(schedule)} ℃</Text>}
        </View>
      </View>

      <RightArrowIcon></RightArrowIcon>
    </TouchableOpacity>
  )
}

function ScheduleList({data, onNavigate, title}) {
  let schedule_groups_raw = groupBy(data, 'name')
  let schedule_groups: any[] = [];

  for (let key in schedule_groups_raw) {
    schedule_groups.push({
      name: key,
      schedules: schedule_groups_raw[key]
    })
  }

  if (schedule_groups.length == 0) {
    return (
      <View style={{marginTop: 16, ...FLEX_ROW_CENTER_CENTER}}>
        <Text>--- ไม่มี{title} ---</Text>
      </View>
    )
  }

  return (
    <FlatList
      data={schedule_groups}
      keyExtractor={(item) => item.name}
      renderItem={({ item: schedule_group }) => (
        <CardButtonMini
          title={schedule_group.name}
          hasChild
          defaultShowChild
          style={{marginBottom: 16}}
        >
          <View style={{paddingHorizontal: 18, ...SHADOW_LIGHT, ...BORDER_LIGHT}}>
            <FlatList
              data={schedule_group.schedules}
              keyExtractor={(item) => item.id}
              renderItem={({ item: schedule }) => (
                <ScheduleListItem 
                  schedule={schedule}
                  onPress={()=>{
                    onNavigate(schedule)
                  }}
                ></ScheduleListItem>
              )}
            ></FlatList>
          </View>
        </CardButtonMini>
      )}
    ></FlatList>
  )
}

function PlaceRoleScheduleList({data, onNavigate}) {
  console.log(data)

  return (<View></View>)
}

function schedulesFilter(schedules, model) {
  let modelData = model.get();
  return schedules.filter(schedule => true && (!modelData.filterDate || scheduleShouldRunOn(schedule, modelData.filterDate)))
}

export default function ScheduleListPage(props) {
  const model = useFormState();

  const [schedules, setSchedules] = useState<any[]>([]);
  const [place, setPlace] = useState<any>();

  const type = getRouteParam(props, "type")
  const id = parseInt(getRouteParam(props, "id") || "0");
  const entity = getRouteParam(props, "entity");
  const pending = getRouteParam(props, "pending");

  useEffect(()=>{
    let placePromise = api.get("/" + type + "s/" + id);
    placePromise.then(response => setPlace(response.data))
    let schedulesPromise = api.get("/schedules?scope_type=" + type + "&scope_id=" + id + (entity ? "&control_entity=" + entity : "") + (pending ? "&pending=1" : ""));
    schedulesPromise.then(response => setSchedules(response.data))

    globalLoadingFn(()=>Promise.all([placePromise, schedulesPromise]));
  }, []);

  const title = pending ? "รายการคำขอ" : "ตารางการทำงาน"

  function LeftPaneChild(props) {
    let leftPaneActive = "schedule"

    if (pending) leftPaneActive = "schedule_request";

    switch (entity) {
      case 'device_control_air': return <LeftPaneControlAirPanel {...props} active={leftPaneActive} data={place}></LeftPaneControlAirPanel>
      default: return <LeftPaneControlPanel {...props} active={leftPaneActive} data={place}></LeftPaneControlPanel>
    }
  }

  function onNavigate(schedule) {
    navigate("schedule_detail", {type, id, control_entity: schedule.control_entity, schedule_id: schedule.id})
  }
  
  if (IS_MOBILE) {
    return (
      <MobileLayout title={title} headerRight={
        <ControlAddScheduleMenu {...props} title={title}></ControlAddScheduleMenu>
      } load={(schedules && place) || false}>
        <View style={{...FLEX_LR_CENTER, marginBottom: 12}}>
          <TextMD>{place?.name}</TextMD>
          <DatePickerComplete variant="button" model={model.$("filterDate")}></DatePickerComplete>
        </View>

        <ScheduleList 
          data={schedulesFilter(schedules, model).filter(schedule => schedule.control_entity != "place_role")}
          onNavigate={onNavigate}
          title={title}
        ></ScheduleList>
      </MobileLayout>
    );
  }

  return (
    <Layout webGrid load={(schedules && place) || false}>
      <LeftPane>
        <LeftPaneChild></LeftPaneChild>
      </LeftPane>

      <CenterPane>
        <Panel>
          <PanelInnerGrid>

            <PanelInner
              header={
                <View style={{
                  ...FLEX_LR_CENTER,
                }}>
                  <View style={{flexGrow: 1}}>
                    <TextMD>{title}</TextMD>
                  </View>
                </View>
              }
            >
              <View style={{...FLEX_LR_CENTER, marginBottom: 12}}>
                <TextMD>{place?.name}</TextMD>
                <DatePickerComplete variant="button" model={model.$("filterDate")}></DatePickerComplete>
              </View>

              <ScheduleList 
                data={schedulesFilter(schedules, model).filter(schedule => schedule.control_entity != "place_role")}
                onNavigate={onNavigate}
                title={title}
              ></ScheduleList>

              <PlaceRoleScheduleList 
                data={schedulesFilter(schedules, model).filter(schedule => schedule.control_entity == "place_role")}
                onNavigate={() => {

                }}
              ></PlaceRoleScheduleList>
            </PanelInner>

            <PanelInner
              header={
                <View style={{
                  ...FLEX_LR_CENTER,
                }}>
                  <View style={{flexGrow: 1}}>
                    <TextMD>จัดการ</TextMD>
                  </View>
                </View>
              }
            >
              <View style={{padding: 32, ...FLEX_COLUMN_CENTER_CENTER, height: "100%"}}>
                <Image src={require('../../img/graphics/working2.png')} style={{width: "100%", marginBottom: 24}}></Image>
                <CommonButton variant="primary" onPress={async ()=>{
                  navigate('schedule_detail', {type, id, ...(entity ? {entity} : {}), control_entity: "device_control_air", ...(pending ? {pending} : {})})
                }}>สร้าง{title}</CommonButton>
              </View>
            </PanelInner>

          </PanelInnerGrid>


        </Panel>
      </CenterPane>

    </Layout>
  )
}
