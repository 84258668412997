import React from "react"
import { View } from "../seperated/react-native"
import { SHADOW_LIGHT } from "./constants/style"

export default function Panel({lowPadding = false, heightLimit = false, ...props}) {
  return (
    <View {...props} style={{
      ...SHADOW_LIGHT,
      borderTopColor: "#49C5F1",
      borderTopWidth: 20,
      borderRadius: 8,
      padding: lowPadding ? 12 : 24,
      paddingTop: 24,
      height: "100%",
      ...(heightLimit ? {maxHeight: "110vh"} : {}),
      ...(props.style || {})
    }}>
      {props.children}
    </View>
  )
}