import React from "react";
import MobileLayout from "../../../../components/MobileLayout";
import { TextL, View } from "../../../../seperated/react-native";
import CardButtonMini from "../../../../components/buttons/CardButtonMini";
import OnlineErrorCount from "../../../../components/control/OnlineErrorCount";
import LeftPaneNavItem from "../../../../components/LeftPane/LeftPaneNavItem";
import { BORDER_LIGHT, SHADOW_LIGHT } from "../../../../components/constants/style";
import { navigate } from "../../../../seperated/components/Router";
import AirAdderStore from "../../../../stores/AirAdderStore";

export default function ControlAirAddTypePage(props) {
  return (
    <MobileLayout title="เพิ่มอุปกรณ์">
      <TextL style={{marginBottom: 16}}>รูปแบบอุปกรณ์</TextL>

      <CardButtonMini
        title="Split Type"
        hasArrow={true}
        onPress={()=>{
          AirAdderStore.setDeviceAirType(1);
          navigate("control_air_add_split_type_1")
        }}
        style={{marginBottom: 12}}
      ></CardButtonMini>

      <CardButtonMini
        title="VRV/VRF"
        hasArrow={true}
        onPress={()=>{
          AirAdderStore.setDeviceAirType(2);
          navigate("control_air_add_split_type_1")
        }}
        style={{marginBottom: 12}}
      ></CardButtonMini>

      {/* <CardButtonMini
        title="Chiller"
        hasArrow={true}
        onPress={()=>{
          AirAdderStore.setDeviceAirType(3);
          navigate("control_air_add_chiller_1")
        }}
        style={{marginBottom: 12}}
      ></CardButtonMini>

      <CardButtonMini
        title="Heat-Exchanger"
        hasArrow={true}
        onPress={()=>{

          navigate("control_air_add_heat_exchanger_1")
        }}
        style={{marginBottom: 12}}
      ></CardButtonMini>

      <CardButtonMini
        title="Manual"
        hasArrow={true}
        onPress={()=>{
          AirAdderStore.setDeviceAirType(4);
          navigate("control_air_manual_1")
        }}
        style={{marginBottom: 12}}
      ></CardButtonMini> */}
    </MobileLayout>
  );
}
