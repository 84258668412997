import React, { useState } from "react"
import { IS_MOBILE } from "../seperated/screen-mode";
import MobileLayout from "../components/MobileLayout";
import Layout from "../components/Layout";
import { FLEX_COLUMN_CENTER_CENTER, FLEX_ROW_CENTER_CENTER } from "../components/constants/style";
import { View, Image, TextL, Text, TextXL, TouchableOpacity } from "../seperated/react-native";
import CommonButton from "../components/buttons/CommonButton";
import ControlAirAddBuilding from "./Control/Air/Add/ControlAirAddBuilding";
import { navigate } from "../seperated/components/Router";
import { applySnapshotAuto, createFromModel } from "../utils/mobx";
import AirAdderStore from "../stores/AirAdderStore";
import { getType } from "mobx-state-tree";
import { navigateToScanQr } from "./ScanQr";

function NoBuildingInner(props) {
  const [showAddModal, setShowAddModal] = useState(false)

  return (
    <View style={{flex: 1, flexDirection: "column", height: "100%"}}>
      <View style={{...FLEX_COLUMN_CENTER_CENTER, flexGrow: 1}}>
        <Image src={require("../img/graphics/building.png")} width={250} style={{marginBottom: 16}}></Image>
        <TextL style={{marginBottom: 8}}>ไม่พบอาคาร</TextL>
        <Text style={{color: "rgb(128, 128, 128)"}}>กรุณาเพิ่มอาคาร</Text>
      </View>

      <View style={{...FLEX_ROW_CENTER_CENTER, marginBottom: 24}}>
        <CommonButton variant="primary" style={{flexGrow: 1, maxWidth: 600}} onPress={()=>{
          navigateToScanQr();
        }}>Scan QR Code เพื่อเข้าใช้งาน</CommonButton>
      </View>

      <View style={{...FLEX_ROW_CENTER_CENTER}}>
        <CommonButton variant="default" style={{flexGrow: 1, maxWidth: 600}} onPress={()=>{
          applySnapshotAuto(AirAdderStore.building, createFromModel(getType(AirAdderStore.building)))

          if (IS_MOBILE) {
            navigate("control_air_add_building_top", {add_building_only: 1})
          } else {
            setShowAddModal(true)
          }
          
        }}>เพิ่มอาคาร</CommonButton>
      </View>

      <ControlAirAddBuilding addBuildingOnly={true} show={showAddModal} onClose={()=>setShowAddModal(false)}></ControlAirAddBuilding>
    </View>
  )
}

export default function NoBuilding(props) {
  if (IS_MOBILE) {
    return (
      <MobileLayout title="AirIn1" menu back={false}>
        <NoBuildingInner {...props}></NoBuildingInner>
      </MobileLayout>
    )
  } else {
    return (
      <Layout>
        <NoBuildingInner {...props}></NoBuildingInner>
      </Layout>
    )
  }
}