import React from "react"
import ControlAirButton from "./ControlAirButton"
import { View } from "../../../seperated/react-native"
import { IS_MOBILE } from "../../../seperated/screen-mode"
import AirStore from "../../../stores/AirStore"
import { navigate } from "../../../seperated/components/Router"
import ScopeStore from "../../../stores/ScopeStore"

const ON_ICON = require("../../../img/controls/control/calendar-active.png")
const OFF_ICON = require("../../../img/controls/control/calendar.png")

export default function ControlAirScheduleButton({on}) {
  return <>
    <View style={IS_MOBILE ? {flexBasis: "33.33%", paddingHorizontal: 4, marginBottom: 8} : {}}>
      <ControlAirButton icon={on ? ON_ICON : OFF_ICON} text="Schedule" onPress={()=>navigate("schedule_list", {type: ScopeStore.controlActiveType, id: ScopeStore.controlActiveId, entity: "device_control_air"})}></ControlAirButton>
    </View>
  </>
}