import * as React from "react";
import Layout from "./components/Layout";
import Counter from "./components/Counter";
import FetchData from "./components/FetchData";

import "./custom.css";
import { useFlaggerPredefined } from "./hooks/Flagger";
import ProfileEditPage from "./pages/ProfileEditPage";
import Home from "./pages/Home";
import CartPage from "./pages/CartPage";
import { RouteContainer, Route } from "./seperated/components/Router";

import { Route as RouteRaw, Switch } from "react-router";
import Login from "./pages/Login";
import Register from "./pages/Register";
import RegisterDetail from "./pages/RegisterDetail";
import RegisterMobile from "./pages/RegisterMobile";
import Dashboard from "./pages/Dashboard";
import Settings from "./pages/Settings";
import ControlAirList from "./pages/Control/Air/ControlAirList";
import ControlAirMap from "./pages/Control/Air/ControlAirMap";
import ControlAirManual from "./pages/Control/Air/ControlAirManual";
import ControlLandingPage from "./pages/Control/ControlLandingPage";
import ControlSelectPage from "./pages/Control/ControlSelectPage";
import ControlAirTypePage from "./pages/Control/Air/ControlAirTypePage";
import { IS_MOBILE } from "./seperated/screen-mode";
import { ControlAirAddBuildingTypePage } from "./pages/Control/Air/Add/ControlAirAddBuildingType";
import ControlAirAddBuilding, { ControlAirAddBuildingTopPage, ControlAirAddBuildingฺBottomPage } from "./pages/Control/Air/Add/ControlAirAddBuilding";
import ControlAirAddTypePage from "./pages/Control/Air/Add/ControlAirAddTypePage";
import { ControlAirAddSplitType1Page } from "./pages/Control/Air/Add/ControlAirAddSplitType1";
import ControlAirAddAnalyzeAC, { ControlAirAddAnalyzeACPage } from "./pages/Control/Air/Add/ControlAirAnalyzeAC";
import { ControlAirAddInsurancePage } from "./pages/Control/Air/Add/ControlAirAddInsurance";
import { ControlAirAddAdvanceInfoPage } from "./pages/Control/Air/Add/ControlAirAddAdvanceInfo";
import { RegisterSuccessPage } from "./pages/Success/RegisterSuccessPage";
import { ControlAirAddSuccessPage } from "./pages/Success/ControlAirAddSuccessPage";
import NoBuilding from "./pages/NoBuilding";
import ControlAirManage from "./pages/Control/Air/ControlAirManage";
import ScheduleListPage from "./pages/Schedule/ScheduleListPage";
import ScheduleDetailPage from "./pages/Schedule/ScheduleDetailPage";
import RoleAcceptQr from "./pages/RoleAcceptQr";
import ResetPasword from "./pages/ResetPassword";
import LoginLanding from "./pages/LoginLanding";
import DeviceHistoryPage from "./pages/Control/DeviceHistoryPage";
import ControlGroupList from "./pages/Control/ControlGroupList";
import ControlGroupEdit from "./pages/Control/ControlGroupEdit";
import ControlAirSettings from "./pages/Control/Air/ControlAirSettings";
import { ScanQrPage } from "./pages/ScanQr";
import ScopeStore from "./stores/ScopeStore";
import EnergyPage from "./pages/Energy/EnergyPage";
import EnergyChartPage from "./pages/Energy/EnergyChartPage";
import LoginToken from "./pages/LoginToken";
import TOS from "./pages/TOS";
import RegisterEmail from "./pages/RegisterEmail";
import ControlAirApiPage from "./pages/ControlAirApiPage";
import PermissionPage from "./pages/PermissionPage";
import { InvitePage } from "./pages/Membership/InvitePages";
import ErrorLogPage from "./pages/ErrorLogPage";
import EnergyManagementPage from "./pages/Energy/EnergyManagementPage";
import LoginOtp from "./pages/LoginOtp";

export default function Routes() {
  return (
    <RouteContainer>
      <Route name="" component={ScopeStore.accessToken ? Dashboard : ( IS_MOBILE ? LoginLanding : Home )} />

      <Route name="tos" component={TOS} />

      <Route name="login" component={LoginLanding} />
      <Route name="login_otp" component={LoginOtp} />
      <Route name="login_email" component={Login} />
      <Route name="login_token" component={LoginToken} />
      <Route name="register" component={Register} />
      <Route name="register_detail" component={RegisterDetail} />
      <Route name="register_mobile" component={RegisterMobile} />
      <Route name="register_email" component={RegisterEmail} />
      <Route name="register_success" component={RegisterSuccessPage} />
      <Route name="reset_password" component={ResetPasword} />

      <Route name="no_building" component={NoBuilding} />

      <Route name="dashboard" component={Dashboard} />
      <Route name="dashboard_after_add_air" component={Dashboard} />
      <Route name="settings" component={Settings} />
      <Route name="permissions" component={PermissionPage} />

      <Route name="energy" component={EnergyPage} />
      <Route name="energy_chart" component={EnergyChartPage} />
      <Route name="energy_management" component={EnergyManagementPage} />

      <Route name="scan_qr" component={ScanQrPage} />
      <Route name="invite" component={InvitePage} />
      <Route name="roleqr" component={RoleAcceptQr} />

      <Route name="schedule_list" component={ScheduleListPage} />
      <Route name="schedule_list_request" component={ScheduleListPage} />
      <Route name="schedule_detail" component={ScheduleDetailPage} />
      <Route name="schedule_detail_duplicate" component={ScheduleDetailPage} />
      <Route name="schedule_detail_view" component={ScheduleDetailPage} />
      <Route name="schedule_detail_edit" component={ScheduleDetailPage} />

      <Route name="device_history" component={DeviceHistoryPage} />

      <Route name="control_landing" component={ControlLandingPage} />
      <Route name="control_select" component={ControlSelectPage} />

      <Route name="control_air_api" component={ControlAirApiPage} />

      <Route name="control_air_type" component={ControlAirTypePage} />
      <Route name="control_air_list" component={ControlAirList} />
      <Route name="control_air_list_device_control_air" component={ControlAirList} />
      <Route name="control_air_list_device_control_sensor" component={ControlAirList} />
      <Route name="control_air_list_building" component={ControlAirList} />
      <Route name="control_air_list_floor" component={ControlAirList} />
      <Route name="control_air_list_room" component={ControlAirList} />
      <Route name="control_air_list_group" component={ControlAirList} />
      <Route name="control_air_list_after_add" component={ControlAirList} />

      <Route name="control_group_list" component={ControlGroupList} />
      <Route name="control_group_edit" component={ControlGroupEdit} />

      <Route name="control_air_map" component={ControlAirMap} />
      <Route name="control_air_map_building" component={ControlAirMap} />
      <Route name="control_air_map_floor" component={ControlAirMap} />
      <Route name="control_air_map_room" component={ControlAirMap} />
      <Route name="control_air_map_device" component={ControlAirManual} />
      <Route name="control_air_manual" component={ControlAirManual} />
      <Route name="control_air_settings" component={ControlAirSettings} />

      <Route name="control_air_manage" component={ControlAirManage} />
      <Route name="control_air_manage_building" component={ControlAirManage} />
      <Route name="control_air_manage_floor" component={ControlAirManage} />
      <Route name="control_air_manage_room" component={ControlAirManage} />

      <Route name="control_air_add_building_type" component={ControlAirAddBuildingTypePage} />
      <Route name="control_air_add_building_top" component={ControlAirAddBuildingTopPage} />
      <Route name="control_air_add_building_bottom" component={ControlAirAddBuildingฺBottomPage} />
      <Route name="control_air_add_type" component={ControlAirAddTypePage} />
      <Route name="control_air_add_split_type_1" component={ControlAirAddSplitType1Page} />
      <Route name="control_air_add_analyze_ac" component={ControlAirAddAnalyzeACPage} />
      <Route name="control_air_add_insurance" component={ControlAirAddInsurancePage} />
      <Route name="control_air_add_advance_info" component={ControlAirAddAdvanceInfoPage} />
      <Route name="control_air_add_success" component={ControlAirAddSuccessPage} />

      <Route name="error_log" component={ErrorLogPage} />

    </RouteContainer>
  );
}
