import React from "react"
import { SuccessPage, SuccessDialog } from "./SuccessPage"
import { navigate, getRouteParam } from "../../seperated/components/Router"
import Layout from "../../components/Layout"
import { IS_MOBILE } from "../../seperated/screen-mode"
import AirAdderStore from "../../stores/AirAdderStore"
import ScopeStore from "../../stores/ScopeStore"

const TITLE = "เพิ่มอุปกรณ์"

function onNavigate(props) {
  let id = IS_MOBILE ? getRouteParam(props, "id") : AirAdderStore.activePage.split(":")[1];

  // navigate("control_air_manual", {type: "device", id: deviceId});

  ScopeStore.setActiveBuilding(id);
  navigate("control_air_list_after_add", {type: "building", id: id})

  AirAdderStore.setActivePage("")
}

export function ControlAirAddSuccessPage(props) {
  if (!IS_MOBILE) {
    return (
      <Layout>
        <ControlAirAddSuccessDialog {...props}></ControlAirAddSuccessDialog>
      </Layout>
    )
  }

  return (
    <SuccessPage 
      title={TITLE}
      onNavigate={()=>onNavigate(props)}
    ></SuccessPage>
  )
}

export function ControlAirAddSuccessDialog(props) {
  return (
    <SuccessDialog
      title={TITLE}
      onNavigate={()=>onNavigate(props)}
    ></SuccessDialog>
  )
}