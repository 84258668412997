import * as React from "react";

import { FormState, useFormStateAgain } from "../../hooks/FormState";
import classNames from "classnames";
import { DEV } from "./../../config";
import { debounce } from "lodash";
import { inputOnChange } from "./core_logic/onChange";
import { TextInput, View, Image, TouchableOpacity, Text } from "../../seperated/react-native";
import InputCompleteBase from "./core_logic/InputCompleteBase";

import { TextField } from "@material-ui/core";
import { FLEX_ROW_CENTER, FLEX_ROW_CENTER_CENTER } from "../constants/style";
import { IS_MOBILE } from "../../seperated/screen-mode";
import FilePickerTouchableOpacity from "../../seperated/components/FilePickerTouchableOpacity";
import DisabledContext, { DisabledOpacity } from "./DisabledContext";
import { useState } from "react";

const BUTTON_SIZE = 110;

export default function IconSelectComplete({
  variant = "circle",
  options,
  ...props
}) {
  let model = useFormStateAgain(props.model, props.defaultValue || "");
  let url = model.dataState.get();

  let [newOptions, setNewOptions] = useState([...options]);
  let matchingOption = newOptions.find(x => x.value == url)

  if (url && !matchingOption) {
    setNewOptions([...newOptions, {value: url, icon: url}])
  }

  let disabled = React.useContext(DisabledContext)

  return (
    <DisabledOpacity value={disabled}>
      <InputCompleteBase {...props} model={model}>
        <View style={{ ...FLEX_ROW_CENTER, flexWrap: "wrap" }}>
          {newOptions.map((option, i) => (
            <View style={{ flexBasis: 100 / (IS_MOBILE ? 3 : 6) + "%", padding: 8, height: "100%" }} key={i}>
              <TouchableOpacity
                style={{
                  borderRadius: 50000,
                  borderColor: model.dataState.get() == option.value ? "#49c5f1" : "#ededed",
                  borderWidth: 3,
                  padding: 8,
                  height: BUTTON_SIZE,
                  width: BUTTON_SIZE,
                  ...FLEX_ROW_CENTER_CENTER,
                }}
                onPress={()=>model.dataState.set(option.value)}
              >
                <Image src={option.icon} width={90} height={90} resizeMode="contain" borderRadius={10000}></Image>
              </TouchableOpacity>
            </View>
          ))}
  
          <View style={{ flexBasis: 100 / 6 + "%", padding: 8, height: "100%" }}>
            <FilePickerTouchableOpacity
              style={{
                borderRadius: 50000,
                borderColor: "#ededed",
                borderWidth: 3,
                padding: 8,
                height: BUTTON_SIZE,
                width: BUTTON_SIZE,
                ...FLEX_ROW_CENTER_CENTER,
              }}
  
              onChange={(url) => {
                model.dataState.set(url)
              }}
            >
              <Text style={{fontSize: 76, lineHeight: 48, marginBottom: 8, color: "#b1b1b1"}}>+</Text>
            </FilePickerTouchableOpacity>
          </View>
        </View>
  
        {/* <TextInput
          value={model.dataState.get()}
          variant={variant}
          {...props}
          //className={classNames([ "form-control form-control-solid " + (model.errorState.get() ? 'is-invalid' : ''), props.className||"" ])}
          onChange={(event, ...args) =>
            inputOnChange(props, model, event.target.value, event, ...args)
          }
        /> */}
      </InputCompleteBase>
    </DisabledOpacity>
  );
}
