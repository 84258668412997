import React from "react"
import { FlatList, ScrollView } from "../../seperated/react-native";
import MapListItem from "./MapListItem";
import { globalLoadingFn } from "../../utils/loading";
import { api } from "../../config";
import ScopeStore from "../../stores/ScopeStore";
import { observer } from "mobx-react-lite";
import { navigate } from "../../seperated/components/Router";
import { DEFAULT_POSITION, DEFAULT_AVATAR } from "../constants/options";
import { IS_MOBILE } from "../../seperated/screen-mode";
import { deviceNotThisType } from "../../utils/deviceUtils";

export default observer<any>(({data, type, editMode, onChange=(item, text)=>{}, addMode=false, parentType, parentId, controlEntity, ...props}) => {
  // console.log("rerender")

  return (
    <ScrollView>
      <FlatList
        data={data}
        renderItem={({item})=>{
          // console.log(ScopeStore.mapActiveType, item.type, ScopeStore.mapActiveId, item.id);

          if (deviceNotThisType(item, controlEntity)) {
            return <></>
          }

          return (
            <MapListItem
              text={item.name}
              selected={ScopeStore.mapActiveType == item.type && ScopeStore.mapActiveId == item.id}
              onChange={async text=>{
                await globalLoadingFn(()=>api.patch("/"+item.type+"s/"+item.id, {name: text}));
                onChange(item, text);
              }}
              onDelete={async text=>{
                if (window.confirm("คุณต้องการลบ "+item.name+" ใช่หรือไม่ ?")) {
                  console.log(parentType)
                  if (parentType == "group") {
                    await globalLoadingFn(()=>api.post("/groups/"+parentId+"/children/delete", {
                      reference_type: item.type,
                      reference_id: item.id,
                    }));
                  } else {
                    if (item.type == "device") {
                      await globalLoadingFn(()=>api.delete("/"+parentType+"s/"+parentId+"/devices/"+item.id));
                    } else {
                      await globalLoadingFn(()=>api.delete("/"+item.type+"s/"+item.id));
                    }
                  }

                  onChange(item, text);
                }
              }}
              onSingleTap={()=>{
                // console.log("dasdsa")
                ScopeStore.setActivePlace("map", item.type, item.id);
              }}
              onDoubleTap={()=>{
                navigate((IS_MOBILE ? "control_air_manage_" : "control_air_map_")+item.type, {type: item.type, id: item.id, edit: editMode ? "1" : "0", control_entity: controlEntity})
              }}
              editable={editMode}
            ></MapListItem>
          )
        }}
        keyExtractor={item => item.id}
        extraData={ScopeStore.mapActiveType + ScopeStore.mapActiveId + editMode}
      ></FlatList>

      {addMode && <MapListItem 
        text="" 
        forceEditing={true}
        onChange={async text => {
          await globalLoadingFn(()=>api.post("/"+type+"s/", {
            name: text, 
            [parentType + "_id"]: parseInt(parentId), 
            position: DEFAULT_POSITION,
            avatar: DEFAULT_AVATAR[type],
            picture: '/img/graphics/empty.png'
          }));
          onChange(undefined, text);
        }}
        onCancel={async () => {
          onChange(undefined, undefined);
        }}
      ></MapListItem>}
    </ScrollView>
  )
})