import 'proxy-polyfill/proxy.min.js'
import "core-js/stable";
import "regenerator-runtime/runtime";
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

//import "bootstrap/dist/css/bootstrap.css";
//import './style/startbootstrap/sb-admin-2.scss'
//import './custom.css'
import "./style.css";

import * as React from "react";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";
import Routes from "./Routes";
// import registerServiceWorker from "./registerServiceWorker";
import { store } from "./seperated/stores";
import { refreshUser } from "./stores_old/Auth";
import { _configSetToken, HOST, LIFF_ID } from "./config";

import { View } from "./seperated/react-native";

import { ToastContainer, toast } from "./seperated/toast";
import { LoadingContainer } from "./seperated/loading";
import ControlAirAdd from "./pages/Control/Air/ControlAirAdd";
import AirStore, { DeviceDetail } from "./stores/AirStore";
import { getType } from "mobx-state-tree";

declare global {
  interface Window {
    liff: any;
    fabric: any;
    NativeLogout: any;
    NativeBack: any;
    NativePrint: any;
    NativeScan: any;
  }
}

function App() {
  console.log(getType(AirStore));

  return (
    <>
      <Routes />
      <ToastContainer></ToastContainer>

      <ControlAirAdd></ControlAirAdd>

      {/* Must be bottom */}
      <LoadingContainer></LoadingContainer>
    </>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));

// registerServiceWorker();
