import React from "react";
import { TouchableOpacity, Text } from "../../seperated/react-native";
import TextWithIcon from "../text/TextWithIcon";
import { GestureResponderEvent } from "react-native";
import LinkOrTouchableOpacity from "../LinkOrTouchableOpacity";

export default function ButtonWithIcon({
  textProps = {},
  backgroundColor,
  icon,
  to,
  onPress,
  ...props
}: {
  textProps?: any;
  backgroundColor: string;
  icon: any;
  to?: string;
  onPress?: (event: GestureResponderEvent) => void;
  [key: string]: any;
}) {
  return (
    <LinkOrTouchableOpacity
      to={to}
      onPress={onPress}

      {...props}

      style={{
        borderRadius: 8,
        padding: 8,
        paddingHorizontal: 12,
        backgroundColor,
        ...(props.style || {}),
      }}
    >
      <TextWithIcon icon={icon} {...textProps} style={{color: "white", ...(textProps.style || {})}}>
        {props.children}
      </TextWithIcon>
    </LinkOrTouchableOpacity>
  );
}
