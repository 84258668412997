import React, { useContext } from "react"
import { WebDiv } from "../../../seperated/web-only"
import ControlAirPowerButton from "./ControlAirPowerButton"
import ControlAirModeButton from "./ControlAirModeButton"
import ControlAirScheduleButton from "./ControlAirScheduleButton"
import ControlAirTimerButton from "./ControlAirTimerButton"
import ControlAirMaintenanceButton from "./ControlAirMaintenanceButton"
import ControlAirRemoteButton from "./ControlAirRemoteButton"
import ControlAirFanSpeedButton from "./ControlAirFanSpeedButton"
import ControlAirSwingButton from "./ControlAirSwingButton"
import { IS_MOBILE } from "../../../seperated/screen-mode"
import { View } from "../../../seperated/react-native"
import { FLEX_ROW_CENTER } from "../../constants/style"
import ControlAirStoreModeContext from "./ControlAirStoreModeContext"
import AirStore from "../../../stores/AirStore"
import DisabledContext from "../../input/DisabledContext"
import { observer } from "mobx-react-lite"

export function getAirButtonDisabled(storeMode) {
  if (storeMode.startsWith("schedule")) return false;

  if (!AirStore.id) return true;

  if (!parseInt(AirStore.device_control_air.device_status)) return true;

  return false;
}

export default observer<any>(function ControlAirPanel({storeMode="air_manual"}) {
  const disabled = getAirButtonDisabled(storeMode);

  console.log(disabled, AirStore.device_control_air.device_status)

  if (IS_MOBILE) {
    return (
      <DisabledContext.Provider value={disabled}>
        <ControlAirStoreModeContext.Provider value={storeMode}>
          <View>
            <View style={{...FLEX_ROW_CENTER, flexWrap: "wrap"}}>
              <DisabledContext.Provider value={false}>
                <ControlAirScheduleButton on={true}></ControlAirScheduleButton>
              </DisabledContext.Provider>
              
              <ControlAirTimerButton on={true}></ControlAirTimerButton>

              <DisabledContext.Provider value={false}>
                <ControlAirRemoteButton></ControlAirRemoteButton>
                <ControlAirMaintenanceButton></ControlAirMaintenanceButton> 
              </DisabledContext.Provider>
              
              <ControlAirFanSpeedButton></ControlAirFanSpeedButton>
              <ControlAirSwingButton></ControlAirSwingButton>
            </View>
          </View>
        </ControlAirStoreModeContext.Provider>
      </DisabledContext.Provider>
    )
  }

  return (
    <DisabledContext.Provider value={disabled}>
      <ControlAirStoreModeContext.Provider value={storeMode}>
        <WebDiv style={{display: "grid", gridTemplateColumns: "50% 50%", width: "100%", justifyItems: "center", rowGap: 16}}>
          <DisabledContext.Provider value={false}>
            <ControlAirPowerButton></ControlAirPowerButton>
          </DisabledContext.Provider>

          <ControlAirModeButton></ControlAirModeButton>

          <DisabledContext.Provider value={false}>
            <ControlAirScheduleButton on={true}></ControlAirScheduleButton>
          </DisabledContext.Provider>

          <ControlAirTimerButton on={true}></ControlAirTimerButton>

          <DisabledContext.Provider value={false}>
            <ControlAirRemoteButton></ControlAirRemoteButton>
            <ControlAirMaintenanceButton></ControlAirMaintenanceButton>
          </DisabledContext.Provider>

          <ControlAirFanSpeedButton></ControlAirFanSpeedButton>
          <ControlAirSwingButton></ControlAirSwingButton>
        </WebDiv>
      </ControlAirStoreModeContext.Provider>
    </DisabledContext.Provider>
  )
})