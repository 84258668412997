import React from "react"

import { getFanModeText as ControlAirFanSpeedLabelFn } from "../components/control/air/ControlAirFanSpeedButton"
import { TEXT2LABEL as ControlAirModeLabel } from "../components/control/air/ControlAirModeButton"
import { TEXT2LABEL as ControlAirRemoteLabel } from "../components/control/air/ControlAirRemoteButton"
import { TEXT2LABEL as ControlAirSwingLabel } from "../components/control/air/ControlAirSwingButton"

import LeftPaneControlAirPanel from "../components/LeftPane/LeftPaneControlAirPanel"
import LeftPaneControlPanel from "../components/LeftPane/LeftPaneControlPanel"
import { date2time } from "./date"
import { placeText } from "./placeUtils"

export function ControlLeftPane({entity, ...props}) {
  switch (entity) {
    case 'device_control_air': return <LeftPaneControlAirPanel {...props} />;
    default: return <LeftPaneControlPanel {...props} />;
  }
}

export function controlEntity2deviceTypeId(entity) {
  switch (entity) {
    case 'device_control_air': return 1;
    case 'device_control_sensor': return 2;
    case 'device_control_light': return 3;
    case 'device_control_curtain': return 4;
    case 'device_control_door': return 5;
    case 'device_control_breaker': return 6;
  }
}

export function deviceNotThisType(place, controlEntity) {
  return controlEntity && place.device_type_id && place.device_type_id != controlEntity2deviceTypeId(controlEntity)
}

export function deviceCircleIconUrl(device, extension="svg") {
  let statusPart = device.status == "on" ? "blue" : "grey"

  if (device.device_type_id == 1) {
    let airTypePart;

    // console.log(device)

    switch (parseInt(device.device_control_air.device_air_type_id)) {
      case 1: airTypePart = 'S'; break;
      case 2: airTypePart = 'V'; break;
      case 3: airTypePart = 'C'; break;
      default: airTypePart = 'H'; break;
    }

    return `SVCH/${airTypePart}/active/${statusPart}.${extension}`
  } else {
    return `SVCH/S/active/${statusPart}.${extension}`
  }
}

export function controlEntityLabel(entity) {
  switch (entity) {
    case 'device_control_air': return 'แอร์';
  }
}

export function controlFieldLabel(entity, field) {
  switch (entity) {
    case 'device_control_air':
      switch (field) {
        case 'device_status': return '';
        case 'device_temp': return 'อุณหภูมิ';
        case 'device_fan_speed': return 'ความเร็วพัดลม';
        case 'device_mode': return 'โหมด';
        case 'device_is_locked_remote': return 'การล็อครีโมท'
        case 'device_swing_mode': return 'โหมด Swing'
      }
    break;
  }
}

export function controlLabel(entity, field, value) {
  if (field == "device_status") {
    if (value == 1) return "เปิด";
    else return "ปิด"
  }

  switch (entity) {
    case 'device_control_air':
      switch (field) {
        case 'device_temp': return value + ' ℃';
        case 'device_fan_speed': return ControlAirFanSpeedLabelFn(value);
        case 'device_mode': return ControlAirModeLabel[value];
        case 'device_is_locked_remote': return ControlAirRemoteLabel[value];
        case 'device_swing_mode': return ControlAirSwingLabel[value];
      }
    break;
  }
}

export function controlChangeDescription(entity, field, value) {
  if (field == "device_status") {
    return controlLabel(entity, field, value) + controlEntityLabel(entity);
  }

  return "ปรับ" + controlFieldLabel(entity, field) + ": " + controlLabel(entity, field, value);
}

export function controlHistoryDescription(history) {
  switch (history.change_entity) {
    case 'device_control_air':
      return  date2time(history.created_at) + ", " + 
              controlLabel(history.change_entity, "device_mode", history.data.device_mode) + ", " +
              controlLabel(history.change_entity, "device_temp", history.data.device_temp)
  }
}

export function changeAgentTypeDescription(agent_type) {
  switch (agent_type) {
    case 'manual': return 'แอปพลิเคชั่น'
    case 'iot': return 'รีโมทคอนโทรล'
    case 'iot_locking': return 'ล็อครีโมทคอนโทรล'
    case 'retry': return 'Retry'

    case 'schedule': return 'ตารางการทำงาน'
    case 'motion_sensor': return 'Motion Sensor'
  }
}

interface DeviceCountStruct {
  online: number;
  offline: number;
  error: number;
  total: number;
}

export const ZERO_DEVICE_COUNT = {
  online: 0,
  offline: 0,
  error: 0,
  total: 0,
}

export function flattenDevices(places: any): any[] {
  if (places.children) return flattenDevices(places.children);
  if (!Array.isArray(places)) return [places];

  let result: any[] = [];

  for (let place of places) {
    result = result.concat(flattenDevices(place));
  }

  return result;
}

export function countDevices(places: any, deviceTypeId?: number): DeviceCountStruct {
  let devices = flattenDevices(places);

  if (deviceTypeId) {
    devices = devices.filter(c => c.device_type_id == deviceTypeId);
  }

  let online = 0;
  let offline = 0;
  let error = 0;
  let total = 0;

  for (let device of devices) {
    total++;

    if (device.status == 'on') {
      online++;
    } else {
      offline++;
    }

    if (device.is_error) {
      error++;
    }
  }

  return {
    online,
    offline,
    error,
    total,
  }
}