import React from "react"
import ControlAirButton from "./ControlAirButton"
import { globalLoadingFn } from "../../../utils/loading"
import { api } from "../../../config"
import AirStore, { refreshAir } from "../../../stores/AirStore"
import ScopeStore from "../../../stores/ScopeStore"
import { applySnapshotAuto } from "../../../utils/mobx"
import { observer } from "mobx-react-lite"

const ON_ICON = require("../../../img/controls/control/power-on.png")
const OFF_ICON = require("../../../img/controls/control/power-off.png")

export async function ControlAirPowerButtonOnPress(on) {
  if (ScopeStore.controlActiveType == 'building' && !window.confirm(`ยืนยัน${on ? "ปิด" : "เปิด"}ทั้งอาคาร?`)) return;
  
  await globalLoadingFn(async ()=>{
    let response = await api.post("/devices/device_control_air/turn_"+(on ? "off" : "on")+"?scope_type="+ScopeStore.controlActiveType+"&scope_id="+ScopeStore.controlActiveId)
    applySnapshotAuto(AirStore, response.data);
  })
}

export default observer(function ControlAirPowerButton() {
  const on = AirStore.device_control_air.device_status == "1";

  return <>
    <ControlAirButton icon={on ? ON_ICON : OFF_ICON} text={on ? "On" : "Off"} onPress={()=>ControlAirPowerButtonOnPress(on)}></ControlAirButton>
  </>
})