import React from "react"
import Dialog from "../../../../components/dialog/Dialog"
import { View, Image, Text, TextMD, TextL } from "../../../../seperated/react-native"
import { FLEX_ROW_CENTER_CENTER, FLEX_ROW_CENTER, FLEX_COLUMN } from "../../../../components/constants/style"
import CardButton from "../../../../components/buttons/CardButton"
import AirAdderStore from "../../../../stores/AirAdderStore"
import CommonButton from "../../../../components/buttons/CommonButton"
import HoriTwo from "../../../../components/HoriTwo"
import TextFieldComplete from "../../../../components/input/TextFieldComplete"
import SelectCustom from "../../../../seperated/components/SelectCustom"
import MobileLayout from "../../../../components/MobileLayout"
import { navigate } from "../../../../seperated/components/Router"
import { IS_MOBILE } from "../../../../seperated/screen-mode"
import { useFormState } from "../../../../hooks/FormState"
import { applySnapshotPatch } from "../../../../utils/mobx"

function updateMobx(model) {
  applySnapshotPatch(AirAdderStore.device.device_control_air, model.dataState.get());
}

function ControlAirAddAdvanceInfoInner({model}) {
  return (
    <View style={{ ...FLEX_COLUMN, padding: IS_MOBILE ? 0 : 28, paddingHorizontal: IS_MOBILE ? 0 : 96 }}>

      <HoriTwo left={<Text>รหัสอุปกรณ์</Text>} style={{marginBottom: 16}}>
        <TextFieldComplete variant="material" model={model.$("device_air_productcode")}></TextFieldComplete>
      </HoriTwo>

      <HoriTwo left={<Text>เวอร์ชั่น</Text>} style={{marginBottom: 16}}>
        <TextFieldComplete variant="material" model={model.$("device_air_version")}></TextFieldComplete>
      </HoriTwo>

      <HoriTwo left={<Text>อัปเกรดเวอร์ชั่น</Text>} style={{marginBottom: 16}}>
        <TextFieldComplete variant="material" model={model.$("device_air_upgrade_version")}></TextFieldComplete>
      </HoriTwo>

      <HoriTwo left={<Text>ชื่อรุ่น</Text>} style={{marginBottom: 16}}>
        <TextFieldComplete variant="material" model={model.$("device_air_product_generation")}></TextFieldComplete>
      </HoriTwo>

      <HoriTwo left={<Text>Wifi</Text>} style={{marginBottom: 16}}>
        <TextFieldComplete variant="material" model={model.$("device_wifi_ssid")}></TextFieldComplete>
      </HoriTwo>

      <HoriTwo left={<Text>Password</Text>} style={{marginBottom: 16}}>
        <TextFieldComplete variant="material" model={model.$("device_wifi_password")} type="password"></TextFieldComplete>
      </HoriTwo>

      {!IS_MOBILE && <View style={{...FLEX_ROW_CENTER, marginTop: 36}}>
        <CommonButton variant="default" style={{marginRight: 16, flexGrow: 1}} onPress={()=>AirAdderStore.goToPreviousPage()}>ย้อนกลับ</CommonButton>
        <CommonButton variant="primary" style={{marginLeft: 16, flexGrow: 1}} onPress={()=>{
          updateMobx(model)
          AirAdderStore.setActivePage("insurance")
        }}>ถัดไป</CommonButton>
      </View>}
    </View>
  )
}

export function ControlAirAddAdvanceInfoPage(props) {
  const model = useFormState()

  return (
    <MobileLayout 
      title="ข้อมูลอุปกรณ์ขั้นสูง"
      bottom={
        <View style={{...FLEX_COLUMN}}>
          <CommonButton variant="default" onPress={()=>AirAdderStore.goToPreviousPage()} style={{marginBottom: 16}}>ย้อนกลับ</CommonButton>
          <CommonButton variant="primary" onPress={()=>{updateMobx(model); navigate("control_air_add_insurance");}}>ถัดไป</CommonButton>
        </View>
      }
    >
      <ControlAirAddAdvanceInfoInner {...props} model={model}></ControlAirAddAdvanceInfoInner>
    </MobileLayout>
  )
}

export default function ControlAirAddAdvanceInfo(props) {
  const model = useFormState()

  return (
    <Dialog show={props.show} title="ข้อมูลเพิ่มเติม" hasClose onClose={()=>AirAdderStore.setActivePage("")}>
      <ControlAirAddAdvanceInfoInner {...props} model={model}></ControlAirAddAdvanceInfoInner>
    </Dialog>
  )
}