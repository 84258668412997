import React from "react"
import { View, Text } from "../../../seperated/react-native"
import { TEXT_WRAP } from "../../constants/style";

export default function InputCompleteBase(props) {
  let variant = props.variant || "solid";

  return (
    <View style={{
      display: "flex",
      flexDirection: variant.startsWith("line-hori") ? (variant == "line-hori" ? "row" : "row-reverse") : "column",
      alignItems: variant.startsWith("line-hori") ? "center" : undefined,
      justifyContent: variant == "line-hori-reverse" ? "flex-end" : "flex-start"
    }}>
      {props.label &&
        <View style={{
          flexBasis: props.labelWidth || undefined,
          marginBottom: variant == "solid" ? 12 : 0
        }}>
          <Text
            style={{
              fontWeight: variant == "solid" ? "bold" : "normal",
              color: props.labelColor || "#49c5f1",
              ...TEXT_WRAP,
              ...(props.labelStyle || {}),
            }}
          >
            {props.label}
          </Text>
        </View>
      }

      <View>
        {props.children}
      </View>

      {/* {props.model.errorState.get() && <div className="invalid-feedback">{props.model.errorState.get()}</div>} */}
    </View>
  )
}