import React, { useState, useEffect } from "react";
import { View, TouchableOpacity } from "react-native-web";
import { FLEX_ROW_CENTER } from "../../components/constants/style";
import { TextMD, TextL } from "../../seperated/react-native";
import Plot from "react-plotly.js";
import { globalLoadingFn } from "../../utils/loading";
import { api } from "../../config";

const INTERVAL_MODES = [
  { mode: "d", label: "วัน" },
  { mode: "w", label: "สัปดาห์" },
  { mode: "mo", label: "เดือน" },
  { mode: "y", label: "ปี" },
  { mode: "all", label: "ทั้งหมด" },
];

const MONTH_LABEL = [
  "มกราคม",
  "กุมภาพันธ์",
  "มีนาคม",
  "เมษายน",
  "พฤษภาคม",
  "มิถุนายน",
  "กรกฎาคม",
  "สิงหาคม",
  "กันยายน",
  "ตุลาคม",
  "พฤศจิกายน",
  "ธันวาคม",
];

export function activeDateText(activeDate: Date, activeIntervalMode: string): string {
  let past6day = new Date(activeDate);
  past6day.setDate(past6day.getDate() - 6);

  switch (activeIntervalMode) {
    case 'mo': return MONTH_LABEL[activeDate.getMonth()] + " " + activeDate.getFullYear();
    case 'w': return past6day.getDate() + " - " + activeDate.getDate() + " " + MONTH_LABEL[activeDate.getMonth()] + " " + activeDate.getFullYear();
    case 'y': return activeDate.getFullYear().toString();
    case 'all': return 'ทั้งหมด';
    default: return activeDate.getDate() + " " + MONTH_LABEL[activeDate.getMonth()] + " " + activeDate.getFullYear();
  }
}

function addActiveDate(activeDate: Date, activeIntervalMode: string, delta: number) {
  let date = new Date(activeDate);

  switch (activeIntervalMode) {
    case 'mo': date.setMonth(date.getMonth() + delta); return date;
    case 'y': date.setFullYear(date.getFullYear() + delta); return date;
    case 'all': return date;
    default: date.setDate(date.getDate() + delta); return date;
  }
}

export default function EnergyChart(props) {
  const [activeIntervalMode, setActiveIntervalMode] = useState(
    props.activeIntervalMode || "d"
  );
  const [activeDate, setActiveDate] = useState(new Date());
  const [chartData, setChartData] = useState<any>([]);

  const chartType = props.chartType;
  const activeLine = props.activeLine || ['Vrv'];

  useEffect(() => {
    async function f() {
      let response = await globalLoadingFn(() => api.get("/sensors/chart", {
        params: {
          scope_type: props.scopeType,
          scope_id: props.scopeId,
          interval: activeIntervalMode,
          date: activeDate,
          chart_type: chartType,
        }
      }))

      let newChartData = response.data;
      let newChartDataLines = newChartData.data.filter(c => activeLine.indexOf(c.name) != -1);

      if (newChartDataLines.length == 0) {
        console.log("FUCK")
        newChartDataLines = response.data.data;
      }

      newChartData.data = newChartDataLines;

      console.log(response.data)

      setChartData(newChartData)

      if (props.onDataChange) props.onDataChange({
        activeIntervalMode,
        activeDate,
        chartData: response.data
      });
    }
    f();
  }, [activeDate, activeIntervalMode])

  return (
    <View>
      <View
        style={{
          ...FLEX_ROW_CENTER,
          justifyContent: "space-around",
          marginBottom: 16,
        }}
      >
        {INTERVAL_MODES.map((mode) => (
          <TouchableOpacity onPress={() => setActiveIntervalMode(mode.mode)}>
            <TextMD
              style={{
                color: activeIntervalMode == mode.mode ? "black" : "#999",
                paddingHorizontal: 12,
              }}
            >
              {mode.label}
            </TextMD>

            {mode.mode == activeIntervalMode && (
              <View
                style={{
                  backgroundColor: "#49c5f1",
                  height: 10,
                }}
              ></View>
            )}
          </TouchableOpacity>
        ))}
      </View>

      <View style={{ ...FLEX_ROW_CENTER, justifyContent: "space-between" }}>
        <TouchableOpacity onPress={() => setActiveDate(addActiveDate(activeDate, activeIntervalMode, -1))}>
          <TextL>&lt;</TextL>
        </TouchableOpacity>

        <TextMD>{activeDateText(activeDate, activeIntervalMode)}</TextMD>

        <TouchableOpacity onPress={() => setActiveDate(addActiveDate(activeDate, activeIntervalMode, 1))}>
          <TextL>&gt;</TextL>
        </TouchableOpacity>
      </View>

      <View>
        <Plot
          data={chartData.data}
          layout={{
            height: 300,
            showlegend: false,
            margin: {
              l: 24,
              r: 24,
              t: 24,
              b: 24,
            },
          }}
        />
      </View>
    </View>
  );
}
