import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { navigate, navigateBack } from './components/Router';

export function alert_success(msg) {
  toast.success(msg)
}

export function alert_error(msg) {
  toast.error(msg)
}

export function alert_info(msg) {
  toast.info(msg)
}

export function alert_warning(msg) {
  toast.warning(msg)
}

export function alertErrorComplete(err, {performBack404 = false} = {}) {
  console.error(err)
  if (err.response) {
    switch (err.response.status) {
      case 401:
        if (err.response.data && err.response.data.message == "OTP") {
          navigate("login_otp");
        } else {
          alert_info("คุณต้องเข้าสู่ระบบก่อน")
          navigate("login", {redirect: window.location.href})
        }
      break;

      case 403:
        if (err.response.data && err.response.data.message) {
          if (err.response.data.message.indexOf("โปรดใช้การเข้าสู่ระบบ") != -1) {
            alert_error(err.response.data.message);
            navigate("login_email")
          } else {
            switch (err.response.data.message) {
              case 'VERIFY_MOBILE': 
                navigate("register_mobile", {
                  user_id: err.response.data.user_id,
                  mobile_number: err.response.data.mobile_number,
                  otp_secret: err.response.data.otp.otp_secret
                });
                break;
  
              case 'VERIFY_EMAIL':
                navigate("register_email", {
                  email: err.response.data.email,
                });
                break;
  
              default: alert_error(err.response.data.message); break;
            }
          }
        } else {
          alert_error("สิทธิ์ของคุณไม่เพียงพอ")
        }
      break;

      case 404:
        if (err.response.data && err.response.data.message) {
          alert_error(err.response.data.message)
        } else {
          if (err.response.config.url.startsWith("/device")) {
            alert_error("ไม่มีอุปกรณ์ในระบบ ให้ตรวจสอบว่าได้ทำการเพิ่มอุปกรณ์แล้วหรือยัง")
          } else {
            alert_error("ไม่พบข้อมูล" + (!performBack404 ? " กรุณาย้อนกลับ แล้วตรวจสอบว่ามีข้อมูลหรือไม่" : ""))
          }
        }

        if (performBack404) {
          navigateBack();
        }
      break;

      default:
        if (err.response.data && err.response.data.message) {
          alert_error(err.response.data.message)
        } else {
          alert_error("เกิดข้อผิดพลาดที่ไม่ทราบสาเหตุ")
        }
    }
  } else {
    if (err.message) {
      alert_error(err.message)
    } else {
      alert_error("เกิดข้อผิดพลาดที่ไม่ทราบสาเหตุ")
    }
  }
}

export { ToastContainer, toast }