import React from "react";
import { TouchableOpacity } from "../../seperated/react-native";
import IconInline from "../icon/IconInline";

const INACTIVE_ICON = {
  circle: require("../../img/icons/checkbox-inactive.png"),
};

const ACTIVE_ICON = {
  circle: require("../../img/icons/checkbox-active.png"),
};

export default function Checkbox({
  active,
  onChange = (val) => {},
  variant = "circle",
  ...props
}) {
  return (
    <TouchableOpacity onPress={() => onChange(!active)}>
      {active ? (
        <IconInline src={ACTIVE_ICON[variant]} size={20} {...props} />
      ) : (
        <IconInline src={INACTIVE_ICON[variant]} size={20} {...props} />
      )}
    </TouchableOpacity>
  );
}
