import React, { useState } from "react";
import Panel from "../Panel";
import LeftPaneNavItem from "./LeftPaneNavItem";
import { View, Image, TextL, TouchableOpacity, TextSM } from "../../seperated/react-native";
import UserAvatar from "../avatar/UserAvatar";
import AirAdderStore from "../../stores/AirAdderStore";
import { InviteDialog } from "../../pages/Membership/InvitePages";
import { navigate, getRouteParam, navigateSameRoute } from "../../seperated/components/Router";
import ScopeStore, { refreshActiveBuilding } from "../../stores/ScopeStore";
import { TEXT_WRAP, TEXT_WRAP_CENTER } from "../constants/style";
import AvatarImage from "../avatar/AvatarImage";
import { globalLoadingFn } from "../../utils/loading";
import { api } from "../../config";
import { useScheduleCount } from "../../hooks/ScheduleCount";
import PathBreadcrumb from "../PathBreadcrumb";
import { navigateToScanQr } from "../../pages/ScanQr";

export default function LeftPaneControlPanel(props) {
  const type = getRouteParam(props, "type")
  const id = parseInt(getRouteParam(props, "id") || "0");
  const [showInviteDialog, setShowInviteDialog] = useState(false)
  const {scheduleActiveCount, schedulePendingCount} = useScheduleCount(type, id);

  return (
    <Panel lowPadding style={{zIndex: 1000}}>
      <View
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginBottom: 28,
        }}
      >
        <TextL style={{marginBottom: (props.data?.path || []).length > 0 ? 4 : 16, ...TEXT_WRAP_CENTER}}>{props.data?.name || "กำลังโหลด..."}</TextL>
        <PathBreadcrumb path={props.data?.path}></PathBreadcrumb>
        <AvatarImage src={props.data?.avatar} />
      </View>

      <LeftPaneNavItem
        icon={require("../../img/icons/power.png")}
        label="ควบคุมอุปกรณ์"
        defaultShow={props.active && props.active.startsWith("device_control")}
        hasChild
      >
        <LeftPaneNavItem
          icon={require("../../img/icons/air.png")}
          label="เครื่องปรับอากาศ"
          active={props.active.startsWith("device_control_air")}
          onPress={()=>navigate("control_air_list_device_control_air", {type: "building", id: ScopeStore.activeBuilding, control_entity: "device_control_air"})}
          isChild
        ></LeftPaneNavItem>

        {/* <LeftPaneNavItem
          icon={require("../../img/icons/air.png")}
          label="Sensor"
          active={props.active.startsWith("device_control_sensor")}
          onPress={()=>navigate("control_air_list_device_control_sensor", {type: "building", id: ScopeStore.activeBuilding, control_entity: "device_control_sensor"})}
          isChild
        ></LeftPaneNavItem> */}

        <LeftPaneNavItem
          icon={require("../../img/icons/air.png")}
          label="หลอดไฟ"
          active={props.active.startsWith("device_control_light")}
          onPress={()=>navigate("control_air_list_device_control_air", {type: "building", id: ScopeStore.activeBuilding, control_entity: "device_control_light"})}
          isChild
        ></LeftPaneNavItem>

        {/* <LeftPaneNavItem
          icon={require("../../img/icons/air.png")}
          label="โซล่าเซลล์"
          active={props.active.startsWith("control_solar")}
          to="control_solar"
          isChild
        ></LeftPaneNavItem>

        <LeftPaneNavItem
          icon={require("../../img/icons/air.png")}
          label="โมชั่นเซ็นเซอร์"
          active={props.active.startsWith("control_motion")}
          to="control_motion"
          isChild
        ></LeftPaneNavItem>

        <LeftPaneNavItem
          icon={require("../../img/icons/air.png")}
          label="สวิซต์เซ็นเซอร์"
          active={props.active.startsWith("control_switch")}
          to="control_switch"
          isChild
        ></LeftPaneNavItem> */}

      </LeftPaneNavItem>

      <LeftPaneNavItem
        icon={require("../../img/icons/calendar.png")}
        label="ตารางการทำงาน"
        active={props.active === "schedule"}
        number={scheduleActiveCount}
        onPress={()=>navigate('schedule_list', {type, id})}
      ></LeftPaneNavItem>

      <LeftPaneNavItem
        icon={require("../../img/icons/envelope.png")}
        label="รายการคำขอ"
        number={schedulePendingCount}
        active={props.active === "schedule_request"}
        onPress={()=>navigate('schedule_list_request', {type, id, pending: 1})}
      ></LeftPaneNavItem>

      <LeftPaneNavItem
        icon={require("../../img/icons/option.png")}
        label="ผู้ใช้"
        active={props.active === "permissions"}
        onPress={()=>navigate('permissions', {type, id, left_pane: 'LeftPaneControlPanel'})}
      ></LeftPaneNavItem>

      <LeftPaneNavItem
        icon={require("../../img/icons/option.png")}
        label="อื่นๆ"
        defaultShow={props.active.startsWith("building_settings")}
        hasChild
      >
        <LeftPaneNavItem
          icon={require("../../img/icons/add2.png")}
          label="เพิ่มอุปกรณ์"
          active={props.active === "building_settings_add_device"}
          /*to="building_settings_add_device"*/
          onPress={()=>AirAdderStore.setActivePage("building_type")}
          isChild
        ></LeftPaneNavItem>

        <LeftPaneNavItem
          icon={require("../../img/icons/scan.png")}
          label="Scan QR Code"
          active={props.active === "scan_qr"}
          onPress={() => navigateToScanQr()}
          isChild
        ></LeftPaneNavItem>

        <LeftPaneNavItem
          icon={require("../../img/icons/share.png")}
          label="ชวนเพื่อน"
          active={props.active === "invite_friend"}
          /*to="building_settings_add_friend"*/
          onPress={()=>setShowInviteDialog(true)}
          isChild
        ></LeftPaneNavItem>

        {/* <LeftPaneNavItem
          icon={require("../../img/icons/delete.png")}
          label="ลบข้อมูลอาคารทั้งหมด"
          active={props.active === "building_settings_delete"}
          onPress={async ()=>{
            if (window.confirm("ยืนยันลบข้อมูลอาคารทั้งหมด")) {
              await globalLoadingFn(async ()=>{
                await api.delete("/buildings/" + ScopeStore.activeBuilding)
                let [building, buildings] = await refreshActiveBuilding()
                if (building) navigate("dashboard")
                else navigate("no_building")
              })
            }
          }}
          isChild
        ></LeftPaneNavItem> */}
      </LeftPaneNavItem>

      <LeftPaneNavItem
        icon={require("../../img/icons/home.png")}
        label="หน้าแรก"
        active={props.active === "dashboard"}
        to="dashboard"
      ></LeftPaneNavItem>

      <InviteDialog show={showInviteDialog} onClose={()=>setShowInviteDialog(false)} place={props.data}></InviteDialog>
    </Panel>
  );
}
