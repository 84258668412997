import * as React from "react";
import { View, Text, Image, TouchableHighlight } from "../../seperated/react-native";
import ContainerArea from "../ContainerArea";
import { Link } from "../../seperated/components/Router";
import ScopeStore, { loadUser } from "../../stores/ScopeStore";
import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Button, ListItemText } from "@material-ui/core";
import DropdownMenu from "../DropdownMenu";
import { MenuItem } from "@material-ui/core";
import { api } from "../../config";
import { performLogout } from "../../pages/Login";
import { FLEX_ROW_CENTER } from "../constants/style";

function AuthButton(props) {
  return (
    <Link to={props.to}>
      <View
        style={{
          width: 128,
          padding: 6,
          borderColor: "#49c5f1",
          borderWidth: 1,
          borderRadius: 8,
          shadowColor: "#49c5f1",
          shadowOpacity: 0.7,
          shadowRadius: 8,
          shadowOffset: { width: 0, height: 2 },
          marginRight: 28,
          marginTop: -2,
          marginBottom: -4,
        }}
      >
        <Text style={{ textAlign: "center", fontWeight: "bold" }}>{props.children}</Text>
      </View>
    </Link>
  );
}

export default observer((props) => {
  const user = ScopeStore.user;
  const loggedIn = ScopeStore.accessToken != "";

  return (
    <View
      style={{
        borderBottomColor: "#eee",
        borderBottomWidth: 1,
        shadowColor: "#ccc",
        shadowOpacity: 0.6,
        shadowRadius: 24,
      }}
    >
      <ContainerArea lowPadding>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        > 
          <Link to={loggedIn ? "dashboard" : ""}>
            <View style={{...FLEX_ROW_CENTER}}>
              <Image src={require("../../img/favicon-lowpadding.jpg")} height={32} style={{marginRight: 4}} />
              <Image src={require("../../img/airin1.png")} height={32} />
            </View>
          </Link>

          <View style={{ display: "flex", flexDirection: "row" }}>
            {loggedIn ? <>
              <DropdownMenu text={user ? user.profile.name : "ผู้ใช้"} right>
                <MenuItem onClick={performLogout}><ListItemText>ออกจากระบบ</ListItemText></MenuItem>
              </DropdownMenu>
            </> : <>
              <AuthButton to="login">เข้าสู่ระบบ</AuthButton>
              <AuthButton to="register">สมัครสมาชิก</AuthButton>
            </>}

            
            {/* <DropdownMenu text="ภาษาไทย" right></DropdownMenu> */}
          </View>
        </View>
      </ContainerArea>
    </View>
  );
})
