import React from "react"
import { View, Text, TextSM } from "../../seperated/react-native"
import { FLEX_ROW_CENTER } from "../constants/style"
import IconInline from "../icon/IconInline";
import EmotionIcon from "../icon/EmotionIcon";
import { toFixed2 } from "../../utils/number";

export default function SensorTable({TextComponent=TextSM, data}) {
  const CELL_STYLE = {flexBasis: "33.33%", padding: 8, paddingHorizontal: 12, ...FLEX_ROW_CENTER};
  const LEFT_CELL_STYLE = {...CELL_STYLE, borderRightColor: "#dedede", borderRightWidth: 1}
  const ICON_STYLE = {marginRight: 6}

  return (
    <View style={{borderColor: "#dedede", borderWidth: 1}}>
      {/* HEADER */}
      <View style={{...FLEX_ROW_CENTER, backgroundColor: "#f7f7f7", borderBottomColor: "#dedede", borderBottomWidth: 1, paddingVertical: 4}}>
        <View style={{...LEFT_CELL_STYLE}}>
          <TextComponent>Air quality</TextComponent>
        </View>

        <View style={{...CELL_STYLE}}>
          <IconInline src={require("../../img/icons/indoor.png")} style={{...ICON_STYLE}}></IconInline> <TextComponent>Indoor</TextComponent>
        </View>

        <View style={{...CELL_STYLE}}>
        <IconInline src={require("../../img/icons/outdoor.png")} style={{...ICON_STYLE}}></IconInline> <TextComponent>Outdoor</TextComponent>
        </View>
      </View>

      {/* BODY */}
      <View style={{paddingVertical: 4}}>
        <View style={{...FLEX_ROW_CENTER}}>
          <View style={{...LEFT_CELL_STYLE}}>
            <TextComponent>Humidity</TextComponent>
          </View>
  
          <View style={{...CELL_STYLE}}>
            <EmotionIcon style={{...ICON_STYLE}} level={200}></EmotionIcon> <TextComponent>{toFixed2(data?.last_data.humidity) ?? "---"} %</TextComponent>
          </View>
  
          <View style={{...CELL_STYLE}}>
            <EmotionIcon style={{...ICON_STYLE}} level={200}></EmotionIcon> <TextComponent>{toFixed2(data?.openweather.humidity)} %</TextComponent>
          </View>
        </View>
  
        <View style={{...FLEX_ROW_CENTER}}>
          <View style={{...LEFT_CELL_STYLE}}>
            <TextComponent>PM 2.5</TextComponent>
          </View>
  
          <View style={{...CELL_STYLE}}>
            <EmotionIcon style={{...ICON_STYLE}} level={200}></EmotionIcon> <TextComponent>{toFixed2(data?.last_data.PM2_5) ?? "---"}</TextComponent>
          </View>
  
          {/* <View style={{...CELL_STYLE}}>
            <EmotionIcon style={{...ICON_STYLE}} level={200}></EmotionIcon> <TextComponent>281</TextComponent>
          </View> */}
        </View>
  
        <View style={{...FLEX_ROW_CENTER}}>
          <View style={{...LEFT_CELL_STYLE}}>
            <TextComponent>CO2</TextComponent>
          </View>
  
          <View style={{...CELL_STYLE}}>
            <EmotionIcon style={{...ICON_STYLE}} level={200}></EmotionIcon> <TextComponent>{toFixed2(data?.last_data.CO2) ?? "---"} kg</TextComponent>
          </View>
        </View>
      </View>

    </View>
  )
}