import React from "react"
import { SuccessPage, SuccessDialog } from "./SuccessPage"
import { navigate, getRouteParam } from "../../seperated/components/Router"
import Layout from "../../components/Layout"
import { IS_MOBILE } from "../../seperated/screen-mode"
import { performLogin } from "../Login"

const TITLE = "ลงทะเบียนสำเร็จ"

async function onNavigate(props) {
  await performLogin({user_id: parseInt(getRouteParam(props, "user_id") || "0"), token: getRouteParam(props, "token")})
}

export function RegisterSuccessPage(props) {
  if (!IS_MOBILE) {
    return (
      <Layout>
        <RegisterSuccessDialog {...props}></RegisterSuccessDialog>
      </Layout>
    )
  }

  return (
    <SuccessPage 
      title={TITLE}
      onNavigate={()=>onNavigate(props)}
    ></SuccessPage>
  )
}

export function RegisterSuccessDialog(props) {
  return (
    <SuccessDialog
      title={TITLE}
      onNavigate={()=>onNavigate(props)}
    ></SuccessDialog>
  )
}