import React, { useState, useEffect } from "react";

import Layout, { LeftPane, CenterPane } from "../../../components/Layout";
import { View, Text, Image, TextInputSolid, TouchableOpacity, TextMD, FlatList, ScrollView, TextL } from "../../../seperated/react-native";
import TextFieldComplete from "../../../components/input/TextFieldComplete";
import { IS_MOBILE } from "../../../seperated/screen-mode";
import { SHADOW_LIGHT, BORDER_LIGHT, FLEX_ROW_CENTER, FLEX_LR_CENTER, FLEX_COLUMN, FLEX_COLUMN_CENTER_CENTER, TEXT_WRAP, TEXT_WRAP_CENTER } from "../../../components/constants/style";
import { Link, navigate, getRouteParam, navigateBack } from "../../../seperated/components/Router";
import LeftPaneControlPanel from "../../../components/LeftPane/LeftPaneControlPanel";
import Panel from "../../../components/Panel";
import PanelInnerGrid from "../../../components/PanelInnerGrid";
import PanelInner from "../../../components/PanelInner";
import DropdownLink from "../../../components/DropdownLink";
import ButtonWithIcon from "../../../components/buttons/ButtonWithIcon";
import MapListItem from "../../../components/list/MapListItem";
import AddAirconButton from "../../../components/buttons/AddAirconButton";
import { globalLoadingFn } from "../../../utils/loading";
import { api } from "../../../config";
import { globalLoading } from "../../../seperated/loading";
import MapList from "../../../components/list/MapList";
import { WebDiv } from "../../../seperated/web-only";
import { nextPlaceType } from "../../../utils/placeUtils";
import { BUILD_CONTROL_PLACE_LINK } from "../../../components/constants/link";
import ManagePlaceButton from "../../../components/buttons/ManagePlaceButton";
import TextWithIcon from "../../../components/text/TextWithIcon";
import TextWithBack from "../../../components/text/TextWithBack";
import ScopeStore from "../../../stores/ScopeStore";
import OnlineErrorCount from "../../../components/control/OnlineErrorCount";
import EditableText from "../../../components/text/EditableText";
import ControlPlaceAddDeviceDialog from "../../../components/control/ControlPlaceAddDeviceDialog";
import MapImageView from "../../../components/map/MapImageView";
import ControlAirVertButton from "../../../components/control/air/ControlAirVertButton";
import MapImageEdit from "../../../components/map/MapImageEdit";
import AvatarImage from "../../../components/avatar/AvatarImage";
import FilePickerTouchableOpacity from "../../../seperated/components/FilePickerTouchableOpacity";
import ControlPlaceEditBasicDialog from "../../../components/control/ControlPlaceEditBasicDialog";


export default function ControlAirMap(props) {
  const [data, setData] = useState<any>();
  const [addMode, setAddMode] = useState(false);

  const type = getRouteParam(props, "type")
  const id = getRouteParam(props, "id");
  const controlEntity = getRouteParam(props, "control_entity") || "device_control_air";
  const editMode = getRouteParam(props, "edit") == "1";

  const [mapEditMode, setMapEditMode] = useState(false);
  const [showPlaceEditDialog, setShowPlaceEditDialog] = useState(false);

  const DROPDOWN_LINK_OPTIONS = BUILD_CONTROL_PLACE_LINK(props);

  async function updatePosition() {
    console.log("UPDATE POSITION")

    let rows: any[] = [];
    for (let child of data.children) {
      rows.push({
        type: child.type,
        id: child.id,
        position: child.position
      })
    }

    console.log(data.children)
    // console.log("UPDATE POSITION END")

    await globalLoadingFn(()=>api.patch("/places/positions", rows));
  }

  async function refreshData() {
    let mustSetMapEditMode = false;

    if (mapEditMode) {
      mustSetMapEditMode = true;
      setMapEditMode(false);
      await updatePosition();
    }

    let response = await globalLoadingFn(()=>api.get("/" + type + "s/" + id));
    setData(response.data)
    setAddMode(false)

    if (mustSetMapEditMode) {
      setMapEditMode(true);
    }
  }

  useEffect(()=>{
    refreshData();
  }, []);

  console.log(data)

  const nextType = data && nextPlaceType(data);
  
  return (
    <Layout webGrid load={data || false}>
      <LeftPane>
        <LeftPaneControlPanel active={controlEntity} data={data}></LeftPaneControlPanel>
      </LeftPane>

      <CenterPane>
        <Panel>
          {editMode ? 
            <View style={{marginBottom: 24}}>
              <TextWithBack>{data && data.name}</TextWithBack>
            </View> 
          :
            <View style={{...FLEX_LR_CENTER, marginBottom: 24, zIndex: 100}}>
              <DropdownLink variant="text_left_md" options={DROPDOWN_LINK_OPTIONS} defaultValue={DROPDOWN_LINK_OPTIONS[1]}></DropdownLink>

              <ManagePlaceButton type={type} id={id} controlEntity={controlEntity}></ManagePlaceButton>
            </View>
          }

          <PanelInnerGrid>

            <PanelInner
              header={
                <View style={{
                  ...FLEX_LR_CENTER,
                }}>
                  <View style={{flexGrow: 1}}>
                    <TextMD>รูปแบบแผนผังอาคาร</TextMD>
                  </View>
                </View>
              }
            >
              {editMode && <View style={{...FLEX_LR_CENTER, marginBottom: 12}}>
                <FilePickerTouchableOpacity onChange={async (url) => {
                  console.log(url)

                  await globalLoadingFn(()=>api.patch("/"+data?.type+"s/"+data?.id, {
                    picture: url,
                  }));

                  refreshData();
                }}>
                  <TextWithIcon icon={require("../../../img/icons/change_map.png")} style={{textDecorationLine: "underline"}}>เปลี่ยนแผนผังอาคาร</TextWithIcon>
                </FilePickerTouchableOpacity>

                {mapEditMode ?
                  <TouchableOpacity onPress={async ()=>{
                    await updatePosition();
                    setMapEditMode(false)
                  }}>
                    <TextWithIcon icon={require("../../../img/icons/save.png")} style={{textDecorationLine: "underline"}}>บันทึก</TextWithIcon>
                  </TouchableOpacity>
                  :
                  <TouchableOpacity onPress={()=>setMapEditMode(true)}>
                    <TextWithIcon icon={require("../../../img/icons/edit.png")} style={{textDecorationLine: "underline"}}>แก้ไข</TextWithIcon>
                  </TouchableOpacity>
                }

              </View>}

              <View style={{display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "space-between"}}>
                <View style={{flexBasis: editMode ? "100%" : "85%"}}>
                  {!mapEditMode ?
                    <MapImageView data={data} to={"control_air_map"} editMode={editMode} mapMode controlEntity={controlEntity}></MapImageView>
                  :
                    <MapImageEdit devices={data} controlEntity={controlEntity} onDataUpdate={data => setData(data)}></MapImageEdit>
                  }
                </View>

                {!editMode && <View>
                  <ControlAirVertButton type={type} id={id}></ControlAirVertButton>
                </View>}
              </View>
            </PanelInner>

            <View style={{...FLEX_COLUMN}}>
              <PanelInner
                header={
                  <View style={{
                    ...FLEX_LR_CENTER,
                  }}>
                    <View style={{flexGrow: 1}}>
                      <TextMD>รายการแอร์</TextMD>
                    </View>
                    <View>
                      <OnlineErrorCount online={data && data.online_count} total={data && data.total_count} error={data && data.error_count}></OnlineErrorCount>
                    </View>
                  </View>
                }

                style={{marginBottom: 24}}
              >
                <View style={{...FLEX_COLUMN_CENTER_CENTER, justifyContent: "space-around"}}>
                  <View>
                    <TouchableOpacity onPress={()=>setShowPlaceEditDialog(true)} style={{...FLEX_COLUMN_CENTER_CENTER}}>
                      <AvatarImage src={data?.avatar} size={150} style={{marginBottom: 8}} />

                      <TextWithIcon
                        icon={require("../../../img/icons/edit.png")}
                        iconRight
                        containerStyle={{justifyContent: "center"}}
                        style={{...TEXT_WRAP_CENTER}}
                      >
                        {data?.name}
                      </TextWithIcon>
                    </TouchableOpacity>
                  </View>
                </View>
              </PanelInner>

              <PanelInner
                style={{marginBottom: 24}}
                noInnerPadding
              >
                <View style={{...FLEX_ROW_CENTER}}>
                  {[
                    ["device_control_air", "A"],
                    ["device_control_light", "L"],
                    ["device_control_sensor", "S"],
                    ["device_control_curtain", "C"],
                    ["device_control_door", "D"],
                    ["device_control_breaker", "B"],
                  ].map(([controlEntity, label]) => (
                    <TouchableOpacity 
                      onPress={() => {
                        var searchParams = new URLSearchParams(window.location.search);
                        searchParams.set("control_entity", controlEntity);
                        window.location.search = searchParams.toString();
                      }} 
                      style={{ 
                        paddingHorizontal: 16, 
                        paddingVertical: 8, 
                        borderColor: "gray", 
                        borderWidth: 2,
                        backgroundColor: new URLSearchParams(window.location.search).get('control_entity') == controlEntity ? 'cyan' : '',
                      }}
                    >
                      <Text>{label}</Text>
                    </TouchableOpacity>
                  ))}
                </View>
              </PanelInner>

              <ScrollView style={{position: "relative"}}>
                <PanelInner
                  header={
                    <View style={{
                      ...FLEX_LR_CENTER,
                    }}>
                      <View style={{flexGrow: 1}}>
                        <TextMD>รายการ</TextMD>
                      </View>
                    </View>
                  }
  
                  style={{flexGrow: 1, height: "100%"}}
  
                  noInnerPadding
                >
                  <View>
                    <MapList data={data?.children} onChange={refreshData} addMode={nextType != "device" && addMode} type={nextType} editMode={editMode} parentType={data?.type} parentId={data?.id} controlEntity={controlEntity}></MapList>
                  </View>

                </PanelInner> 

                <WebDiv style={{position: "sticky", bottom: 0, left: 0, width: "100%"}}>
                  {!editMode ? 
                    <View style={{
                      ...FLEX_LR_CENTER, 
                      borderWidth: 3,
                      borderColor: "rgb(210, 210, 210)",
                      borderBottomLeftRadius: 4,
                      borderBottomRightRadius: 4,
                      marginTop: -2,
                      alignItems: "stretch",
                      backgroundColor: "white",
                    }}>
                      <TouchableOpacity style={{padding: 12, paddingLeft: 24}}>
                        <TextWithIcon style={{color: "#49c5f1"}} icon={require("../../../img/icons/arrow-left-outline.png")} onPress={()=>navigateBack()}>ย้อนกลับ</TextWithIcon>
                      </TouchableOpacity>

                      <TouchableOpacity style={{padding: 12, paddingRight: 24}}>
                        <TextWithIcon style={{color: "#49c5f1"}} icon={require("../../../img/icons/arrow-right-outline.png")} iconRight onPress={()=>{
                          if (ScopeStore.mapActiveType != nextType) {
                            alert("กรุณาเลือกรายการที่ต้องการไปต่อก่อน")
                            return;
                          }

                          navigate("control_air_map_"+ScopeStore.mapActiveType, {type: ScopeStore.mapActiveType, id: ScopeStore.mapActiveId, edit: editMode ? "1" : "0", control_entity: controlEntity})
                        }}>ถัดไป</TextWithIcon>
                      </TouchableOpacity>
                    </View> 
                  :
                    <AddAirconButton type={nextType} onAdd={()=>setAddMode(true)}></AddAirconButton>
                  }
                </WebDiv>
              </ScrollView>     
            </View>

          </PanelInnerGrid>
        </Panel>
      </CenterPane>

      <ControlPlaceAddDeviceDialog show={nextType == "device" && addMode} onClose={()=>setAddMode(false)} type={data?.type} id={data?.id} onChange={async (scopes) => {
        await globalLoadingFn(()=>api.patch("/rooms/"+id+"/devices", {device_ids: scopes.map(x => x.scope_id)}));
        refreshData()
      }} controlEntity={controlEntity}></ControlPlaceAddDeviceDialog>

      <ControlPlaceEditBasicDialog show={showPlaceEditDialog} place={data} onClose={()=>{
        refreshData();
        setShowPlaceEditDialog(false);
      }}></ControlPlaceEditBasicDialog>

    </Layout>
  )
}