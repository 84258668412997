import React, { useState, useEffect } from "react";

import Layout, { LeftPane, CenterPane } from "../components/Layout";
import {
  View,
  Text,
  Image,
  TextInputSolid,
  TouchableOpacity,
  TextL,
  TextMD,
} from "../seperated/react-native";
import TextFieldComplete from "../components/input/TextFieldComplete";
import { IS_MOBILE } from "../seperated/screen-mode";
import { SHADOW_LIGHT, BORDER_LIGHT, FLEX_ROW_CENTER, FLEX_LR_CENTER, FLEX_ROW_CENTER_CENTER, BORDER_LINE_BOTTOM, LINK_COLOR } from "../components/constants/style";
import { Link, navigate } from "../seperated/components/Router";
import Panel from "../components/Panel";
import LeftPaneRootPanel from "../components/LeftPane/LeftPaneRootPanel";
import PanelInner from "../components/PanelInner";
import PanelInnerGrid from "../components/PanelInnerGrid";
import LinkText from "../components/text/LinkText";
import UserAvatar from "../components/avatar/UserAvatar";
import LRList from "../components/list/LRList";
import EditableText from "../components/text/EditableText";
import TextWithIcon from "../components/text/TextWithIcon";
import SelectCustom from "../seperated/components/SelectCustom";
import RightArrowIcon from "../components/icon/RightArrowIcon";
import SwitchCustom from "../seperated/components/SwitchCustom";
import { performLogout } from "./Login";
import { observer } from "mobx-react-lite";
import ScopeStore, { loadUser } from "../stores/ScopeStore";
import { globalLoadingFn } from "../utils/loading";
import { api } from "../config";
import FilePickerTouchableOpacity from "../seperated/components/FilePickerTouchableOpacity";
import AvatarImage from "../components/avatar/AvatarImage";
import useActiveBuilding from "../hooks/ActiveBuilding";
import { placePermissionText } from "../utils/placeUtils";
import MobileLayout from "../components/MobileLayout";

export default observer(function Settings(props) {
  const [diag, setDiag] = useState(true)
  const [building, setBuilding] = useActiveBuilding();

  console.log(building)

  const LeftPanelChild = observer(() => {
    return (<View>
      <View style={{...FLEX_LR_CENTER}}>
        <TextMD>โปรไฟล์</TextMD>
        <FilePickerTouchableOpacity onChange={async (val) => {
          await globalLoadingFn(async ()=> {
            await api.patch("/me", {avatar: val})
            await loadUser(true)
            window.location.reload();
          })
        }}>
          <Text style={{color: LINK_COLOR}}>แก้ไขรูป</Text>
        </FilePickerTouchableOpacity>
      </View>

      <View style={{marginTop: 28, marginBottom: 28, ...FLEX_ROW_CENTER_CENTER}}>
        <FilePickerTouchableOpacity onChange={async (val) => {
          await globalLoadingFn(async ()=> {
            await api.patch("/me", {avatar: val})
            await loadUser(true)
          })
        }}>
          <AvatarImage src={ScopeStore.user?.profile.avatar} size={250} />
        </FilePickerTouchableOpacity>
      </View>

      <LRList
        left={<Text>ชื่อ</Text>}
        right={<EditableText onChange={async (val)=>{
          await globalLoadingFn(async ()=> {
            await api.patch("/me", {name: val})
            await loadUser(true)
          })
        }}>{ScopeStore?.user?.username}</EditableText>}
      />

      <LRList
        left={<Text>อีเมล</Text>}
        right={<TextWithIcon iconRight>{ScopeStore?.user?.email}</TextWithIcon>}
      />

      <LRList
        left={<Text>เบอร์โทรศัพท์</Text>}
        right={<TextWithIcon iconRight>{ScopeStore?.user?.mobile_number}</TextWithIcon>}
        style={{}}
      />

      {building && <LRList
        left={<Text>ระดับสิทธิ์</Text>}
        right={<TextWithIcon iconRight>{placePermissionText(building)}</TextWithIcon>}
        style={{marginBottom: 20}}
      />}

      {/* <LRList
        left={<Text>ชื่อบัญชี</Text>}
        right={<TextWithIcon iconRight>chomtana001</TextWithIcon>}
        style={{marginBottom: 20}}
      />

      <TextMD style={{marginBottom: 4}}>การแสดงผล</TextMD>

      <LRList
        left={<Text>หน่วยอุณหภูมิ</Text>}
        right={<SelectCustom variant="text_right"></SelectCustom>}
        style={{zIndex: 101}}
      ></LRList>

      <LRList
        left={<Text>ตั้งค่าภาษา</Text>}
        right={<SelectCustom variant="text_right"></SelectCustom>}
        style={{zIndex: 100}}
      ></LRList> */}
    </View>)
  })

  const RightPanelChild = observer(() => {
    return (
      <View>
        <TextMD style={{marginBottom: 4}}>ความปลอดภัย</TextMD>

        <TouchableOpacity onPress={()=>navigate("reset_password")}>
          <LRList
            left={<Text>เปลี่ยนรหัสผ่าน</Text>}
            right={<RightArrowIcon></RightArrowIcon>}
            style={{marginBottom: 20}}
          ></LRList>
        </TouchableOpacity>

        <TouchableOpacity>
          <LRList
            left={<Text>SMS Two factor</Text>}
            right={<SwitchCustom checked={ScopeStore?.user?.two_factor_mobile ?? false} onChange={async (val)=>{
              await globalLoadingFn(async ()=> {
                await api.patch("/me", {two_factor_mobile: Boolean(!ScopeStore?.user?.two_factor_mobile)})
                await loadUser(true)
              })
            }}></SwitchCustom>}
            style={{marginBottom: 20}}
          ></LRList>
        </TouchableOpacity>


        {/* <LRList
          left={<Text>การแชร์อุปกรณ์</Text>}
          right={<RightArrowIcon></RightArrowIcon>}
          style={{marginBottom: 20}}
        ></LRList>

        <TextMD style={{marginBottom: 4}}>การตรวจจับข้อผิดพลาด</TextMD>

        <LRList
          left={<Text>ตรวจสอบสถานะเครือข่าย</Text>}
          right={<SwitchCustom checked={diag} onChange={()=>{setDiag(!diag)}}></SwitchCustom>}
        ></LRList>

        <LRList
          left={<Text>วิเคราะห์เครือข่าย</Text>}
          right={<RightArrowIcon></RightArrowIcon>}
          style={{marginBottom: 20}}
        ></LRList> */}

        {/* <TextMD style={{marginBottom: 4}}>อื่นๆ</TextMD>

        <LRList
          left={<Text>เกี่ยวกับ</Text>}
          right={<RightArrowIcon></RightArrowIcon>}
        ></LRList> */}

        <View style={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          justifyContent: "flex-end"
        }}>
          <TouchableOpacity style={{
            padding: 12,
            ...BORDER_LIGHT,
            ...SHADOW_LIGHT,
            backgroundColor: "#9ce2f8"
          }} onPress={()=>performLogout()}>
            <Text style={{textAlign: "center"}}>ออกจากระบบ</Text>
          </TouchableOpacity>
        </View>
      </View>
    )
  })

  if (IS_MOBILE) {
    return (
      <MobileLayout title="ตั้งค่า" load={(building) || false}>
        <LeftPanelChild></LeftPanelChild>
        <RightPanelChild></RightPanelChild>
      </MobileLayout>
    )
  }

  return (
    <Layout webGrid>
      <LeftPane>
        <LeftPaneRootPanel active="settings"></LeftPaneRootPanel>
      </LeftPane>

      <CenterPane>
        <Panel>
          <PanelInnerGrid>
            <PanelInner
              header={<TextMD>ตั้งค่า</TextMD>}
              h100
            >
              <LeftPanelChild></LeftPanelChild>
            </PanelInner>

            <PanelInner
              header={<TextMD>&nbsp;</TextMD>}
              h100
            >
              <RightPanelChild></RightPanelChild>
            </PanelInner>
          </PanelInnerGrid>
        </Panel>
      </CenterPane>
    </Layout>
  );
})
