import { useStateProvider, StateProvider } from "./StateProvider";
import { useMemo } from 'react';
import { alertErrorComplete } from "../seperated/toast";

export class FormState {
  dataState: StateProvider
  errorState: StateProvider
  metadataState: StateProvider

  constructor(dataState: StateProvider, errorState: StateProvider, metadataState: StateProvider) {
    this.dataState = dataState;
    this.errorState = errorState;
    this.metadataState = metadataState;
  }

  $(path) {
    return new FormState(
      this.dataState.$(path),
      this.errorState.$(path),
      this.metadataState.$(path),
    )
  }

  clear() {
    this.dataState.set({});
    this.errorState.set({});
    this.metadataState.set({});
  }

  get() {
    return this.dataState.get();
  }

  // async to bypass react error
  getAndValidate() {
    let data = this.dataState.get()

    let metadata = this.metadataState.get();
    for(let key in metadata) {
      if (metadata[key]?.validator) {
        let message = metadata[key]?.validator(data[key] || "", data)
        if (message) throw new Error(message);
      }
    }

    return data;
  }
}

export function useFormState(initialData: any = {}, validators: any = {}) {
  let metadata = {}

  for(let key in validators) {
    if (!metadata[key]) metadata[key] = {}
    metadata[key].validator = validators[key]
  }

  const dataState = useStateProvider(initialData);
  const errorState = useStateProvider(typeof initialData === "object" ? undefined : "");
  const metadataState = useStateProvider(metadata);

  const res = useMemo(()=>new FormState(dataState, errorState, metadataState), [dataState, errorState, metadataState]);

  return res;
}

export function useFormStateAgain(formState: FormState | undefined, defaultValue: any = "") {
  let backup = useFormState(defaultValue);
  if (formState) return formState;
  return backup;
}