import React, { useState } from 'react';
import Modal from './components/Modal';
import { View, Text } from './react-native';

export let IS_GLOBAL_LOADING = 0;

// Working because only one LoadingContainer
let setLoading = (loading)=>{IS_GLOBAL_LOADING = loading};

export function globalLoading(loading?: boolean | undefined) {
  if (typeof loading === "undefined") loading = IS_GLOBAL_LOADING == 0;

  setLoading(loading ? IS_GLOBAL_LOADING + 1 : IS_GLOBAL_LOADING - 1);
}

export function Loading() {
  return <View style={{flex: 1, justifyContent: "center", alignItems: "center", zIndex: 100000}}>
    <div className="spinner-border" style={{color: "#9ce2f8" /*"#49C5F1"*/}}></div>
  </View>
}

export function LoadingContainer() {
  const [loading, setLoadingRaw] = useState(IS_GLOBAL_LOADING);

  setLoading = (l) => {
    setLoadingRaw(l)
    IS_GLOBAL_LOADING = l;
  }

  return (
    <Modal show={loading > 0} centerOnly noWrapper>
      <Loading></Loading>
    </Modal>
  )
}