import React from "react"
import Dialog from "../../../../components/dialog/Dialog"
import { View, Image, TextMD, Text } from "../../../../seperated/react-native"
import { FLEX_ROW_CENTER_CENTER, FLEX_ROW_CENTER, FLEX_COLUMN, TEXT_WRAP } from "../../../../components/constants/style"
import CardButton from "../../../../components/buttons/CardButton"
import HoriTwo from "../../../../components/HoriTwo"
import TextFieldComplete from "../../../../components/input/TextFieldComplete"
import CommonButton from "../../../../components/buttons/CommonButton"
import { IS_MOBILE } from "../../../../seperated/screen-mode"
import MobileLayout from "../../../../components/MobileLayout"
import { navigate } from "../../../../seperated/components/Router"
import AirAdderStore from "../../../../stores/AirAdderStore"
import DatePickerComplete from "../../../../components/input/DatePickerComplete"
import { useFormState } from "../../../../hooks/FormState"
import { formatDate } from "../../../../utils/date"
import { globalLoadingFn } from "../../../../utils/loading"
import { api } from "../../../../config"
import { getSnapshot } from "mobx-state-tree"
import { $LOCK_NUMBER } from "../../../../utils/validation"

const DEFAULT_VALUE = {
  delivery_date: new Date()
}

async function doFinal() {
  let response = await globalLoadingFn(()=>api.post("/devices", AirAdderStore));

  let buildingId = response.data.building_id;

  if (IS_MOBILE) {
    navigate("control_air_add_success", {id: buildingId});
  } else {
    AirAdderStore.setActivePage("success_air:"+buildingId)
  }
}

function updateMobx(model) {
  AirAdderStore.fillFromInsuranceForm(model.dataState.get())

  // This is final flow -> doFinal
  doFinal();
}

function InsuranceDate({label, model, modelKey, ...props}) {
  const insuranceDate = new Date(model.dataState.get().delivery_date)
  insuranceDate.setMonth(insuranceDate.getMonth() + parseInt(model.$(modelKey).dataState.get() || "0"))

  return (
    <View style={{...(IS_MOBILE ? FLEX_COLUMN : FLEX_ROW_CENTER), paddingTop: 16, paddingBottom: 0, borderTopColor: "#f7f7f7", borderTopWidth: 2}}>
      <HoriTwo left={<Text>{label}</Text>} style={{marginBottom: 20, flexBasis: "55%", marginRight: IS_MOBILE ? undefined : 48}}>
        <View style={{...FLEX_ROW_CENTER}}>
          <TextFieldComplete variant="material" type="number" style={{flexGrow: 1, marginRight: 8, maxWidth: 100}} model={model.$(modelKey)} lock={$LOCK_NUMBER}></TextFieldComplete>
          <Text>เดือน</Text>
        </View>
      </HoriTwo>

      <HoriTwo left={<Text>วันหมดประกัน</Text>} style={{marginBottom: 20, flexGrow: 1}}>
        <Text style={{textAlign: "right"}}>{formatDate(insuranceDate)}</Text>
      </HoriTwo>
    </View>
  )
}

function ControlAirAddInsuranceInner({model}) {
  return (
    <View style={{ padding: IS_MOBILE ? 0 : 36 }}>
      <HoriTwo left={<Text>ชื่อร้าน</Text>} style={{marginBottom: 16}} leftSize={IS_MOBILE ? 240 : 200}>
        <TextFieldComplete variant="material" model={model.$("under_shop_name")}></TextFieldComplete>
      </HoriTwo>

      <HoriTwo left={<Text>ชื่อช่าง</Text>} style={{marginBottom: 16}} leftSize={IS_MOBILE ? 240 : 200}>
        <TextFieldComplete variant="material" model={model.$("name")}></TextFieldComplete>
      </HoriTwo>

      <HoriTwo left={<Text>ที่อยู่</Text>} style={{marginBottom: 16}} leftSize={IS_MOBILE ? 240 : 200}>
        <TextFieldComplete variant="material" model={model.$("address")}></TextFieldComplete>
      </HoriTwo>

      <HoriTwo left={<Text>เบอร์โทรศัพท์</Text>} style={{marginBottom: 20}} leftSize={IS_MOBILE ? 240 : 200}>
        <TextFieldComplete variant="material" model={model.$("phone")} lock={$LOCK_NUMBER}></TextFieldComplete>
      </HoriTwo>

      <HoriTwo left={<Text>วันที่ส่งมอบติดตั้ง</Text>} style={{marginBottom: 32}} leftSize={IS_MOBILE ? 240 : 200}>
        <DatePickerComplete variant="material" model={model.$("delivery_date")}></DatePickerComplete>
      </HoriTwo>

      <InsuranceDate label="ประกันติดตั้ง" model={model} modelKey="warranty_installation_month"></InsuranceDate>
      <InsuranceDate label="ประกันตัวแอร์ All Part" model={model} modelKey="warranty_all_part_month"></InsuranceDate>
      <InsuranceDate label="ประกัน Compressor" model={model} modelKey="warranty_compressor_month"></InsuranceDate>

      <View>
        <Text style={{...TEXT_WRAP}}>การรับประกัน compressor คือรับประกันแค่อุปกรณ์เท่านั้นไม่รวมค่าบริการ</Text>
      </View>

      {!IS_MOBILE && <View style={{...FLEX_ROW_CENTER, marginTop: 36}}>
        <CommonButton variant="default" style={{marginRight: 16, flexGrow: 1}} onPress={()=>AirAdderStore.goToPreviousPage()}>ย้อนกลับ</CommonButton>
        <CommonButton variant="primary" style={{marginLeft: 16, flexGrow: 1}} onPress={()=>updateMobx(model)}>เสร็จสิ้น</CommonButton>
      </View>}
    </View>
  )
}

export function ControlAirAddInsurancePage(props) {
  const model = useFormState(DEFAULT_VALUE)

  return (
    <MobileLayout 
      title="ประกันอุปกรณ์"
      bottom={
        <View style={{...FLEX_COLUMN}}>
          <CommonButton variant="default" onPress={()=>AirAdderStore.goToPreviousPage()} style={{marginBottom: 16}}>ย้อนกลับ</CommonButton>
          <CommonButton variant="primary" onPress={()=>updateMobx(model)}>เสร็จสิ้น</CommonButton>
        </View>
      }
    >
      <ControlAirAddInsuranceInner {...props} model={model}></ControlAirAddInsuranceInner>
    </MobileLayout>
  )
}

export default function ControlAirAddInsurance(props) {
  const model = useFormState(DEFAULT_VALUE)

  return (
    <Dialog show={props.show} title="ประกันอุปกรณ์" hasClose onClose={()=>AirAdderStore.setActivePage("")}>
      <ControlAirAddInsuranceInner {...props} model={model}></ControlAirAddInsuranceInner>
    </Dialog>
  )
}