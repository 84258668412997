import React from "react"
import { View, ScrollView } from "../seperated/react-native"
import { SHADOW_LIGHT, BORDER_LIGHT } from "./constants/style"
import { IS_MOBILE } from "../seperated/screen-mode"

export default function PanelInner(props) {
    return (
        <View style={{
            ...SHADOW_LIGHT,
            ...BORDER_LIGHT,
            borderColor: "#D2D2D2",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            height: props.h100 ? "100%" : undefined,
            gridColumn: props.col2 ? "1 / span 2" : undefined,
            ...(props.style || {})
        }}>
            {props.header &&
                <View style={{backgroundColor: "#f7f7f7", padding: 12, paddingHorizontal: 20}}>
                    {props.header}
                </View>
            }
            <View style={{flexGrow: 1, padding: props.noInnerPadding ? 0 : (IS_MOBILE ? 16 : 20)}}>
                {props.children}
            </View>
            
        </View>
    )
}