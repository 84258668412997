import React from "react"
import ButtonWithIcon from "./ButtonWithIcon"
import { navigate } from "../../seperated/components/Router"

export default function DetailButton(props) {
  return (
    <ButtonWithIcon 
      backgroundColor="#b1b1b1" 
      icon={require("../../img/icons/detail-white.png")}
      onPress={()=>navigate("control_air_map", {type: props.type, id: props.id})}
      {...props}
    >
      รายละเอียด
    </ButtonWithIcon>
  )
}