import React, { useEffect } from "react"
import { getRouteParam, getRouteParams, navigate } from "../seperated/components/Router"
import { globalLoadingFn } from "../utils/loading";
import { performLogin } from "./Login";

const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export default function LoginToken(props) {
  let {_user_id, _token, _to} = getRouteParams(props);

  useEffect(() => {
    globalLoadingFn(async () => {
      if (!_to) _to = "dashboard";

      await performLogin({
        user_id: parseInt(_user_id),
        token: _token,
        noRedirect: true,
      })

      await wait(100);

      window.location.href = _to;

      await new Promise(resolve => {});
    });
  }, [])

  return <div></div>
}