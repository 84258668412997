import React, { useState, useEffect } from "react";

import Layout from "../components/Layout";
import { View, Text, Image, TextInputSolid, TouchableOpacity } from "../seperated/react-native";
import TextFieldComplete from "../components/input/TextFieldComplete";
import { IS_MOBILE } from "../seperated/screen-mode";
import { SHADOW_LIGHT, BORDER_LIGHT } from "../components/constants/style";
import { Link, navigate, getRouteParam } from "../seperated/components/Router";
import { useFormState } from "../hooks/FormState";
import { globalLoadingFn } from "../utils/loading";
import { api } from "../config";
import { performLogin, resolveUsername } from "./Login";

export default function ResetPasword(props) {
  const model = useFormState()
  const [otpSecret, setOtpSecret] = useState();
  const [step, setStep] = useState(0);
  const FINAL_STEP = 1;

  return (
    <Layout title="ลืมรหัสผ่าน">
      <View style={{
        display: "flex",
        flexDirection: IS_MOBILE ? "column" : "row",
        justifyContent: IS_MOBILE ? "space-around" : "flex-start",
        minHeight: "100%"
      }}>
        <View style={{flexGrow: 1, flexBasis: IS_MOBILE ? undefined : "60%", justifyContent: "center", alignItems: "center"}}>
          <Image src={require('../img/working1.png')} style={{width: "75%"}} />
        </View>
        <View style={{flexGrow: 1, flexBasis: IS_MOBILE ? undefined : "40%", flexDirection: "column", justifyContent: "center"}}>
          {/* <View style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
            <Text style={{fontSize: 28, marginBottom: 24}}>กรุณาใส่รหัสยืนยัน</Text>
            <Text style={{fontSize: 28, color: "#b1b1b1", marginBottom: 48}}>0954141228</Text>
          </View> */}

          <View style={{marginBottom: 32}}>
            <TextFieldComplete  model={model.$("username")} label="อีเมล หรือ หมายเลขโทรศัพท์" onCHange={() => setStep(1)} />
          </View>

          {step >= 1 && <View style={{marginBottom: 32}}>
            <TextFieldComplete  model={model.$("otp_key")} label="รหัสยืนยัน" secureTextEntry={true} />
          </View>}

          {step >= 1 && <View style={{marginBottom: 32}}>
            <TextFieldComplete  model={model.$("password")} label="รหัสผ่านใหม่" secureTextEntry={true} />
          </View>}

          <View style={{marginBottom: 24, marginTop: 12}}>
            <TouchableOpacity onPress={async ()=>{
              if (step < FINAL_STEP) {
                let modelData = resolveUsername(model.get());
                let response;

                if (modelData.email) {
                  response = await globalLoadingFn(() => api.post("/resend_otp_email/" + encodeURIComponent(modelData.email)))
                } else {
                  response = await globalLoadingFn(() => api.post("/resend_otp/" + encodeURIComponent(modelData.mobile_number)))
                }

                setOtpSecret(response.data.otp_secret)

                setStep(step + 1)
                return;
              }

              await globalLoadingFn(async ()=>{
                let modelData = resolveUsername(model.get());

                if (modelData.email) {
                  await api.post("/reset_password_email", {
                    ...modelData,
                    otp_secret: otpSecret
                  })
                } else {
                  await api.post("/reset_password_mobile", {
                    ...modelData,
                    otp_secret: otpSecret
                  })
                }

                navigate("login_email")
              });
            }}>
              <View style={{padding: 12, ...SHADOW_LIGHT, ...BORDER_LIGHT, backgroundColor: "#9ce2f8"}}>
                <Text style={{textAlign: "center", fontWeight: "bold"}}>{step < FINAL_STEP ? "ต่อไป" : "เปลี่ยนรหัสผ่าน" }</Text>
              </View>
            </TouchableOpacity>
          </View>
        </View>
        
      </View>
    </Layout>
  )
}
