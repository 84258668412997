import React, { useState, useEffect } from "react";

import Layout, { LeftPane, CenterPane } from "../../../components/Layout";
import {
  View,
  Text,
  Image,
  TextInputSolid,
  TouchableOpacity,
  TextMD,
  TextSM,
  TextXXL,
} from "../../../seperated/react-native";
import TextFieldComplete from "../../../components/input/TextFieldComplete";
import { IS_MOBILE } from "../../../seperated/screen-mode";
import {
  SHADOW_LIGHT,
  BORDER_LIGHT,
  FLEX_ROW_CENTER,
  BORDER_LINE_BOTTOM_HARD,
  FLEX_LR_CENTER,
  FLEX_ROW_CENTER_CENTER,
  FLEX_COLUMN,
  TEXT_WRAP,
  FLEX_COLUMN_CENTER_CENTER,
} from "../../../components/constants/style";
import { Link, navigate, getRouteParam } from "../../../seperated/components/Router";
import LeftPaneControlPanel from "../../../components/LeftPane/LeftPaneControlPanel";
import Panel from "../../../components/Panel";
import PanelInnerGrid from "../../../components/PanelInnerGrid";
import PanelInner from "../../../components/PanelInner";
import TextWithCircleDot from "../../../components/text/TextWithCircleDot";
import ButtonWithIcon from "../../../components/buttons/ButtonWithIcon";
import ControlButton from "../../../components/buttons/ControlButton";
import EnergyButton from "../../../components/buttons/EnergyButton";
import PlantButton from "../../../components/buttons/PlantButton";
import SelectCustom from "../../../seperated/components/SelectCustom";
import DropdownLink from "../../../components/DropdownLink";
import TextWithIcon from "../../../components/text/TextWithIcon";
import DetailButton from "../../../components/buttons/DetailButton";
import ControlListItem from "../../../components/control/ControlListItem";
import MobileLayout from "../../../components/MobileLayout";
import ControlAirVertButton from "../../../components/control/air/ControlAirVertButton";
import OnlineErrorCount from "../../../components/control/OnlineErrorCount";
import IconInline from "../../../components/icon/IconInline";
import { api } from "../../../config";
import { globalLoadingFn } from "../../../utils/loading";
import { globalLoading } from "../../../seperated/loading";
import { BUILD_CONTROL_PLACE_LINK } from "../../../components/constants/link";
import ManagePlaceButton from "../../../components/buttons/ManagePlaceButton";
import ControlAirHeaderMenu from "../../../components/mobile_menu/ControlAirHeaderMenu";
import MapImageView from "../../../components/map/MapImageView";
import AvatarImage from "../../../components/avatar/AvatarImage";
import ControlListItemList from "../../../components/control/ControlListItemList";
import CircleDot from "../../../components/CircleDot";
import { nextPlaceType } from "../../../utils/placeUtils";
import Dialog from "../../../components/dialog/Dialog";

function ControlAirListDesktopMap({data, controlEntity}) {
  console.log(data)

  return (
    <View style={{display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "space-around"}}>
      <View style={{flexBasis: "40%"}}>
        <Image src={data.avatar} style={{width: "100%", height: "100%"}}></Image>
      </View>

      <View style={{flexBasis: "50%"}}>
        <MapImageView data={data} to="control_air_list" controlEntity={controlEntity}></MapImageView>
      </View>
    </View>
  )
}

function ControlAirListHeaderItem({data, active}) {
  return (
    <TouchableOpacity 
      style={{
        ...FLEX_ROW_CENTER, 
        ...SHADOW_LIGHT,
        ...(active ? {} : BORDER_LIGHT),
        borderRadius: 6,
        marginRight: 16,
        padding: 8,
        backgroundColor: active ? "#49c5f1" : "transparent",
        flexWrap: "wrap",
      }}

      onPress={() => navigate("control_air_list_" + data.type, {type: data.type, id: data.id})}
    >
      <div className={active ? "text-white" : ""}>
        <View style={{marginRight: 16}}>
          <Text>{data.name}</Text>
        </View>
      </div>

      <div className={active ? "text-white" : ""}>
        <OnlineErrorCount online={data?.V_online_count} total={data?.V_total_count} error={data?.V_error_count} gap={12}></OnlineErrorCount>
      </div>
    </TouchableOpacity>
  )
}

function ControlAirListHeaderList({building, active_type, active_id}) {
  return (
    <View style={{...FLEX_ROW_CENTER, flexWrap: 'wrap', maxWidth: 'calc(100vw - 750px)'}}>
      <ControlAirListHeaderItem data={building} active={active_type == 'building' && active_id == building.id}></ControlAirListHeaderItem>
      {building.children.map(floor => (
        <ControlAirListHeaderItem data={floor} active={(active_type == (building.is_two_level ? 'room' : 'floor')) && active_id == floor.id}></ControlAirListHeaderItem>
      ))}
    </View>
  )
}

function ControlAirShortcutItem({width = 200, data, setActiveShortcutDialog, onChange = (data) => {}}) {
  const height = width;

  const [on, setOn] = useState(data.online_count > 0);

  return (
    <TouchableOpacity 
      style={{
        ...FLEX_COLUMN,
        width, 
        height,
        borderRadius: 6,
        borderColor: "#49c5f1",
        borderWidth: 3,
      }}

      onPress={() => {
        if (data.type == "device") {
          navigate("control_air_manual", {type: data.type, id: data.id})
        } else if (data.type == "room") {
          setActiveShortcutDialog({
            type: "room",
            id: data.id,
          })
        } else {
          navigate("control_air_list_" + data.type, {type: data.type, id: data.id})
        }
      }}
    >
      <View style={{flex: 1, position: "relative", padding: 4, paddingHorizontal: 24, ...FLEX_COLUMN_CENTER_CENTER}}>
        <AvatarImage src={data.avatar} size={140}></AvatarImage>

        <TouchableOpacity 
          style={{position: "absolute", top: 10, right: 10}}
          onPress={async () => {
            await globalLoadingFn(() => api.post("/devices/device_control_air/turn_"+(on ? "off" : "on")+"?scope_type=" + data.type + "&scope_id=" + data.id));
            setOn(!on);
            onChange({...data, status: on ? "off" : "on"});
          }}
        >
          <CircleDot color={on ? "#84eaa7" : "#ffbcc6"}></CircleDot>
        </TouchableOpacity>
      </View>

      <View style={{flexBasis: 40, flexGrow: 0, flexShrink: 0, padding: 8, backgroundColor: "#49c5f1"}}>
        <TextSM style={{textAlign: "center", color: "white"}} numberOfLines={1}>
          {data.name}
        </TextSM>
      </View>
    </TouchableOpacity>
  )
}

function ControlAirShortcutItemList({children, setActiveShortcutDialog = (x) => {}, onChange = (x) => {}}) {
  return (
    <View style={{...FLEX_ROW_CENTER_CENTER, flexWrap: "wrap"}}>
      {children.map(child => (
        <View style={{padding: 8}}>
          <ControlAirShortcutItem data={child} setActiveShortcutDialog={setActiveShortcutDialog} onChange={onChange}></ControlAirShortcutItem>
        </View>
      ))}
    </View>
  )
}

interface ActiveShortcutDialogState {
  type: string
  id: number
}

function ControlAirShortcutDialog({type, id, show, onClose}) {
  const [data, setData] = useState<any>();

  async function refreshData() {
    let response = await globalLoadingFn(()=>api.get("/" + type + "s/" + id));
    setData(response.data)
    // console.log(response.data)
  }

  useEffect(()=>{
    refreshData();
  }, []);

  return (
    <Dialog show={show} title={data?.name ?? "กำลังโหลด..."} hasClose onClose={onClose}>
      <View style={{padding: 24}}>
        <ControlAirShortcutItemList children={data ? data.children : []} onChange={(data) => refreshData()}></ControlAirShortcutItemList>
      </View>

      <View style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        padding: 12,
        paddingHorizontal: 16,
      }}>
        <OnlineErrorCount online={data?.online_count} total={data?.total_count} error={data?.error_count}></OnlineErrorCount>
      </View>
    </Dialog>
  )
}

export default function ControlAirList(props) {
  const [data, setData] = useState<any>();
  const [building, setBuilding] = useState<any>();

  const [activeShortcutDialog, setActiveShortcutDialog] = useState<ActiveShortcutDialogState | null>(null);

  const type = getRouteParam(props, "type")
  const id = getRouteParam(props, "id");
  const controlEntity = getRouteParam(props, "control_entity") || "device_control_air";
  const mapMode = getRouteParam(props, "map_mode");
  const planMode = type == 'building' || getRouteParam(props, "plan_mode") == '1';

  const headerType = type == 'building' ? type : nextPlaceType(building);
  const headerId = headerType == type ? id : (headerType == "floor" ? data?.floor_id : data?.room_id);

  const DROPDOWN_LINK_OPTIONS = BUILD_CONTROL_PLACE_LINK(props);

  useEffect(()=>{
    async function f() {
      let response = await globalLoadingFn(()=>api.get("/" + type + "s/" + id));
      setData(response.data)
      if (type == 'building') {
        setBuilding(response.data)
      } else {
        let responseBuilding = await globalLoadingFn(()=>api.get("/buildings/" + response.data.building_id));
        setBuilding(responseBuilding.data)
      }
    }
    f();
  }, []);

  if (IS_MOBILE) {
    return (
      <MobileLayout title="รายการแอร์" load={data || false} headerRight={
        <ControlAirHeaderMenu {...props}></ControlAirHeaderMenu>
      }>
        <View style={{ ...FLEX_ROW_CENTER, alignItems: "flex-start", marginBottom: 16 }}>
          <View style={{ ...FLEX_COLUMN, flexGrow: 1, maxWidth: "calc(100% - 70px)" }}>
            <View style={{ marginRight: 24, marginBottom: 8, maxWidth: "100%" }}>
              <TextMD style={{...TEXT_WRAP}}>{data?.name}</TextMD>
            </View>
  
            <OnlineErrorCount online={data?.S_online_count} total={data?.S_total_count} error={data?.S_error_count}></OnlineErrorCount>

            <View style={{...FLEX_ROW_CENTER_CENTER, marginTop: 16, marginBottom: 8}}>
              {mapMode ?
                <MapImageView data={data} to="control_air_list" width={275} mapModeRouteParams controlEntity={controlEntity}></MapImageView>
              :
                <AvatarImage src={data?.avatar} size={275} />
              }
            </View>
          </View>

          <View style={{paddingHorizontal: 12}}>
            <ControlAirVertButton type={type} id={id}></ControlAirVertButton>
          </View> 
        </View>

        <View style={{marginBottom: 8}}>
          <Text>รายการแอร์</Text>
        </View>

        <View>
          {/* CONTROL LIST */}
          <ControlListItemList data={data} controlEntity={controlEntity}></ControlListItemList>
        </View>
      </MobileLayout>
    );
  }

  return (
    <Layout webGrid load={data || false}>
      <LeftPane>
        <LeftPaneControlPanel active={controlEntity} data={data}></LeftPaneControlPanel>
      </LeftPane>

      <CenterPane>
        <Panel>
          <View style={{ ...FLEX_LR_CENTER, marginBottom: 24, zIndex: 100 }}>
            <DropdownLink
              variant="text_left_md"
              options={DROPDOWN_LINK_OPTIONS}
              defaultValue={DROPDOWN_LINK_OPTIONS[0]}
            ></DropdownLink>

            <ManagePlaceButton type={type} id={id} controlEntity={controlEntity}></ManagePlaceButton>
          </View>

          <View>
            <PanelInner
              header={
                <View style={{}}>
                  <View
                    style={{
                      ...BORDER_LINE_BOTTOM_HARD,
                      paddingBottom: 8,
                      marginBottom: 12,
                      width: "100%",
                      ...FLEX_LR_CENTER,
                    }}
                  >
                    <View style={{ ...FLEX_ROW_CENTER }}>
                      {building && <ControlAirListHeaderList building={building} active_type={headerType} active_id={headerId}></ControlAirListHeaderList>}
                    </View>

                    <View style={{ ...FLEX_ROW_CENTER }}>
                      <ControlButton style={{ marginRight: 8 }} type={data?.type} id={data?.id}></ControlButton>
                      <EnergyButton style={{ marginRight: 8 }} type={data?.type} id={data?.id}></EnergyButton>
                      {/* <PlantButton type={data?.type} id={data?.id}></PlantButton> */}
                    </View>
                  </View>

                  <View style={{ ...FLEX_ROW_CENTER }}>
                    <View style={{...FLEX_ROW_CENTER, marginRight: 64}}>
                      <IconInline src={require("../../../img/SVCH/S/inactive/blue.png")} style={{height: 32, marginRight: 12}}></IconInline>
                      <OnlineErrorCount online={data?.S_online_count} total={data?.S_total_count} error={data?.S_error_count} gap={16}></OnlineErrorCount>
                    </View>

                    <View style={{...FLEX_ROW_CENTER, marginRight: 64}}>
                      <IconInline src={require("../../../img/SVCH/V/inactive/blue.png")} style={{height: 32, marginRight: 12}}></IconInline>
                      <OnlineErrorCount online={data?.V_online_count} total={data?.V_total_count} error={data?.V_error_count} gap={16}></OnlineErrorCount>
                    </View>

                    <View style={{...FLEX_ROW_CENTER, marginRight: 64}}>
                      <IconInline src={require("../../../img/SVCH/C/inactive/blue.png")} style={{height: 32, marginRight: 12}}></IconInline>
                      <OnlineErrorCount online={data?.C_online_count} total={data?.C_total_count} error={data?.C_error_count} gap={16}></OnlineErrorCount>
                    </View>
                  </View>
                </View>
              }
              col2
            >
              {!planMode &&
                <View style={{marginBottom: 24}}>
                  <ControlAirShortcutItemList children={data ? data.children : []} setActiveShortcutDialog={setActiveShortcutDialog}></ControlAirShortcutItemList>
                </View>
              }

              {planMode &&
                <View>
                  <ControlAirListDesktopMap data={data} controlEntity={controlEntity}></ControlAirListDesktopMap>
                </View>
              }
            </PanelInner>
          </View>

          {/* CONTROL LIST */}
          <ControlListItemList controlEntity={controlEntity} data={data}></ControlListItemList>
        </Panel>
      </CenterPane>

      {/* ACTIVE SHORTCUT DIALOG */}
      {activeShortcutDialog &&
        <ControlAirShortcutDialog {...activeShortcutDialog} show={true} onClose={() => setActiveShortcutDialog(null)}></ControlAirShortcutDialog>
      }
    </Layout>
  );
}
