import React, { useState, useEffect } from "react";

import Layout from "../components/Layout";
import { View, Text, Image, TextInputSolid, TouchableOpacity } from "../seperated/react-native";
import TextFieldComplete from "../components/input/TextFieldComplete";
import { IS_MOBILE } from "../seperated/screen-mode";
import { SHADOW_LIGHT, BORDER_LIGHT, FLEX_ROW_CENTER, LINK_COLOR, FLEX_COLUMN_CENTER_CENTER } from "../components/constants/style";
import { Link, navigate, getRouteParam, getRouteParams } from "../seperated/components/Router";
import { useFormState } from "../hooks/FormState";
import { globalLoadingFn } from "../utils/loading";
import { api } from "../config";
import { performLogin } from "./Login";

async function resendConfirmationEmail(email) {
  return await globalLoadingFn(() => api.post("/resend_confirmation_email/" + encodeURIComponent(email)));
}

export default function RegisterEmail(props) {
  const model = useFormState()

  const email = getRouteParam(props, "email");

  return (
    <Layout title="ยืนยันหมายเลขโทรศัพท์">
      <View style={{
        display: "flex",
        flexDirection: IS_MOBILE ? "column" : "row",
        justifyContent: IS_MOBILE ? "space-around" : "flex-start",
        minHeight: "100%",
      }}>
        <View style={{flexGrow: 1, flexBasis: IS_MOBILE ? undefined : "60%", justifyContent: "center", alignItems: "center"}}>
          <Image src={require('../img/working1.png')} style={{width: "75%"}} />
        </View>
        <View style={{flexGrow: 1, flexBasis: IS_MOBILE ? undefined : "40%", flexDirection: "column", justifyContent: "center"}}>
          <View style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
            <Text style={{fontSize: 28, marginBottom: 24}}>กรุณายืนยันอีเมลของคุณ</Text>
            <Text style={{fontSize: 28, color: "#b1b1b1", marginBottom: 48}}>{email}</Text>
          </View>

          <View style={{...FLEX_COLUMN_CENTER_CENTER}}>
            <View style={{marginBottom: 24}}>
              <Text>เปิดอีเมลของคุณ แล้วกดปุ่มยืนยัน</Text>
            </View>
  
            <View style={{marginBottom: 24}}>
              <View style={{...FLEX_ROW_CENTER}}>
                <Text>เมื่อยืนยันแล้ว</Text>
                <TouchableOpacity onPress={()=>navigate("login")} style={{marginLeft: 4}}>
                  <Text style={{color: LINK_COLOR, textDecorationLine: "underline"}}>กลับเข้าสู่ระบบ</Text>
                </TouchableOpacity>
              </View>
            </View>

            <View style={{marginBottom: 24}}>
              <TouchableOpacity onPress={()=>resendConfirmationEmail(email)} style={{marginLeft: 4}}>
                <Text style={{color: LINK_COLOR, textDecorationLine: "underline"}}>ส่งอีเมลยืนยันใหม่อีกรอบ</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
        
      </View>
    </Layout>
  )
}
