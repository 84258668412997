import React, { useState, useEffect } from "react";
import MobileLayout from "../../../components/MobileLayout";
import MapList from "../../../components/list/MapList";
import { getRouteParam, navigate } from "../../../seperated/components/Router";
import { globalLoadingFn } from "../../../utils/loading";
import { nextPlaceType } from "../../../utils/placeUtils";
import { api } from "../../../config";
import {
  TextMD,
  View,
  Text,
  TouchableOpacity,
} from "../../../seperated/react-native";
import {
  BORDER_LIGHT,
  SHADOW_LIGHT,
  FLEX_ROW_CENTER_CENTER,
  TEXT_WRAP,
} from "../../../components/constants/style";
import ControlPlaceAddDeviceDialog, { ControlPlaceAddDevice } from "../../../components/control/ControlPlaceAddDeviceDialog";

// Mobile only
export default function ControlAirManage(props) {
  const [data, setData] = useState<any>();
  const [addMode, setAddMode] = useState(false);

  const type = getRouteParam(props, "type");
  const id = getRouteParam(props, "id");
  const controlEntity = getRouteParam(props, "control_entity") || "device_control_air";
  const editMode = true || getRouteParam(props, "edit") == "1";

  async function refreshData() {
    let response = await globalLoadingFn(() => api.get("/" + type + "s/" + id));
    setData(response.data);
    setAddMode(false);
  }

  useEffect(() => {
    refreshData();
  }, []);

  console.log(data);

  const nextType = data && nextPlaceType(data);

  if (nextType == "device") {
    return (
      <MobileLayout title="จัดการข้อมูล" load={data || false}>
        <View style={{flex: 1, height: "100%"}}>
          <View style={{ marginBottom: 16 }}>
            <TextMD style={{ ...TEXT_WRAP }}>{data?.name}</TextMD>
          </View>
  
          <ControlPlaceAddDevice show={true} type={data?.type} id={data?.id} onChange={async (scopes) => {
            await globalLoadingFn(()=>api.patch("/rooms/"+id+"/devices", {device_ids: scopes.map(x => x.scope_id)}));
            navigate("control_air_list", {type, id})
          }} onClose={()=>{}} controlEntity={controlEntity}></ControlPlaceAddDevice>
        </View>
      </MobileLayout>
    )
  }

  return (
    <MobileLayout title="จัดการข้อมูล" load={data || false}>
      <View style={{ marginBottom: 16 }}>
        <TextMD>{data?.name}</TextMD>
      </View>

      <MapList
        data={data?.children}
        onChange={refreshData}
        addMode={addMode}
        type={nextType}
        editMode={editMode}
        parentType={data?.type}
        parentId={data?.id}
        controlEntity={controlEntity}
      ></MapList>

      <TouchableOpacity
        style={{
          ...BORDER_LIGHT,
          ...SHADOW_LIGHT,
          padding: 12,
          ...FLEX_ROW_CENTER_CENTER,
        }}

        onPress={()=>setAddMode(true)}
      >
        <Text>+ Add {nextType}</Text>
      </TouchableOpacity>
    </MobileLayout>
  );
}
