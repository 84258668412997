import React, { useEffect } from "react"
import Dialog from "../dialog/Dialog"
import HoriTwo from "../HoriTwo"
import TextFieldComplete from "../input/TextFieldComplete"
import { useFormState } from "../../hooks/FormState"
import { View, Text } from "../../seperated/react-native"
import { FLEX_ROW_CENTER } from "../constants/style"
import CommonButton from "../buttons/CommonButton"
import { globalLoadingFn } from "../../utils/loading"
import { api } from "../../config"
import IconSelectComplete from "../input/IconSelectComplete"
import { ICON_BUILDING_BASE, ICON_FLOOR_BASE, ICON_ROOM_BASE } from "../constants/options"
import { $VAL_REQUIRED } from "../../utils/validation"
import { alertErrorComplete } from "../../seperated/toast"

function calculateDefaultIconSet(type) {
  switch (type) {
    case 'building': return [...ICON_BUILDING_BASE];
    case 'floor': return [...ICON_FLOOR_BASE];
    case 'room': case 'group': return [...ICON_ROOM_BASE];
    default:
      return [...ICON_BUILDING_BASE];
  }
}

export default function ControlPlaceEditBasicDialog({show, onClose, place}) {
  const model = useFormState({}, {
    name: val => $VAL_REQUIRED(val, "จำเป็นต้องใส่ชื่อด้วย"),
    avatar: val => $VAL_REQUIRED(val, "จำเป็นต้องเลือกรูปภาพด้วย")
  });

  useEffect(()=>{
    if (place) {
      model.dataState.set({
        name: place.name,
        avatar: place.avatar,
      })
    }
  }, [place])

  if (!place) return <></>

  return (
    <Dialog show={show} title="จัดการข้อมูล">
      <View style={{padding: 36}}>
        <HoriTwo left={<Text>ชื่อ</Text>} leftSize={100} style={{marginBottom: 24}}>
          <TextFieldComplete model={model.$("name")} variant="material"></TextFieldComplete>
        </HoriTwo>

        <View style={{ marginBottom: 24 }}>
          <IconSelectComplete
            options={calculateDefaultIconSet(place?.type)}
            model={model.$("avatar")}
          ></IconSelectComplete>
        </View>
  
        <View style={{...FLEX_ROW_CENTER}}>
          <CommonButton style={{flexBasis: "50%", paddingRight: 16}} variant="secondary" onPress={onClose}>ยกเลิก</CommonButton>
          <CommonButton style={{flexBasis: "50%", paddingLeft: 16}} variant="primary" onPress={async ()=>{
            try {
              let modelData = model.getAndValidate();
  
              await globalLoadingFn(()=>api.patch("/"+place.type+"s/"+place.id, {
                name: modelData.name,
                avatar: modelData.avatar
              }));
    
              onClose();
            } catch (err) {
              alertErrorComplete(err);
            }
          }}>บันทึก</CommonButton>
        </View>
      </View>
    </Dialog>
  )
}