import { types } from "mobx-state-tree";
import { $_GET } from "../utils/path";
import { applySnapshotAuto, fitToModel, DateType } from "../utils/mobx";
import { api } from "../config";
import { alert_info } from "../seperated/toast";
import { navigate } from "../seperated/components/Router";
import { performLogout } from "../pages/Login";

const urlParams = new URLSearchParams(window.location.search);

export const UserProfile = types.model({
  name: "",
  avatar: ""
})

export const User = types.model({
  id: 0,
  email: "dummy@gmail.com",
  username: "dummy",
  mobile_number: "0831111111",
  
  email_verified_at: types.maybe(DateType),
  mobile_verified_at: types.maybe(DateType),
  profile: UserProfile,

  two_factor_mobile: true,

  created_at: new Date(),
  updated_at: new Date(),
})

export const ScopeStoreType = types.model({
  activeBuilding: 0,

  controlActiveType: "",
  controlActiveId: 0,

  mapActiveType: "",
  mapActiveId: 0,

  accessToken: "",
  userId: 1,
  user: types.maybe(User),

  redirect: ""
}).actions(self => ({
  setRedirect(redirect) {
    if (redirect) self.redirect = redirect;
  },
  setActivePlace(key, type, id) {
    console.log(key, type, id)
    self[key+"ActiveType"] = type;
    self[key+"ActiveId"] = id;
  },
  resetActivePlace(key) {
    this.setActivePlace(key, "", 0);
  },
  setActiveBuilding(id) {
    if (!id) {
      self.activeBuilding = 0;
      localStorage.removeItem("active_building")
    } else {
      self.activeBuilding = parseInt(id);
      localStorage.setItem("active_building", id);
    }
  },
  setUser(token, user_id) {
    self.accessToken = token;
    self.userId = user_id;
    self.user = undefined; // Force refresh in header
  },
  setUserObject(user) {
    self.user = fitToModel(User, user)
  }
}));

const ScopeStore = ScopeStoreType.create({
  activeBuilding: parseInt(localStorage.getItem("active_building") || "0"),

  // Desktop Only
  controlActiveType: $_GET("type") || "",
  controlActiveId: parseInt($_GET("id") || "") || 0,

  accessToken: urlParams.get("access_token") || localStorage.getItem("access_token") || "",
  userId: parseInt(localStorage.getItem("user_id") || "0"),
  user: undefined,
});

export default ScopeStore;

export async function refreshActiveBuilding() {
  let buildings = (await api.get("/buildings")).data

  if (buildings.length == 0) {
    ScopeStore.setActiveBuilding(null)

    return [null, buildings];
  } else {
    ScopeStore.setActiveBuilding(buildings[0].id)

    return [buildings[0], buildings];
  }
}

export async function selfResolveActiveBuilding() {
  let building_id = ScopeStore.activeBuilding;

  if (building_id) {
    try {
      let building = (await api.get("/buildings/"+building_id)).data;
      if (!building) {
        throw building;
      }
    } catch (err) {
      console.error(err);
      // alert_info("โปรดรอสักครู่ กำลังทำการแก้ไขปัญหาโดยอัตโนมัติ");
      
      try {
        const [new_building, buildings] = await refreshActiveBuilding();
        if (new_building) {
          window.location.reload();
        } else {
          alert_info("กรุณาเข้าสู่ระบบใหม่อีกครั้ง (CODE 1)");
          performLogout()
          // navigate("no_building");
        }
      } catch (err) {
        alert_info("กรุณาเข้าสู่ระบบใหม่อีกครั้ง (CODE 2)");
        performLogout()
      }
    }
  } else {
    try {
      const [new_building, buildings] = await refreshActiveBuilding();
      if (new_building || buildings.length == 0) {
        // navigate("dashboard")
      } else {
        alert_info("กรุณาเข้าสู่ระบบใหม่อีกครั้ง (CODE 3)");
        performLogout()
      }
    } catch (err) {
      alert_info("กรุณาเข้าสู่ระบบใหม่อีกครั้ง (CODE 4)");
      performLogout()
    }

  }
}
setTimeout(() => selfResolveActiveBuilding(), 1000);

export async function loadUser(forceRefresh = false) {
  if (!forceRefresh && ScopeStore.user) return ScopeStore.user;

  const loggedIn = ScopeStore.accessToken != "";

  if (loggedIn) {
    let response = await api.get("/me");
    ScopeStore.setUserObject(response.data);
    return response.data;
  } else {
    return undefined;
  }
}
loadUser()
