import { alertErrorComplete } from "../seperated/toast";

export function $LOCK_NUMBER(s) {
  return s.replace(/\D/g, "");
}

export function $LOCK_LENGTH(s, length) {
  if (s.length > length) return s.slice(0, length);
  return s;
}

export function $VAL_REQUIRED(s, text) {
  if (!s) return text;
  return false;
}

// NOT WORKING

// export function alertErrorDecorator(
//   target: Object,
//   propertyName: string,
//   propertyDesciptor: PropertyDescriptor
// ): PropertyDescriptor {
//   const method = propertyDesciptor.value;

//   propertyDesciptor.value = function (...args: any[]) {
//     try {
//       return method.apply(this, args);
//     } catch (err) {
//       alertErrorComplete(err);
//     }
//   };

//   return propertyDesciptor;
// }

// export function alertErrorDecoratorAsync(
//   target: Object,
//   propertyName: string,
//   propertyDesciptor: PropertyDescriptor
// ): PropertyDescriptor {
//   const method = propertyDesciptor.value;

//   propertyDesciptor.value = async function (...args: any[]) {
//     try {
//       return await method.apply(this, args);
//     } catch (err) {
//       alertErrorComplete(err);
//     }
//   };

//   return propertyDesciptor;
// }
