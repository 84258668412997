import { applySnapshot, getType, isUnionType, getSnapshot } from "mobx-state-tree";
import { cloneDeep } from "lodash";

import { types } from "mobx-state-tree";

export const AnyType = types.custom<any, any>({
  name: "Any",
  fromSnapshot(value: any) {
    return cloneDeep(value);
  },
  toSnapshot(value: any) {
    return cloneDeep(value);
  },
  isTargetType(value: string | any): boolean {
    return true;
  },
  getValidationMessage(value: string): string {
    if (this.isTargetType(value)) return ""; // OK
    return `'${JSON.stringify(value)}' is not any (but it shoule be)`;
  }
});

export const DateType = types.custom<string|number, Date>({
  name: "Date",
  toSnapshot(value: Date): string {
    return value.toISOString();
  },
  fromSnapshot(value: string|number) {
    return dateSafariFix(value);
  },
  isTargetType(value: any): boolean {
    return typeof value != "undefined" && value !== null && (value instanceof Date || typeof value == "string" || typeof value == "number");
  },
  getValidationMessage(value: any): string {
    if (this.isTargetType(value)) return ""; // OK
    return `'${this.fromSnapshot(value)}' is not date (but it shoule be)`;
  }
});

export function createFromModel(model, defaultValue={}) {
  let data: any = {}

  // console.log(model)

  for(let key in model.properties) {
    if (typeof defaultValue[key] !== "undefined") {
      data[key] = defaultValue[key];
      continue;
    }

    if (model.properties[key]._defaultValue) {
      data[key] = model.properties[key]._defaultValue;
      continue;
    }

    let types = [model.properties[key]]

    if (isUnionType(model.properties[key])) {
      types = model.properties[key]._types;

      if (types.find(x => x.name == "undefined")) {
        continue;
      }
    }

    for(let type of types) {
      switch (type.name) {
        case "string": data[key] = ""; break;
        case "number": data[key] = 0; break;
        case "integer": data[key] = 0; break;
        case "boolean": data[key] = false; break;
        case "Date": data[key] = new Date(); break;
        default:
          if (type.properties) {
            data[key] = createFromModel(type);
          }
      }
    }
  }

  return data;
}

function dateSafariFix(date) {
  if (typeof date === "string") {
    // 2021-02-13 00:21:28.000000
    let parts = date.split(" ");
    if (parts.length > 1) {
      date = parts[0] + "T" + parts[1] + "Z";
    }
  }

  return new Date(date)
} 

export function fitToModel(model, data) {
  data = cloneDeep(data)

  for(let key in data) {
    if (!model.properties[key]) {
      delete data[key]
      continue;
    }

    let types = [model.properties[key]]

    if (isUnionType(model.properties[key])) {
      types = model.properties[key]._types;

      let hasUndefined = types.find(x => x.name == "undefined");

      if (hasUndefined && typeof data[key] === "undefined") {
        continue;
      }

      if (hasUndefined && data[key] === null) {
        data[key] = undefined;
        continue;
      }
    }

    for(let type of types) {
      // console.log(key, data[key])
      switch (type.name) {
        case "string": data[key] = (data[key] || (data[key] !== undefined && data[key] !== null ? data[key].toString() : "")).toString(); break;
        case "number": data[key] = parseFloat(data[key] || "0"); break;
        case "integer": data[key] = parseInt(data[key] || "0"); break;
        case "boolean": data[key] = data[key] ? true : false; break;
        case "Date": data[key] = data[key] ? dateSafariFix(data[key]) : new Date(); break;
        default:
          if (type.properties) {
            data[key] = fitToModel(type, data[key]);
          }
      }
    }
  }

  // console.log(model)

  return data;
}

export function applySnapshotAuto(target, data) {
  return applySnapshot(target, fitToModel(getType(target), data))
}

export function applySnapshotPatch(target, data) {
  // console.log({...getSnapshot(target), ...data})
  return applySnapshotAuto(target, {...getSnapshot(target), ...data})
}