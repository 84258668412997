import React, { useState } from "react";
import { View, Text, TouchableOpacity } from "../../seperated/react-native";
import DoubleTap from "../DoubleTap";
import {
  FLEX_ROW_CENTER,
  BORDER_LINE_BOTTOM_HARD,
  FLEX_ROW_CENTER_CENTER,
  BORDER_LINE_TOP_HARD,
} from "../constants/style";
import IconInline from "../icon/IconInline";
import TextFieldComplete from "../input/TextFieldComplete";

export default function AddAirconButton({ type, onAdd, borderFull = true, ...props }) {
  return (
    <View
      style={{
        ...FLEX_ROW_CENTER,
        ...(borderFull ? {
          borderWidth: 3,
          borderColor: "rgb(210, 210, 210)",
        } : {
          borderTopWidth: 3,
          borderTopColor: "rgb(210, 210, 210)",
        }),
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4,
        marginTop: -2,
        alignItems: "stretch",
        backgroundColor: "white",
      }}
    >
      <TouchableOpacity
        {...props}
        style={{ flexGrow: 1, padding: 12, paddingLeft: 24 }}
        onPress={onAdd}
      >
        <Text>+ Add {type}</Text>
      </TouchableOpacity>
    </View>
  );
}
