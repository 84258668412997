import React, { useRef, useMemo, useEffect } from "react";
import { View, Image, TouchableOpacity } from "../../seperated/react-native";
import { navigate } from "../../seperated/components/Router";

import { v4 as uuidv4 } from "uuid";
import ScopeStore from "../../stores/ScopeStore";
import { observe } from "mobx";

import { cloneDeep } from "lodash"
import { deviceNotThisType, deviceCircleIconUrl } from "../../utils/deviceUtils";

const fabricRect = Symbol('fabric_rect')
const fabricLabel = Symbol('fabric_label')
const dataObj = Symbol('data')

function fabricRestrictBound(canvas) {
  canvas.on("object:moving", function (e) {
    var obj = e.target;
    // if object is too big ignore
    if (
      obj.currentHeight > obj.canvas.height ||
      obj.currentWidth > obj.canvas.width
    ) {
      return;
    }
    obj.setCoords();
    // top-left  corner
    if (obj.getBoundingRect().top < 0 || obj.getBoundingRect().left < 0) {
      obj.top = Math.max(obj.top, obj.top - obj.getBoundingRect().top);
      obj.left = Math.max(obj.left, obj.left - obj.getBoundingRect().left);
    }
    // bot-right corner
    if (
      obj.getBoundingRect().top + obj.getBoundingRect().height >
        obj.canvas.height ||
      obj.getBoundingRect().left + obj.getBoundingRect().width >
        obj.canvas.width
    ) {
      obj.top = Math.min(
        obj.top,
        obj.canvas.height -
          obj.getBoundingRect().height +
          obj.top -
          obj.getBoundingRect().top
      );
      obj.left = Math.min(
        obj.left,
        obj.canvas.width -
          obj.getBoundingRect().width +
          obj.left -
          obj.getBoundingRect().left
      );
    }
  });

  var left1 = 0;
  var top1 = 0;
  var scale1x = 0;
  var scale1y = 0;
  var width1 = 0;
  var height1 = 0;
  canvas.on("object:scaling", function (e) {
    var obj = e.target;
    obj.setCoords();
    var brNew = obj.getBoundingRect();

    if (
      brNew.width + brNew.left >= obj.canvas.width ||
      brNew.height + brNew.top >= obj.canvas.height ||
      brNew.left < 0 ||
      brNew.top < 0
    ) {
      obj.left = left1;
      obj.top = top1;
      obj.scaleX = scale1x;
      obj.scaleY = scale1y;
      obj.width = width1;
      obj.height = height1;
    } else {
      left1 = obj.left;
      top1 = obj.top;
      scale1x = obj.scaleX;
      scale1y = obj.scaleY;
      width1 = obj.width;
      height1 = obj.height;
    }
  });
}

function resetSelected(canvas, datas) {
  for (let data of datas) {
    data[fabricRect].set({
      fill: "rgba(155, 210, 247, 0.5)",
      stroke: "#49c5f1",
    });
  }

  canvas.renderAll();
}

function makeSelected(canvas, data) {
  data[fabricRect].set({
    fill: "rgba(253, 202, 62, 0.35)",
    stroke: "#faad00", 
  })
  canvas.setActiveObject(data[fabricRect])
  canvas.renderAll();
}

export default function MapImageEdit({
  devices, controlEntity, onDataUpdate
}) {
  const containerRef = useRef<any>();
  const canvasRef = useRef<any>();
  const canvasData = useRef<any>({});

  const canvasId = useMemo(() => uuidv4(), []);

  let data = cloneDeep(devices)
  data.children = data.children.filter(place => !deviceNotThisType(place, controlEntity));

  useEffect(()=>{
    window.fabric.Object.prototype.transparentCorners = false;
    window.fabric.Object.prototype.cornerColor = "#faad00"

    let observeDisposer;

    let container = containerRef.current;
    if (container) {
      let initialPolling = setInterval(()=>{
        let width = container.clientWidth
        let height = container.clientHeight

        if (!height || !width) return;

        clearInterval(initialPolling)

        ScopeStore.resetActivePlace("map");

        canvasData.current.width = width;
        canvasData.current.height = height;

        // console.log(container, width, height)

        let canvas = canvasRef.current = new window.fabric.Canvas(canvasId, { width, height })

        for (let child of data.children) {
          let position = child.position;

          if (child.type == "device") {
            if (position.width * width < position.height * height) {
              position.size = position.width * width;
            } else {
              position.size = position.height * height;
            }

            window.fabric.Image.fromURL('/img/' + deviceCircleIconUrl(child), function(iconImg) {
              child[fabricRect] = iconImg;

              //i create an extra var for to change some image properties
              iconImg.set({
                top: position.top * height,
                left: position.left * width,
                scaleX: (position.size - 3) / iconImg.width,
                scaleY: (position.size - 3) / iconImg.height,
                // fill: "rgba(155, 210, 247, 0.5)",
                // stroke: "#49c5f1",
                // strokeWidth: 3,
                lockRotation: true,
                hasRotatingPoint: false,
                lockUniScaling: true,
                lockScalingFlip: true,
              });

              iconImg[dataObj] = child;

              canvas.add(iconImg);

              // let label = new window.fabric.Text(child.name, {
              //   top: position.top * height + position.height * height + 10,
              //   left: position.left * width,
              //   fontSize: 22,
              //   textAlign: "center",
              //   backgroundColor: "white",
              //   borderColor: "#49c5f1"
              // })

              // canvas.add(label)
            });             
          } else {
            let rect = child[fabricRect] = new window.fabric.Rect({
              top: position.top * height,
              left: position.left * width,
              width: position.width * width - 3,
              height: position.height * height - 3,
              fill: "rgba(155, 210, 247, 0.5)",
              stroke: "#49c5f1",
              strokeWidth: 3,
              lockRotation: true,
              hasRotatingPoint: false,
            })
  
            rect[dataObj] = child;
  
            canvas.add(rect)
          }
        }

        canvas.on('object:modified', ({target}) => {
          if (target[dataObj]) {
            let position = target[dataObj].position;
            const currpos = target.getBoundingRect()

            position.top = currpos.top / height;
            position.left = currpos.left / width;
            position.width = currpos.width / width;
            position.height = currpos.height / height;

            console.log(target[dataObj])
            onDataUpdate(data);
          }
        })

        const selectionEvent = ({target}) => {
          if (target && target[dataObj]) {
            ScopeStore.setActivePlace("map", target[dataObj].type, target[dataObj].id)
          }
        }

        canvas.on('object:selected', selectionEvent);
        canvas.on('selection:updated', selectionEvent);

        canvas.on('before:selection:cleared', () => {
          resetSelected(canvas, data.children)
        });

        observeDisposer = observe(ScopeStore, "mapActiveId", (change) => {
          for (let c of data.children) {
            if (c.type == ScopeStore.mapActiveType && c.id == ScopeStore.mapActiveId) {
              resetSelected(canvas, data.children);
              makeSelected(canvas, c);
              return;
            }
          }
        })

        fabricRestrictBound(canvas)
      }, 250);
    }

    return () => {
      observeDisposer();
      
      for (let child of data.children) {
        delete child[fabricRect]
      }
    }
  }, [containerRef])

  return (
    <div style={{ position: "relative" }} className="reset-zoom">
      <Image
        src={data.picture}
        style={{ width: "100%", height: "100%" }}
      ></Image>

      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}

        ref={containerRef}
      >
        <canvas
          id={canvasId}
          style={{width: "100%", height: "100%"}}
        ></canvas>
      </div>
    </div>
  );
}
