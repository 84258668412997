import React from "react"
import GoogleLogin from 'react-google-login';
import { GOOGLE_CLIENT_ID } from "../../config";
import { performLoginSocial } from "../../pages/Login";
import LoginWhiteButton from "./LoginWhiteButton";

export default function GoogleLoginButton({prefix = "เข้าสู่ระบบ"}) {
  return (
    <GoogleLogin
      clientId={GOOGLE_CLIENT_ID}
      render={renderProps => (
        <LoginWhiteButton label={prefix + "ด้วย Google"} icon={require('../../img/icons/google.png')} onPress={renderProps.onClick}></LoginWhiteButton>
      )}
      buttonText="Login"
      onSuccess={async (response: any) => {
        let token = response.accessToken;
        console.log(response, token)

        await performLoginSocial("google", token)
      }}
      onFailure={async (response) => {
        if (response.error == "popup_closed_by_user") return;

        console.log(response)
      }}
      cookiePolicy={'single_host_origin'}
    />
  )
}