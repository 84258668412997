import React from "react"
import FilePickerTouchableOpacity from "../../seperated/components/FilePickerTouchableOpacity"
import { View, Image } from "../../seperated/react-native"

export default function AvatarImage({src, size=200, ...props}) {
  return (
    <View {...props}>
      <Image src={src} width={size} height={size} resizeMode="contain" style={{borderRadius: 10000}}></Image>
    </View>
  )
}