import React, { useState } from "react"
import MobileLayout from "../../../components/MobileLayout"
import { View, TextL, TouchableOpacity, Image, TextMD, TextSM } from "../../../seperated/react-native"
import { FLEX_ROW_CENTER, SHADOW_LIGHT, BORDER_LIGHT, FLEX_COLUMN } from "../../../components/constants/style";
import DownArrowIcon from "../../../components/icon/DownArrowIcon";
import RightArrowIcon from "../../../components/icon/RightArrowIcon";
import { navigate } from "../../../seperated/components/Router";
import ScopeStore from "../../../stores/ScopeStore";
import ControlAddHeaderMenu from "../../../components/mobile_menu/ControlAddHeaderMenu";

function CardButtonSpecial({
  icon,
  title,
  subtitle,
  hasChild = false,
  hasArrow = true,
  onPress = undefined,
  ...props
}: {
  icon?: any;
  title: string;
  subtitle?: any;
  hasChild?: boolean;
  hasArrow?: boolean;
  onPress?: () => void;
  [x: string]: any;
}) {
  const [showChild, setShowChild] = useState(false);

  const onPressToggleChild = () => setShowChild(!showChild);

  const onPressInner = onPress
    ? onPress
    : hasChild
    ? () => {
        onPressToggleChild();
      }
    : () => {};

  const ICON_SIZE = 72

  return (
    <View {...props}>
      <View
        style={{
          ...FLEX_ROW_CENTER,
          ...SHADOW_LIGHT,
          ...BORDER_LIGHT,
          padding: 28,
          paddingHorizontal: 24,
        }}
      >
        <TouchableOpacity
          onPress={onPressInner}
          style={{ flexGrow: 1, ...FLEX_ROW_CENTER }}
        >
          {icon && (
            <View style={{ width: ICON_SIZE, flexBasis: ICON_SIZE, marginRight: 48 }}>
              <Image src={icon} style={{ height: ICON_SIZE }} resizeMode="contain" />
            </View>
          )}

          <View style={{ ...FLEX_COLUMN, flexGrow: 1 }}>
            <TextMD style={{ marginBottom: subtitle ? 4 : 0 }}>{title}</TextMD>
            {subtitle && <View>{subtitle}</View>}
          </View>
        </TouchableOpacity>

        {hasArrow && (
          <TouchableOpacity
            onPress={hasChild ? onPressToggleChild : onPressInner}
          >
            <RightArrowIcon style={{height: 24}}></RightArrowIcon>
          </TouchableOpacity>
        )}
      </View>

      {showChild ? props.children : <></>}
    </View>
  );
}


export default function ControlAirTypePage(props) {
  return (
    <MobileLayout title="ควบคุมอุปกรณ์" headerRight={
      <ControlAddHeaderMenu {...props}></ControlAddHeaderMenu>
    }>
      <View style={{marginBottom: 16}}>
        <TextL>เลือกรูปแบบการควบคุม</TextL>
      </View>

      <View>
        <View style={{marginBottom: 20}}>
          <CardButtonSpecial 
            icon={require("../../../img/icons/air.png")} 
            title="รายการแอร์"
            style={{marginBottom: 8}}
            onPress={()=>navigate("control_air_list", {type: "building", id: ScopeStore.activeBuilding})}
          ></CardButtonSpecial>

          <TextSM style={{color: "#999999", paddingLeft: 8}}>* แสดงผลรูปแบบรายการ</TextSM>
        </View>

        <View style={{marginBottom: 20}}>
          <CardButtonSpecial 
            icon={require("../../../img/icons/air.png")} 
            title="แผนผังอาคาร"
            style={{marginBottom: 8}}
            onPress={()=>navigate("control_air_list", {type: "building", id: ScopeStore.activeBuilding, map_mode: true})}
          ></CardButtonSpecial>

          <TextSM style={{color: "#999999", paddingLeft: 8}}>* แสดงผลรูปแบบแผนผังอาคาร</TextSM>
        </View>

        <View style={{marginBottom: 20}}>
          <CardButtonSpecial 
            icon={require("../../../img/icons/air.png")} 
            title="รูปแบบกลุ่ม"
            style={{marginBottom: 8}}
            onPress={()=>navigate("control_group_list")}
          ></CardButtonSpecial>

          <TextSM style={{color: "#999999", paddingLeft: 8}}>* แสดงผลรูปแบบกลุ่ม</TextSM>
        </View>
      </View>
    </MobileLayout>
  )
}