import React from "react"
import Dialog from "../../../../components/dialog/Dialog"
import { View, Image, TextMD, TextL } from "../../../../seperated/react-native"
import { FLEX_ROW_CENTER_CENTER, FLEX_ROW_CENTER, FLEX_COLUMN } from "../../../../components/constants/style"
import CardButton from "../../../../components/buttons/CardButton"
import AirAdderStore from "../../../../stores/AirAdderStore"
import CommonButton from "../../../../components/buttons/CommonButton"
import HoriTwo from "../../../../components/HoriTwo"
import TextFieldComplete from "../../../../components/input/TextFieldComplete"
import SelectCustom from "../../../../seperated/components/SelectCustom"
import MobileLayout from "../../../../components/MobileLayout"
import { navigate } from "../../../../seperated/components/Router"
import { IS_MOBILE } from "../../../../seperated/screen-mode"

function ControlAirAddAnalyzeACInner(props) {
  return (
    <View style={{ ...FLEX_COLUMN, padding: IS_MOBILE ? 0 : 28, paddingHorizontal: IS_MOBILE ? 0 : 96 }}>
      <HoriTwo left={<TextMD>XXX</TextMD>} style={{marginBottom: 20}}>
        <TextFieldComplete variant="material"></TextFieldComplete>
      </HoriTwo>

      <HoriTwo left={<TextMD>XXX</TextMD>} style={{marginBottom: 20}}>
        <TextFieldComplete variant="material"></TextFieldComplete>
      </HoriTwo>

      <View><TextMD>TODO REMEMBER PASSWORD</TextMD></View>

      {!IS_MOBILE && <View style={{...FLEX_ROW_CENTER, marginTop: 36}}>
        <CommonButton variant="default" style={{marginRight: 16, flexGrow: 1}}>ยกเลิก</CommonButton>
        <CommonButton variant="primary" style={{marginLeft: 16, flexGrow: 1}} onPress={()=>AirAdderStore.setActivePage("advance_info")}>ค้นหา</CommonButton>
      </View>}
    </View>
  )
}

export function ControlAirAddAnalyzeACPage(props) {
  return (
    <MobileLayout 
      title="เพิ่มอุปกรณ์" 
      bottom={<CommonButton variant="primary" onPress={()=>navigate("control_air_add_advance_info")}>Analyze your AC</CommonButton>}
    >
      <TextL style={{marginBottom: 18}}>Analyze your AC</TextL>

      <View style={{padding: 8}}>
        <ControlAirAddAnalyzeACInner></ControlAirAddAnalyzeACInner>
      </View>
    </MobileLayout>
  )
}

export default function ControlAirAddAnalyzeAC(props) {
  return (
    <Dialog show={props.show} title="Analyze your AC" hasClose onClose={()=>AirAdderStore.setActivePage("")}>
      <ControlAirAddAnalyzeACInner {...props}></ControlAirAddAnalyzeACInner>
    </Dialog>
  )
}