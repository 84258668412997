import React from "react"
import ButtonWithIcon from "./ButtonWithIcon"
import { navigate } from "../../seperated/components/Router"
import { View } from "../../seperated/react-native"

export default function EnergyButton(props) {
  return (
    <ButtonWithIcon 
      backgroundColor="#e69d63" 
      icon={require("../../img/icons/energy-white.png")}
      {...props}
      onPress={()=>navigate("energy", {type: props.type, id: props.id})}
    >
      พลังงาน
    </ButtonWithIcon>
  )
}