import React from "react"
import { View, Image, Text } from "../../seperated/react-native"
import { FLEX_ROW_CENTER_CENTER, LAYER_FULL, FLEX_COLUMN } from "../constants/style"
import TextWithCircleDot from "../text/TextWithCircleDot"
import { IS_MOBILE } from "../../seperated/screen-mode"
import { toFixed2 } from "../../utils/number"

export default function Weather({data}) {
  return (
    <View>
      <View style={{...FLEX_ROW_CENTER_CENTER, paddingBottom: 40}}>
        <View style={{position: "relative"}}>
          <Image src={require("../../img/icons/weather.png")} width={IS_MOBILE ? 75 : 100} height={IS_MOBILE ? 50 : 75} style={{marginLeft: 30}} />

          <View style={{...LAYER_FULL, ...FLEX_COLUMN}}>
            <View style={{marginLeft: IS_MOBILE ? - 12 : -30, marginTop: 8}}>
              <Text style={{fontSize: IS_MOBILE ? 36 : 54}}>{Math.round(data?.openweather.temperature)} ℃</Text>
            </View>

            {/* <View style={{alignSelf: "flex-end", marginTop: -8, marginRight: -12}}>
              <TextWithCircleDot circleColor="#d7eaf7">{toFixed2(data?.last_data.temperature) || "---"} C</TextWithCircleDot>
            </View> */}
          </View>

          <View style={{...FLEX_ROW_CENTER_CENTER, paddingTop: 16}}>
            <Text>OUTDOOR {IS_MOBILE ? "" : "AMBIENT"}</Text>
          </View>
        </View>
      </View>


    </View>
  )
}