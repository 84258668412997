import React from "react"
import { IS_MOBILE } from "../seperated/screen-mode"
import { SafeAreaView } from "../seperated/react-native"
import { WebDiv } from './../seperated/web-only';

export default function ContainerArea({lowPadding = false, ...props}) {
  if (IS_MOBILE) {
    return <SafeAreaView style={{flex: 1, height: "100%", padding: 36}}>
      {props.children}
    </SafeAreaView>
  } else {
    return <WebDiv className={"container "+(lowPadding ? "container-low-padding" : "")}>
      {props.children}
    </WebDiv>
  }
}