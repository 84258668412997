import React, { useEffect } from "react"
import { View, Text } from "../../seperated/react-native"
import { FLEX_ROW_CENTER, BORDER_LIGHT, SHADOW_LIGHT, FLEX_ROW_CENTER_CENTER } from "../constants/style"
import TimePickerComplete from "../input/TimePickerComplete"
import ScheduleStore from "../../stores/ScheduleStore"
import { useFormState } from "../../hooks/FormState"
import { date2time } from "../../utils/date"
import { observer } from "mobx-react-lite"
import { withStyles, TextField, createStyles } from "@material-ui/core"
import { IS_MOBILE } from "../../seperated/screen-mode"
import Checkbox from "../input/Checkbox"

function ControlScheduleOnOffTextField(color) {
  return (props) => <TextField {...props} inputProps={{disableUnderline: true, style: {
    textAlign: "center",
    fontSize: "28pt",
    padding: 0,
    color
  }}}></TextField>
}

export const ControlScheduleOnTextField = ControlScheduleOnOffTextField("#49c5f1");
export const ControlScheduleOffTextField = ControlScheduleOnOffTextField("#ff9191");

export const ControlScheduleOnOffTimeConnected = observer<any>(({controlKey}) => {
  return (
    <ControlScheduleOnOffTime
      on={ScheduleStore.controls.get(controlKey + "_on")?.every_time}
      off={ScheduleStore.controls.get(controlKey + "_off")?.every_time}
      onChange={(date)=>ScheduleStore.controls.get(controlKey + "_on")?.setEveryTime(date2time(date))}
      offChange={(date)=>ScheduleStore.controls.get(controlKey + "_off")?.setEveryTime(date2time(date))}
      controlKey={controlKey}
    ></ControlScheduleOnOffTime>
  )
})

export default function ControlScheduleOnOffTime({on, off, onChange, offChange, controlKey}) {
  const model = useFormState({
    on, off
  })

  useEffect(()=>{
    model.dataState.patch({on, off})
  }, [on, off])

  return (
    <div style={{gridColumn: "1 / span 2", paddingLeft: 8, paddingRight: 8}}>
      <View style={{...FLEX_ROW_CENTER, ...BORDER_LIGHT, ...SHADOW_LIGHT, width: "100%"}}>
        {/* On */}
        <View style={{flexBasis: "50%"}}>
          <View style={{padding: 8, paddingRight: 0, backgroundColor: "#fafafa", ...FLEX_ROW_CENTER_CENTER, width: "100%", borderRightColor: "#666", borderRightWidth: 3}}>
            <View style={{marginRight: 8}}>
              <Checkbox active={on} onChange={val => {
                if (val) {
                  alert("ไม่สามารถเปิดใช้งานการตั้งเวลาเปิดได้หลังจากยกเลิกไปแล้ว กรุณาสร้างตารางการทำงานใหม่");
                  return;
                } else {
                  if (window.confirm("เมื่อปิดใช้งานการตั้งเวลาเปิดแล้วจะไม่สามารถเปิดใหม่ได้ ถ้าต้องการเปิดต้องสร้างตารางการทำงานใหม่เท่านั้น")) {
                    ScheduleStore.removeControl(controlKey + "_on");
                  }
                }
              }}></Checkbox>
            </View>
            <Text style={{textAlign: "center"}}>เวลาเปิด</Text>
          </View>

          <View style={{height: 100 - 16*2, ...FLEX_ROW_CENTER_CENTER, marginVertical: IS_MOBILE ? 8 : 16, borderRightColor: "#AAA", borderRightWidth: 3}}>
            {on && <TimePickerComplete variant="schedule_on" onChange={onChange} model={model.$("on")}></TimePickerComplete>}
          </View>
        </View>

        {/* Off */}
        <View style={{flexBasis: "50%"}}>
          <View style={{padding: 8, paddingRight: 0, backgroundColor: "#fafafa", ...FLEX_ROW_CENTER_CENTER, width: "100%"}}>
          <View style={{marginRight: 8}}>
              <Checkbox active={off} onChange={val => {
                if (val) {
                  ScheduleStore.airAddOffControl(controlKey);
                } else {
                  ScheduleStore.removeControl(controlKey + "_off");
                }
              }}></Checkbox>
            </View>
            <Text style={{textAlign: "center"}}>เวลาปิด</Text>
          </View>

          <View style={{height: 100 - 16*2, ...FLEX_ROW_CENTER_CENTER, marginVertical: IS_MOBILE ? 8 : 16}}>
            {off && <TimePickerComplete variant="schedule_off" onChange={offChange} model={model.$("off")}></TimePickerComplete>}
          </View>
        </View>
      </View>
    </div>
  )
}