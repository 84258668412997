import React, { useContext } from "react"
import { WebDiv } from "../../../seperated/web-only"
import ControlAirPowerButton from "./ControlAirPowerButton"
import ControlAirModeButton from "./ControlAirModeButton"
import ControlAirScheduleButton from "./ControlAirScheduleButton"
import ControlAirTimerButton from "./ControlAirTimerButton"
import ControlAirMaintenanceButton from "./ControlAirMaintenanceButton"
import ControlAirRemoteButton from "./ControlAirRemoteButton"
import ControlAirFanSpeedButton from "./ControlAirFanSpeedButton"
import ControlAirSwingButton from "./ControlAirSwingButton"
import { IS_MOBILE } from "../../../seperated/screen-mode"
import { View } from "../../../seperated/react-native"
import { FLEX_ROW_CENTER } from "../../constants/style"
import ControlAirStoreModeContext from "./ControlAirStoreModeContext"
import ScheduleStore from "../../../stores/ScheduleStore"
import ControlAirButton from "./ControlAirButton"
import { observer } from "mobx-react-lite"
import ControlScheduleOnOffTime, { ControlScheduleOnOffTimeConnected } from "../ControlScheduleOnOffTime"

const ControlAirPrecoolButton = observer<any>(function ControlAirPrecoolButton({ enablePrecool }) {
  const storeMode = useContext(ControlAirStoreModeContext);

  return <>
    <View style={IS_MOBILE ? {flexBasis: "33.33%", paddingHorizontal: 4, marginBottom: 8} : {}}>
      <ControlAirButton icon={enablePrecool ? require("../../../img/controls/control/precool-active.png") : require("../../../img/controls/control/precool.png")} text="Precool" onPress={()=>{
        if (enablePrecool) {
          ScheduleStore.airDisablePrecool();
        } else {
          ScheduleStore.airEnablePrecool();
          setTimeout(()=>window.scrollBy(0, 300), 250);
        }
      }} iconSize={90}></ControlAirButton>
    </View>
  </>
})

export default observer<any>(function ControlAirSchedulePanel({storeMode="schedule:common_on"}) {
  const isPrecool = storeMode == "schedule:precool_on";
  const enablePrecool = ScheduleStore.isAirEnablePrecool();
  const controlKey = storeMode.split(':')[1];
  const controlOn = ScheduleStore.controls.get(controlKey)

  if (IS_MOBILE) {
    return (
      <ControlAirStoreModeContext.Provider value={storeMode}>
        <View>
          <View style={{...FLEX_ROW_CENTER, flexWrap: "wrap"}}>
            <ControlAirFanSpeedButton></ControlAirFanSpeedButton>
            <ControlAirSwingButton></ControlAirSwingButton>
            {/* <ControlAirRemoteButton></ControlAirRemoteButton> */}
            <ControlAirPrecoolButton enablePrecool={enablePrecool}></ControlAirPrecoolButton>
          </View>
        </View>
      </ControlAirStoreModeContext.Provider>
    )
  }

  return (
    <ControlAirStoreModeContext.Provider value={storeMode}>
      <WebDiv style={{display: "grid", gridTemplateColumns: "50% 50%", width: "100%", justifyItems: "center", rowGap: 16}}>
        <ControlScheduleOnOffTimeConnected controlKey={controlKey.replace("_on", "")}></ControlScheduleOnOffTimeConnected>
        {controlOn && <>
          <ControlAirModeButton></ControlAirModeButton>
          <ControlAirFanSpeedButton></ControlAirFanSpeedButton>
          <ControlAirSwingButton></ControlAirSwingButton>
          {/* <ControlAirRemoteButton></ControlAirRemoteButton> */}
          {!isPrecool && <>
            {/* <ControlAirButton icon={require("../../../img/controls/control/air-motion-sensor.png")} text="Motion sensor"></ControlAirButton> */}
            <ControlAirPrecoolButton enablePrecool={enablePrecool}></ControlAirPrecoolButton>
          </>}
        </>}
      </WebDiv>
    </ControlAirStoreModeContext.Provider>
  )
})