import React, { useState, useEffect } from "react";

import Layout from "../components/Layout";
import { View, Text, Image, TextInputSolid, TouchableOpacity } from "../seperated/react-native";
import TextFieldComplete from "../components/input/TextFieldComplete";
import { IS_MOBILE } from "../seperated/screen-mode";
import { SHADOW_LIGHT, BORDER_LIGHT } from "../components/constants/style";
import { Link, navigate } from "../seperated/components/Router";
import CheckboxComplete from "../components/input/CheckboxComplete";
import { useFormState, FormState } from "../hooks/FormState";
import { alert_error, alertErrorComplete } from "../seperated/toast";
import { globalLoadingFn } from "../utils/loading";
import { api } from "../config";
import { $VAL_REQUIRED, $LOCK_NUMBER } from "../utils/validation";

const FORM_VALIDATION = {
  username: val => $VAL_REQUIRED(val, "จำเป็นต้องใส่ชื่อ"),
  password: val => $VAL_REQUIRED(val, "จำเป็นต้องใส่รหัสผ่าน"),
  confirm_password: (val, data) => {
    console.log(val, data)
    if (val != data.password) return "รหัสผ่าน กับ ยืนยันรหัสผ่าน ไม่ตรงกัน";

    return $VAL_REQUIRED(val, "จำเป็นต้องใส่ยืนยันรหัสผ่าน");
  },
  email: val => {
    console.log(val)
    if (val.indexOf('@') == -1) return "อีเมลไม่ถูกต้อง"

    return $VAL_REQUIRED(val, "จำเป็นต้องใส่อีเมล")
  },
  mobile_number: val => {
    let patt = /^(?:\+[0-9]{0,3})?[0-9]{10}$/;
    if (!patt.test(val)) return "หมายเลขโทรศัพท์ไม่ถูกต้อง"

    return $VAL_REQUIRED(val, "จำเป็นต้องใส่หมายเลขโทรศัพท์")
  },
}

async function performRegister(model: FormState) {
  try {
    let data;
    try {
      data = Object.assign({}, model.getAndValidate());
    } catch (err) {
      alertErrorComplete(err);
    }

    if (data.password != data.confirm_password) {
      alert_error("รหัสผ่าน กับ ยืนยันรหัสผ่าน ไม่ตรงกัน")
      return;
    }
  
    if (!data.accept_tos) {
      alert_error("คุณจำเป็นต้องยอมรับเงื่อนไขในการใช้บริการด้วย")
      return;
    }
  
    delete data.confirm_password;
    delete data.accept_tos;
  
    let response = await globalLoadingFn(()=>api.post("/register", data));
  
    navigate("register_mobile", {
      user_id: response.data.id, 
      mobile_number: response.data.mobile_number,
      otp_secret: response.data.otp.otp_secret
    })
  } catch (err) {
    // alertErrorComplete(err);
  }
}

export default function RegisterDetail(props) {
  const model = useFormState({}, FORM_VALIDATION)

  return (
    <Layout title="ลงทะเบียน">
      <View style={{
        display: "flex",
        flexDirection: IS_MOBILE ? "column" : "row",
        minHeight: "100%",
      }}>
        {!IS_MOBILE && 
        <View style={{flexGrow: 1, flexBasis: "60%", justifyContent: "center", alignItems: "center"}}>
          <Image src={require('../img/working1.png')} style={{width: "75%"}} />
        </View>}
        <View style={{flexGrow: 1, flexBasis: "40%", flexDirection: "column", justifyContent: "center"}}>
          <View style={{marginVertical: 8}}>
            <TextFieldComplete model={model.$("username")} label="ชื่อ" placeholder="ชื่อ" />
          </View>

          <View style={{marginVertical: 8}}>
            <TextFieldComplete model={model.$("password")} label="รหัสผ่าน" placeholder="รหัสผ่าน" secureTextEntry={true} />
          </View>

          <View style={{marginVertical: 8}}>
            <TextFieldComplete model={model.$("confirm_password")} label="ยืนยันรหัสผ่าน" placeholder="ยืนยันรหัสผ่าน" secureTextEntry={true} />
          </View>

          <View style={{marginVertical: 8}}>
            <TextFieldComplete model={model.$("email")} label="อีเมล" placeholder="อีเมล" />
          </View>

          <View style={{marginVertical: 8}}>
            <TextFieldComplete model={model.$("mobile_number")} lock={$LOCK_NUMBER} label="หมายเลขโทรศัพท์" placeholder="หมายเลขโทรศัพท์" />
          </View>

          <TouchableOpacity style={{marginTop: 16}} onPress={() => window.open("/tos")}>
            <CheckboxComplete model={model.$("accept_tos")} label="ยอมรับเงื่อนไขในการใช้บริการ" labelColor="black" labelStyle={{
              textDecorationLine: "underline"
            }}></CheckboxComplete>
          </TouchableOpacity>

          <View style={{marginVertical: 24}}>
            <TouchableOpacity onPress={()=>performRegister(model)}>
              <View style={{padding: 12, ...SHADOW_LIGHT, ...BORDER_LIGHT, backgroundColor: "#9ce2f8"}}>
                <Text style={{textAlign: "center", fontWeight: "bold"}}>ยืนยัน</Text>
              </View>
            </TouchableOpacity>
          </View>
        </View>
        
      </View>
    </Layout>
  )
}
