import React from "react"
import SelectCustom from "../seperated/components/SelectCustom"
import { navigate } from "../seperated/components/Router"

export default function DropdownLink(props) {
  return (
    <SelectCustom {...props} onChange={({value, params}) => {
      navigate(value, params || {})
    }}></SelectCustom>
  )
}