import ScopeStore from "../stores/ScopeStore";
import { useState, useEffect } from "react";
import { navigate } from "../seperated/components/Router";
import { globalLoadingFn } from "../utils/loading";
import { api } from "../config";

export default function useActiveBuilding() {
  const [building, setBuilding] = useState<any>();

  useEffect(()=>{
    if (!ScopeStore.activeBuilding) {
      navigate("no_building")
      return;
    }

    async function f() {
      let building = (await globalLoadingFn(()=>api.get("/buildings/"+ScopeStore.activeBuilding))).data;
      if (building) {
        setBuilding(building)
      } else {
        
      }
    }
    f()
  }, [ScopeStore.activeBuilding])

  return [building, setBuilding];
}