import React, { useContext } from "react"
import { SHADOW_LIGHT, BORDER_LIGHT, FLEX_COLUMN, FLEX_COLUMN_CENTER_CENTER, FLEX_ROW_CENTER_CENTER, FLEX_ROW_CENTER, TEXT_WRAP } from "../../constants/style"
import { TouchableOpacity, View, Text, Image, TextSM } from "../../../seperated/react-native"
import { IS_MOBILE } from "../../../seperated/screen-mode"
import DisabledContext, { DisabledOpacity, InsistDisabledContext } from "../../input/DisabledContext"

const BUTTON_SIZE = 160
const ICON_SIZE = 68
const ICON_SIZE_MOBILE = 24

export default function ControlAirButton({icon, text, iconSize = ICON_SIZE, ...props}: {icon: any, text: string, iconSize?: number, [x: string]: any}) {
  const insistDisabled = useContext(InsistDisabledContext);
  const _disabled = useContext(DisabledContext);
  const disabled = insistDisabled || _disabled;

  if (IS_MOBILE) {
    // Has hardcoded fix for contact repairing
    return (
      <DisabledOpacity value={disabled}>
        <TouchableOpacity {...props} style={{...SHADOW_LIGHT, ...BORDER_LIGHT, ...FLEX_ROW_CENTER_CENTER, padding: text == "Contact repairing" ? 0 : 12, ...(props.style || {})}}>
          <View style={{marginRight: 8}}>
            <Image src={icon} height={ICON_SIZE_MOBILE}></Image>
          </View>
  
          <View>
            {text == "Contact repairing" ? <TextSM>Contact<br/>repairing</TextSM> : <TextSM>{text}</TextSM>}
          </View>
        </TouchableOpacity>
      </DisabledOpacity>
    )
  }

  return (
    <DisabledOpacity value={disabled}>
      <TouchableOpacity {...props} style={{...SHADOW_LIGHT, ...BORDER_LIGHT, ...FLEX_COLUMN, width: BUTTON_SIZE, height: BUTTON_SIZE, ...(props.style || {})}}>
        <View style={{flexGrow: 1, ...FLEX_COLUMN_CENTER_CENTER}}>
          <Image src={icon} style={{width: iconSize}}></Image>
        </View>

        <View style={{backgroundColor: "#fafafa", ...FLEX_ROW_CENTER_CENTER, padding: 6}}>
          <TextSM>{text}</TextSM>
        </View>
      </TouchableOpacity>
    </DisabledOpacity>
  )
}