import React, { useEffect } from "react"
import Dialog from "../../../../components/dialog/Dialog"
import { View, Image, TextMD, TextL, Text, TouchableOpacity, TextSM } from "../../../../seperated/react-native"
import { FLEX_ROW_CENTER_CENTER, FLEX_ROW_CENTER, FLEX_COLUMN, LINK_COLOR, TEXT_WRAP } from "../../../../components/constants/style"
import CardButton from "../../../../components/buttons/CardButton"
import AirAdderStore from "../../../../stores/AirAdderStore"
import CommonButton from "../../../../components/buttons/CommonButton"
import HoriTwo from "../../../../components/HoriTwo"
import TextFieldComplete from "../../../../components/input/TextFieldComplete"
import SelectCustom from "../../../../seperated/components/SelectCustom"
import MobileLayout from "../../../../components/MobileLayout"
import { IS_MOBILE } from "../../../../seperated/screen-mode"
import { navigate } from "../../../../seperated/components/Router"
import SelectComplete from "../../../../components/input/SelectComplete"
import { useFormState } from "../../../../hooks/FormState"
import { observer } from "mobx-react-lite"
import { getSnapshot } from "mobx-state-tree"
import { applySnapshotAuto } from "../../../../utils/mobx"
import { BRAND_OPTIONS, SENSOR_TYPE_OPTIONS } from "../../../../components/constants/options"
import IFrame from "../../../../seperated/components/IFrame"
import { $VAL_REQUIRED } from "../../../../utils/validation"
import { alertErrorComplete } from "../../../../seperated/toast"

const FORM_VALIDATION = {
  device_iot_id: val => $VAL_REQUIRED(val, "จำเป็นต้องใส่ Device ID"),
  device_name: val => $VAL_REQUIRED(val, "จำเป็นต้องใส่ชื่อ Device"),
  // device_air_brand_id: val => $VAL_REQUIRED(val, "จำเป็นต้องเลือกยี่ห้อของแอร์"),
  // device_wifi_ssid: val => $VAL_REQUIRED(val, "จำเป็นต้องใส่ Wifi"),
  device_mqtt_hostname: val => $VAL_REQUIRED(val, "จำเป็นต้องใส่ MQTT Hostname"),
  device_mqtt_port: val => $VAL_REQUIRED(val, "จำเป็นต้องใส่ MQTT Port"),
}

function updateMobx(model) {
  let data = model.getAndValidate();
  applySnapshotAuto(AirAdderStore.device.device_control_air, {...AirAdderStore.device.device_control_air, ...data})
  AirAdderStore.setDeviceName(data.device_name)
}

let ControlAirAddSplitType1Inner = observer<any>(({model}) => {
  const DeviceControlAirSnapshot = getSnapshot(AirAdderStore.device.device_control_air)

  useEffect(()=>{
    model.dataState.set({
      ...DeviceControlAirSnapshot,
      device_name: AirAdderStore.device.device_detail.name
    })
  }, [DeviceControlAirSnapshot, AirAdderStore.device.device_detail.name])

  return (
    <View style={{ ...FLEX_COLUMN, padding: IS_MOBILE ? 0 : 28, paddingHorizontal: IS_MOBILE ? 0 : 64 }}>
      <View style={{marginBottom: 20}}>
        <TextL>Sensor</TextL>
      </View>

      <View>
        <View style={{marginBottom: 16}}>
          <HoriTwo left={<Text>MQTT Topic</Text>} style={{marginBottom: 4}}>
            <TextFieldComplete variant="material" model={model.$("device_iot_id")}></TextFieldComplete>
          </HoriTwo>

          <TextSM>* ไม่ต้องใส่ /# ต่อท้าย</TextSM>
        </View>
  
        <HoriTwo left={<Text>Device Name</Text>} style={{marginBottom: 16}}>
          <TextFieldComplete variant="material" model={model.$("device_name")}></TextFieldComplete>
        </HoriTwo>

        <HoriTwo left={<Text>ประเภท</Text>} style={{marginBottom: 16}}>
          <SelectComplete variant="material" model={model.$("device_air_brand_id")} options={SENSOR_TYPE_OPTIONS}></SelectComplete>
        </HoriTwo>
  
        <HoriTwo left={<Text>MQTT Hostname</Text>} style={{marginBottom: 16}}>
          <TextFieldComplete variant="material" model={model.$("device_mqtt_hostname")}></TextFieldComplete>
        </HoriTwo>
  
        <HoriTwo left={<Text>MQTT Port</Text>} style={{marginBottom: 16}}>
          <TextFieldComplete variant="material" model={model.$("device_mqtt_port")}></TextFieldComplete>
        </HoriTwo>
  
        <HoriTwo left={<Text>MQTT Username</Text>} style={{marginBottom: 16}}>
          <TextFieldComplete variant="material" model={model.$("device_mqtt_username")}></TextFieldComplete>
        </HoriTwo>
  
        <HoriTwo left={<Text>MQTT Password</Text>} style={{marginBottom: 16}}>
          <TextFieldComplete variant="material" model={model.$("device_mqtt_password")} type="password"></TextFieldComplete>
        </HoriTwo>
      </View>

      {/* <View><Text>TODO REMEMBER PASSWORD</Text></View> */}

      {!IS_MOBILE && <View style={{...FLEX_ROW_CENTER, marginTop: 36}}>
        <CommonButton variant="default" style={{marginRight: 16, flexGrow: 1}} onPress={()=>AirAdderStore.goToPreviousPage()}>ย้อนกลับ</CommonButton>
        <CommonButton variant="primary" style={{marginLeft: 16, flexGrow: 1}} onPress={()=>{
          try {
            updateMobx(model)
            AirAdderStore.setActivePage("advance_info")
          } catch (err) {
            alertErrorComplete(err);
          }
        }}>ถัดไป</CommonButton>
      </View>}
    </View>
  )
})

export function ControlAirAddSplitType1Page(props) {
  const model = useFormState({}, FORM_VALIDATION)

  return (
    <MobileLayout 
      title="เพิ่มอุปกรณ์"
      bottom={<CommonButton variant="primary" onPress={()=>{
        try {
          updateMobx(model); 
          navigate("control_air_add_advance_info");
        } catch (err) {
          alertErrorComplete(err);
        }
      }}>ถัดไป</CommonButton>}
    >
      <ControlAirAddSplitType1Inner model={model}></ControlAirAddSplitType1Inner>
    </MobileLayout>
  )
}

export default function ControlAirAddSplitType1(props) {
  const model = useFormState({}, FORM_VALIDATION)

  return (
    <Dialog show={props.show} title="Add New Sensor" hasClose onClose={()=>AirAdderStore.setActivePage("")}>
      <ControlAirAddSplitType1Inner {...props} model={model}></ControlAirAddSplitType1Inner>
    </Dialog>
  )
}