import React, { useState, useEffect } from "react";

import Layout, { LeftPane, CenterPane } from "../components/Layout";
import {
  View,
  Text,
  Image,
  TextInputSolid,
  TouchableOpacity,
  TextMD,
} from "../seperated/react-native";
import TextFieldComplete from "../components/input/TextFieldComplete";
import { IS_MOBILE } from "../seperated/screen-mode";
import {
  SHADOW_LIGHT,
  BORDER_LIGHT,
  FLEX_ROW_CENTER,
  FLEX_LR_CENTER,
  LINK_COLOR,
} from "../components/constants/style";
import { Link, navigate } from "../seperated/components/Router";
import LeftPaneControlPanel from "../components/LeftPane/LeftPaneControlPanel";
import Panel from "../components/Panel";
import PanelInnerGrid from "../components/PanelInnerGrid";
import PanelInner from "../components/PanelInner";
import LeftPaneRootPanel from "../components/LeftPane/LeftPaneRootPanel";
import ScopeStore from "../stores/ScopeStore";
import { globalLoadingFn } from "../utils/loading";
import { api } from "../config";
import CommonButton from "../components/buttons/CommonButton";

export default function ControlAirApiPage(props) {
  const [places, setPlaces] = useState<any[]>([])

  useEffect(() => {
    async function f() {
      let response = await globalLoadingFn(() => api.get("/places/buildings/"+ScopeStore.activeBuilding+"?include_building=1&include_group=1"))
      response.data.reverse();
      setPlaces(response.data);
    }
    f()
  }, [])

  return (
    <Layout webGrid>
      <LeftPane>
        <LeftPaneRootPanel active="..."></LeftPaneRootPanel>
      </LeftPane>

      <CenterPane>
        <Panel>
          <View style={{ marginBottom: 24 }}>
            <PanelInner
              header={
                <View
                  style={{
                    ...FLEX_LR_CENTER,
                  }}
                >
                  <View style={{ flexGrow: 1 }}>
                    <TextMD>Environment variable</TextMD>
                  </View>
                </View>
              }
            >
              <View style={{ marginBottom: 12 }}>
                <TextMD>* นำไปใส่ในโปรแกรมที่ท่านเขียน</TextMD>
              </View>

              <table style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th style={{ textAlign: "left" }}>Name</th>
                    <th style={{ textAlign: "left" }}>Value</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>AIRIN1_API_HOST</td>
                    <td>https://api.airin1.com</td>
                  </tr>

                  <tr>
                    <td>AIRIN1_TOKEN</td>
                    <td>{ScopeStore.accessToken}</td>
                  </tr>

                  <tr>
                    <td>AIRIN1_MQTT_HOST</td>
                    <td>wss://mqtt.eclipseprojects.io/mqtt</td>
                  </tr>

                  <tr>
                    <td>AIRIN1_MQTT_PORT</td>
                    <td>443</td>
                  </tr>

                  <tr>
                    <td>AIRIN1_MQTT_USERNAME</td>
                    <td></td>
                  </tr>

                  <tr>
                    <td>AIRIN1_MQTT_PASSWORD</td>
                    <td></td>
                  </tr>

                  <tr>
                    <td>AIRIN1_SCOPE_TYPE</td>
                    <td>เลือกจากตารางด้านล่าง</td>
                  </tr>

                  <tr>
                    <td>AIRIN1_SCOPE_ID</td>
                    <td>เลือกจากตารางด้านล่าง</td>
                  </tr>
                </tbody>
              </table>
            </PanelInner>
          </View>

          <View style={{ marginBottom: 24 }}>
            <PanelInner
              header={
                <View
                  style={{
                    ...FLEX_LR_CENTER,
                  }}
                >
                  <View style={{ flexGrow: 1 }}>
                    <TextMD>AIRIN1_SCOPE_XXX</TextMD>
                  </View>
                </View>
              }
            >
              <table style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th style={{ textAlign: "left" }}>Name</th>
                    <th style={{ textAlign: "left" }}>AIRIN1_SCOPE_TYPE</th>
                    <th style={{ textAlign: "left" }}>AIRIN1_SCOPE_ID</th>
                  </tr>
                </thead>

                <tbody>
                  {places.map(place => <tr key={place.id}>
                    <td>{place.name}</td>
                    <td>{place.type}</td>
                    <td>{place.id}</td>
                  </tr>)}
                </tbody>
              </table>
            </PanelInner>
          </View>

          <View style={{ marginBottom: 24 }}>
            <PanelInner
              header={
                <View
                  style={{
                    ...FLEX_LR_CENTER,
                  }}
                >
                  <View style={{ flexGrow: 1 }}>
                    <TextMD>API Document (For developer)</TextMD>
                  </View>
                </View>
              }
            >
              <View style={{marginBottom: 24}}>
                <a href="https://gist.github.com/Chomtana/d297317f1cf768ec0467d6373df4f0cb" target="_blank">
                  <CommonButton variant="primary">อ่าน Document</CommonButton>
                </a>
              </View>
            </PanelInner>
          </View>
        </Panel>
      </CenterPane>
    </Layout>
  );
}
