import { getRouteParam } from "../../seperated/components/Router";

export function BUILD_CONTROL_PLACE_LINK(props) {
  const params = {type: getRouteParam(props, "type"), id: getRouteParam(props, "id"), control_entity: getRouteParam(props, "control_entity")}

  return [
    { value: "control_air_list", label: "รูปแบบรายการ", params },
    { value: "control_air_map", label: "รูปแบบแผนผังอาคาร", params },
    { value: "control_group_list", label: "รูปแบบกลุ่ม", params },
  ];
}