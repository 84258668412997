import React, { useContext } from "react"
import { View } from "../../seperated/react-native"

const DisabledContext = React.createContext(false)
export const InsistDisabledContext = React.createContext(false)

export default DisabledContext

export function DisabledOpacity({value, children, opacityOnly=false}) {
  const oldDisabled = useContext(DisabledContext);

  return (
    <DisabledContext.Provider value={opacityOnly ? oldDisabled : value}>
      <View style={value ? {opacity: 0.6} : {}} pointerEvents={value ? "none" : undefined}>
        {children}
      </View>
    </DisabledContext.Provider>
  )
}