import { getCookie } from "./utils/cookie"
import axios from "axios"

export const DEV = process.env.NODE_ENV && process.env.NODE_ENV === 'development'

export const HOST = window.location.origin
export const API_HOST = process.env.REACT_APP_API_HOST || (DEV ? 'https://api.airin1.com/api' : 'https://api.airin1.com/api')

export const DEFAULT_MQTT = {
  device_mqtt_hostname: "wss://mqtt.eclipseprojects.io/mqtt",
  device_mqtt_port: 443,
  device_mqtt_username: undefined,
  device_mqtt_password: undefined,
}

// Social login config
export const FACEBOOK_CLIENT_ID = "548146529319340"
export const GOOGLE_CLIENT_ID = "465711577085-4qlo847oacbtgj8dvpuv2cn9lkpli2t1.apps.googleusercontent.com"

// Liff config
export const LIFF_ID = "1654289314-rBMx7V20"; // Choose one if many

// Auth config
export const AUTH_TENANT = "";
export const AUTH_TENENT_ID = "";
export const AUTH_CLIENT_ID = "";
export const AUTH_API_CLIENT_ID = "";
export const AUTH_POLICY = "";

export const AUTH_ORGANIZER = "";

export const AUTH_UI_LOCALES = "th";

// Auth low level system (Don't touch it)
const urlParams = new URLSearchParams(window.location.search);
export let TOKEN = urlParams.get('access_token') || localStorage.getItem('access_token')

function buildAxios() {
  let headers: any = {};

  if (TOKEN) {
    headers.Authorization = 'Bearer '+TOKEN;
  }

  let api = axios.create({
    baseURL: API_HOST,
    headers: headers
  })

  return api;
}

export let api = buildAxios();

export function _configSetToken(token: string) {
  TOKEN = token;
  api = buildAxios();
}
