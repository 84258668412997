import React, { useContext } from "react";
import {
  TouchableOpacity,
  View,
  TextMD,
  Text,
  Image,
  TextSM,
} from "../../seperated/react-native";
import {
  FLEX_COLUMN,
  FLEX_ROW_CENTER_CENTER,
  BORDER_LIGHT,
  SHADOW_LIGHT,
  FLEX_ROW_CENTER,
} from "../constants/style";
import LinkOrTouchableOpacity from "../LinkOrTouchableOpacity";
import DisabledContext, { DisabledOpacity } from "../input/DisabledContext";

export default function CardButton({
  icon,
  title,
  subtitle = "",
  hasRightArrow = false,
  active = false,
  sm = false,
  ...props
}) {
  const Title = sm ? Text : Text
  const Subtitle = sm ? TextSM : Text

  const disabled = useContext(DisabledContext);

  return (
    <DisabledOpacity value={disabled}>
      <LinkOrTouchableOpacity
        {...props}
        style={{
          width: sm ? 240 : 360,
          ...FLEX_COLUMN,
          ...BORDER_LIGHT,
          ...SHADOW_LIGHT,
          borderColor: active ? "#9ce2f8" : "#f2f2f2",
          borderWidth: active ? 3 : 2,
          ...(props.style || {}),
        }}
      >
        <View
          style={{
            padding: 16,
            backgroundColor: "#f0f9ff",
            height: sm ? 150 : 225,
            ...FLEX_ROW_CENTER_CENTER,
          }}
        >
          {icon}
        </View>
        <View style={{ padding: sm ? 12 : 16 }}>
          <View style={{ flex: 1, flexDirection: "row" }}>
            <View style={{ flexGrow: 1 }}>
              <Title>{title}</Title>
              <Subtitle style={{ color: "#b1b1b1", whiteSpace: "pre-line" }}>{subtitle}</Subtitle>
            </View>
  
            {hasRightArrow ? (
              <View style={{paddingTop: 8}}>
                <Image
                  src={require("../../img/icons/arrow-right-outline.png")}
                  height="16"
                ></Image>
              </View>
            ) : (
              <></>
            )}
          </View>
        </View>
      </LinkOrTouchableOpacity>
    </DisabledOpacity>
  );
}
