import React from "react";
import ScheduleStore from "../../../stores/ScheduleStore";
import { observer } from "mobx-react-lite";
import PanelInnerGrid from "../../../components/PanelInnerGrid";
import PanelInner from "../../../components/PanelInner";
import { View } from "react-native-web";
import {
  FLEX_LR_CENTER,
  FLEX_ROW_CENTER_CENTER,
  FLEX_COLUMN_CENTER_CENTER,
} from "../../../components/constants/style";
import { TextMD, TouchableOpacity } from "../../../seperated/react-native";
import TextFieldComplete from "../../../components/input/TextFieldComplete";
import { model } from "mobx-state-tree/dist/internal";
import ControlAirSchedulePanel from "../../../components/control/air/ControlAirSchedulePanel";
import ControlTempSlider from "../../../components/control/ControlTempSlider";
import { IS_MOBILE } from "../../../seperated/screen-mode";
import { ControlAirModePanel } from "../../../components/control/air/ControlAirModeButton";
import ControlAirStoreModeContext from "../../../components/control/air/ControlAirStoreModeContext";
import { ControlScheduleOnOffTimeConnected } from "../../../components/control/ControlScheduleOnOffTime";

function ControlTemp({ controlKey, refreshKey }) {
  return (
    <ControlTempSlider
      defaultTemp={
        ScheduleStore.controls.get(controlKey)?.control_data.device_temp
      }
      refreshKey={refreshKey}
      storeMode={"schedule:" + controlKey}
    ></ControlTempSlider>
  );
}

export default observer<any>(function ScheduleAirPanel({
  refreshKey,
  model,
  controlKey,
  hasName = false,
}) {
  const controlOn = ScheduleStore.controls.get(controlKey)

  if (IS_MOBILE) {
    return (
      <ControlAirStoreModeContext.Provider value={"schedule:" + controlKey}>
        <View style={{...FLEX_COLUMN_CENTER_CENTER}}>
          <View style={{marginBottom: 24}}>
            <ControlScheduleOnOffTimeConnected controlKey={controlKey.replace("_on", "")}></ControlScheduleOnOffTimeConnected>
          </View>

          {controlOn && <>
            <View style={{marginBottom: 18, width: "100%", paddingHorizontal: 24}}>
              <ControlAirModePanel></ControlAirModePanel>
            </View>
    
            <View style={{marginBottom: 24 }}>
              <ControlTemp
                controlKey={controlKey}
                refreshKey={refreshKey}
              ></ControlTemp>
            </View>
    
            <View style={{width: "100%"}}>
              <ControlAirSchedulePanel
                storeMode={"schedule:" + controlKey}
              ></ControlAirSchedulePanel>
            </View>
          </>}
        </View>
      </ControlAirStoreModeContext.Provider>
    );
  }

  return (
    <PanelInnerGrid style={{ marginBottom: 24 }} autoHeight>
      <PanelInner
        header={
          <View
            style={{
              ...FLEX_LR_CENTER,
            }}
          >
            <View style={{ flexGrow: 1 }}>
              <TextMD>{controlKey == "precool_on" ? "Precool" : "แผงควบคุม"}</TextMD>
            </View>

            {controlKey == "precool_on" && (
              <TouchableOpacity onPress={()=>ScheduleStore.airDisablePrecool()}>
                <TextMD style={{textDecorationLine: "underline"}}>X ยกเลิก</TextMD>
              </TouchableOpacity>
            )}
          </View>
        }
      >
        {hasName && (
          <View style={{ marginBottom: 36 }}>
            <TextMD>ชื่อตารางการทำงาน</TextMD>
            <TextFieldComplete
              variant="material"
              model={model.$("name")}
            ></TextFieldComplete>
          </View>
        )}

        <View style={{ ...FLEX_ROW_CENTER_CENTER, marginBottom: 36 }}>
          {controlOn && <>
            <ControlTemp
              controlKey={controlKey}
              refreshKey={refreshKey}
            ></ControlTemp>
          </>}
        </View>
      </PanelInner>

      <PanelInner
        header={
          <View
            style={{
              ...FLEX_LR_CENTER,
            }}
          >
            <View style={{ flexGrow: 1 }}>
              <TextMD>รายการ</TextMD>
            </View>
          </View>
        }
      >
        <ControlAirSchedulePanel
          storeMode={"schedule:" + controlKey}
        ></ControlAirSchedulePanel>
      </PanelInner>
    </PanelInnerGrid>
  );
});
