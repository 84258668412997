import React, { useState, useEffect } from "react";

import Layout, { LeftPane, CenterPane } from "../../components/Layout";
import {
  View,
  Text,
  Image,
  TextInputSolid,
  TouchableOpacity,
  TextMD,
  TextSM,
  TextXXL,
} from "../../seperated/react-native";
import TextFieldComplete from "../../components/input/TextFieldComplete";
import { IS_MOBILE } from "../../seperated/screen-mode";
import {
  SHADOW_LIGHT,
  BORDER_LIGHT,
  FLEX_ROW_CENTER,
  BORDER_LINE_BOTTOM_HARD,
  FLEX_LR_CENTER,
  FLEX_ROW_CENTER_CENTER,
  FLEX_COLUMN,
} from "../../components/constants/style";
import { Link, navigate, getRouteParam } from "../../seperated/components/Router";
import LeftPaneControlPanel from "../../components/LeftPane/LeftPaneControlPanel";
import Panel from "../../components/Panel";
import PanelInnerGrid from "../../components/PanelInnerGrid";
import PanelInner from "../../components/PanelInner";
import TextWithCircleDot from "../../components/text/TextWithCircleDot";
import ButtonWithIcon from "../../components/buttons/ButtonWithIcon";
import ControlButton from "../../components/buttons/ControlButton";
import EnergyButton from "../../components/buttons/EnergyButton";
import PlantButton from "../../components/buttons/PlantButton";
import SelectCustom from "../../seperated/components/SelectCustom";
import DropdownLink from "../../components/DropdownLink";
import TextWithIcon from "../../components/text/TextWithIcon";
import DetailButton from "../../components/buttons/DetailButton";
import ControlListItem from "../../components/control/ControlListItem";
import MobileLayout from "../../components/MobileLayout";
import ControlAirVertButton from "../../components/control/air/ControlAirVertButton";
import OnlineErrorCount from "../../components/control/OnlineErrorCount";
import IconInline from "../../components/icon/IconInline";
import { api } from "../../config";
import { globalLoadingFn } from "../../utils/loading";
import { globalLoading } from "../../seperated/loading";
import { BUILD_CONTROL_PLACE_LINK } from "../../components/constants/link";
import ManagePlaceButton from "../../components/buttons/ManagePlaceButton";
import ControlAirHeaderMenu from "../../components/mobile_menu/ControlAirHeaderMenu";
import MapImageView from "../../components/map/MapImageView";
import AvatarImage from "../../components/avatar/AvatarImage";
import ScopeStore from "../../stores/ScopeStore";
import ControlListItemList from "../../components/control/ControlListItemList";

export default function ControlGroupList(props) {
  const [data, setData] = useState<any>();
  const [building, setBuilding] = useState<any>();
  const controlEntity = getRouteParam(props, "control_entity") || "device_control_air";

  const DROPDOWN_LINK_OPTIONS = BUILD_CONTROL_PLACE_LINK(props);

  useEffect(()=>{
    async function f() {
      await globalLoadingFn(async ()=>{
        let groups = await api.get("/buildings/" + ScopeStore.activeBuilding + "/groups")
        setData(groups.data)

        let building = await api.get("/buildings/" + ScopeStore.activeBuilding)
        setBuilding(building.data)
      });
      
    }
    f();
  }, []);

  if (IS_MOBILE) {
    return (
      <MobileLayout title="รายการแอร์" load={data || false}>
        <View style={{marginBottom: 8}}>
          <Text>รายการแอร์</Text>
        </View>

        <View>
          {/* CONTROL LIST */}
          <ControlListItemList data={{
            type: "group_wrapper",
            children: data
          }} controlEntity={controlEntity}></ControlListItemList>
        </View>
      </MobileLayout>
    );
  }

  return (
    <Layout webGrid load={data || false}>
      <LeftPane>
        <LeftPaneControlPanel active={controlEntity} data={building || {}}></LeftPaneControlPanel>
      </LeftPane>

      <CenterPane>
        <Panel>
          <View style={{ ...FLEX_LR_CENTER, marginBottom: 24, zIndex: 100 }}>
            <DropdownLink
              variant="text_left_md"
              options={DROPDOWN_LINK_OPTIONS}
              defaultValue={DROPDOWN_LINK_OPTIONS[2]}
            ></DropdownLink>

            <ButtonWithIcon
              backgroundColor="#ff9191"
              icon={require("../../img/icons/home-white.png")}
              style={{ marginRight: 8, paddingHorizontal: 24 }}
              onPress={()=>navigate("control_group_edit", {type: props.type, id: props.id, edit: 1})}
            >
              จัดการข้อมูล
            </ButtonWithIcon>
          </View>

          {/* CONTROL LIST */}
          <ControlListItemList data={{
            type: "group_wrapper",
            children: data
          }} controlEntity={controlEntity}></ControlListItemList>
        </Panel>
      </CenterPane>
    </Layout>
  );
}
