import React from "react"
import IconInline from "./IconInline"

export default function DownArrowIcon(props) {
  return (
    <IconInline {...props} style={{
      height: 10,
      ...(props.style || {})
    }} src={require("../../img/icons/arrow-down-outline.png")} />
  )
}