import React, { useState, useEffect } from "react";
import MobileLayout from "../../components/MobileLayout";
import { TextL, View, Text, Image, TextMD } from "../../seperated/react-native";
import CardButtonMini from "../../components/buttons/CardButtonMini";
import OnlineErrorCount from "../../components/control/OnlineErrorCount";
import { navigate } from "../../seperated/components/Router";
import { BORDER_LIGHT, SHADOW_LIGHT, FLEX_ROW_CENTER, BORDER_LINE_BOTTOM, TEXT_WRAP } from "../../components/constants/style";
import ControlAddHeaderMenu from "../../components/mobile_menu/ControlAddHeaderMenu";
import { globalLoadingFn } from "../../utils/loading";
import { api } from "../../config";
import ScopeStore from "../../stores/ScopeStore";

function TypeOnlineErrorCount({title, online, total, error, icon=undefined, hasLine=true}) {
  const ICON_SIZE = 32;

  return (
    <View style={{...FLEX_ROW_CENTER, paddingVertical: 12, ...(hasLine ? BORDER_LINE_BOTTOM : {})}}>
      <View style={{ width: ICON_SIZE, flexBasis: ICON_SIZE, marginRight: 24 }}>
        <Image src={icon} style={{ height: ICON_SIZE }} resizeMode="contain" />
      </View>

      <View style={{flexGrow: 1}}>
        <TextMD>{title}</TextMD>
      </View>

      <OnlineErrorCount online={online} total={total} error={error}></OnlineErrorCount>
    </View>
  )
}

export default function ControlSelectPage(props) {
  let [building, setBuilding] = useState<any>();

  useEffect(()=>{
    async function f() {
      let response = await globalLoadingFn(()=>api.get("/buildings/"+ScopeStore.activeBuilding));
      setBuilding(response.data);
    }
    f();
  }, [])

  return (
    <MobileLayout title="ควบคุมอุปกรณ์" headerRight={
      <ControlAddHeaderMenu {...props}></ControlAddHeaderMenu>
    } load={building || false}>
      <View style={{marginBottom: 16}}>
        <TextL style={{...TEXT_WRAP}}>{building?.name}</TextL>
      </View>

      <CardButtonMini
        icon={require("../../img/icons/air.png")}
        title="เครื่องปรับอากาศ"
        hasChild
        subtitle={
          <OnlineErrorCount online={building?.online_count} total={building?.total_count} error={building?.error_count}></OnlineErrorCount>
        }
        onPress={()=>navigate("control_air_type")}
        style={{marginBottom: 16}}
      >
        <View style={{paddingHorizontal: 18, ...SHADOW_LIGHT, ...BORDER_LIGHT}}>
          <TypeOnlineErrorCount title="Split Type" online={0} total={0} error={0}></TypeOnlineErrorCount>
          <TypeOnlineErrorCount title="VRV/VRF" online={0} total={0} error={0}></TypeOnlineErrorCount>
          <TypeOnlineErrorCount title="Chiller" online={0} total={0} error={0}></TypeOnlineErrorCount>
        </View>
      </CardButtonMini>
    </MobileLayout>
  );
}
