import React, { useState, useEffect } from "react";

import Layout from "../components/Layout";
import { View, Text, Image, TextXL, TextXXL, TextL, TouchableOpacity } from "../seperated/react-native";
import LayerContainer from "../components/layer/LayerContainer";
import Layer from "../components/layer/Layer";
import { BORDER_LIGHT, SHADOW_LIGHT, FLEX_ROW_CENTER_CENTER } from "../components/constants/style";
import { Link } from "react-router-dom";
import { WebDiv } from "../seperated/web-only";
import ContainerArea from "../components/ContainerArea";
import { observer } from "mobx-react-lite";
import ScopeStore from "../stores/ScopeStore";
import { navigate } from "../seperated/components/Router";

function Why({img, title, des}) {
  return (
    <View style={{display: "flex", flexDirection: "column", alignItems: "center", width: "100%"}}>
      <View style={{marginBottom: 24}}>
        <Image src={img} style={{width: 80, height: 80}} />
      </View>

      <View style={{marginBottom: 12}}>
        <TextL style={{fontWeight: "bold"}}>{title}</TextL>
      </View>

      <View>
        <Text>{des}</Text>
      </View>
    </View>
  )
}

function Detail({img, title, subtitle, reverse, children}) {
  return (
    <WebDiv className={"homepage-detail " + (reverse ? "homepage-detail-reverse" : "")}>
      <View>

      </View>
      
      <View>

      </View>
    </WebDiv>
  )
}

export default observer(function Home(props) {
  if (ScopeStore.activeBuilding) {
    navigate("dashboard")
  }

  return (
    <Layout fluid>
      {/* Block 1 */}
      <View style={{display: "flex", flexDirection: "row"}}>
        <View style={{flexBasis: "35%", backgroundColor: "rgba(221, 221, 221, 0.24)", display: "flex", flexDirection: "column", alignItems: "flex-end", zIndex: 2}}>
          <Image src={require("../img/homepage/AirIn1.png")} style={{
            height: 250,
            width: 350,
            marginTop: "17.5%",
            marginRight: -64
          }} />
        </View>
        <View style={{flexBasis: "65%", backgroundColor: "#c5e9fe", display: "flex", flexDirection: "column", alignItems: "flex-end"}}>
          <View>
            <Image src={require("../img/homepage/air.png")} style={{width: "100%", height: "100%", alignSelf: "center"}} />        
          </View>

          <View style={{marginRight: "16%"}}>
            <TextXXL>ควบคุมอุปกรณ์ด้วยระบบอัจฉริยะ</TextXXL>
          </View>

          <View style={{marginRight: "16%", marginTop: 20}}>
            <Text style={{color: "#818181"}}>ควบคุมอุปกรณ์ด้วยระบบอัจฉริยะ</Text>
          </View>

          <View style={{marginRight: "16%", marginTop: 36, marginBottom: 48}}>
            <Link to="login">
              <View style={{
                ...BORDER_LIGHT,
                ...SHADOW_LIGHT,
                padding: 10,
                backgroundColor: "white",
                width: 200
              }}>
                <Text style={{textAlign: "center", fontWeight: "bold"}}>
                  เข้าสู่ระบบ
                </Text>
              </View>
            </Link>
          </View>

        </View>
      </View>

      {/* Block 2 */}
      <View style={{
        paddingTop: 96,
        paddingBottom: 128
      }}>
        <ContainerArea>
          <View>
            <TextXXL style={{textAlign: "center"}}>ทำไมต้องเลือก Airinone</TextXXL>
          </View>

          <WebDiv className="homepage-why-grid">
            <Why img={require('../img/homepage/power.png')} title="ควบคุมอุปกรณ์" des="ควบคุมได้ทุกที่ ทุกเวลา" />
            <Why img={require('../img/homepage/stat.png')} title="เก็บสถิติอย่างละเอียด" des="ประวัติการใช้งานและพลังงาน" />
            <Why img={require('../img/homepage/energy.png')} title="ประหยัดพลังงาน" des="พวกเราไดกิ้นในฐานะ" />
            <Why img={require('../img/homepage/time.png')} title="ตั้งเวลา เปิด-ปิด" des="ตั้งเวลาล่วงหน้าได้มากถึง 365 วัน" />
          </WebDiv>  
        </ContainerArea>
      </View>

      {/* Block 3 */}
      <View style={{backgroundColor: "#f7f7f7"}}>

      </View>

      <View style={{...FLEX_ROW_CENTER_CENTER, padding: 12}}>
        <TouchableOpacity onPress={()=>navigate("tos")}>
          <Text style={{textDecorationLine: "underline"}}>Privacy Policies and Terms and Conditions</Text>
        </TouchableOpacity>
      </View>
    </Layout>
  )
})
