import { types, getType, applySnapshot } from "mobx-state-tree"
import { api } from "../config"
import { applySnapshotAuto, DateType, createFromModel, applySnapshotPatch, AnyType } from "../utils/mobx"
import { globalLoadingFn } from "../utils/loading"
import ScopeStore from "./ScopeStore"
import { buildDefaultMqtt } from "../utils/mqtt"

export const airMqtt = buildDefaultMqtt();

export const DefaultPosition = {
  top: 0.4,
  left: 0.4,
  width: 0.20,
  height: 0.20,
}

export const Position = types.model("Position", DefaultPosition)

export const Place = types.model("Place", {
  id: 0,
  type: "",
  name: "",
  avatar: "",
  picture: "",
  is_two_level: types.maybe(types.boolean),
  position: types.optional(Position, DefaultPosition),

  permission_level: types.maybe(types.integer),

  online_count: types.maybe(types.integer),
  total_count: types.maybe(types.integer),
  error_count: types.maybe(types.integer),

  path: types.maybe(types.array(AnyType)),
})

export const DeviceBrand = types.model("DeviceBrand", {
  // Read Only
  id: 0,
  name: "",
  key: "",
  status: ""
})

export const DeviceAirType = types.model("DeviceAirType", {
  // Read Only
  id: 0,
  name: "",
  key: "",
  status: ""
})

export const DeviceType = types.model("DeviceType", {
  // Read Only
  id: 0,
  name: "",
  key: "",
  status: ""
})

export const DeviceDetail_Form = types.model("DeviceDetail_Form", {
  // Sent on add
  name: "",
  avatar: "/img/graphics/air.png",
  device_type_id: 1,
  position: Position
})

export const DeviceDetail_ReadOnly = types.model("DeviceDetail_ReadOnly", {
  // Modified later
  room_id: 0,

  device_type: DeviceType,

  // Read Only
  id: 0,
  created_by: 0,
  created_at: DateType,
  updated_by: 0,
  updated_at: DateType,

  scope: Place
})

export const DeviceDetail = types.compose(DeviceDetail_Form, DeviceDetail_ReadOnly).named("DeviceDetail")

export const Technician = types.model("Technician", {
  id: types.maybe(types.integer),
  name: "",
  phone: "",
  address: "",
  under_shop_name: "",
})

export const DeviceControlAir_Form = types.model("DeviceControlAir_Form", {
  // Common data
  device_iot_id: "",
  device_air_brand_id: 1,
  device_air_type_id: 1,
  device_wifi_ssid: "",
  device_wifi_password: "",
  device_air_productcode: "",
  device_air_product_generation: "",
  device_air_upgrade_version: 0,
  device_air_version: 0,
  technician: Technician,
  device_mqtt_hostname: "mqtt.airin1.com",
  device_mqtt_port: 1883,
  device_mqtt_username: "airin1",
  device_mqtt_password: "Aaairin0ne*",

  // Insurance data
  device_air_buy_form: "",
  delivery_date: types.maybe(DateType),
  warranty_all_part_month: 0,
  warranty_compressor_month: 0,
  warranty_installation_month: 0,

  // Sensor only
  device_linked_id: 0,
})

export const DeviceControlAir_ReadOnly = types.model("DeviceControlAir_ReadOnly", {
  // Control data
  device_fan_speed: "",
  device_mode: "",
  device_status: "",
  device_swing_mode: "",
  device_is_locked_remote: "",
  device_temp: 0,
  device_is_locked_temp: 0,
  device_is_energy_saving_mode: false,

  priority: 0,
  timer_schedule_due: types.maybe(DateType),


  // Read Only
  device_id: 0,
  created_by: 0,
  created_at: DateType,
  updated_by: 0,
  updated_at: DateType,
  device_air_mac_address: "",
  device_air_brand: DeviceBrand,
  device_air_type: DeviceAirType,
})

export const DeviceControlAir = types.compose(DeviceControlAir_ReadOnly, DeviceControlAir_Form).named('DeviceControlAir')

export const Air = types.compose(DeviceDetail, types.model({
  device_control_air: DeviceControlAir
})).actions(self => ({
  setActivePage(page) {
    console.log(page)
    //self.activePage = page;
  },
  setDisplayType(type) {
    console.log(type)
    //self.building.display_type = type;
  }
})).named("Air")

const AirStore = Air.create(createFromModel(Air));

const mqtt_alreadylisten = {};
const mqtt_scope_device_iot_ids = {};

export async function refreshAir({quiet = false} = {}) {
  let controlActiveType = ScopeStore.controlActiveType;
  let controlActiveId = ScopeStore.controlActiveId;

  let responsePromise = api.get("/devices/airs/scope?scope_type="+controlActiveType+"&scope_id="+controlActiveId);
  let response;
  
  try {
    if (quiet) {
      response = await responsePromise;
    } else {
      response = await globalLoadingFn(()=>responsePromise, "", {performBack404: true});
    }
  } catch (err) {
    if (err.response?.status == 404) {
      // Clear data but not neccessary
    }

    throw err;
  }

  console.log(response)

  let data = response.data;

  applySnapshotAuto(AirStore, data);

  // function subscribeMqttInner(device_iot_id) {
  //   airMqtt.subscribe('/airin1/control/device_control_air/' + device_iot_id, function (err) {
  //     if (err) {
  //       console.error(err);
  //       return;
  //     }

  //     console.log("SUBSCRIBE SUCCESS device_control_air " + device_iot_id)
  //   })
  // }

  // function subscribeMqtt(device_iot_id) {
  //   if (!mqtt_alreadylisten[device_iot_id]) {
  //     subscribeMqttInner(device_iot_id);
  
  //     airMqtt.on('connect', function () {
  //       subscribeMqttInner(device_iot_id)
  //     })
  
  //     mqtt_alreadylisten[device_iot_id] = true;
  //   }
  // }

  // // Add ids to mqtt_scope_device_iot_ids
  // console.log(data.ids)
  // for (let id of data.ids) {
  //   let key = controlActiveType + ":" + controlActiveId;
  //   if (!mqtt_scope_device_iot_ids[key]) mqtt_scope_device_iot_ids[key] = new Set();
  //   mqtt_scope_device_iot_ids[key].add(id.device_iot_id);

  //   subscribeMqtt(id.device_iot_id);
  // }

}

// async function initMqtt() {
//   airMqtt.on('message', function (topic, message) {
//     if (topic.startsWith("/airin1/control/device_control_air")) {
//       let controlActiveType = ScopeStore.controlActiveType;
//       let controlActiveId = ScopeStore.controlActiveId;

//       let data = JSON.parse(message.toString())
//       console.log(data)

//       if (mqtt_scope_device_iot_ids[controlActiveType + ":" + controlActiveId]?.has(data.device_iot_id)) {
//         console.log({device_control_air: {...AirStore.device_control_air, ...data}})
//         applySnapshotPatch(AirStore, {device_control_air: {...AirStore.device_control_air, ...data}});
//       }
//     }
//   })
// }

// initMqtt();

export default AirStore;