import React, { useState } from "react";
import { View, TouchableOpacity, Image, Text, TextMD } from "../../seperated/react-native";
import { FLEX_ROW_CENTER, SHADOW_LIGHT, FLEX_ROW_CENTER_CENTER, TEXT_WRAP } from "../constants/style";
import TextWithIcon from "../text/TextWithIcon";
import TextWithCircleDot from "../text/TextWithCircleDot";
import DetailButton from "../buttons/DetailButton";
import ControlButton from "../buttons/ControlButton";
import EnergyButton from "../buttons/EnergyButton";
import { IS_MOBILE } from "../../seperated/screen-mode";
import { Link, navigate } from "../../seperated/components/Router";
import OnlineErrorCount from "./OnlineErrorCount";

export default function ControlListItem({
  text = "อาคารสำนักงานใหญ่ ชั้น 2",
  online = 0,
  total = 0,
  error = 0,
  level = 0,
  onPress = () => {},
  type,
  id,
  ...props
}) {
  const [showChild, setShowChild] = useState(false);
  const hasChild = type != "device";

  if (IS_MOBILE) {
    return (
      <TouchableOpacity
        style={{
          ...SHADOW_LIGHT,
          borderRadius: 4,
          borderColor: "#9ce2f8",
          borderWidth: 2,
          padding: 16,
          paddingLeft: 24,
          marginBottom: 16,
          borderLeftColor: "#9ce2f8",
          borderLeftWidth: 16,
          ...FLEX_ROW_CENTER,
        }}

        onPress={async () => {
          await onPress();

          if (hasChild) {
            setShowChild(!showChild);
          }

          // navigate("control_air_manual")
        }}
      >
        <View style={{ flex: 1, flexDirection: "column" }}>
          <TextMD style={{marginBottom: 6, ...TEXT_WRAP}}>{text}</TextMD>

          <OnlineErrorCount online={online} total={total} error={error}></OnlineErrorCount>

        </View>

        <View style={{...FLEX_ROW_CENTER_CENTER}}>
          <Image src={require("../../img/icons/arrow-right-outline.png")} height="24" />
        </View>
      </TouchableOpacity>
    );
  }

  return (
    <>
      <TouchableOpacity
        style={{
          ...SHADOW_LIGHT,
          borderRadius: 4,
          borderColor: showChild ? "#9ce2f8" : "#f2f2f2",
          borderWidth: 2,
          padding: 18,
          paddingLeft: 18 + 24 * level,
          marginTop: 16,
          ...FLEX_ROW_CENTER,
        }}

        onPress={async () => {
          await onPress();

          if (hasChild) {
            setShowChild(!showChild);
          }
          
          // navigate("control_air_manual")
        }}
      >
        <View style={{ flex: 1 }}>
          <TextWithIcon icon={
            hasChild ? (
              showChild ? require("../../img/icons/arrow-down-solid.png") : require("../../img/icons/arrow-right-solid.png")
            ) : undefined
          } iconProps={{
            style: {
              height: hasChild ? 12 - (showChild ? 2 : 0) : undefined
            }
          }}>{text}</TextWithIcon>
        </View>

        <OnlineErrorCount online={online} total={total} error={error} style={{marginRight: 48}}></OnlineErrorCount>

        <View style={{ ...FLEX_ROW_CENTER }}>
          {type != "device" && <DetailButton style={{ marginRight: 8 }} type={type} id={id}></DetailButton>}
          <ControlButton style={{ marginRight: 8 }} type={type} id={id}></ControlButton>
          <EnergyButton type={type} id={id}></EnergyButton>
        </View>
      </TouchableOpacity>

      {showChild ? props.children : <></>}
    </>
  );
}
