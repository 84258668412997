import React, { useState, useEffect } from "react";
import PanelInner from "../PanelInner";
import { FLEX_LR_CENTER, FLEX_ROW_CENTER, FLEX_COLUMN } from "../constants/style";
import { TextMD, View } from "../../seperated/react-native";
import CompareTotalEnergyChart from "./CompareTotalEnergyChart";
import CircleDot from "../CircleDot";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { globalLoadingFn } from "../../utils/loading";
import { api } from "../../config";
import { IS_MOBILE } from "../../seperated/screen-mode";

function formatMonthText(date) {
  return (date.getMonth() + 1).toString().padStart('0', 2) + '/' + date.getFullYear();
}

export default function CompareTotalEnergy(props) {
  let previousMonthDate = new Date();
  previousMonthDate.setMonth(previousMonthDate.getMonth() - 1);

  const [startMonth, setStartMonth] = useState<Date | null>(previousMonthDate);
  const [endMonth, setEndMonth] = useState<Date | null>(new Date());

  const [chartStartData, setChartStartData] = useState<any>([]);
  const [chartEndData, setChartEndData] = useState<any>([]);

  useEffect(() => {
    async function f() {
      let response = await globalLoadingFn(() => api.get("/sensors/chart", {
        params: {
          scope_type: props.scopeType,
          scope_id: props.scopeId,
          interval: "mo",
          date: startMonth,
          chart_type: "energy_consumption",
          plotly_chart_type: "bar_as_scatter",
          split_control_entity: '["total"]',
        }
      }))

      if (response.data.data[0]) response.data.data[0].name = formatMonthText(startMonth);

      console.log(response.data)

      setChartStartData(response.data)
    }
    f();
  }, [startMonth])

  useEffect(() => {
    async function f() {
      let response = await globalLoadingFn(() => api.get("/sensors/chart", {
        params: {
          scope_type: props.scopeType,
          scope_id: props.scopeId,
          interval: "mo",
          date: endMonth,
          chart_type: "energy_consumption",
          plotly_chart_type: "bar_as_scatter",
          split_control_entity: '["total"]',
        }
      }))

      if (response.data.data[0]) response.data.data[0].name = formatMonthText(endMonth);

      console.log(response.data)

      setChartEndData(response.data)
    }
    f();
  }, [endMonth])

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <PanelInner>
        <View style={{ ...(IS_MOBILE ? FLEX_COLUMN : FLEX_LR_CENTER), marginBottom: IS_MOBILE ? 12 : 0 }}>
          <TextMD>Compare Total Energy</TextMD>
          <View style={{ ...FLEX_ROW_CENTER }}>
            <View style={{ ...FLEX_ROW_CENTER, marginRight: 16 }}>
              <CircleDot color={"#ff8b2e"} style={{marginRight: 8}}></CircleDot>
              <DatePicker
                variant="inline"
                openTo="month"
                format="MM/yyyy"
                views={["year", "month"]}
                value={endMonth}
                onChange={setEndMonth}
                style={{
                  width: 90
                }}
              />
            </View>

            <View style={{ ...FLEX_ROW_CENTER, marginRight: 16 }}>
              <CircleDot color={"#1f77b4"} style={{marginRight: 8}}></CircleDot>
              <DatePicker
                variant="inline"
                openTo="month"
                format="MM/yyyy"
                views={["year", "month"]}
                value={startMonth}
                onChange={setStartMonth}
                style={{
                  width: 90
                }}
              />
            </View>
          </View>
        </View>

        <View style={{ ...FLEX_ROW_CENTER }}>
          <View style={{ flexGrow: 1 }}>
            <CompareTotalEnergyChart
              data={chartStartData.data && chartEndData.data && [...chartStartData.data, ...chartEndData.data]}
            ></CompareTotalEnergyChart>
          </View>
        </View>
      </PanelInner>
    </MuiPickersUtilsProvider>
  );
}
