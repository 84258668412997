import React from "react";
import TextWithIcon from "./TextWithIcon";
import { TextL } from "../../seperated/react-native";
import { navigateBack } from "../../seperated/components/Router";

export default function TextWithBack({ Component = TextL, color = "blue", ...props }) {
  return (
    <TextWithIcon
      Component={Component}
      {...props}
      icon={require("../../img/icons/arrow-left-outline" + (color == "black" ? "-black" : "") + ".png")}
      iconProps={{
        style: {
          height: 20,
          marginRight: 16
        },
        onPress() {
          props.onPress ? props.onPress() : navigateBack();
        }
      }}
    ></TextWithIcon>
  );
}
