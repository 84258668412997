import React, { useState, useRef } from "react";
import { View, Text, TouchableOpacity } from "../../seperated/react-native";
import DoubleTap from "../DoubleTap";
import { FLEX_ROW_CENTER, BORDER_LINE_BOTTOM_HARD, FLEX_ROW_CENTER_CENTER, SHADOW_LIGHT, BORDER_LIGHT, TEXT_WRAP } from "../constants/style";
import IconInline from "../icon/IconInline";
import TextFieldComplete from "../input/TextFieldComplete";
import Checkbox from "../input/Checkbox";
import { IS_MOBILE } from "../../seperated/screen-mode";
import { alertErrorComplete } from "../../seperated/toast";

export default function MapListItem(
  {text = "FCU.SP 01", editable = true, selected = false, onChange, onDelete, onSingleTap, onDoubleTap, onCancel, hasCheckbox = false, forceEditing=false, optional=false, showCount=false, count=0, ...rest}: 
  {text?: string, editable?: boolean, onChange?: any, onSingleTap?: any, onDoubleTap?: any, selected?: boolean, [x: string]: any}
) {
  const [editing, setEditing] = useState(forceEditing)
  const [editingText, setEditingText] = useState(text)
  
  return (
    <TouchableOpacity style={
      IS_MOBILE 
      ?
      {...FLEX_ROW_CENTER, ...SHADOW_LIGHT, ...BORDER_LIGHT, marginBottom: 12, alignItems: "stretch"}
      :
      {...FLEX_ROW_CENTER, ...BORDER_LINE_BOTTOM_HARD, alignItems: "stretch"}
    } onPress={onSingleTap}>
      {hasCheckbox && <View style={{...FLEX_ROW_CENTER_CENTER, padding: 12, paddingLeft: 12}}>
        <Checkbox active={selected} onChange={onSingleTap}></Checkbox>
      </View>}

      {editing ?
        <View
          style={{flexGrow: 1, paddingVertical: 12, paddingLeft: 24, backgroundColor: (selected && !hasCheckbox) ? "#ffd280" : "white"}}
        >
          <TextFieldComplete variant="text" value={editingText} style={{width: "100%"}} onChange={event=>{
            setEditingText(event.target.value)
          }} autofocus={true} />
        </View>
      :
        <DoubleTap
          singleTap={onSingleTap}
          doubleTap={onDoubleTap}

          style={{flexGrow: 1, padding: 12, paddingLeft: 24, backgroundColor: (selected && !hasCheckbox) ? "#ffdb99" : "white", maxWidth: "calc(100% - 102px)"}}
        >
          <Text style={{...TEXT_WRAP}}>{text} {showCount ? `(${count} รายการ)` : ""}</Text>
        </DoubleTap>
      }

      {editable ? (editing ?
        <View style={{...FLEX_ROW_CENTER, alignItems: "stretch"}}>
          <TouchableOpacity 
            style={{padding: 8, paddingHorizontal: 8, backgroundColor: "#fce5e5", ...FLEX_ROW_CENTER_CENTER}}
            onPress={()=>{
              if (onCancel) onCancel();
              setEditingText(text)
              setEditing(false)
            }}
          >
            <IconInline src={require("../../img/icons/cancel-red.png")} size={28} />
          </TouchableOpacity>

          <TouchableOpacity 
            style={{padding: 8, paddingHorizontal: 16, backgroundColor: "#f0f9ff", ...FLEX_ROW_CENTER_CENTER}}
            onPress={()=>{
              if (!optional && !editingText) {
                alertErrorComplete({message: "โปรดตรวจสอบว่าคุณได้กรอกข้อมูลครบถ้วนแล้วหรือไม่"})
                return;
              }
  
              if (onChange) onChange(editingText)
              setEditing(false)
            }}
          >
            <IconInline src={require("../../img/icons/save.png")} />
          </TouchableOpacity>
        </View>
      :
        <View style={{...FLEX_ROW_CENTER, alignItems: "stretch"}}>
          <TouchableOpacity 
            style={{padding: 8, paddingHorizontal: 16, backgroundColor: "#f0f9ff", ...FLEX_ROW_CENTER_CENTER}}
            onPress={()=>setEditing(true)}
          >
            <IconInline src={require("../../img/icons/edit.png")} />
          </TouchableOpacity>

          <TouchableOpacity 
            style={{padding: 8, paddingHorizontal: 18, backgroundColor: "#fce5e5", ...FLEX_ROW_CENTER_CENTER}}
            onPress={()=>{
              if (onDelete) onDelete()
            }}
          >
            <IconInline src={require("../../img/icons/delete-red.png")} />
          </TouchableOpacity>
        </View>
      ) : <></>}
    </TouchableOpacity>
  );
}
