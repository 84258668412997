import { StyleProp, ViewStyle } from "react-native-web"

export const LINK_COLOR = "#49b9f1";
export const LINK_COLOR_DARK = "#0044cc";

export const SHADOW_LIGHT = {
  shadowColor: "#bbb",
  shadowOpacity: 0.6,
  shadowRadius: 8,
  shadowOffset: { width: 0, height: 4 },
}

export const BORDER_LIGHT = {
  borderWidth: 3,
  borderColor: "#e9e9e9",
  borderRadius: 6
}

export const BORDER_LINE_TOP = {
  borderTopWidth: 2,
  borderTopColor: "#EEEEEE",
}

export const BORDER_LINE_TOP_HARD = {
  borderTopWidth: 2,
  borderTopColor: "#dedede",
}

export const BORDER_LINE_BOTTOM = {
  borderBottomWidth: 2,
  borderBottomColor: "#EEEEEE",
}

export const BORDER_LINE_BOTTOM_HARD = {
  borderBottomWidth: 2,
  borderBottomColor: "#dedede",
}

export const BORDER_LINE_RIGHT_HARD = {
  borderRightWidth: 2,
  borderRightColor: "#ededed",
}

export const BORDER_LINE_LEFT_HARD = {
  borderLeftWidth: 2,
  borderLeftColor: "#ededed",
}

export const FLEX_ROW_CENTER: object = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center"
}

export const FLEX_ROW_CENTER_CENTER: object = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
}

export const FLEX_LR_CENTER: object = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between"
}

export const FLEX_COLUMN_CENTER_CENTER: object = {
  display: "flex", 
  flexDirection: "column", 
  justifyContent: "center", 
  alignItems: "center"
}

export const FLEX_COLUMN: object = {
  display: "flex", 
  flexDirection: "column", 
  height: "100%",
}

export const TEXT_WRAP: object = {
  flexWrap: "wrap",
  whiteSpace: "normal",
}

export const TEXT_WRAP_CENTER: object = {
  ...TEXT_WRAP,
  textAlign: "center"
}

export const LAYER_FULL: object = {
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
}