import React from "react"
import { TouchableOpacity, View } from "../react-native"
import { globalLoadingFn } from "../../utils/loading";
import { api } from "../../config";

async function uploadFile(file, upload_mode) {
  let formData = new FormData();
  formData.append("file", file);
  
  let response = await globalLoadingFn(()=>api.post('/upload/' + upload_mode, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
  }))

  return response.data.url;
}

export default function FilePickerTouchableOpacity({children, onChange, upload_mode="image", ...props}) {
  return (
    <TouchableOpacity {...props} style={{position: "relative", ...(props.style || {})}}>
      {children}

      <View style={{position: "absolute", top: 0, left: 0, width: "100%", height: "100%"}}>
        <label className="hover-pointer" style={{width: "100%", height: "100%"}}>
          <input
            style={{display: "none"}}
            type="file"
            onChange={async (event) => {
              let target = event.target;

              if (event.target.files && event.target.files.length > 0) {
                try {
                  onChange(await uploadFile(event.target.files[0], upload_mode));
                } finally {
                  target.value = "";
                }

              }
            }}
            accept={upload_mode == "image" ? "image/*" : "*"}
          ></input>
        </label>

      </View>
    </TouchableOpacity>
  )
}