import React, { useEffect, useState } from "react"
import Dialog from "../../../../components/dialog/Dialog"
import { View, Image, TextMD, TextL, Text, TouchableOpacity } from "../../../../seperated/react-native"
import { FLEX_ROW_CENTER_CENTER, FLEX_ROW_CENTER, FLEX_COLUMN, LINK_COLOR, TEXT_WRAP } from "../../../../components/constants/style"
import CardButton from "../../../../components/buttons/CardButton"
import AirAdderStore from "../../../../stores/AirAdderStore"
import CommonButton from "../../../../components/buttons/CommonButton"
import HoriTwo from "../../../../components/HoriTwo"
import TextFieldComplete from "../../../../components/input/TextFieldComplete"
import SelectCustom from "../../../../seperated/components/SelectCustom"
import MobileLayout from "../../../../components/MobileLayout"
import { IS_MOBILE } from "../../../../seperated/screen-mode"
import { navigate } from "../../../../seperated/components/Router"
import SelectComplete from "../../../../components/input/SelectComplete"
import { useFormState } from "../../../../hooks/FormState"
import { observer } from "mobx-react-lite"
import { getSnapshot } from "mobx-state-tree"
import { applySnapshotAuto } from "../../../../utils/mobx"
import { BRAND_OPTIONS, DEVICE_AIR_TYPES_OPTIONS } from "../../../../components/constants/options"
import IFrame from "../../../../seperated/components/IFrame"
import { $VAL_REQUIRED } from "../../../../utils/validation"
import { alertErrorComplete } from "../../../../seperated/toast"
import Checkbox from "../../../../components/input/Checkbox"
import CheckboxComplete from "../../../../components/input/CheckboxComplete"

const DEFAULT_FORM_STATE = {
  modbus_tcp_port: 502,
}

const FORM_VALIDATION = {
  // device_iot_id: val => $VAL_REQUIRED(val, "จำเป็นต้องใส่ Device ID"),
  device_name: val => $VAL_REQUIRED(val, "จำเป็นต้องใส่ชื่อ Device"),
  device_air_brand_id: val => $VAL_REQUIRED(val, "จำเป็นต้องเลือกยี่ห้อของแอร์"),
  // device_mqtt_hostname: val => $VAL_REQUIRED(val, "จำเป็นต้องใส่ MQTT Hostname"),
  // device_mqtt_port: val => $VAL_REQUIRED(val, "จำเป็นต้องใส่ MQTT Port"),
}

function isVRV() {
  return AirAdderStore.device.device_control_air.device_air_type_id == 2;
}

function getDefaultConnectionMode() {
  return isVRV() ? "RaspberryPI" : "MQTT";
}

function getDeviceIotId(model) {
  let device_iot_id = "";
  let data = model.getAndValidate();

  let connectionMode = data.connection_mode || getDefaultConnectionMode();

  if (connectionMode == "MQTT") {
    if (!data.device_iot_id) throw new Error("จำเป็นต้องใส่ Device ID");
    device_iot_id = data.device_iot_id;
  } else if (connectionMode == "ModbusTCP") {
    if (!data.modbus_tcp_host || !data.modbus_tcp_port) throw new Error("จำเป็นต้องใส่ Host และ Port");
    device_iot_id = `tcp/${data.modbus_tcp_host}:${data.modbus_tcp_port}`
  } else if (connectionMode == "RaspberryPI") {
    if (!data.modbus_tcp_host || !data.modbus_tcp_port) throw new Error("จำเป็นต้องใส่ Host และ Raspberry PI ID");
    device_iot_id = `http/${data.modbus_tcp_host}:${data.modbus_tcp_port}`
  }

  if (isVRV()) {
    if (!data.fcu_id) throw new Error("จำเป็นต้องใส่ FCU ID");
    device_iot_id += "/" + data.fcu_id;
  }

  console.log(device_iot_id, connectionMode)

  return device_iot_id;
}

function updateMobx(model) {
  let data = model.getAndValidate();
  data.device_iot_id = getDeviceIotId(model);

  delete data.connection_mode;
  delete data.use_custom_mqtt;
  delete data.modbus_tcp_host;
  delete data.modbus_tcp_port;
  delete data.fcu_id;

  applySnapshotAuto(AirAdderStore.device.device_control_air, {...AirAdderStore.device.device_control_air, ...data})
  AirAdderStore.setDeviceName(data.device_name)
}

function ConnectionMode({children, active, onPress}) {
  return <>
    <TouchableOpacity onPress={onPress}>
      <Text style={{color: active ? 'black' : '#9B9B9B', textDecorationLine: active ? "underline" : "none"}}>{children}</Text>
    </TouchableOpacity>
  </>
}

let ControlAirAddSplitType1Inner = observer<any>(({model}) => {
  const DeviceControlAirSnapshot = getSnapshot(AirAdderStore.device.device_control_air)

  const isVRV = AirAdderStore.device.device_control_air.device_air_type_id == 2;

  const connectionMode = model.get().connection_mode || getDefaultConnectionMode();
  const useCustomMqtt = model.get().use_custom_mqtt;

  useEffect(()=>{
    model.dataState.set({
      ...model.get(),
      ...DeviceControlAirSnapshot,
      device_name: AirAdderStore.device.device_detail.name
    })
  }, [DeviceControlAirSnapshot, AirAdderStore.device.device_detail.name])

  function setConnectionMode(mode) {
    model.$("connection_mode").dataState.set(mode);
  }

  return (
    <View style={{ ...FLEX_COLUMN, padding: IS_MOBILE ? 0 : 28, paddingHorizontal: IS_MOBILE ? 0 : 64 }}>
      <View style={{marginBottom: 20}}>
        <TextL>{DEVICE_AIR_TYPES_OPTIONS.find(c => c.value == AirAdderStore.device.device_control_air.device_air_type_id.toString())?.label}</TextL>
      </View>

      {/* <View style={{marginBottom: 20}}>
        <Text style={{...TEXT_WRAP}}>Step 1: ทำการ Reset air controller โดยการกดปุ่ม M ค้างไว้ประมาณ​ 10 วินาที</Text>
        <Text style={{...TEXT_WRAP}}>Step 2: ทำการเชื่อมต่อ Wifi Daikin</Text>

        <TouchableOpacity onPress={()=>window.open("http://192.168.4.1/config/mqtt.tpl")}>
          <Text style={{color: LINK_COLOR, ...TEXT_WRAP}}>Step 3: คลิกที่ลิ้งนี้ แล้วกรอกข้อมูล MQTT จากนั้นกด Save แล้วปิด tab กลับมาหน้านี้</Text>
        </TouchableOpacity>

        <TouchableOpacity onPress={()=>window.open("http://192.168.4.1")}>
          <Text style={{color: LINK_COLOR, ...TEXT_WRAP}}>Step 4: คลิกที่ลิ้งนี้ แล้วกรอกข้อมูล Wifi จากนั้นกด Connect แล้วปิด tab กลับมาหน้านี้</Text>
        </TouchableOpacity>

        <Text style={{...TEXT_WRAP}}>Step 5: นำข้อมูล MQTT และ Wifi มากรอกด้านล่าง แล้วกดถัดไป</Text>
      </View> */}

      <View>
        <HoriTwo left={<Text>Device Name</Text>} style={{marginBottom: 16}}>
          <TextFieldComplete variant="material" model={model.$("device_name")}></TextFieldComplete>
        </HoriTwo>
  
        <HoriTwo left={<Text>Brand</Text>} style={{marginBottom: 16}}>
          <SelectComplete variant="material" model={model.$("device_air_brand_id")} options={BRAND_OPTIONS}></SelectComplete>
        </HoriTwo>
  
        {/* <HoriTwo left={<Text>Type</Text>} style={{marginBottom: 16}}>
          <SelectComplete variant="material"></SelectComplete>
        </HoriTwo> */}
  
        {/* <HoriTwo left={<Text>Private IP Address</Text>} style={{marginBottom: 16}}>
          <TextFieldComplete variant="material" model={model.$("device_private_ip_address")}></TextFieldComplete>
        </HoriTwo>
  
        <HoriTwo left={<Text>Static IP Address</Text>} style={{marginBottom: 16}}>
          <TextFieldComplete variant="material" model={model.$("device_static_ip_address")}></TextFieldComplete>
        </HoriTwo> */}

        <View style={{ marginBottom: 16, marginTop: 8, ...FLEX_ROW_CENTER, justifyContent: "space-around" }}>
          <ConnectionMode 
            active={connectionMode == "MQTT"} 
            onPress={() => setConnectionMode("MQTT")}
          >
            MQTT
          </ConnectionMode>

          <ConnectionMode 
            active={connectionMode == "ModbusTCP"} 
            onPress={() => setConnectionMode("ModbusTCP")}
          >
            Modbus TCP
          </ConnectionMode>

          <ConnectionMode 
            active={connectionMode == "RaspberryPI"} 
            onPress={() => setConnectionMode("RaspberryPI")}
          >
            Raspberry PI
          </ConnectionMode>
        </View>

        {connectionMode == "MQTT" && <>
          <HoriTwo left={<Text style={{...TEXT_WRAP}}>Device ID</Text>} style={{marginBottom: 16}}>
            <TextFieldComplete variant="material" model={model.$("device_iot_id")}></TextFieldComplete>
          </HoriTwo>

          {isVRV &&
            <HoriTwo left={<Text style={{...TEXT_WRAP}}>FCU ID</Text>} style={{marginBottom: 16}}>
              <TextFieldComplete variant="material" model={model.$("fcu_id")}></TextFieldComplete>
            </HoriTwo>
          }

          <View style={{marginBottom: 16}}>
            <Text>* อุปกรณ์บางรุ่นใช้ Mac Address เป็น Device ID</Text>
          </View>

          <View style={{...FLEX_ROW_CENTER, marginBottom: 16}}>
            <View style={{marginRight: 16}}>
              <CheckboxComplete model={model.$("use_custom_mqtt")}></CheckboxComplete>
            </View>

            <View>
              <Text>ใช้ Server MQTT ของตัวเอง</Text>
            </View>
          </View>
    
          {useCustomMqtt && <View>
            <HoriTwo left={<Text>MQTT Hostname</Text>} style={{marginBottom: 16}}>
              <TextFieldComplete variant="material" model={model.$("device_mqtt_hostname")}></TextFieldComplete>
            </HoriTwo>
      
            <HoriTwo left={<Text>MQTT Port</Text>} style={{marginBottom: 16}}>
              <TextFieldComplete variant="material" model={model.$("device_mqtt_port")}></TextFieldComplete>
            </HoriTwo>
      
            <HoriTwo left={<Text>MQTT Username</Text>} style={{marginBottom: 16}}>
              <TextFieldComplete variant="material" model={model.$("device_mqtt_username")}></TextFieldComplete>
            </HoriTwo>
      
            <HoriTwo left={<Text>MQTT Password</Text>} style={{marginBottom: 16}}>
              <TextFieldComplete variant="material" model={model.$("device_mqtt_password")} type="password"></TextFieldComplete>
            </HoriTwo>
          </View>}
        </>}

        {connectionMode == "ModbusTCP" && <>
          <HoriTwo left={<Text style={{...TEXT_WRAP}}>Host</Text>} style={{marginBottom: 16}}>
            <TextFieldComplete variant="material" model={model.$("modbus_tcp_host")}></TextFieldComplete>
          </HoriTwo>

          <HoriTwo left={<Text style={{...TEXT_WRAP}}>Port</Text>} style={{marginBottom: 16}}>
            <TextFieldComplete variant="material" model={model.$("modbus_tcp_port")}></TextFieldComplete>
          </HoriTwo>

          {isVRV &&
            <HoriTwo left={<Text style={{...TEXT_WRAP}}>FCU ID</Text>} style={{marginBottom: 16}}>
              <TextFieldComplete variant="material" model={model.$("fcu_id")}></TextFieldComplete>
            </HoriTwo>
          }
        </>}

        {connectionMode == "RaspberryPI" && <>
          <HoriTwo left={<Text style={{...TEXT_WRAP}}>Host</Text>} style={{marginBottom: 16}}>
            <TextFieldComplete variant="material" model={model.$("modbus_tcp_host")}></TextFieldComplete>
          </HoriTwo>

          <HoriTwo left={<Text style={{...TEXT_WRAP}}>Modbus ID</Text>} style={{marginBottom: 16}}>
            <TextFieldComplete variant="material" model={model.$("modbus_tcp_port")}></TextFieldComplete>
          </HoriTwo>

          {isVRV &&
            <HoriTwo left={<Text style={{...TEXT_WRAP}}>FCU ID</Text>} style={{marginBottom: 16}}>
              <TextFieldComplete variant="material" model={model.$("fcu_id")}></TextFieldComplete>
            </HoriTwo>
          }
        </>}
      </View>

      {/* <View><Text>TODO REMEMBER PASSWORD</Text></View> */}

      {!IS_MOBILE && <View style={{...FLEX_ROW_CENTER, marginTop: 36}}>
        <CommonButton variant="default" style={{marginRight: 16, flexGrow: 1}} onPress={()=>AirAdderStore.goToPreviousPage()}>ย้อนกลับ</CommonButton>
        <CommonButton variant="primary" style={{marginLeft: 16, flexGrow: 1}} onPress={()=>{
          try {
            updateMobx(model)
            AirAdderStore.setActivePage("advance_info")
          } catch (err) {
            alertErrorComplete(err);
          }
        }}>ถัดไป</CommonButton>
      </View>}
    </View>
  )
})

export function ControlAirAddSplitType1Page(props) {
  const model = useFormState({...DEFAULT_FORM_STATE}, FORM_VALIDATION)

  return (
    <MobileLayout 
      title="เพิ่มอุปกรณ์"
      bottom={<CommonButton variant="primary" onPress={()=>{
        try {
          updateMobx(model); 
          navigate("control_air_add_advance_info");
        } catch (err) {
          alertErrorComplete(err);
        }
      }}>ถัดไป</CommonButton>}
    >
      <ControlAirAddSplitType1Inner model={model}></ControlAirAddSplitType1Inner>
    </MobileLayout>
  )
}

export default function ControlAirAddSplitType1(props) {
  const model = useFormState({...DEFAULT_FORM_STATE}, FORM_VALIDATION)

  return (
    <Dialog show={props.show} title="Add New Aircondition" hasClose onClose={()=>AirAdderStore.setActivePage("")}>
      <ControlAirAddSplitType1Inner {...props} model={model}></ControlAirAddSplitType1Inner>
    </Dialog>
  )
}