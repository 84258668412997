import React, { useState } from "react";
import { View, Image, TouchableOpacity, Text } from "../../seperated/react-native";
import { navigate } from "../../seperated/components/Router";
import { observer } from "mobx-react-lite";
import ScopeStore from "../../stores/ScopeStore";
import { TEXT_WRAP_CENTER, FLEX_ROW_CENTER_CENTER } from "../constants/style";
import { deviceNotThisType, deviceCircleIconUrl } from "../../utils/deviceUtils";
import { IS_MOBILE } from "../../seperated/screen-mode";
import Dialog from "../dialog/Dialog";
import CardButton from "../buttons/CardButton";
import ControlAirButton from "../control/air/ControlAirButton";
import { globalLoadingFn } from "../../utils/loading";
import { api } from "../../config";

const ON_ICON = require("../../img/controls/control/power-on.png")
const OFF_ICON = require("../../img/controls/control/power-off.png")

const MapImageViewBox = observer<any>(({ data, to, editMode=false, mapMode=false, mapModeRouteParams=false }) => {
  const position = data.position;
  const active = mapMode && ScopeStore.mapActiveId == data.id && ScopeStore.mapActiveType == data.type

  const [lastPress, setLastPress] = useState(0);
  const [actionActive, setActionActive] = useState(false);
  const [on, setOn] = useState(data.online_count > 0);

  let fontSize = 50 / data.name.length

  function navigateNext(editMode) {
    if (mapMode) {
      ScopeStore.setActivePlace("map", data.type, data.id);
      setLastPress(Date.now())
      if (Date.now() - lastPress > 500) return;
    }

    if (data.type == "device") {
      navigate("control_air_manual", {type: data.type, id: data.id});
    } else {
      navigate(to+"_"+data.type, {type: data.type, id: data.id, ...(editMode ? {edit_mode: 1} : {}), plan_mode: 1, ...(mapModeRouteParams ? {map_mode: 1} : {})})
    }
  }

  async function toggleAirStatus(on) {
    await globalLoadingFn(async ()=>{
      let response = await api.post("/devices/device_control_air/turn_"+(on ? "on" : "off")+"?scope_type="+data.type+"&scope_id="+data.id)
      setOn(on);
      setActionActive(false);
    })
  }

  return (
    <TouchableOpacity
      style={{
        position: "absolute",
        top: (position.top * 100) + "%",
        left: (position.left * 100) + "%",
        width: (position.width * 100) + "%",
        height: (position.height * 100) + "%",
        ...( data.type != "device" ? {
          backgroundColor: active ? "rgba(253, 202, 62, 0.35)" : (on ? '#5cecff99' : "#ff222299"),
          // borderWidth: 3,
          // borderColor: active ? "#faad00" : (on ? "#5cecff" : "red"),
        } : {} )
      }}

      onPress={()=>{
        if (mapMode) {
          navigateNext(editMode);
        } else {
          setActionActive(true)
        }
      }}
    >
      {data.type == "device" ? <View style={{position: "relative", height: "100%"}}>
        <Image src={"/img/" + deviceCircleIconUrl(data)} style={{
          width: position.width <= position.height ? "100%" : undefined, 
          height: position.height <= position.width ? "100%" : undefined
        }} />

        {/* <div style={{
          position: "absolute", 
          top: "110%", 
          left: "50%", 
          transform: "translateX(-50%)",
          border: "solid 2px #49c5f1",
          borderRadius: 20,
          padding: 4,
          paddingLeft: 16,
          paddingRight: 16,
          backgroundColor: active ? "#eaeaea" : "white",
          boxShadow: "0px 0px 7px 1px rgba(155,155,155,0.8)",
          MozBoxShadow: "0px 0px 7px 1px rgba(155,155,155,0.8)",
          WebkitBoxShadow: "0px 0px 7px 1px rgba(155,155,155,0.8)",
          maxWidth: 400
        }}>
          <Text style={{...TEXT_WRAP_CENTER}}>{data.name}</Text>
        </div> */}
      </View> : <View style={{...FLEX_ROW_CENTER_CENTER, height: "100%"}}>
        {data.name.length * 0.02 < position.width &&
          <View style={{borderRadius: 36, backgroundColor: "rgba(255, 255, 255, 0.8)", paddingHorizontal: 8, paddingVertical: 4}}>
            <div style={{fontSize: IS_MOBILE ? 14 : 18, textOverflow: "ellipsis"}}>{data.name}</div>
          </View>
        }
      </View>}

      <Dialog show={actionActive} title={data.name} hasClose onClose={()=>setActionActive(false)}>
        <View style={{...FLEX_ROW_CENTER_CENTER, padding: 32}}>
          <View style={{paddingHorizontal: 12}}>
            <ControlAirButton 
              icon={on ? ON_ICON : OFF_ICON} 
              text={on ? "On" : "Off"} 
              onPress={()=>toggleAirStatus(!on)}
            ></ControlAirButton>
          </View>

          <View style={{paddingHorizontal: 12}}>
            <ControlAirButton 
              icon={require("../../img/icons/indoor.png")} 
              text={"เข้าไปข้างใน"} 
              onPress={()=>navigateNext(editMode)}
            ></ControlAirButton>
          </View>

          {/* <View style={{paddingHorizontal: 12}}>
            <ControlAirButton 
              icon={require("../../img/icons/edit.png")} 
              text={"แก้ไขข้อมูล"} 
              onPress={()=>navigateNext(true)}
            ></ControlAirButton>
          </View> */}
        </View>
      </Dialog>
    </TouchableOpacity>
  );
})

export default function MapImageView({ data, to, editMode=false, mapMode=false, mapModeRouteParams=false, width=0, controlEntity }) {
  return (
    <View style={{ position: "relative" }}>
      <Image
        src={data.picture}
        style={{ width: width || "100%", height: "100%" }}
      ></Image>

      {data.children.filter(place => !deviceNotThisType(place, controlEntity)).map((child) => (
        <MapImageViewBox
          key={child.id}
          data={child}
          to={to}
          editMode={editMode}
          mapMode={mapMode}
          mapModeRouteParams={mapModeRouteParams}
        ></MapImageViewBox>
      ))}
    </View>
  );
}
