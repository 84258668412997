const MONTH_LABEL = [
  "มกราคม",
  "กุมภาพันธ์",
  "มีนาคม",
  "เมษายน",
  "พฤษภาคม",
  "มิถุนายน",
  "กรกฎาคม",
  "สิงหาคม",
  "กันยายน",
  "ตุลาคม",
  "พฤศจิกายน",
  "ธันวาคม"
]

export function formatDate(date) {
  date = new Date(date);

  return date.getDate() + "/" + (date.getMonth() + 1) + "/" + (date.getFullYear())
}

export function formatLongDate(date) {
  date = new Date(date)
  return date.getDate() + " " + MONTH_LABEL[date.getMonth()] + " " + date.getFullYear();
}

export function formatDateDiff(a, b) {
  a = new Date(a)
  b = new Date(b)
  let diff = Math.floor(Math.max(0, (a - b)/1000));

  return Math.floor(diff / 60).toString().padStart(2, '0') + ":" + (diff % 60).toString().padStart(2, '0');
}

export function toDateString(date) {
  date = new Date(date)
  return date.getFullYear() + "-" + (date.getMonth() + 1).toString().padStart(2, '0') + "-" + (date.getDate()).toString().padStart(2, '0')
}

export function toThaiDateString(date) {
  date = new Date(date)
  return (date.getDate()).toString().padStart(2, '0') + "/" + (date.getMonth() + 1).toString().padStart(2, '0') + "/" + date.getFullYear()
}

export function date2time(date, seconds = false) {
  date = new Date(date);

  return date.getHours().toString().padStart(2, '0') + ":" + date.getMinutes().toString().padStart(2, '0') + (seconds ? ":" + date.getSeconds().toString().padStart(2, '0') : "");
}

export function time2date(time) {
  if (time instanceof Date) return time;
  return new Date("2020-03-03T"+time);
}

export function mergeDateAndTime(date, time) {
  let result = time2date(time);

  date = new Date(date)

  result.setDate(date.getDate());
  result.setMonth(date.getMonth());
  result.setFullYear(date.getFullYear());

  return result;
}

export function getTimezone() {
  var offset = new Date().getTimezoneOffset(), o = Math.abs(offset);
  return (offset < 0 ? "+" : "-") + ("00" + Math.floor(o / 60)).slice(-2) + ":" + ("00" + (o % 60)).slice(-2);
}

export function timeAdd(time: string, hour=0, minute=0) {
  let d = time2date(time)
  d.setHours(d.getHours() + hour);
  d.setMinutes(d.getMinutes() + minute);

  return d.getHours().toString().padStart(2, "0") + ":" + d.getMinutes().toString().padStart(2, "0");
}