import * as React from "react";

import { FormState, useFormStateAgain } from "../../hooks/FormState";
import classNames from "classnames";
import { DEV } from "../../config";
import { debounce } from "lodash";
import { inputOnChange } from "./core_logic/onChange";
import { TextInput, TouchableOpacity } from "../../seperated/react-native";
import InputCompleteBase from "./core_logic/InputCompleteBase";
import { formatDateDiff, toDateString, date2time } from "../../utils/date";

import { TextField } from "@material-ui/core";

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  DatePicker,
} from '@material-ui/pickers';
import IconInline from "../icon/IconInline";

export default function DatePickerComplete({variant="material", ...props}) {
  let model = useFormStateAgain(props.model, props.defaultValue || undefined);
  const [open, setOpen] = React.useState(false);

  if (variant == "material") {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          {...props}
          disableToolbar
          variant="inline"
          format="dd/MM/yyyy"
          label={props.label}
          value={model.dataState.get()}
          onChange={date => inputOnChange(props, model, toDateString(date), date)}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </MuiPickersUtilsProvider>
    );
  } else if (variant == "material_nokeyboard") {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          {...props}
          disableToolbar
          variant="inline"
          format="dd/MM/yyyy"
          label={props.label}
          value={model.dataState.get()}
          onChange={date => inputOnChange(props, model, date, date)}
        />
      </MuiPickersUtilsProvider>
    );
  } else if (variant == "button") {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <TouchableOpacity onPress={() => setOpen(true)}>
          <IconInline src={require('../../img/icons/calendar.png')} size={24}></IconInline>
        </TouchableOpacity>
        <DatePicker 
          open={open} 
          label={props.label}
          value={model.dataState.get()}
          onChange={date => inputOnChange(props, model, date, date)}
          onClose={()=>setOpen(false)}
          style={{display: "none"}}
        />
      </MuiPickersUtilsProvider>
    )
  }

  return (
    <InputCompleteBase {...props} model={model}>
      <TextInput
        value={model.dataState.get()}
        variant={variant}
        {...props}
        //className={classNames([ "form-control form-control-solid " + (model.errorState.get() ? 'is-invalid' : ''), props.className||"" ])}
        onChange={(event, ...args) =>
          inputOnChange(props, model, event.target.value, event, ...args)
        }
      />
    </InputCompleteBase>
  );
}
