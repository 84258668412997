import React, { useState, useEffect } from "react";

import Layout from "../components/Layout";
import { View, Text, Image, TextInputSolid, TouchableOpacity } from "../seperated/react-native";
import TextFieldComplete from "../components/input/TextFieldComplete";
import { IS_MOBILE } from "../seperated/screen-mode";
import { SHADOW_LIGHT, BORDER_LIGHT, FLEX_ROW_CENTER, LINK_COLOR, FLEX_ROW_CENTER_CENTER } from "../components/constants/style";
import { Link, navigate, getRouteParam, getRouteParams } from "../seperated/components/Router";
import { useFormState } from "../hooks/FormState";
import { globalLoadingFn } from "../utils/loading";
import { api } from "../config";
import { performLogin } from "./Login";
import { alert_error } from "../seperated/toast";

async function resendOtp(mobile_number) {
  return await globalLoadingFn(() => api.post("/resend_otp/" + mobile_number));
}

export default function RegisterMobile(props) {
  const model = useFormState()

  const [otp_secret, set_otp_secret] = useState(getRouteParam(props, "otp_secret"));
  const mobile_number = getRouteParam(props, "mobile_number");
  const user_id = getRouteParam(props, "user_id");

  return (
    <Layout title="ยืนยันหมายเลขโทรศัพท์">
      <View style={{
        display: "flex",
        flexDirection: IS_MOBILE ? "column" : "row",
        justifyContent: IS_MOBILE ? "space-around" : "flex-start",
        minHeight: "100%",
      }}>
        <View style={{flexGrow: 1, flexBasis: IS_MOBILE ? undefined : "60%", justifyContent: "center", alignItems: "center"}}>
          <Image src={require('../img/working1.png')} style={{width: "75%"}} />
        </View>
        <View style={{flexGrow: 1, flexBasis: IS_MOBILE ? undefined : "40%", flexDirection: "column", justifyContent: "center"}}>
          <View style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
            <Text style={{fontSize: 28, marginBottom: 24}}>กรุณาใส่รหัสยืนยัน</Text>
            <Text style={{fontSize: 28, color: "#b1b1b1", marginBottom: 48}}>{mobile_number}</Text>
          </View>

          <View style={{marginBottom: 48}}>
            <TextFieldComplete  model={model.$("otp_key")} label="รหัสยืนยันหมายเลขโทรศัพท์" secureTextEntry={true} />
          </View>

          <View style={{marginBottom: 24}}>
            <TouchableOpacity onPress={async ()=>{
              let otpData = model.dataState.get();

              if (!otpData.otp_key) {
                alert_error("กรุณากรอกรหัสยืนยันหมายเลขโทรศัพท์");
                return;
              }

              await globalLoadingFn(async ()=>{
                let response = await api.post("/verify_otp", {
                  ...otpData, 
                  user_id,
                  otp_secret
                })
                navigate("register_success", {token: response.data.token, user_id: response.data.user_id})
              });
            }}>
              <View style={{padding: 12, ...SHADOW_LIGHT, ...BORDER_LIGHT, backgroundColor: "#9ce2f8"}}>
                <Text style={{textAlign: "center", fontWeight: "bold"}}>ยืนยัน</Text>
              </View>
            </TouchableOpacity>
          </View>

          <TouchableOpacity onPress={async ()=> {
            let response = await resendOtp(mobile_number);
            // console.log(response.data);
            set_otp_secret(response.data.otp_secret);
          }} style={{...FLEX_ROW_CENTER_CENTER, marginBottom: 24, marginLeft: 4}}>
            <Text style={{color: LINK_COLOR, textDecorationLine: "underline"}}>ขอรหัสยืนยันใหม่</Text>
          </TouchableOpacity>
        </View>
        
      </View>
    </Layout>
  )
}
