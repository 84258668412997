import React, { useState, useContext } from "react"
import ControlAirButton from "./ControlAirButton"
import Dialog from "../../dialog/Dialog"
import { View, TextMD, TextL, Text, Image } from "../../../seperated/react-native"
import { FLEX_COLUMN_CENTER_CENTER, FLEX_COLUMN, FLEX_ROW_CENTER_CENTER } from "../../constants/style"
import { map } from "lodash"
import ControlAirModalButton from "./ControlAirModalButton"
import AirStore from "../../../stores/AirStore"
import { observer } from "mobx-react-lite"
import ControlAirStoreModeContext, { getControlFieldValue } from "./ControlAirStoreModeContext"
import DisabledContext, { InsistDisabledContext, DisabledOpacity } from "../../input/DisabledContext"
import { getAirButtonDisabled } from "./ControlAirPanel"

const KEYS = [
  // "4", //"auto"
  "1", //"cool"
  "0", //"fan"
  "2", //"dry"
  // "3" //"heat"
]

const ICONS = {
  // 4: require("../../../img/controls/mode/auto.png"),
  1: require("../../../img/controls/mode/cool.png"),
  0: require("../../../img/controls/mode/fan.png"),
  2: require("../../../img/controls/mode/dry.png"),
  // 3: require("../../../img/controls/mode/heat.png"),
}

const ICONS_ACTIVE = {
  // 4: require("../../../img/controls/mode/auto-active.png"),
  1: require("../../../img/controls/mode/cool-active.png"),
  0: require("../../../img/controls/mode/fan-active.png"),
  2: require("../../../img/controls/mode/dry-active.png"),
  // 3: require("../../../img/controls/mode/heat.png"),
}

export const TEXT2LABEL = {
  4: "Auto",
  1: "Cool",
  0: "Fan",
  2: "Dry",
  3: "Heat",
  "Loading...": "Loading...",
}

function capitalize(str) {
  return str[0].toUpperCase() + str.slice(1)
}

export let ControlAirModePanel = observer((props) => {
  const storeMode = useContext(ControlAirStoreModeContext);
  let mode = getControlFieldValue(storeMode, 'device_mode');

  const insistDisabled = useContext(InsistDisabledContext);
  const _disabled = useContext(DisabledContext);
  const disabled = insistDisabled || _disabled || getAirButtonDisabled(storeMode);

  return (
    <DisabledOpacity value={disabled}>
      <View style={{...FLEX_ROW_CENTER_CENTER, justifyContent: "space-around"}}>
        {KEYS.map(key => (
          <ControlAirModalButton icon={mode == key ? ICONS_ACTIVE[key] : ICONS[key]} key={key} field="device_mode" value={key}>
            <View style={{opacity: 0.45}}>
              <Text>{TEXT2LABEL[key]}</Text>
            </View>
          </ControlAirModalButton>
        ))}
      </View>
    </DisabledOpacity>
  )
})

export const ControlAirModeModal= observer<any>((props) => {
  const storeMode = useContext(ControlAirStoreModeContext);
  let mode = getControlFieldValue(storeMode, 'device_mode');

  return (
    <Dialog {...props} title="Mode" hasClose>
      <View style={{...FLEX_COLUMN_CENTER_CENTER}}>
        <View style={{padding: 24, marginTop: 12, ...FLEX_COLUMN, width: 600}}>
          <View style={{paddingBottom: 24, borderBottomColor: "#ededed", borderBottomWidth: 2}}>
            <TextL style={{textAlign: "center"}}>{TEXT2LABEL[mode]} Mode</TextL>
          </View>
  
          <View style={{padding: 24}}>
            <ControlAirModePanel></ControlAirModePanel>
          </View>
        </View>
      </View>
    </Dialog>
  )
})

export default observer(function ControlAirModeButton() {
  const [showModal, setShowModal] = useState(false)
  const storeMode = useContext(ControlAirStoreModeContext);
  let mode = getControlFieldValue(storeMode, 'device_mode');

  console.log(mode)

  return <>
    <ControlAirButton icon={ICONS_ACTIVE[mode]} text={TEXT2LABEL[mode]} onPress={()=>setShowModal(true)}></ControlAirButton>
    <ControlAirModeModal show={showModal} onClose={()=>setShowModal(false)}></ControlAirModeModal>
  </>
})