import React from "react"
import { View, TextMD, Text, TextL, TextXL } from "../../seperated/react-native"
import { FLEX_ROW_CENTER, FLEX_COLUMN_CENTER_CENTER, BORDER_LINE_BOTTOM_HARD, FLEX_LR_CENTER, FLEX_COLUMN, TEXT_WRAP_CENTER } from "../constants/style"
import AvatarImage from "../avatar/AvatarImage"
import { IS_MOBILE } from "../../seperated/screen-mode"
import { countDevices } from "../../utils/deviceUtils"

function DeviceCountTableItem({titleTop="Operating", title, count, subcount}) {
  return (
    <View style={{...BORDER_LINE_BOTTOM_HARD, paddingLeft: 32, paddingRight: 8, ...FLEX_LR_CENTER, paddingVertical: 12}}>
      <View>
        {titleTop && <Text>{titleTop}</Text>}
        <Text>{title}</Text>
      </View>

      <View>
        <TextXL style={{fontWeight: "normal", textAlign: "center"}}>{count}</TextXL>
        <Text style={{textAlign: "center", marginTop: -4}}>{subcount}</Text>
      </View>
    </View>
  )
}

export default function DeviceCountTable({data}) {
  // console.log(data)

  const {
    online: airOn,
    offline: airOff,
    error: airErr,
    total: airTotal,
  } = countDevices(data || {}, 1);

  const {
    online: lightOn,
    offline: lightOff,
    error: lightErr,
    total: lightTotal,
  } = countDevices(data || {}, 3);

  const {
    online: curtainOn,
    offline: curtainOff,
    error: curtainErr,
    total: curtainTotal,
  } = countDevices(data || {}, 4);

  const {
    online: doorOn,
    offline: doorOff,
    error: doorErr,
    total: doorTotal,
  } = countDevices(data || {}, 5);

  return (
    <View style={{...(IS_MOBILE ? FLEX_COLUMN : FLEX_ROW_CENTER)}}>
      <View style={{flexBasis: "50%", ...FLEX_COLUMN_CENTER_CENTER, marginBottom: IS_MOBILE ? 12 : 0}}>
        <View style={{marginBottom: 16}}>
          <div className="bruno-noinvert">
            <AvatarImage src={require("../../img/graphics/building.png")}></AvatarImage>
          </div>
        </View>

        <TextMD style={{...TEXT_WRAP_CENTER}}>{data?.name}</TextMD>
      </View>

      <View style={{flexBasis: "50%", marginTop: -12}}>
        <DeviceCountTableItem title="Air Condition" count={airOn} subcount={"Total " + airTotal}></DeviceCountTableItem>
        <DeviceCountTableItem title="Lighting" count={lightOn} subcount={"Total " + lightTotal}></DeviceCountTableItem>
        <DeviceCountTableItem title="Curtain" count={curtainOn} subcount={"Total " + curtainTotal}></DeviceCountTableItem>
        <DeviceCountTableItem title="Door" count={doorOn} subcount={"Total " + doorTotal}></DeviceCountTableItem>
      </View>
    </View>
  )
}