import React, { useState, useEffect } from "react";
import { cloneDeep } from "lodash"

import Layout from "../components/Layout";
import { View, Text, Image, TextInputSolid, TouchableOpacity } from "../seperated/react-native";
import TextFieldComplete from "../components/input/TextFieldComplete";
import { IS_MOBILE } from "../seperated/screen-mode";
import { SHADOW_LIGHT, BORDER_LIGHT } from "../components/constants/style";
import { Link, navigate, getRouteParam } from "../seperated/components/Router";
import { useFormState } from "../hooks/FormState";
import { globalLoadingFn } from "../utils/loading";
import { api, _configSetToken } from "../config";
import ScopeStore, { refreshActiveBuilding } from "../stores/ScopeStore";
import { $VAL_REQUIRED } from "../utils/validation";

const FORM_VALIDATION = {
  username: val => $VAL_REQUIRED(val, "จำเป็นต้องใส่อีเมล หรือ หมายเลขโทรศัพท์"),
  password: val => $VAL_REQUIRED(val, "จำเป็นต้องใส่รหัสผ่าน"),
}

const TEXT_FIELD_VARIANT = IS_MOBILE ? "material" : "solid"

export function resolveUsername(data) {
  data = cloneDeep(data)

  console.log(data);
  if (data.username.indexOf("@") != -1) {
    data.email = data.username;
    delete data.username;
    delete data.mobile_number;
  } else {
    data.mobile_number = data.username;
    delete data.username;
    delete data.email;
  }

  return data;
}

export async function performLogin({user_id, token, noRedirect = false, otp_key = ""}) {
  if (otp_key) {
    let response = await api.post('/login', {
      token,
      otp_key
    });

    user_id = response.data.user_id;
    token = response.data.token;
  }
  
  localStorage.setItem("access_token", token)
  localStorage.setItem("user_id", user_id)
  ScopeStore.setUser(token, user_id)

  _configSetToken(token);

  const redirect = ScopeStore.redirect;

  let [building, buildings] = await refreshActiveBuilding();

  if (!noRedirect) {
    globalLoadingFn(()=>new Promise(resolve => {}))

    if (!building) {
      if (redirect) window.location.href = redirect;
      else window.location.href = "/no_building"
      // else navigate("no_building")
    } else {
      if (redirect) window.location.href = redirect;
      else window.location.href = "/dashboard"
      // else navigate("dashboard")
    }
  }
}

export async function performLoginSocial(provider, token) {
  if (!token) return;
  let response = await globalLoadingFn(()=>api.post("/login_social", {provider, token}))
  performLogin(response.data)
}

export async function performLogout() {
  if (window.NativeLogout) {
    window.NativeLogout.postMessage("");
    return;
  }

  ScopeStore.setUserObject(undefined)
  ScopeStore.setUser("", 0)
  ScopeStore.setActiveBuilding(0)

  localStorage.removeItem("access_token")
  localStorage.removeItem("user_id")
  localStorage.removeItem("active_building")

  navigate("login")
}

export default function Login(props) {
  const model = useFormState({}, FORM_VALIDATION)

  const redirect = getRouteParam(props, "redirect");

  useEffect(()=>ScopeStore.setRedirect(redirect), [redirect]);

  return (
    <Layout>
      <View style={{
        display: "flex",
        flexDirection: IS_MOBILE ? "column" : "row",
        minHeight: "100%",
      }}>
        <View style={{flexGrow: 1, flexBasis: IS_MOBILE ? undefined : "60%", justifyContent: "center", alignItems: "center"}}>
          <Image src={require('../img/working1.png')} style={{width: "75%"}} />
        </View>
        <View style={{flexGrow: 1, flexBasis: IS_MOBILE ? undefined : "40%", flexDirection: "column", justifyContent: "center"}}>
          <View style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
            <Image src={require('../img/favicon-lowpadding.jpg')} style={{height: 48, marginRight: 8}} />
            <Image src={require('../img/airin1_caption.png')} style={{width: "40%", height: "100%"}} />
          </View>

          <View style={{marginVertical: 8}}>
            <TextFieldComplete model={model.$("username")} variant={TEXT_FIELD_VARIANT} label="อีเมล หรือ หมายเลขโทรศัพท์" placeholder="อีเมล หรือ หมายเลขโทรศัพท์" labelColor={IS_MOBILE ? undefined : "black"} />
          </View>

          <View style={{marginVertical: 8}}>
            <TextFieldComplete model={model.$("password")} variant={TEXT_FIELD_VARIANT} label="รหัสผ่าน" placeholder="รหัสผ่าน" secureTextEntry={true} labelColor={IS_MOBILE ? undefined : "black"} type="password" />
          </View>

          <View style={{marginVertical: 16}}>
            <TouchableOpacity onPress={async ()=>{
              try {
                await globalLoadingFn(async ()=>{
                  let response = await api.post("/login", resolveUsername(model.getAndValidate()))
                  await performLogin(response.data)
                });
              } catch (err) {}
            }}>
              <View style={{padding: 12, ...SHADOW_LIGHT, ...BORDER_LIGHT, backgroundColor: "#9ce2f8"}}>
                <Text style={{textAlign: "center", fontWeight: "bold"}}>เข้าสู่ระบบ</Text>
              </View>
            </TouchableOpacity>
          </View>

          <View style={{marginBottom: 16}}>
            <Link to="register">
              <View style={{padding: 12, ...SHADOW_LIGHT, ...BORDER_LIGHT}}>
                <Text style={{textAlign: "center", fontWeight: "bold"}}>สมัครสมาชิก</Text>
              </View>
            </Link>
          </View>

          <View style={{display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: 24}}>
            <Link to="register">
              <Text style={{textDecorationLine: "underline"}}>สมัครสมาชิก</Text>
            </Link>
            <Link to="reset_password">
              <Text style={{textDecorationLine: "underline"}}>ลืมรหัสผ่าน</Text>
            </Link>
          </View>
        </View>
        
      </View>
    </Layout>
  )
}
