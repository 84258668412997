import React from "react"
import { View, TouchableOpacity, Text, TextMD } from "../../seperated/react-native"
import { SHADOW_LIGHT, BORDER_LIGHT } from "../constants/style"

const VARIANT2BG = {
  "default": "white",
  "primary": "#9ce2f8",
  "secondary": "#ebebeb",
  "danger": "#ffb2b2",
}

export default function CommonButton({variant="default", padding=16, Component=Text, ...props}) {
  return (
    <TouchableOpacity {...props}>
      <View style={{padding: padding, ...SHADOW_LIGHT, ...BORDER_LIGHT, borderRadius: 8, backgroundColor: VARIANT2BG[variant]}}>
        <Component style={{textAlign: "center"}}>{props.children}</Component>
      </View>
    </TouchableOpacity>
  )
}