import React, { useState, useEffect } from "react";
import Dialog from "../../components/dialog/Dialog";
import { View, Image, TextL, TextMD, Text, TextXL, TouchableOpacity } from "../../seperated/react-native";
import CardButton from "../../components/buttons/CardButton";
import { FLEX_ROW_CENTER_CENTER, FLEX_ROW_CENTER, FLEX_COLUMN_CENTER_CENTER, TEXT_WRAP_CENTER, FLEX_COLUMN } from "../../components/constants/style";
import AirAdderStore from "../../stores/AirAdderStore";
import CommonButton from "../../components/buttons/CommonButton";
import { globalLoadingFn } from "../../utils/loading";
import { api } from "../../config";
import { formatDateDiff, toDateString, date2time } from "../../utils/date";
import { useFormState } from "../../hooks/FormState";
import CheckboxComplete from "../../components/input/CheckboxComplete";
import DatePickerComplete from "../../components/input/DatePickerComplete";
import TimePickerComplete from "../../components/input/TimePickerComplete";
import CardButtonMini from "../../components/buttons/CardButtonMini";
import { alert_success } from "../../seperated/toast";
import { IS_MOBILE } from "../../seperated/screen-mode";
import MobileLayout from "../../components/MobileLayout";
import { getRouteParam } from "../../seperated/components/Router";
var QRCode = require('qrcode.react');

const PERMISSION_LEVEL = {
  "advance": 110,
  "admin": 90,
  "controller": 70,
  "user": 50
}

function shouldShow(userLevel, requiredLevel, requestMode) {
  console.log(userLevel);

  let result = userLevel > requiredLevel;
  if (userLevel >= 100) result = true;

  return requestMode ? !result : result;
}

function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function InviteQrDialog({show, onClose, place, level, secret, expireAt, model}) {
  const [refreshKey, setRefreshKey] = useState(Math.random());

  const expireText = formatDateDiff(expireAt, new Date());

  useEffect(()=>{
    if (show && expireAt) {
      if (expireText == "00:00") onClose();
      setTimeout(()=>setRefreshKey(Date.now()), 1000)
    }
  }, [show, expireAt, refreshKey])

  useEffect(()=>{
    if (show) {
      setRefreshKey(Date.now())
    }
  }, [show])

  return (
    <Dialog show={show} title="แชร์​ / ชวนเพื่อน" hasClose onClose={onClose}>
      <View style={{padding: 36}}>
        <View style={{...(IS_MOBILE ? FLEX_COLUMN_CENTER_CENTER : FLEX_ROW_CENTER), width: "100%"}}>
          <View style={{...FLEX_COLUMN_CENTER_CENTER, padding: 24, flexBasis: "50%"}}>
            <TextMD style={{...TEXT_WRAP_CENTER}}>{place.name}</TextMD>
            <TextMD style={{...TEXT_WRAP_CENTER, marginBottom: 20}}>{capitalize(level)}</TextMD>

            {expireAt && <>
              <Text>หมดอายุ</Text>
              <TextXL>{expireText}</TextXL>
            </>}
          </View>

          <View style={{...FLEX_COLUMN_CENTER_CENTER, padding: 24, flexBasis: "50%"}}>
            <QRCode value={window.location.origin+"/roleqr?s="+secret} size={200} />
          </View>
        </View>

        {!IS_MOBILE &&
          <View style={{...(IS_MOBILE ? FLEX_COLUMN_CENTER_CENTER : FLEX_ROW_CENTER), width: "100%"}}>
            <View style={{padding: 24, flexBasis: "50%"}}>
              <CommonButton variant="secondary" onPress={onClose}>ยกเลิก</CommonButton>
            </View>

            <View style={{padding: 24, flexBasis: "50%"}}>
              <CommonButton variant="primary" onPress={onClose}>เสร็จสิ้น</CommonButton>
            </View>
          </View>
        }
      </View>
    </Dialog>
  )
}

function InviteLevelDialogMode({children, active, onPress}) {
  return <>
    <TouchableOpacity onPress={onPress}>
      <TextMD style={{color: active ? 'black' : '#9B9B9B', textDecorationLine: active ? "underline" : "none"}}>{children}</TextMD>
    </TouchableOpacity>
  </>
}

function InviteLevelInner({ onInvite, model, place, ...props }) {
  const [requestMode, setRequestMode] = useState(true);

  return (
    <View style={{padding: 24}}>
      <View style={{ marginBottom: 24, ...(IS_MOBILE ? FLEX_COLUMN_CENTER_CENTER : FLEX_ROW_CENTER), justifyContent: "space-around" }}>
        <View style={{marginBottom: IS_MOBILE ? 8 : 0}}>
          <InviteLevelDialogMode 
            active={requestMode} 
            onPress={() => {
              setRequestMode(true)
              model.dataState.$("schedule_mode").set(true)
            }}
          >
            ขอสิทธิ์
          </InviteLevelDialogMode>
        </View>

        <View>
          <InviteLevelDialogMode 
            active={!requestMode} 
            onPress={() => {
              setRequestMode(false)
              model.dataState.$("schedule_mode").set(false)
            }}
          >
            ชวนเพื่อน
          </InviteLevelDialogMode>
        </View>
      </View>

      <View style={{paddingHorizontal: IS_MOBILE ? 0 : 36, marginBottom: 24}}>
        <CheckboxComplete model={model.$("schedule_mode")} label={(requestMode ? "ขอสิทธิ์" : "อนุญาต") + "บางช่วงเวลา (อย่างเช่น การทำ OT)"} labelColor="black"></CheckboxComplete>
      </View>

      {model.$("schedule_mode").get() && 
        <View style={{...(IS_MOBILE ? FLEX_COLUMN : FLEX_ROW_CENTER), justifyContent: "space-between", paddingHorizontal: IS_MOBILE ? 0 : 36, marginBottom: 24}}>
          <View style={{...FLEX_ROW_CENTER}}>
            <Text style={{marginRight: 8}}>ตั้งแต่</Text>

            <DatePickerComplete model={model.$("schedule_from_date")} style={{width: 160, marginRight: 8}}></DatePickerComplete>
            <TimePickerComplete model={model.$("schedule_from_time")} style={{width: 110, marginRight: 8}}></TimePickerComplete>
          </View>

          <View style={{...FLEX_ROW_CENTER}}>
            <Text style={{marginRight: 8}}>จนถึง</Text>

            <DatePickerComplete model={model.$("schedule_to_date")} style={{width: 160, marginRight: 8}}></DatePickerComplete>
            <TimePickerComplete model={model.$("schedule_to_time")} style={{width: 110, marginRight: 8}}></TimePickerComplete>
          </View>
        </View>
      }

      <View style={{ ...(IS_MOBILE ? FLEX_COLUMN_CENTER_CENTER : FLEX_ROW_CENTER_CENTER) }}>
        {shouldShow(place.permission_level, 40, requestMode) &&
          <CardButton
            icon={
              <Image
                src={require("../../img/icons/person.png")}
                height="75"
              />
            }
            title="User"
            subtitle="ส่งรายการคำขอ, ดูสถานะได้"
            style={{ margin: 8 }}
            hasRightArrow
            sm
            onPress={() => onInvite("user", requestMode)}
          ></CardButton>
        }

        {shouldShow(place.permission_level, 60, requestMode) &&
          <CardButton
            icon={
              <Image
                src={require("../../img/icons/person-suit.png")}
                height="75"
              />
            }
            title="Controller User"
            subtitle="ควบคุมอุปกรณ์ได้"
            style={{ margin: 8 }}
            hasRightArrow
            sm
            onPress={() => onInvite("controller", requestMode)}
          ></CardButton>
        }

        {shouldShow(place.permission_level, 80, requestMode) &&
          <CardButton
            icon={
              <Image
                src={require("../../img/icons/person-suit-star.png")}
                height="85"
              />
            }
            title="Admin User"
            subtitle="ควบคุมอุปกรณ์, อนุมัติคำขอได้"
            style={{ margin: 8 }}
            hasRightArrow
            sm
            onPress={() => onInvite("admin", requestMode)}
          ></CardButton>
        }
      </View>

      {shouldShow(place.permission_level, 100, requestMode) &&
        <View style={{marginTop: 16}}>
          <CardButtonMini
            icon={require("../../img/icons/person-suit-star.png")}
            title="Advance User"
            subtitle={
              <Text style={{color: "rgb(177, 177, 177)"}}>แก้ไขข้อมูล เพิ่ม ลบ ได้ทุกฟังก์ชั่น</Text>
            }
            onPress={()=>onInvite("advance", requestMode)}
          ></CardButtonMini>
        </View>
      }

    </View>
  )
}

export function InviteLevelDialog({ show, onClose, onInvite, model, place, ...props }) {
  if (IS_MOBILE) {
    return (
      <MobileLayout title="ขอสิทธิ์ / ชวนเพื่อน">
        <InviteLevelInner onInvite={onInvite} model={model} place={place}></InviteLevelInner>
      </MobileLayout>
    );
  }

  return (
    <Dialog show={show} title={"ขอสิทธิ์ / ชวนเพื่อน"} hasClose onClose={onClose}>
      <InviteLevelInner onInvite={onInvite} model={model} place={place}></InviteLevelInner>
    </Dialog>
  );
}

export function InviteDialog({ show, onClose, place, ...props }) {
  const [step, setStep] = useState(0);
  const [secret, setSecret] = useState("")
  const [level, setLevel] = useState("")
  const [expireAt, setExpireAt] = useState(new Date());
  const model = useFormState({
    schedule_mode: true,
    schedule_from_date: toDateString(new Date()),
    schedule_from_time: date2time(new Date()),
    schedule_to_date: toDateString(new Date()),
    schedule_to_time: date2time(new Date()),
    timezone: "+07:00",
  });

  useEffect(()=>{
    if (!show) setStep(0);
    else setStep(1);
  }, [show])

  if (!place) return <View></View>

  return (
    <>
      <InviteLevelDialog show={step == 1} onClose={onClose} model={model} place={place} onInvite={async (level, requestMode)=>{
        let expireAt = new Date();
        expireAt.setMinutes(expireAt.getMinutes() + 20);

        const modelData = model.get();

        let response = await globalLoadingFn(()=>api.post("/roles/make_qr", {
          reference_type: place.type,
          reference_id: place.id,
          permission_level: PERMISSION_LEVEL[level],
          expire_at: expireAt,
          ...(modelData.schedule_mode ? {
            schedule_mode: modelData.schedule_mode,
            schedule_from_date: toDateString(modelData.schedule_from_date),
            schedule_from_time: modelData.schedule_from_time,
            schedule_to_date: toDateString(modelData.schedule_to_date),
            schedule_to_time: modelData.schedule_to_time,
            timezone: modelData.timezone
          } : {}),
          request_mode: requestMode
        }));

        setSecret(response.data.secret)
        setExpireAt(expireAt)
        setLevel(level)

        if (requestMode) {
          alert_success("การขอสิทธิ์สำเร็จ รอการอนุมัติ");
          setStep(0)
          onClose();
        } else {
          setStep(2)
        }
        
      }} />

      <InviteQrDialog show={step == 2} onClose={onClose} secret={secret} place={place} level={level} expireAt={expireAt} model={model} />
    </>
  )
}

export function InvitePage(props) {
  const [data, setData] = useState<any>();
  const [show, setShow] = useState(true);

  const type = getRouteParam(props, "type")
  const id = getRouteParam(props, "id");

  useEffect(()=>{
    async function f() {
      let response = await globalLoadingFn(()=>api.get("/" + type + "s/" + id));
      setData(response.data)
    }
    f();
  }, []);

  if (!data) {
    return <></>
  }

  return (
    <InviteDialog show={show} onClose={()=>{setShow(false)}} place={data}></InviteDialog>
  )
}