import React from "react"
import {
  View,
  Text as TextRaw,
  TouchableOpacity,
  TouchableHighlight,
  ScrollView,
  SafeAreaView,
  FlatList,
  TextInput as TextInputRaw,
  Button,
  TextProps,
} from "react-native";
import styled from "styled-components";
import { SHADOW_LIGHT, BORDER_LIGHT } from "../components/constants/style";
import { WebSpan } from "./web-only";
import PanResponder from 'react-panresponder-web';

const TEXT_SCALE = 1.2;

interface TextPropsExtended extends TextProps {
  variant?: string
}

function Image({resizeMode, circle, ...rest}: {resizeMode?: "contain" | "cover" | undefined, [x: string]: any}) {
  return <img {...rest} style={{
    objectFit: resizeMode,
    borderRadius: circle ? 100000 : 0,
    ...(rest.style || {})
  }} />
}

function TextInputSolid(props) {
  return <TextInputRaw {...props} style={{
    padding: 12,
    paddingLeft: 20,
    fontSize: 16,
    ...BORDER_LIGHT,
    ...SHADOW_LIGHT,
    ...(props.style || {})
  }}></TextInputRaw>
}

function TextInput(props: any) {
  if (props.variant == "solid") {
    return <TextInputSolid {...props} />
  } else if (props.variant == "text") {
    return <WebSpan className="input-nofocus input-text"><TextInputRaw {...props}/></WebSpan>
  } else {
    return <TextInputSolid {...props} />
  }
}

export function TextSM(props) {
  return <TextRaw {...props} style={{
    fontSize: 14 * TEXT_SCALE,
    whiteSpace: "nowrap",
    ...(props.style || {})
  }}/>;
}

export function Text(props) {
  return <TextRaw {...props} style={{
    fontSize: 16 * TEXT_SCALE,
    whiteSpace: "nowrap",
    ...(props.style || {})
  }}/>;
}

export function TextMD(props) {
  return <TextRaw {...props} style={{
    fontSize: 20 * TEXT_SCALE,
    whiteSpace: "nowrap",
    ...(props.style || {})
  }}/>;
}

export function TextL(props) {
  return <TextRaw {...props} style={{
    fontSize: 24 * TEXT_SCALE,
    whiteSpace: "nowrap",
    ...(props.style || {})
  }}/>;
}

export function TextXL(props) {
  return <TextRaw {...props} style={{
    fontSize: 28 * TEXT_SCALE,
    fontWeight: "bold",
    whiteSpace: "nowrap",
    ...(props.style || {})
  }}/>;
}

export function TextXXL(props) {
  return <TextRaw {...props} style={{
    fontSize: 36 * TEXT_SCALE,
    fontWeight: "bold",
    whiteSpace: "nowrap",
    ...(props.style || {})
  }}/>;
}

export {
  View,
  TouchableOpacity,
  TouchableHighlight,
  ScrollView,
  SafeAreaView,
  FlatList,
  Image,
  TextInputSolid,
  TextInput,
  Button,
  PanResponder
};
