import React, { useState, useEffect } from "react";

import Layout, { LeftPane, CenterPane } from "../components/Layout";
import { View, Text, Image, TextInputSolid, TouchableOpacity, TextMD } from "../seperated/react-native";
import TextFieldComplete from "../components/input/TextFieldComplete";
import { IS_MOBILE } from "../seperated/screen-mode";
import { SHADOW_LIGHT, BORDER_LIGHT, FLEX_ROW_CENTER, FLEX_LR_CENTER, FLEX_COLUMN } from "../components/constants/style";
import { Link, navigate, getRouteParam } from "../seperated/components/Router";
import LeftPaneControlPanel from "../components/LeftPane/LeftPaneControlPanel";
import Panel from "../components/Panel";
import PanelInnerGrid from "../components/PanelInnerGrid";
import PanelInner from "../components/PanelInner";
import LeftPaneRootPanel from "../components/LeftPane/LeftPaneRootPanel";
import { globalLoadingFn } from "../utils/loading";
import { api } from "../config";
import ScopeStore from "../stores/ScopeStore";
import { toThaiDateString, mergeDateAndTime } from "../utils/date";
import useActiveBuilding from "../hooks/ActiveBuilding";
import CommonButton from "../components/buttons/CommonButton";
import Dialog from "../components/dialog/Dialog";
import HoriTwo from "../components/HoriTwo";
import { useFormState } from "../hooks/FormState";
import SelectComplete from "../components/input/SelectComplete";
import { PERMISSION_OPTIONS } from "../components/constants/options";
import { resolveLeftPanePanel } from "../utils/placeUtils";
import DownArrowIcon from "../components/icon/DownArrowIcon";
import MobileLayout from "../components/MobileLayout";

// Begin override
function Table(props) {
  if (IS_MOBILE) {
    return <table {...props}></table>
  }

  return <table {...props}></table>
}

function THead(props) {
  if (IS_MOBILE) {
    return <thead {...props}></thead>
  }

  return <thead {...props}></thead>
}

function TBody(props) {
  if (IS_MOBILE) {
    return <tbody {...props}></tbody>
  }

  return <tbody {...props}></tbody>
}

function Tr(props) {
  return <tr {...props}></tr>
}

function MobileTd(props) {
  if (IS_MOBILE) {
    return <td {...props}></td>
  }

  return props.children
}

function Td(props) {
  if (IS_MOBILE) {
    return <View {...props}></View>
  }

  return <td {...props}></td>
}

function MobileTh(props) {
  if (IS_MOBILE) {
    return <th {...props}></th>
  }

  return props.children
}

function Th(props) {
  if (IS_MOBILE) {
    return <View {...props}></View>
  }

  return <th {...props}></th>
}
// End override

function resolveScopeType(type) {
  switch (type) {
    case 'building': return 'อาคาร';
    case 'floor': return 'ชั้น';
    case 'room': return 'ห้อง';
    case 'device': return 'อุปกรณ์';
  }
}

function isScheduleRunning(schedule) {
  let startDate = mergeDateAndTime(schedule.device_schedules[0].device_schedule_timers[0].every_date, schedule.device_schedules[0].device_schedule_timers[0].every_time)
  let endDate = schedule.device_schedules.length > 1 ? mergeDateAndTime(schedule.device_schedules[1].device_schedule_timers[0].every_date, schedule.device_schedules[1].device_schedule_timers[0].every_time) : null;

  if (schedule.status == "pending") {
    if (endDate === null) return false;
    return new Date() >= endDate;
  }

  return new Date() >= startDate;
}

function permissionLevelText(permissionLevel) {
  if (permissionLevel >= 100) return "Advance";
  if (permissionLevel >= 80) return "Admin";
  if (permissionLevel >= 60) return "Controller";
  if (permissionLevel >= 40) return "User";
  return "Guest";
}

export default function PermissionPage(props) {
  const LeftPanePanel = resolveLeftPanePanel(getRouteParam(props, "left_pane"));

  const [building, setBuilding] = useActiveBuilding();

  const [place, setPlace] = useState<any>();
  const [permissions, setPermissions] = useState<any[]>([]);
  const [schedules, setSchedules] = useState<any[]>([]);
  // const [building, setBuilding] = useActiveBuilding();
  const [showAddPermissionDialog, setShowAddPermissionDialog] = useState(false);
  const [editingEmail, setEditingEmail] = useState("");
  const [editingPermissionId, setEditingPermissionId] = useState(0);

  const addPermissionModel = useFormState();

  const type = getRouteParam(props, "type") || "building";
  const id = parseInt(getRouteParam(props, "id") || "") || ScopeStore.activeBuilding;

  async function refreshData() {
    let responsePromise = api.get("/places/roles?scope_type=" + type + "&scope_id=" + id);
    responsePromise.then(response => {
      let data = response.data;
      console.log(data)

      // make adjacent user not show name
      if (data.length > 0) {
        data[0].show_user_name = true;
      }
      for (let i = 1; i < data.length; i++) {
        if (data[i].user_id == data[i-1].user_id) {
          data[i].show_user_name = false;
        } else {
          data[i].show_user_name = true;
        }
      }

      setPermissions(data)
    })

    let responseSchedulesPromise = api.get("/schedules?scope_type=building&scope_id=" + ScopeStore.activeBuilding + "&control_entity=place_role&all_alive=1");
    responseSchedulesPromise.then(responseSchedules => {
      console.log(responseSchedules.data)
      setSchedules(responseSchedules.data)
    })

    let placePromise = api.get("/" + type + "s/" + id);
    placePromise.then(response => setPlace(response.data));

    return await globalLoadingFn(() => Promise.all([responsePromise, responseSchedulesPromise, placePromise]))
  }

  useEffect(() => {
    refreshData();
  }, [])

  let permission_level_length = 4;

  if (building) {
    if (building.permission_level < 80) {
      permission_level_length = 0;
    } else if (building.permission_level < 100) {
      permission_level_length = 3
    }
  }

  const PERMISSION_OPTIONS_FILTERED = PERMISSION_OPTIONS.slice(0, permission_level_length);

  if (!building) return <div></div>;

  const PermissionBody = <>
    {permission_level_length > 0 &&
      <View style={{marginBottom: 16}}>
        <CommonButton variant="primary" onPress={() => setShowAddPermissionDialog(true)}>+ เพิ่มสิทธิ์ผู้ใช้</CommonButton>
      </View>
    }

    <PanelInner
      header={
        <View style={{
          ...FLEX_LR_CENTER,
        }}>
          <View style={{flexGrow: 1}}>
            <TextMD>คำขอทำ OT</TextMD>
          </View>
        </View>
      }

      style={{marginBottom: 24}}
    >
      <View>
        <Table>
          <THead>
            <Tr className="th-left">
              <MobileTh>
                {!IS_MOBILE && <Th>UID</Th>}
                <Th>ผู้ใช้ / สิทธิ์ที่ขอ</Th>
                <Th>สถานที่</Th>
              </MobileTh>
              <MobileTh>
                <Th>เริ่ม</Th>
              </MobileTh>
              <MobileTh>
                <Th>สิ้นสุด</Th>
              </MobileTh>
              <MobileTh>
                <Th>&nbsp;</Th>
              </MobileTh>
            </Tr>
          </THead>

          <TBody>
            {schedules.filter(schedule => !isScheduleRunning(schedule)).map(schedule => (
              <Tr key={schedule.id}>
                {!IS_MOBILE && <Td>{schedule.device_schedules[0].control_data.user_id}</Td>}
                <MobileTd>
                  <Td>
                    <div>{schedule.device_schedules[0].control_data.user_name}</div>
                    <div>{permissionLevelText(schedule.device_schedules[0].control_data.permission_level)}</div>
                  </Td>
                  <Td>
                    {IS_MOBILE ? <div>{resolveScopeType(schedule.device_schedule_scopes[0].scope_type)} {schedule.device_schedule_scopes[0].scope.name}</div> : (
                      <div>
                        <div>{resolveScopeType(schedule.device_schedule_scopes[0].scope_type)}</div>
                        <div>{schedule.device_schedule_scopes[0].scope.name}</div>
                      </div>
                    )}
                  </Td>
                </MobileTd>
                <MobileTd>
                  <Td>
                    <div>{toThaiDateString(schedule.device_schedules[0].device_schedule_timers[0].every_date)}</div>
                    <div>{schedule.device_schedules[0].device_schedule_timers[0].every_time}</div>
                  </Td>
                </MobileTd>
                <MobileTd>
                  <Td>
                    {schedule.device_schedules.length > 1 ? <div>
                      <div>{toThaiDateString(schedule.device_schedules[1].device_schedule_timers[0].every_date)}</div>
                      <div>{schedule.device_schedules[1].device_schedule_timers[0].every_time}</div>
                    </div> : <div>ตลอดกาล</div>}
                  </Td>
                </MobileTd>
                <MobileTd>
                  <Td>
                    {schedule.status == 'pending' ? (
                      <View style={{...(IS_MOBILE ? FLEX_COLUMN : FLEX_ROW_CENTER)}}>
                        <TouchableOpacity
                          onPress={async ()=>{
                            if (window.confirm("ยืนยันการอนุมัติ?")) {
                              let patchSchedule = (await globalLoadingFn(()=>api.get("/schedules/" + schedule.id))).data
                              await globalLoadingFn(()=>api.patch("/schedules/" + schedule.id, patchSchedule))
                              refreshData();
                            }
                          }}
                        >
                          <Text style={{
                            color: "green",
                            marginRight: 16,
                          }}>อนุมัติ</Text>
                        </TouchableOpacity>
  
                        <TouchableOpacity
                          onPress={async ()=>{
                            if (window.confirm("ยืนยันการปฏิเสธ?")) {
                              await globalLoadingFn(()=>api.delete("/schedules/" + schedule.id))
                              refreshData();
                            }
                          }}
                        >
                          <Text style={{
                            color: "red"
                          }}>ปฏิเสธ</Text>
                        </TouchableOpacity>
                      </View>
                    ) : (
                      <View style={{...FLEX_ROW_CENTER}}>
                        <TouchableOpacity
                          onPress={async ()=>{
                            if (window.confirm("ยืนยันยกเลิกการอนุมัติ?")) {
                              await globalLoadingFn(()=>api.delete("/schedules/" + schedule.id))
                              refreshData();
                            }
                          }}
                        >
                          <Text style={{
                            color: "red"
                          }}>ยกเลิกการอนุมัติ</Text>
                        </TouchableOpacity>
                      </View>
                    )}
                  </Td>
                </MobileTd>
              </Tr>
            ))}
          </TBody>
        </Table>
      </View>
    </PanelInner>

    <PanelInner
      header={
        <View style={{
          ...FLEX_LR_CENTER,
        }}>
          <View style={{flexGrow: 1}}>
            <TextMD>จัดการผู้ใช้</TextMD>
          </View>
        </View>
      }
    >
      <View>
        <Table className="td-padding">
          <THead>
            <Tr className="th-left">
              <MobileTh>
                <Th>(ID) ผู้ใช้</Th>
                <Th>Email</Th>
              </MobileTh>
              <MobileTh>
                <Th>ระดับสิทธิ์</Th>
              </MobileTh>
              <MobileTh>
                <Th>ชนิดสถานที่</Th>
                <Th>ชื่อสถานที่</Th>
              </MobileTh>
              <MobileTh>
              	<Th>&nbsp;</Th>
              </MobileTh>
            </Tr>
          </THead>

          <TBody>
            {permissions.filter(permission => permission.permission_level >= 40).map(permission => (
              <Tr key={permission.id}>
                <MobileTd>
                  {IS_MOBILE || permission.show_user_name ? <Td>{IS_MOBILE ? "" : "(" + permission.user_id + ")"} {permission.user.username}</Td> : <td><br/></td>}
                  {IS_MOBILE || permission.show_user_name ? <Td>{permission.user.email}</Td> : <td><br/></td>}
                </MobileTd>
                <MobileTd>
                  <Td>
                    <TouchableOpacity style={{...FLEX_ROW_CENTER}} onPress={() => {
                      setEditingEmail(permission.user.email || permission.user.mobile_number)
                      setEditingPermissionId(permission.id)
                      setShowAddPermissionDialog(true)
                    }}>
                      <div>
                        {permissionLevelText(permission.permission_level)} 
                      </div>
                      <div style={{marginLeft: 4}}>
                        <DownArrowIcon style={{height: 8}}></DownArrowIcon>
                      </div>
                    </TouchableOpacity>
                  </Td>
                </MobileTd>
                <MobileTd>
                  <Td>{resolveScopeType(permission.reference_type)}</Td>
                  <Td>{permission.reference.name}</Td>
                </MobileTd>
                <MobileTd>
                  <Td>
                    {permission.user_id != ScopeStore.userId && 
                    building.permission_level >= 80 && 
                    (building.permission_level > permission.permission_level || building.permission_level >= 100) &&
                      <TouchableOpacity
                        onPress={async ()=>{
                          if (window.confirm("ยืนยันลบผู้ใช้?")) {
                            await globalLoadingFn(()=>api.delete("/roles/" + permission.id))
                            refreshData();
                          }
                        }}
                      >
                        <Text style={{
                          color: "red"
                        }}>ลบ</Text>
                      </TouchableOpacity>
                    }
                  </Td>
                </MobileTd>
              </Tr>
            ))}
          </TBody>
        </Table>
      </View>
    </PanelInner>

    <Dialog show={showAddPermissionDialog} title={editingEmail ? "เปลี่ยนระดับสิทธิ์" : "เพิ่มสิทธิ์ผู้ใช้"} hasClose onClose={() => window.location.reload()}>
      <View style={{ ...FLEX_COLUMN, padding: IS_MOBILE ? 16 : 28, paddingHorizontal: IS_MOBILE ? 0 : 64 }}>
        {!editingEmail &&
          <HoriTwo left={<Text>อีเมล</Text>} style={{marginBottom: 16}}>
            <TextFieldComplete variant="material" model={addPermissionModel.$("email")}></TextFieldComplete>
          </HoriTwo>
        }

        <HoriTwo left={<Text>ระดับสิทธิ์</Text>} style={{marginBottom: 16}}>
          <SelectComplete variant="material" model={addPermissionModel.$("permission_level")} options={PERMISSION_OPTIONS_FILTERED}></SelectComplete>
        </HoriTwo>

        <CommonButton variant="primary" onPress={async () => {
          let postBody = addPermissionModel.get();

          if (editingPermissionId) {
            await globalLoadingFn(()=>api.delete("/roles/" + editingPermissionId))
          }

          await globalLoadingFn(() => api.post("/roles/place", {
            email: editingEmail || postBody.email,
            reference_type: type,
            reference_id: id,
            permission_level: postBody.permission_level,
          }), "เพิ่มสิทธิ์สำเร็จ")

          window.location.reload()
        }} style={{marginTop: 16}}>
          เพิ่มสิทธิ์
        </CommonButton>
      </View>
    </Dialog>
  </>

  if (IS_MOBILE) {
    return (
      <MobileLayout title="ผู้ใช้">
        {PermissionBody}
      </MobileLayout>
    )
  }

  return (
    <Layout webGrid>
      <LeftPane>
        <LeftPanePanel active="permissions" data={place || {}}></LeftPanePanel>
      </LeftPane>

      <CenterPane>

        <Panel>
          {/* <PanelInnerGrid> */}
          {PermissionBody}
          {/* </PanelInnerGrid> */}
        </Panel>
      </CenterPane>

    </Layout>
  )
}