import React, { useState, useEffect } from "react";

import Layout, { LeftPane, CenterPane } from "../../components/Layout";
import { View, Text, Image, TextInputSolid, TouchableOpacity, TextMD, FlatList, ScrollView, TextL } from "../../seperated/react-native";
import TextFieldComplete from "../../components/input/TextFieldComplete";
import { IS_MOBILE } from "../../seperated/screen-mode";
import { SHADOW_LIGHT, BORDER_LIGHT, FLEX_ROW_CENTER, FLEX_LR_CENTER, FLEX_COLUMN, FLEX_COLUMN_CENTER_CENTER } from "../../components/constants/style";
import { Link, navigate, getRouteParam, navigateBack } from "../../seperated/components/Router";
import LeftPaneControlPanel from "../../components/LeftPane/LeftPaneControlPanel";
import Panel from "../../components/Panel";
import PanelInnerGrid from "../../components/PanelInnerGrid";
import PanelInner from "../../components/PanelInner";
import DropdownLink from "../../components/DropdownLink";
import ButtonWithIcon from "../../components/buttons/ButtonWithIcon";
import MapListItem from "../../components/list/MapListItem";
import AddAirconButton from "../../components/buttons/AddAirconButton";
import { globalLoadingFn } from "../../utils/loading";
import { api } from "../../config";
import { globalLoading } from "../../seperated/loading";
import MapList from "../../components/list/MapList";
import { WebDiv } from "../../seperated/web-only";
import { nextPlaceType } from "../../utils/placeUtils";
import { BUILD_CONTROL_PLACE_LINK } from "../../components/constants/link";
import ManagePlaceButton from "../../components/buttons/ManagePlaceButton";
import TextWithIcon from "../../components/text/TextWithIcon";
import TextWithBack from "../../components/text/TextWithBack";
import ScopeStore from "../../stores/ScopeStore";
import OnlineErrorCount from "../../components/control/OnlineErrorCount";
import EditableText from "../../components/text/EditableText";
import ControlPlaceAddDeviceDialog from "../../components/control/ControlPlaceAddDeviceDialog";
import ControlAirVertButton from "../../components/control/air/ControlAirVertButton";
import AvatarImage from "../../components/avatar/AvatarImage";
import FilePickerTouchableOpacity from "../../seperated/components/FilePickerTouchableOpacity";
import ControlPlaceEditBasicDialog from "../../components/control/ControlPlaceEditBasicDialog";


export default function ControlGroupEdit(props) {
  const [data, setData] = useState<any>();
  const [addMode, setAddMode] = useState(false);
  const [addGroupMode, setAddGroupMode] = useState(false);
  const [activeGroupId, setActiveGroupId] = useState(0);
  const [activeGroup, setActiveGroup] = useState<any>();
  const [building, setBuilding] = useState<any>();

  // const type = getRouteParam(props, "type")
  const id = getRouteParam(props, "id");
  const controlEntity = getRouteParam(props, "control_entity") || "device_control_air";
  // const editMode = getRouteParam(props, "edit") == "1";
  const editMode = true;

  const [mapEditMode, setMapEditMode] = useState(false);
  const [showPlaceEditDialog, setShowPlaceEditDialog] = useState(false);

  const DROPDOWN_LINK_OPTIONS = BUILD_CONTROL_PLACE_LINK(props);

  async function updatePosition() {
    let rows: any[] = [];
    for (let child of data.children) {
      rows.push({
        type: child.type,
        id: child.id,
        position: child.position
      })
    }

    await globalLoadingFn(()=>api.patch("/places/positions", rows));
  }

  async function refreshData(refreshGroups=false) {
    await globalLoadingFn(async ()=>{
      if (true || refreshGroups) {
        let groups = await api.get("/buildings/" + ScopeStore.activeBuilding + "/groups")
        setData(groups.data)
      }

      if (activeGroupId) {
        let group = await api.get("/groups/" + activeGroupId);
        setActiveGroup(group.data)
      }
    })

    setAddMode(false)
    setAddGroupMode(false)
  }

  useEffect(()=>{
    refreshData(true);
    
    api.get("/buildings/" + ScopeStore.activeBuilding).then(building => setBuilding(building.data))
  }, []);

  useEffect(()=>{
    if (activeGroupId) refreshData();
  }, [activeGroupId])

  console.log(data)

  // const nextType = data && nextPlaceType(data);
  
  return (
    <Layout webGrid load={data || false}>
      <LeftPane>
        <LeftPaneControlPanel active={controlEntity} data={building || {}}></LeftPaneControlPanel>
      </LeftPane>

      <CenterPane>
        <Panel>
          {editMode ? 
            <View style={{marginBottom: 24}}>
              <TextWithBack>รายการกลุ่ม {building && building.name}</TextWithBack>
            </View> 
          :
            <View style={{...FLEX_LR_CENTER, marginBottom: 24, zIndex: 100}}>
              <DropdownLink variant="text_left_md" options={DROPDOWN_LINK_OPTIONS} defaultValue={DROPDOWN_LINK_OPTIONS[1]}></DropdownLink>

              {/* <ManagePlaceButton type={type} id={id} controlEntity={controlEntity}></ManagePlaceButton> */}
            </View>
          }

          <PanelInnerGrid>

            <PanelInner
              header={
                <View style={{
                  ...FLEX_LR_CENTER,
                }}>
                  <View style={{flexGrow: 1}}>
                    <TextMD>รายการกลุ่ม</TextMD>
                  </View>
                </View>
              }

              noInnerPadding
            >
              <View style={{...FLEX_COLUMN}}>
                <View style={{flexGrow: 1}}>
                  {data?.map(group => (
                    <MapListItem
                      editable={true}
                      key={group.id}
                      selected={activeGroupId == group.id}
                      text={group.name}
                      onChange={async (name)=>{
                        await globalLoadingFn(()=>api.patch("/groups/"+group.id, {name}))
                        await refreshData(true);
                      }}
                      onSingleTap={()=>setActiveGroupId(group.id)}
                      onDelete={async () => {
                        await globalLoadingFn(()=>api.delete("/groups/"+group.id));
                        await refreshData(true);
                      }}
                      showCount={true}
                      count={group.children.length}
                    ></MapListItem>
                  ))}
    
                  {addGroupMode && 
                    <MapListItem
                      editable={true}
                      forceEditing={true}
                      text=""
                      onChange={async (name)=>{
                        await globalLoadingFn(()=>api.post("/groups", {name, avatar: "/img/graphics/communicate.png", building_id: building.id}))
                        await refreshData(true);
                      }}
                    ></MapListItem>
                  }
                </View>
  
                <AddAirconButton type={"new group"} onAdd={()=>setAddGroupMode(true)} borderFull={false}></AddAirconButton>
              </View>
            </PanelInner>

            <View style={{...FLEX_COLUMN}}>
              <PanelInner
                header={
                  <View style={{
                    ...FLEX_LR_CENTER,
                  }}>
                    <View style={{flexGrow: 1}}>
                      <TextMD>ข้อมูลกลุ่ม</TextMD>
                    </View>
                    <View>
                      <OnlineErrorCount online={activeGroup && activeGroup.online_count} total={activeGroup && activeGroup.total_count} error={activeGroup && activeGroup.error_count}></OnlineErrorCount>
                    </View>
                  </View>
                }

                style={{marginBottom: 24}}
              >
                <View style={{...FLEX_COLUMN_CENTER_CENTER, justifyContent: "space-around"}}>
                  {activeGroup ? 
                    <View>
                      <TouchableOpacity onPress={()=>setShowPlaceEditDialog(true)} style={{...FLEX_COLUMN_CENTER_CENTER}}>
                        <AvatarImage src={activeGroup?.avatar} size={150} style={{marginBottom: 8}} />

                        <TextWithIcon
                          icon={require("../../img/icons/edit.png")}
                          iconRight
                          containerStyle={{justifyContent: "center"}}
                        >
                          {activeGroup?.name}
                        </TextWithIcon>
                      </TouchableOpacity>
                    </View>
                  :
                    <View>
                      <Text>กรุณาเลือกกลุ่ม</Text>
                    </View>
                  }
                </View>
              </PanelInner>          

              <ScrollView style={{position: "relative"}}>
                <PanelInner
                  header={
                    <View style={{
                      ...FLEX_LR_CENTER,
                    }}>
                      <View style={{flexGrow: 1}}>
                        <TextMD>รายการ</TextMD>
                      </View>
                    </View>
                  }
  
                  style={{flexGrow: 1, height: "100%"}}
  
                  noInnerPadding
                >
                  <View>
                    <MapList data={activeGroup?.children} onChange={refreshData} addMode={false} type={"notused"} editMode={editMode} parentType={"group"} parentId={activeGroup?.id} controlEntity={controlEntity}></MapList>
                  </View>

                </PanelInner> 

                <WebDiv style={{position: "sticky", bottom: 0, left: 0, width: "100%"}}>
                  {!editMode ? 
                    <View style={{
                      ...FLEX_LR_CENTER, 
                      borderWidth: 3,
                      borderColor: "rgb(210, 210, 210)",
                      borderBottomLeftRadius: 4,
                      borderBottomRightRadius: 4,
                      marginTop: -2,
                      alignItems: "stretch",
                      backgroundColor: "white",
                    }}>
                      <TouchableOpacity style={{padding: 12, paddingLeft: 24}}>
                        <TextWithIcon style={{color: "#49c5f1"}} icon={require("../../img/icons/arrow-left-outline.png")} onPress={()=>navigateBack()}>ย้อนกลับ</TextWithIcon>
                      </TouchableOpacity>

                      <TouchableOpacity style={{padding: 12, paddingRight: 24}}>
                        <TextWithIcon style={{color: "#49c5f1"}} icon={require("../../img/icons/arrow-right-outline.png")} iconRight onPress={()=>{
                          navigate("control_air_map_"+ScopeStore.mapActiveType, {type: ScopeStore.mapActiveType, id: ScopeStore.mapActiveId, edit: editMode ? "1" : "0", control_entity: controlEntity})
                        }}>ถัดไป</TextWithIcon>
                      </TouchableOpacity>
                    </View> 
                  :
                    <AddAirconButton type={"item to group"} onAdd={()=>setAddMode(true)}></AddAirconButton>
                  }
                </WebDiv>
              </ScrollView>     
            </View>

          </PanelInnerGrid>
        </Panel>
      </CenterPane>

      <ControlPlaceAddDeviceDialog show={addMode} onClose={()=>setAddMode(false)} type={"all"} id={activeGroupId} selected={activeGroup?.children} onChange={async (scopes) => {
        await globalLoadingFn(()=>api.patch("/groups/"+activeGroupId+"/children", scopes.map(x => ({ reference_type: x.scope_type, reference_id: x.scope_id }))));
        refreshData()
      }}></ControlPlaceAddDeviceDialog>

      <ControlPlaceEditBasicDialog show={showPlaceEditDialog} place={activeGroup} onClose={()=>{
        refreshData();
        setShowPlaceEditDialog(false);
      }}></ControlPlaceEditBasicDialog>

    </Layout>
  )
}