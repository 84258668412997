import * as React from "react";

import { FormState, useFormStateAgain } from "../../hooks/FormState";
import classNames from "classnames";
import { DEV } from "../../config";
import { debounce } from "lodash";
import { inputOnChange } from "./core_logic/onChange";
import { TextInput } from "../../seperated/react-native";
import InputCompleteBase from "./core_logic/InputCompleteBase";


import { TextField } from "@material-ui/core";

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
  TimePicker
} from '@material-ui/pickers';
import { time2date, date2time } from "../../utils/date";
import { ControlScheduleOnTextField, ControlScheduleOffTextField } from "../control/ControlScheduleOnOffTime";

export default function TimePickerComplete({variant="material", ...props}) {
  let model = useFormStateAgain(props.model, props.defaultValue || undefined);

  if (variant == "material") {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardTimePicker
          {...props}
          ampm={false}
          variant="inline"
          label={props.label}
          value={time2date(model.dataState.get())}
          onChange={date => inputOnChange(props, model, date2time(date), date)}
          KeyboardButtonProps={{
            'aria-label': 'change time',
          }}
        />
      </MuiPickersUtilsProvider>
    );
  } else if (variant.startsWith("schedule")) {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <TimePicker
          {...props}
          ampm={false}
          variant="dialog"
          label={props.label}
          value={time2date(model.dataState.get())}
          onChange={date => inputOnChange(props, model, date2time(date), date)}
          TextFieldComponent={variant == "schedule_on" ? ControlScheduleOnTextField : ControlScheduleOffTextField}
        />
      </MuiPickersUtilsProvider>
    )
  }

  return (
    <InputCompleteBase {...props} model={model}>
      <TextInput
        value={model.dataState.get()}
        variant={variant}
        {...props}
        //className={classNames([ "form-control form-control-solid " + (model.errorState.get() ? 'is-invalid' : ''), props.className||"" ])}
        onChange={(event, ...args) =>
          inputOnChange(props, model, event.target.value, event, ...args)
        }
      />
    </InputCompleteBase>
  );
}
