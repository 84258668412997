import React, { useState, useEffect } from "react";

import Layout, { LeftPane, CenterPane } from "../../components/Layout";
import {
  View,
  Text,
  Image,
  TextInputSolid,
  TouchableOpacity,
  TextMD,
  TextSM,
} from "../../seperated/react-native";
import TextFieldComplete from "../../components/input/TextFieldComplete";
import { IS_MOBILE } from "../../seperated/screen-mode";
import {
  SHADOW_LIGHT,
  BORDER_LIGHT,
  FLEX_ROW_CENTER,
  FLEX_LR_CENTER,
  FLEX_COLUMN,
  FLEX_COLUMN_CENTER_CENTER,
  TEXT_WRAP,
} from "../../components/constants/style";
import {
  Link,
  navigate,
  navigateSameParams,
  getRouteParams,
  getRouteParam,
} from "../../seperated/components/Router";
import LeftPaneControlPanel from "../../components/LeftPane/LeftPaneControlPanel";
import Panel from "../../components/Panel";
import PanelInnerGrid from "../../components/PanelInnerGrid";
import PanelInner from "../../components/PanelInner";
import LeftPaneRootPanel from "../../components/LeftPane/LeftPaneRootPanel";
import Weather from "../../components/energy/Weather";
import SensorTable from "../../components/energy/SensorTable";
import DeviceCountChart from "../../components/energy/DeviceCountChart";
import EnergyOffset from "../../components/energy/EnergyOffset";
import DeviceStatusTable from "../../components/energy/DeviceStatusTable";
import ProgressCircle from "../../components/energy/ProgressCircle";
import EnergyConsumptionChart from "../../components/energy/EnergyConsumptionChart";
import CompareTotalEnergyChart from "../../components/energy/CompareTotalEnergyChart";
import EnergyConsumptionSummary from "../../components/energy/EnergyConsumptionSummary";
import WeatherAndSensorTable from "../../components/energy/WeatherAndSensorTable";
import EnergyUsageSummary from "../../components/energy/EnergyUsageSummary";
import DeviceCountTable from "../../components/energy/DeviceCountTable";
import CompareTotalEnergy from "../../components/energy/CompareTotalEnergy";
import { api } from "../../config";
import { globalLoadingFn } from "../../utils/loading";
import { toDateString, date2time } from "../../utils/date";
import MobileLayout from "../../components/MobileLayout";

function navigateToEnergyChart(props, chart_type) {
  navigate("energy_chart", {
    ...getRouteParams(props),
    ...{
      chart_type,
      interval: "d",
    },
  });
}

function EnergyLink({
  title,
  icon = "",
  to = "energy_chart",
  chart_type = "",
  ...props
}) {
  return (
    <TouchableOpacity
      style={{ flexBasis: IS_MOBILE ? "50%" : "25%", paddingHorizontal: 8, paddingVertical: 8 }}
      onPress={() => {
        if (to == "energy_chart") {
          navigateToEnergyChart(props, chart_type);
        } else {
          navigate(to, {
            ...getRouteParams(props),
          });
        }
      }}
    >
      <PanelInner style={{ height: 140 }}>
        <TextMD style={{ ...TEXT_WRAP }}>{title}</TextMD>
      </PanelInner>
    </TouchableOpacity>
  );
}

export default function EnergyPage(props) {
  const scopeType = getRouteParam(props, "type") || "building";
  const scopeId = parseInt(getRouteParam(props, "id") || "0");
  const isBruno = parseInt(getRouteParam(props, "bruno") || "0");

  const [energyData, setEnergyData] = useState<any>();
  const [building, setBuilding] = useState<any>();

  useEffect(() => {
    async function f() {
      const { data } = await api.get(
          "/sensors/energy_page_detail?scope_type=" +
            scopeType +
            "&scope_id=" +
            scopeId
        )
      setEnergyData(data);

      console.log(data);

      const { data: buildingData } = await api.get(
          "/buildings/" + (scopeType == 'building' ? scopeId : data.scope.building_id) + "?deep=1"
        )
      setBuilding(buildingData);
    }
    globalLoadingFn(f);
  }, []);

  const EnergyBody = (
    <>
      {/* Building search devices */}
      {/* <View style={{ marginBottom: 16 }}>
            <PanelInner></PanelInner>
          </View> */}

      {/* Building & Hardware list */}
      <View style={{ marginBottom: 16 }}>
        <PanelInner
          header={
            <View
              style={{
                ...FLEX_LR_CENTER,
              }}
            >
              <View style={{ flexGrow: 1 }}>
                <TextMD>Hardware list</TextMD>
              </View>
            </View>
          }
        >
          <DeviceCountTable data={building}></DeviceCountTable>
        </PanelInner>
      </View>

      <View
        style={{
          ...FLEX_ROW_CENTER,
          flexWrap: "wrap",
          alignItems: "stretch",
        }}
      >
        {/* Today Weather */}
        <View style={{ flexBasis: IS_MOBILE ? "100%" : "50%", paddingRight: IS_MOBILE ? 0 : 8, marginBottom: 16 }}>
          <PanelInner
            header={
              <View
                style={{
                  ...FLEX_LR_CENTER,
                }}
              >
                <View style={{ flexGrow: 1 }}>
                  <TextMD>Today Weather</TextMD>
                </View>
              </View>
            }
            h100
          >
            <WeatherAndSensorTable data={energyData}></WeatherAndSensorTable>
          </PanelInner>
        </View>

        {/* Device count */}
        <View style={{ flexBasis: IS_MOBILE ? "100%" : "50%", paddingLeft: IS_MOBILE ? 0 : 8, marginBottom: 16 }}>
          <PanelInner
            header={
              <View
                style={{
                  ...FLEX_LR_CENTER,
                }}
              >
                <View style={{ flexGrow: 1 }}>
                  <TextMD>Device count</TextMD>
                </View>
              </View>
            }
            h100
          >
            <DeviceCountChart data={building}></DeviceCountChart>
          </PanelInner>
        </View>

        {/* Energy offset - Daily */}
        <View style={{ flexBasis: IS_MOBILE ? "100%" : "50%", paddingRight: IS_MOBILE ? 0 : 8, marginBottom: 16 }}>
          <PanelInner
            header={
              <View
                style={{
                  ...FLEX_LR_CENTER,
                }}
              >
                <View style={{ flexGrow: 1 }}>
                  <TextMD>Energy offset - Daily</TextMD>
                </View>
              </View>
            }
            h100
          >
            <EnergyOffset data={energyData}></EnergyOffset>
          </PanelInner>
        </View>

        {/* Building services status */}
        <View style={{ flexBasis: IS_MOBILE ? "100%" : "50%", paddingLeft: IS_MOBILE ? 0 : 8, marginBottom: 16 }}>
          <PanelInner
            header={
              <View
                style={{
                  ...FLEX_LR_CENTER,
                }}
              >
                <View style={{ flexGrow: 1 }}>
                  <TextMD>Building services status</TextMD>
                </View>
              </View>
            }
            h100
          >
            <DeviceStatusTable data={building}></DeviceStatusTable>
          </PanelInner>
        </View>
      </View>

      {/* Energy Usage */}
      <View style={{ marginBottom: 16 }}>
        <EnergyUsageSummary
          data={energyData}
          moreClick={() => navigateToEnergyChart(props, "energy_consumption")}
        ></EnergyUsageSummary>
      </View>

      {/* Energy Consumption */}
      <View style={{ marginBottom: 16 }}>
        <EnergyConsumptionSummary
          averageKW={energyData?.daily_energy.whtotal || 0}
          scopeType={scopeType}
          scopeId={scopeId}
          moreClick={() => navigateToEnergyChart(props, "energy_consumption")}
        ></EnergyConsumptionSummary>
      </View>

      {/* Compare Total Energy */}
      <View style={{ marginBottom: 16 }}>
        <CompareTotalEnergy
          scopeType={scopeType}
          scopeId={scopeId}
        ></CompareTotalEnergy>
      </View>

      {/* Energy links */}
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          margin: -8,
        }}
      >
        <EnergyLink
          {...props}
          chart_type="temperature_humidity"
          title="Temperature & Humidity"
        ></EnergyLink>

        <EnergyLink
          {...props}
          chart_type="device_operation"
          title="Device Operation"
        ></EnergyLink>

        <EnergyLink
          {...props}
          chart_type="energy_consumption"
          title="Energy Consumption"
        ></EnergyLink>

        <EnergyLink
          {...props}
          chart_type="performance_value"
          title="Performance Value"
        ></EnergyLink>

        <EnergyLink
          {...props}
          chart_type="pv_power"
          title="PV Power"
        ></EnergyLink>

        <EnergyLink
          {...props}
          chart_type="solarcell_power"
          title="Solarcell Power"
        ></EnergyLink>

        <EnergyLink
          {...props}
          chart_type="energy_saving"
          title="Energy Saving"
        ></EnergyLink>

        <EnergyLink
          {...props}
          chart_type=""
          title="Energy Management"
          to="energy_management"
        ></EnergyLink>
      </View>
    </>
  );

  if (isBruno) {
    return (
      <div className="bruno">
        {EnergyBody}
      </div>
    )
  }

  if (IS_MOBILE) {
    return (
      <MobileLayout title="พลังงาน" load={energyData || false}>
        {EnergyBody}
      </MobileLayout>
    )
  }

  return (
    <Layout webGrid load={energyData || false}>
      <LeftPane>
        <LeftPaneRootPanel active="energy"></LeftPaneRootPanel>
      </LeftPane>

      <CenterPane>
        <Panel>{EnergyBody}</Panel>
      </CenterPane>
    </Layout>
  );
}
