import React, { useEffect, useState } from "react";
import Panel from "../Panel";
import LeftPaneNavItem from "./LeftPaneNavItem";
import { View, Image, TextL } from "../../seperated/react-native";
import UserAvatar from "../avatar/UserAvatar";
import ScopeStore from "../../stores/ScopeStore";
import { navigate, getRouteParam } from "../../seperated/components/Router";
import { TEXT_WRAP, TEXT_WRAP_CENTER } from "../constants/style";
import AvatarImage from "../avatar/AvatarImage";
import { api } from "../../config";
import { globalLoadingFn } from "../../utils/loading";
import { useScheduleCount } from "../../hooks/ScheduleCount";
import PathBreadcrumb from "../PathBreadcrumb";

export default function LeftPaneControlAirPanel(props) {
  const type = props.data?.type
  const id = props.data?.id

  const {scheduleActiveCount, schedulePendingCount} = useScheduleCount(type, id);

  console.log(props.data)

  return (
    <Panel lowPadding>
      <View
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginBottom: 28,
        }}
      >
        <TextL style={{marginBottom: props.data?.path?.length > 0 ? 4 : 16, ...TEXT_WRAP_CENTER}}>{props.data?.name}</TextL>
        <PathBreadcrumb path={props.data?.path}></PathBreadcrumb>
        <AvatarImage src={props.data?.avatar} />
      </View>

      <LeftPaneNavItem
        icon={require("../../img/icons/power.png")}
        label="แผงควบคุม"
        active={props.active == "control_air_manual"}
        onPress={()=>navigate("control_air_manual", {type, id})}
      ></LeftPaneNavItem>

      <LeftPaneNavItem
        icon={require("../../img/icons/calendar.png")}
        label={"ตารางการทำงาน"}
        number={scheduleActiveCount}
        active={props.active === "schedule"}
        onPress={()=>navigate("schedule_list", {type, id, entity: "device_control_air"})}
      ></LeftPaneNavItem>

      <LeftPaneNavItem
        icon={require("../../img/icons/envelope.png")}
        label="รายการคำขอ"
        number={schedulePendingCount}
        active={props.active === "schedule_request"}
        onPress={()=>navigate('schedule_list_request', {type, id, entity: "device_control_air", pending: 1})}
      ></LeftPaneNavItem>

      {/* <LeftPaneNavItem
        icon={require("../../img/icons/energy.png")}
        label="การใช้พลังงาน"
        active={props.active === "energy"}
        onPress={()=>navigate("energy", {type, id})}
      ></LeftPaneNavItem>  */}

      <LeftPaneNavItem
        icon={require("../../img/icons/option.png")}
        label="ตั้งค่าอุปกรณ์"
        active={props.active.startsWith("control_air_settings")}
        onPress={()=>navigate("control_air_settings", {type, id})}
      ></LeftPaneNavItem>

      <LeftPaneNavItem
        icon={require("../../img/icons/option.png")}
        label="ผู้ใช้"
        active={props.active === "permissions"}
        onPress={()=>navigate('permissions', {type, id, left_pane: 'LeftPaneControlAirPanel'})}
      ></LeftPaneNavItem>

      <LeftPaneNavItem
        icon={require("../../img/icons/history.png")}
        label="ประวัติการใช้งาน"
        active={props.active.startsWith("device_history")}
        onPress={()=>navigate("device_history", {entity: "device_control_air", type, id})}
      ></LeftPaneNavItem>

      <LeftPaneNavItem
        icon={require("../../img/icons/history.png")}
        label="รายงาน ERROR"
        active={props.active.startsWith("error_log")}
        onPress={()=>navigate("error_log", {entity: "device_control_air", type, id})}
      ></LeftPaneNavItem>

      <View style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
        <View style={{flexGrow: 1}}></View>

        <LeftPaneNavItem
          icon={require("../../img/icons/home.png")}
          label="หน้าแรก"
          active={props.active.startsWith("control_air_list")}
          onPress={()=>navigate("control_air_list", {type: "building", id: ScopeStore.activeBuilding})}
        ></LeftPaneNavItem>      
      </View>

    </Panel>
  );
}
