import React from "react"
import { View, TextSM } from "../../seperated/react-native"
import EmotionIcon from "../icon/EmotionIcon"
import { FLEX_LR_CENTER, FLEX_ROW_CENTER } from "../constants/style"
import { toFixed2 } from "../../utils/number"

function EnergyOffsetItem({emotionLevel, label, value, TextComponent=TextSM}) {
  const ICON_STYLE = {marginRight: 12}

  return (
    <View style={{...FLEX_LR_CENTER, marginVertical: 6}}>
      <View style={{...FLEX_ROW_CENTER}}>
        <EmotionIcon style={{...ICON_STYLE}} level={emotionLevel}></EmotionIcon> <TextComponent>{label}</TextComponent>
      </View>
      <View>
        <TextComponent>{value}</TextComponent>
      </View>
    </View>
  )
}

export default function EnergyOffset({TextComponent=TextSM, data, ...props}) {
  const electricity_formula = window.eval((data?.electricity_formula.code || "(x) => x"))

  return (
    <View>
      <EnergyOffsetItem 
        TextComponent={TextComponent}
        emotionLevel={300}
        label="CO2"
        value={(toFixed2(data?.last_data.CO2) || "---") + " kg"}
      ></EnergyOffsetItem>
      
      <EnergyOffsetItem 
        TextComponent={TextComponent}
        emotionLevel={300}
        label="Power Electricity"
        value={(toFixed2(data?.daily_energy.whtotal ? data?.daily_energy.whtotal * 24 : null) || "---") + " kWh"}
      ></EnergyOffsetItem>

      <EnergyOffsetItem 
        TextComponent={TextComponent}
        emotionLevel={200}
        label="Budget Saving"
        value={toFixed2(electricity_formula((data?.monthly_energy_previous.whtotal * 24 || 0) - (data?.monthly_energy.whtotal * 24 || 0))) + " THB"}
      ></EnergyOffsetItem>
    </View>
  )
}