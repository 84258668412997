import React from "react"
import DropdownMenuIcon from "../DropdownMenuIcon"
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { MenuItem, ListItemText } from "@material-ui/core";
import { navigate, getRouteParam } from "../../seperated/components/Router";

export default function ControlAirHeaderMenu(props) {
  const type = getRouteParam(props, "type")
  const id = getRouteParam(props, "id")

  return (
    <DropdownMenuIcon iconChildren={<MoreVertIcon></MoreVertIcon>}>
      <MenuItem onClick={()=>navigate("control_air_manage", {type, id})}>
        <ListItemText>จัดการข้อมูล</ListItemText>
      </MenuItem>

      <MenuItem onClick={()=>navigate("control_air_add_building_type")}>
        <ListItemText>เพิ่มอุปกรณ์</ListItemText>
      </MenuItem>
    </DropdownMenuIcon>
  )
}