import React from "react"
import { View, Text, Image } from "../../seperated/react-native"
import { FLEX_ROW_CENTER } from "../constants/style"
import IconInline from "../icon/IconInline"
import CircleDot from "../CircleDot"

export default function TextWithCircleDot({Component = Text, circleColor, circleProps = {}, children, circleRight = false, ...props}: {Component?: any, circleColor?: any, circleProps?: any, children?: any, circleRight?: boolean, [x: string]: any}) {
    return (
        <View style={{
            ...FLEX_ROW_CENTER,
            flexDirection: circleRight ? "row-reverse" : "row"
        }}>
            <CircleDot {...circleProps} color={circleColor} style={{
                ...(circleRight ? {marginLeft: 8} : {marginRight: 8}),
                opacity: circleColor ? 1 : 0,
                ...(circleProps.style || {})
            }}></CircleDot>
            <Component {...props}>{children}</Component>
        </View>
    )
}