import React from "react";
import Panel from "../Panel";
import LeftPaneNavItem from "./LeftPaneNavItem";
import { View, Image, TextL } from "../../seperated/react-native";
import UserAvatar from "../avatar/UserAvatar";
import { navigate } from "../../seperated/components/Router";
import ScopeStore from "../../stores/ScopeStore";
import { TEXT_WRAP, TEXT_WRAP_CENTER } from "../constants/style";
import AvatarImage from "../avatar/AvatarImage";
import { observer } from "mobx-react-lite";
import useActiveBuilding from "../../hooks/ActiveBuilding";

export default observer<any>(function LeftPaneRootPanel(props) {
  const [building, setBuilding] = useActiveBuilding();

  return (
    <Panel lowPadding>
      <View
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginBottom: 28,
        }}
      >
        <AvatarImage src={ScopeStore.user?.profile.avatar} style={{marginBottom: 12}}></AvatarImage>
        <TextL style={{...TEXT_WRAP_CENTER}}>{ScopeStore.user?.profile.name}</TextL>
      </View>

      <LeftPaneNavItem
        icon={require("../../img/icons/home.png")}
        label="หน้าแรก"
        active={props.active === "dashboard"}
        to="dashboard"
      ></LeftPaneNavItem>

      <LeftPaneNavItem
        icon={require("../../img/icons/power.png")}
        label="ควบคุมอุปกรณ์"
        onPress={()=>navigate("control_air_list", {type: "building", id: ScopeStore.activeBuilding})}
      ></LeftPaneNavItem>

      <LeftPaneNavItem
        icon={require("../../img/icons/energy.png")}
        label="พลังงาน"
        active={props.active === "energy"}
        onPress={()=>navigate("energy", {type: "building", id: ScopeStore.activeBuilding})}
      ></LeftPaneNavItem>

      {building?.permission_level >= 80 &&
        <LeftPaneNavItem
          icon={require("../../img/icons/history.png")}
          label="Monitoring"
          active={false}
          onPress={async ()=>{
            window.location.href = "https://airin1.com/monitoring/buildings/" + encodeURIComponent(building.name.replace(/ /g, "-"))
          }}
        ></LeftPaneNavItem>
      }

      {/* <LeftPaneNavItem
        icon={require("../../img/icons/home.png")}
        label="รายการ"
        to="list"
      ></LeftPaneNavItem> */}

      <LeftPaneNavItem
        icon={require("../../img/icons/option.png")}
        label="ตั้งค่า"
        active={props.active === "settings"}
        to="settings"
      ></LeftPaneNavItem>

      <LeftPaneNavItem
        icon={require("../../img/icons/option.png")}
        label="ผู้ใช้"
        active={props.active === "permissions"}
        to="permissions"
      ></LeftPaneNavItem>
    </Panel>
  );
})
