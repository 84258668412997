import { useState, useEffect } from "react";
import { globalLoadingFn } from "../utils/loading";
import { api } from "../config";

export function useScheduleCount(scope_type, scope_id) {
  const [schedules, setSchedules] = useState<any[]>([]);

  useEffect(()=>{
    if (scope_type && scope_id) {
      let schedulesPromise = api.get("/schedules?scope_type=" + scope_type + "&scope_id=" + scope_id + "&all_alive=1");
      schedulesPromise.then(response => setSchedules(response.data))
  
      // Do it quietly
      // globalLoadingFn(()=>Promise.all([schedulesPromise]));
    }
  }, [scope_type, scope_id]);

  console.log(schedules);

  return {
    scheduleActiveCount: schedules.filter(schedule => schedule.control_entity != "place_role" && schedule.status == "active").length,
    schedulePendingCount: schedules.filter(schedule => schedule.control_entity != "place_role" && schedule.status == "pending").length,
  }
}