import React from "react";
import { Image, TouchableOpacity, View } from "../../seperated/react-native";

const SIZE_SCALE = 1.2;

export default function IconInline({ size = 16, ...props }) {
  const Wrapper = (props.onPress && TouchableOpacity) || View;

  return (
    <Wrapper onPress={props.onPress}>
      <Image
        {...props}
        style={{
          ...(!props.src ? { width: size * SIZE_SCALE } : {}),
          height: size * SIZE_SCALE,
          ...(props.style || {}),
        }}
      ></Image>
    </Wrapper>
  );
}
