import React, { useEffect } from "react";
import Dialog from "../../../components/dialog/Dialog";
import CardButton from "../../../components/buttons/CardButton";
import { View, Image } from "../../../seperated/react-native";
import { FLEX_ROW_CENTER_CENTER } from "../../../components/constants/style";
import ControlAirAddBuildingType from "./Add/ControlAirAddBuildingType";
import ControlAirAddBuilding from "./Add/ControlAirAddBuilding";
import ControlAirAddInsurance from "./Add/ControlAirAddInsurance";

import { observer } from "mobx-react-lite";
import AirAdderStore from "../../../stores/AirAdderStore";
import ControlAirAddSplitType1 from "./Add/ControlAirAddSplitType1";
import ControlAirAddAdvanceInfo from "./Add/ControlAirAddAdvanceInfo";
import ControlAirAddAnalyzeAC from "./Add/ControlAirAnalyzeAC";
import { ControlAirAddSuccessDialog } from "../../Success/ControlAirAddSuccessPage";
import { applySnapshotAuto, createFromModel } from "../../../utils/mobx";
import { getType } from "mobx-state-tree";
import ControlAirAddSensor from "./Add/ControlAirAddSensor";
import ControlAirAddLight from "./Add/ControlAirAddLight";

export default observer(function ControlAirAdd(props) {
  const activePage = AirAdderStore.activePage;

  useEffect(() => {
    if (!activePage) {
      // reset all adder store
      // applySnapshotAuto(DeviceAdderStore, createFromModel(DeviceAdderStore));
      applySnapshotAuto(AirAdderStore, createFromModel(getType(AirAdderStore)));
    }
  }, [activePage])

  return (
    <>
      <ControlAirAddBuildingType show={activePage == "building_type"}></ControlAirAddBuildingType>
      <ControlAirAddBuilding show={activePage == "building"}></ControlAirAddBuilding>
      
      <ControlAirAddSplitType1 show={activePage == "split_type_1"}></ControlAirAddSplitType1>
      <ControlAirAddSensor show={activePage == "sensor"}></ControlAirAddSensor>
      <ControlAirAddLight show={activePage == "light"}></ControlAirAddLight>

      <ControlAirAddAnalyzeAC show={activePage == "analyze_ac"}></ControlAirAddAnalyzeAC>
      <ControlAirAddAdvanceInfo show={activePage == "advance_info"}></ControlAirAddAdvanceInfo>

      <ControlAirAddInsurance show={activePage == "insurance"}></ControlAirAddInsurance>

      {activePage.startsWith("success_air") && <ControlAirAddSuccessDialog></ControlAirAddSuccessDialog>}
    </>
  );
})
