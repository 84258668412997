import React from "react"
import { View, Text, Image } from "../../seperated/react-native"
import { FLEX_ROW_CENTER } from "../constants/style"
import IconInline from "../icon/IconInline"

export default function TextWithIcon({Component = Text, icon, iconProps = {}, children, iconRight = false, containerStyle = {}, ...props}: {Component?: any, icon?: any, iconProps?: any, children?: any, iconRight?: boolean, [x: string]: any}) {
    return (
        <View style={{
            ...FLEX_ROW_CENTER,
            flexDirection: iconRight ? "row-reverse" : "row",
            ...containerStyle
        }}>
            <IconInline {...iconProps} src={icon} style={{
                ...(iconRight ? {marginLeft: 8} : {marginRight: 8}),
                opacity: icon ? 1 : 0,
                ...(iconProps.style || {})
            }}></IconInline>
            <Component {...props}>{children}</Component>
        </View>
    )
}