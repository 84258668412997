import React from "react"
import DropdownMenuIcon from "../DropdownMenuIcon"
import AddIcon from '@material-ui/icons/Add';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MenuItem from "@material-ui/core/MenuItem";
import { ListItemText } from "@material-ui/core";
import { navigate, getRouteParam } from "../../seperated/components/Router";

export default function ControlAddScheduleMenu(props) {
  const type = getRouteParam(props, "type")
  const id = parseInt(getRouteParam(props, "id") || "0");
  const entity = getRouteParam(props, "entity");
  const pending = getRouteParam(props, "pending");
  
  return (
    <DropdownMenuIcon iconChildren={<AddIcon></AddIcon>}>
      <MenuItem onClick={()=>navigate('schedule_detail', {type, id, ...(entity ? {entity} : {}), control_entity: "device_control_air"})}>
        <ListItemText>สำหรับแอร์</ListItemText>
      </MenuItem>
    </DropdownMenuIcon>
  )
}