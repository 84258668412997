import React, { useState, useEffect } from "react";

import Layout, { LeftPane, CenterPane } from "../../components/Layout";
import { View, Text, Image, TextInputSolid, TouchableOpacity, TextMD, FlatList } from "../../seperated/react-native";
import TextFieldComplete from "../../components/input/TextFieldComplete";
import { IS_MOBILE } from "../../seperated/screen-mode";
import { SHADOW_LIGHT, BORDER_LIGHT, FLEX_ROW_CENTER, FLEX_LR_CENTER, BORDER_LINE_BOTTOM, TEXT_WRAP } from "../../components/constants/style";
import { Link, navigate, getRouteParam } from "../../seperated/components/Router";
import LeftPaneControlPanel from "../../components/LeftPane/LeftPaneControlPanel";
import Panel from "../../components/Panel";
import PanelInnerGrid from "../../components/PanelInnerGrid";
import PanelInner from "../../components/PanelInner";
import { ControlLeftPane, changeAgentTypeDescription, controlHistoryDescription, controlChangeDescription } from "../../utils/deviceUtils";
import { globalLoadingFn } from "../../utils/loading";
import { api } from "../../config";
import LeftPaneControlAirPanel from "../../components/LeftPane/LeftPaneControlAirPanel";
import { toDateString, date2time } from "../../utils/date";
import CircleDot from "../../components/CircleDot";
import CommonButton from "../../components/buttons/CommonButton";
import DatePickerComplete from "../../components/input/DatePickerComplete";
import { useFormState } from "../../hooks/FormState";
import { placeText } from "../../utils/placeUtils";

function groupHistory(histories) {
  let res = {};
  let keys: string[] = [];

  for(let history of histories) {
    let key = toDateString(history.created_at)
    if (!(key in res)) {
      res[key] = [];
      keys.push(key)
    }
    res[key].push(history);
    
  }

  keys.sort().reverse();

  let resArray: {date: string, value: any[]}[] = [];

  for(let key of keys) {
    resArray.push({
      date: key,
      value: res[key]
    })
  }

  return resArray;
}

function HistoryItem({history}) {
  // console.log(history)

  if (history.change_field == 'device_status') {
    if (history.change_value == 0) {
      history.status = 'off';
    } else {
      history.status = 'on';
    }
  }

  history.data[history.change_field] = history.change_value;

  return (
    <View style={{
      paddingVertical: 8,
      paddingLeft: 24,
      ...BORDER_LINE_BOTTOM,
      ...FLEX_ROW_CENTER
    }}>
      <View style={{...FLEX_ROW_CENTER, flexBasis: 70}}>
        <CircleDot 
          color={history.status == "on" ? "#49c5f1" : "#b1b1b1"}
          style={{marginRight: 12}}
        ></CircleDot>
        <Text>{history.status == "on" ? "เปิด" : "ปิด"}</Text>
      </View>

      <div style={{...FLEX_ROW_CENTER, flexBasis: 180, overflow: 'hidden', textOverflow: 'ellipsis', marginRight: 8}}>
        <Text>({placeText(history.change_agent_scope, history.change_agent_scope_type)})</Text>
      </div>

      <div style={{...FLEX_ROW_CENTER, flexBasis: 180, overflow: 'hidden', textOverflow: 'ellipsis', marginRight: 8}}>
        <Text>({history.change_agent_type == 'manual' ? history.updated_by.username : changeAgentTypeDescription(history.change_agent_type)})</Text>
      </div>

      <div style={{...FLEX_ROW_CENTER, flexBasis: 180, overflow: 'hidden', textOverflow: 'ellipsis', marginRight: 8}}>
        <Text style={{...TEXT_WRAP}}>
          {controlHistoryDescription(history)}
        </Text>
      </div>

      <div style={{...FLEX_ROW_CENTER, flexGrow: 1, overflow: 'hidden', textOverflow: 'ellipsis', marginRight: 8}}>
        <Text style={{...TEXT_WRAP}}>
          {controlChangeDescription(history.change_entity, history.change_field, history.change_value)}
        </Text>
      </div>
    </View>
  )
}

export default function DeviceHistoryPage(props) {
  const entity = getRouteParam(props, "entity") || "device_control_air"
  const type = getRouteParam(props, "type") || "device"
  const id = parseInt(getRouteParam(props, "id") || "") || 0
  const showRetry = getRouteParam(props, "type") == '1'

  const [place, setPlace] = useState();
  const [histories, setHistories] = useState<{date: string, value: any[]}[]>([]);
  const [lastId, setLastId] = useState(0);
  const [showLoadMore, setShowLoadMore] = useState(true)
  // const [showEndDatePicker, setShowEndDatePicker] = useState(false)

  const endDateModel = useFormState({endDate: new Date()})

  useEffect(()=>{
    globalLoadingFn(()=>refreshHistories(true));
  }, [endDateModel])

  async function refreshHistories(hardRefresh=false) {
    let end_date = new Date(endDateModel.get().endDate)
    end_date.setHours(0)
    end_date.setMinutes(0)
    end_date.setSeconds(0)
    end_date.setDate(end_date.getDate() + 1);

    let historiesRaw = (await api.get("/devices/history", {
      params: {
        scope_type: type,
        scope_id: id,
        change_entity: entity,
        last_id: hardRefresh ? 0 : lastId,
        end_date: end_date
      }
    })).data;

    // historiesRaw = historiesRaw.filter(c => c.change_agent_type != 'retry' || showRetry);

    if (historiesRaw.length > 0) {
      setLastId(historiesRaw[historiesRaw.length - 1].id);
    } else {
      setShowLoadMore(false);
    }

    let histories_new = groupHistory(historiesRaw);

    if (!hardRefresh) {
      if (histories_new.length > 0 && histories.length > 0) {
        if (histories_new[0].date == histories[histories.length - 1].date) {
          histories[histories.length - 1].value = [...histories[histories.length - 1].value, ...histories_new[0].value]
          histories_new.shift();
        }
      }

      histories_new = [...histories, ...histories_new]
    }

    setHistories(histories_new)

    console.log(histories_new);

    return histories_new;
  }

  useEffect(()=>{
    globalLoadingFn(async () => {
      setPlace((await api.get("/" + type + "s/" + id)).data)
      await refreshHistories();
    });
  }, [])
  
  return (
    <Layout webGrid>
      <LeftPane>
        <ControlLeftPane entity={entity} active="device_history" data={place}></ControlLeftPane>
      </LeftPane>

      <CenterPane>
        <Panel>
          <PanelInnerGrid>

            <PanelInner
              header={
                <View style={{
                  ...FLEX_LR_CENTER,
                }}>
                  <View style={{flexGrow: 1}}>
                    <TextMD>ประวัติการใช้งาน</TextMD>
                  </View>

                  <View>
                    <DatePickerComplete variant="button" model={endDateModel.$("endDate")}></DatePickerComplete>
                  </View>
                </View>
              }
              col2
            >
              {histories?.map(historiesOneDay =>
                <View style={{marginBottom: 28}} key={historiesOneDay.date}>
                  <TextMD>{historiesOneDay.date}</TextMD>

                  <View>
                    {historiesOneDay.value.map(history => 
                      <HistoryItem history={history} key={history.id} />
                    )}
                  </View>
                </View>
              )}

              {showLoadMore &&
                <CommonButton variant="primary" onPress={async ()=>{
                  let scrollY = window.scrollY;
                  await globalLoadingFn(()=>refreshHistories())
                  window.scrollTo(window.scrollX, scrollY);
                }}>โหลดประวัติเพิ่มเติม...</CommonButton>
              }
            </PanelInner>

          </PanelInnerGrid>
        </Panel>
      </CenterPane>

    </Layout>
  )
}