import LeftPaneControlPanel from "../components/LeftPane/LeftPaneControlPanel"
import LeftPaneControlAirPanel from "../components/LeftPane/LeftPaneControlAirPanel"
import LeftPaneRootPanel from "../components/LeftPane/LeftPaneRootPanel"

export function nextPlaceType(data) {
  switch (data?.type) {
    case "building": return (data.is_two_level ? "room" : "floor")
    case "floor": return "room"
    case "room": return "device"
    default: return "building"
  }
}

export function permissionText(permissionLevel) {
  if (permissionLevel >= 100) return "Advance User"
  if (permissionLevel >= 80) return "Admin user"
  if (permissionLevel >= 60) return "Controller user"
  if (permissionLevel >= 40) return "User"
  if (permissionLevel >= 20) return "Guest"
  return "No permission"
}

export function placePermissionText(place) {
  return permissionText(place.permission_level) + (place.permission_level !== place.permission_level_permanent ? " (ชั่วคราว)" : "");
}

export function resolveLeftPanePanel(name) {
  switch (name) {
    case 'LeftPaneRootPanel': return LeftPaneRootPanel;
    case 'LeftPaneControlPanel': return LeftPaneControlPanel;
    case 'LeftPaneControlAirPanel': return LeftPaneControlAirPanel;
    default: return LeftPaneRootPanel;
  }
}

export function placeText(place, type?): string {
  if (!place) return "";

  let typePart = "";

  if (!type) type = place.type;

  switch (type) {
    case 'group': typePart = 'กลุ่ม'; break;
    case 'building': typePart = 'อาคาร'; break;
    case 'floor': typePart = 'ชั้น'; break;
    case 'room': typePart = 'ห้อง'; break;
  }

  return (typePart + ' ' + place.name).trim();
}
