import React from "react"
import ScheduleStore from "../../../stores/ScheduleStore";
import { observer } from "mobx-react-lite";
import ScheduleAirPanel from "./ScheduleAirPanel";
import { IS_MOBILE } from "../../../seperated/screen-mode";
import { View, TextMD, TouchableOpacity, TextL } from "../../../seperated/react-native";
import { FLEX_LR_CENTER, BORDER_LIGHT, SHADOW_LIGHT } from "../../../components/constants/style";

export default observer<any>(function ScheduleAir({ refreshKey, model }) {
  const enablePrecool = ScheduleStore.isAirEnablePrecool();

  if (IS_MOBILE) {
    return (
      <>
        <View style={{marginBottom: 24}}>
          <ScheduleAirPanel
            refreshKey={refreshKey}
            model={model}
            controlKey="common_on"
            hasName
          ></ScheduleAirPanel>
        </View>

        <View style={{marginBottom: 24, padding: 16, ...BORDER_LIGHT, ...SHADOW_LIGHT}}>
          <TouchableOpacity style={{...FLEX_LR_CENTER}} onPress={()=>{
            if (enablePrecool) {
              ScheduleStore.airDisablePrecool();
            } else {
              ScheduleStore.airEnablePrecool();
              setTimeout(()=>window.scrollBy(0, 300), 250);
            }
          }}>
            <TextMD>Precool</TextMD>
            <div className="san-serif">
              {enablePrecool ? <TextMD>X</TextMD> : <TextL>+</TextL>}
            </div>
          </TouchableOpacity>

          {enablePrecool && 
            <View style={{marginTop: 20}}>
              <ScheduleAirPanel
                refreshKey={refreshKey}
                model={model}
                controlKey="precool_on"
              ></ScheduleAirPanel>
            </View>
          }
        </View>
      </>
    )
  }

  return (
    <>
      <ScheduleAirPanel
        refreshKey={refreshKey}
        model={model}
        controlKey="common_on"
        hasName
      ></ScheduleAirPanel>

      {enablePrecool &&
        <ScheduleAirPanel
          refreshKey={refreshKey}
          model={model}
          controlKey="precool_on"
        ></ScheduleAirPanel>
      }
    </>
  )
})