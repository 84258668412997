import React from "react"
import ButtonWithIcon from "./ButtonWithIcon"
import { navigate } from "../../seperated/components/Router"

export default function ControlButton(props) {
  return (
    <ButtonWithIcon 
      backgroundColor="#49c5f1" 
      icon={require("../../img/icons/power-white.png")}
      {...props}
      onPress={()=>navigate("control_air_manual", {type: props.type, id: props.id})}
    >
      ควบคุม
    </ButtonWithIcon>
  )
}