import React, { useState, useContext } from "react"
import ControlAirButton from "./ControlAirButton"
import Dialog from "../../dialog/Dialog"
import { View, TextL, TouchableOpacity } from "../../../seperated/react-native"
import { FLEX_COLUMN_CENTER_CENTER, FLEX_COLUMN, FLEX_ROW_CENTER_CENTER, FLEX_ROW_CENTER } from "../../constants/style"
import ControlAirModalButton, { ControlAirModalButtonPress } from "./ControlAirModalButton"
import { range } from "lodash"
import { IS_MOBILE } from "../../../seperated/screen-mode"
import AirStore from "../../../stores/AirStore"
import { observer } from "mobx-react-lite"
import ControlAirStoreModeContext, { getControlFieldValue } from "./ControlAirStoreModeContext"

const ICONS = {
  "A": require("../../../img/controls/fanspeed/auto-active.png"),
  "B": require("../../../img/controls/fanspeed/night-active.png"),
  3: require("../../../img/controls/control/fan1.png"),
  4: require("../../../img/controls/control/fan2.png"),
  5: require("../../../img/controls/control/fan3.png"),
  6: require("../../../img/controls/control/fan4.png"),
  7: require("../../../img/controls/control/fan5.png"),
}

function capitalize(str) {
  return str[0].toUpperCase() + str.slice(1)
}

export function getFanModeText(fanMode) {
  switch (fanMode) {
    case "A": return "Auto";
    case "B": return "Night";
    default: return "Level " + (parseInt(fanMode) - 2);
  }
}

export let ControlAirFanSpeedModal = observer<any>((props) => {
  const storeMode = useContext(ControlAirStoreModeContext);
  let fanMode = getControlFieldValue(storeMode, 'device_fan_speed');

  let fanSpeed = parseInt(fanMode)
  if (isNaN(fanSpeed)) {
    fanSpeed = 0;
  } else {
    fanSpeed -= 2;
  }

  return (
    <Dialog {...props} title="Fan speed" hasClose>
      <View style={{...FLEX_COLUMN_CENTER_CENTER}}>
        <View style={{padding: 24, marginTop: 12, ...FLEX_COLUMN, width: IS_MOBILE ? 450 : 600}}>
          <View style={{paddingBottom: 24, borderBottomColor: "#ededed", borderBottomWidth: 2}}>
            <TextL style={{textAlign: "center"}}>{getFanModeText(fanMode)}</TextL>
          </View>
  
          <View style={{...FLEX_ROW_CENTER_CENTER, justifyContent: "space-around", padding: 30}}>
            <ControlAirModalButton icon={fanMode == "A" ? require("../../../img/controls/fanspeed/auto-active.png") : require("../../../img/controls/fanspeed/auto.png")} field="device_fan_speed" value={"A"}></ControlAirModalButton>
            <ControlAirModalButton icon={fanMode == "B" ? require("../../../img/controls/fanspeed/night-active.png") : require("../../../img/controls/fanspeed/night.png")} field="device_fan_speed" value={"B"}></ControlAirModalButton>

            <View style={{position: "relative"}}>
              <ControlAirModalButton icon={require("../../../img/controls/fanspeed/fan"+fanSpeed+".png")} field="device_fan_speed" value={AirStore.device_control_air.device_fan_speed}></ControlAirModalButton>

              <View style={{
                position: "absolute", 
                ...FLEX_ROW_CENTER,     
                width: "100%",
                height: "100%",
                top: 0,
                left: 0,
              }}>
                {range(5).map(i => 
                  <TouchableOpacity style={{flexGrow: 1, height: "100%"}} key={i} onPress={()=>ControlAirModalButtonPress("device_fan_speed", i+3, storeMode)}></TouchableOpacity>
                )}
              </View>
            </View>
            
          </View>
        </View>
      </View>
    </Dialog>
  )
})

function mobileLabel(mode) {
  switch (mode) {
    case "A": return "Auto"
    case "B": return "Night"
    default: return ""
  }
}

export default observer(function ControlAirFanSpeedButton() {
  const [showModal, setShowModal] = useState(false);
  const storeMode = useContext(ControlAirStoreModeContext);
  let mode = getControlFieldValue(storeMode, 'device_fan_speed');

  return <>
    <View style={IS_MOBILE ? {flexBasis: "33.33%", paddingHorizontal: 4, marginBottom: 8} : {}}>
      <ControlAirButton icon={ICONS[mode]} text={IS_MOBILE ? mobileLabel(mode) : "Fan Speed"} iconSize={parseInt(mode) ? 110 : 75} onPress={()=>setShowModal(true)}></ControlAirButton>
    </View>
    <ControlAirFanSpeedModal show={showModal} onClose={()=>setShowModal(false)}></ControlAirFanSpeedModal>
  </>
})