import React from "react"
import IconInline from "./IconInline"

export default function EmotionIcon({level, ...props}) {
  let icon;

  if (level <= 100) {
    icon = require("../../img/icons/emotion-cry.png");
  } else if (level <= 200) {
    icon = require("../../img/icons/emotion-sad.png");
  } else {
    icon = require("../../img/icons/emotion-smile.png");
  }

  return (
    <IconInline {...props} src={icon}></IconInline>
  )
}