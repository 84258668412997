import React from 'react';
import Plot from 'react-plotly.js';

export default function EnergyConsumptionChart(props) {
    return (
      <Plot
        data={props.data}
        layout={ {
          height: 300,
          margin: {
            l: 24,
            r: 24,
            t: 24,
            b: 24,
          }
        } }
      />
    )
}