import React from "react";
import ControlAirButton from "./ControlAirButton";
import { View } from "../../../seperated/react-native";
import { IS_MOBILE } from "../../../seperated/screen-mode";
import ScopeStore from "../../../stores/ScopeStore";
import AirStore from "../../../stores/AirStore";

export default function ControlAirMaintenanceButton() {
  return (
    <>
      <View
        style={
          IS_MOBILE
            ? { flexBasis: "33.33%", paddingHorizontal: 4, marginBottom: 8 }
            : {}
        }
      >
        <ControlAirButton
          icon={require("../../../img/controls/control/settings.png")}
          text="Contact repairing"
          onPress={() => {
            window.location.href =
              "https://airin1.com/services/form?device_id=" +
              AirStore.id;
          }}
        ></ControlAirButton>
      </View>
    </>
  );
}
