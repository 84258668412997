import React from "react"
import { TextSM } from "../../seperated/react-native";
import { countDevices } from "../../utils/deviceUtils";

export default function DeviceStatusTable({TextComponent=TextSM, data}) {
  const {
    online: airOn,
    offline: airOff,
    error: airErr,
    total: airTotal,
  } = countDevices(data || {}, 1);

  const {
    online: lightOn,
    offline: lightOff,
    error: lightErr,
    total: lightTotal,
  } = countDevices(data || {}, 3);

  const {
    online: curtainOn,
    offline: curtainOff,
    error: curtainErr,
    total: curtainTotal,
  } = countDevices(data || {}, 4);

  const {
    online: doorOn,
    offline: doorOff,
    error: doorErr,
    total: doorTotal,
  } = countDevices(data || {}, 5);

  return (
    <table className="device-status-table">
      <thead>
        <tr>
          <th style={{textAlign: "left"}}><TextComponent>Device</TextComponent></th>
          <th><TextComponent>Total</TextComponent></th>
          <th><TextComponent>On</TextComponent></th>
          <th><TextComponent>Offline</TextComponent></th>
          <th><TextComponent>Error</TextComponent></th>
          {/* <th><TextComponent>Status</TextComponent></th> */}
        </tr>
      </thead>
      <tbody>
        <tr style={{textAlign: "center"}}>
          <td style={{textAlign: "left"}}>Air Condition</td>
          <td>{airTotal}</td>
          <td>{airOn}</td>
          <td>{airOff}</td>
          <td>{airErr}</td>
          {/* <td></td> */}
        </tr>

        <tr style={{textAlign: "center"}}>
          <td style={{textAlign: "left"}}>Light</td>
          <td>{lightTotal}</td>
          <td>{lightOn}</td>
          <td>{lightOff}</td>
          <td>{lightErr}</td>
          {/* <td></td> */}
        </tr>

        <tr style={{textAlign: "center"}}>
          <td style={{textAlign: "left"}}>Curtain</td>
          <td>{curtainTotal}</td>
          <td>{curtainOn}</td>
          <td>{curtainOff}</td>
          <td>{curtainErr}</td>
          {/* <td></td> */}
        </tr>

        <tr style={{textAlign: "center"}}>
          <td style={{textAlign: "left"}}>Door</td>
          <td>{doorTotal}</td>
          <td>{doorOn}</td>
          <td>{doorOff}</td>
          <td>{doorErr}</td>
          {/* <td></td> */}
        </tr>
      </tbody>
    </table>
  )
}