import React, { useState, useContext } from "react"
import ControlAirButton from "./ControlAirButton"
import Dialog from "../../dialog/Dialog"
import { View, TextL, TouchableOpacity, Text } from "../../../seperated/react-native"
import { FLEX_COLUMN_CENTER_CENTER, FLEX_COLUMN, FLEX_ROW_CENTER_CENTER } from "../../constants/style"
import ControlAirModalButton from "./ControlAirModalButton"
import { IS_MOBILE } from "../../../seperated/screen-mode"
import AirStore from "../../../stores/AirStore"
import { observer } from "mobx-react-lite"
import ControlAirStoreModeContext, { getControlFieldValue } from "./ControlAirStoreModeContext"
import { navigateSameParams } from "../../../seperated/components/Router"

const ICONS = {
  "L": require("../../../img/controls/Remote/remote-lock.png"),
  "S": require("../../../img/controls/Remote/remote-semilock.png"),
  "T": require("../../../img/controls/Remote/remote-semilock.png"),
  "U": require("../../../img/controls/Remote/remote-unlock.png"),
}

const ICONS_ACTIVE = {
  "L": require("../../../img/controls/Remote/remote-lock-active.png"),
  "S": require("../../../img/controls/Remote/remote-semilock-active.png"),
  "T": require("../../../img/controls/Remote/remote-semilock-active.png"),
  "U": require("../../../img/controls/Remote/remote-unlock-active.png"),
}

export const TEXT2LABEL = {
  "L": "ล็อค",
  "S": "ยกเว้นปุ่ม เปิด/ปิด",
  "T": "ล็อคเฉพาะอุณหภูมิ",
  "U": "ไม่ล็อค",
  "Loading...": "Loading...",
}

function capitalize(str) {
  return str[0].toUpperCase() + str.slice(1)
}

export let ControlAirRemoteModal = ((props) => {
  const storeMode = useContext(ControlAirStoreModeContext);
  let mode = getControlFieldValue(storeMode, 'device_is_locked_remote');

  return (
    <Dialog {...props} title="Remote" hasClose>
      <View style={{...FLEX_COLUMN_CENTER_CENTER}}>
        <View style={{padding: 24, marginTop: 12, ...FLEX_COLUMN, width: IS_MOBILE ? 450 : 600}}>
          <View style={{paddingBottom: 24, borderBottomColor: "#ededed", borderBottomWidth: 2}}>
            <TextL style={{textAlign: "center"}}>{TEXT2LABEL[mode]}</TextL>
          </View>
  
          <View style={{...FLEX_ROW_CENTER_CENTER, justifyContent: "space-around", padding: 30}}>
            {Object.keys(ICONS).map(key => (
              <ControlAirModalButton icon={mode == key ? ICONS_ACTIVE[key] : ICONS[key]} key={key} field="device_is_locked_remote" value={key} height={IS_MOBILE ? 60 : 80}>

              </ControlAirModalButton>
            ))}
          </View>

          <TouchableOpacity style={{...FLEX_ROW_CENTER_CENTER}} onPress={() => navigateSameParams("control_air_settings", props)}>
            <Text style={{textDecorationLine: "underline"}}>ตั้งค่าการล็อคอุณหภูมิ</Text>
          </TouchableOpacity>
        </View>
      </View>
    </Dialog>
  )
})

export default observer(function ControlAirRemoteButton() {
  const [showModal, setShowModal] = useState(false)
  const storeMode = useContext(ControlAirStoreModeContext);
  let mode = getControlFieldValue(storeMode, 'device_is_locked_remote');

  return <>
    <View style={IS_MOBILE ? {flexBasis: "33.33%", paddingHorizontal: 4, marginBottom: 8} : {}}>
      <ControlAirButton icon={ICONS_ACTIVE[mode]} text={"Remote"} onPress={()=>setShowModal(true)} iconSize={48}></ControlAirButton>
    </View>
    <ControlAirRemoteModal show={showModal} onClose={()=>setShowModal(false)}></ControlAirRemoteModal>
  </>
})