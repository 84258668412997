import React, { PureComponent, useState, useEffect, useContext } from 'react';
import { View } from 'react-native';
import { PanResponder } from '../../seperated/react-native';
import Svg, { Circle, G, LinearGradient, Path, Defs, Stop, Text } from 'react-native-svg';
import { range } from 'lodash';
import { interpolateHcl as interpolateGradient } from 'd3-interpolate';
import PropTypes from 'prop-types'; // ES6
import { WebDiv } from '../../seperated/web-only';
import { IS_MOBILE, ZOOM_LEVEL_MD } from '../../seperated/screen-mode';
import { api } from '../../config';
import AirStore from '../../stores/AirStore';
import { globalLoadingFn } from '../../utils/loading';
import { alertErrorComplete } from '../../seperated/toast';
import { applySnapshotAuto } from '../../utils/mobx';
import ScopeStore from '../../stores/ScopeStore';
import { debounce } from 'lodash'
import ScheduleStore from '../../stores/ScheduleStore';
import { getAirButtonDisabled } from './air/ControlAirPanel';
import { DisabledOpacity, InsistDisabledContext } from '../input/DisabledContext';
import { observer } from 'mobx-react-lite';

const wait = (ms) => new Promise(resolve => setTimeout(resolve, ms))

const BUTTON_ANGLE = 35;
const BUTTON_ANGLE_RAD = Math.PI/180 * BUTTON_ANGLE;
const TEMP_MAX_ANGLE_LENGTH = 2*Math.PI - 2*BUTTON_ANGLE_RAD;

const TEMP_MIN = 16;
const TEMP_MAX = 33;

const TEMP_MIN_PROG = TEMP_MIN - 0.4;
const TEMP_MAX_PROG = TEMP_MAX + 0.4;
const TEMP_MAX_LENGTH_PROG = TEMP_MAX_PROG - TEMP_MIN_PROG;

const SIZE_MUL = IS_MOBILE ? 0.8 : 1;

function gTransform(transform) {
  let result = ""
  for(let key in transform) {
    result += key+"("+transform[key].replace(/,/g, ' ')+")\n"
  }
  return result;
}


function calculateArcColor(index0, segments, gradientColorFrom, gradientColorTo) {
  const interpolate = interpolateGradient(gradientColorFrom, gradientColorTo);

  return {
    fromColor: interpolate(index0 / segments),
    toColor: interpolate((index0 + 1) / segments),
  }
}

function calculateArcCircle(index0, segments, radius, startAngle0 = 0, angleLength0 = 2 * Math.PI) {
  // Add 0.0001 to the possible angle so when start = stop angle, whole circle is drawn
  const startAngle = startAngle0 % (2 * Math.PI);
  const angleLength = angleLength0 % (2 * Math.PI);
  const index = index0 + 1;
  const fromAngle = angleLength / segments * (index - 1) + startAngle;
  const toAngle = angleLength / segments * index + startAngle;
  const fromX = radius * Math.sin(fromAngle);
  const fromY = -radius * Math.cos(fromAngle);
  const realToX = radius * Math.sin(toAngle);
  const realToY = -radius * Math.cos(toAngle);

  // add 0.005 to start drawing a little bit earlier so segments stick together
  const toX = radius * Math.sin(toAngle + 0.005);
  const toY = -radius * Math.cos(toAngle + 0.005);

  return {
    fromX,
    fromY,
    toX,
    toY,
    realToX,
    realToY,
  };
}

function getGradientId(index) {
  return `gradient${index}`;
}

class CircularSlider extends PureComponent<any> {

  [x: string]: any

  static propTypes = {
    onUpdate: PropTypes.func.isRequired,
    startAngle: PropTypes.number.isRequired,
    angleLength: PropTypes.number.isRequired,
    segments: PropTypes.number,
    strokeWidth: PropTypes.number,
    radius: PropTypes.number,
    gradientColorFrom: PropTypes.string,
    gradientColorTo: PropTypes.string,
    showClockFace: PropTypes.bool,
    clockFaceColor: PropTypes.string,
    bgCircleColor: PropTypes.string,
    bgColor: PropTypes.string,
    stopIcon: PropTypes.element,
    startIcon: PropTypes.element,
    semicircle: PropTypes.bool,
    temp: PropTypes.number,
    mode: PropTypes.string,

    onChangeTemp: PropTypes.func.isRequired,
    onRelease: PropTypes.func.isRequired,
  }

  static defaultProps = {
    segments: 5,
    strokeWidth: 40,
    radius: 145,
    gradientColorFrom: '#ff9800',
    gradientColorTo: '#ffcf00',
    clockFaceColor: '#9d9d9d',
    bgCircleColor: '#171717',
  }

  state = {
    circleCenterX: 0,
    circleCenterY: 0,
  }

  componentWillMount() {
    this._sleepPanResponder = PanResponder.create({
      onMoveShouldSetPanResponder: (evt, gestureState) => true,
      onMoveShouldSetPanResponderCapture: (evt, gestureState) => true,
      onPanResponderGrant: (evt, gestureState) => { this.setCircleCenter() },
      onPanResponderMove: (evt, { moveX, moveY }) => {
        const { circleCenterX, circleCenterY } = this.state;
        const { angleLength, startAngle, onUpdate } = this.props;

        const currentAngleStop = (startAngle + angleLength) % (2 * Math.PI);
        let newAngle = Math.atan2(moveY - circleCenterY, moveX - circleCenterX) + Math.PI/2;

        if (newAngle < 0) {
          newAngle += 2 * Math.PI;
        }

        let newAngleLength = currentAngleStop - newAngle;

        if (newAngleLength < 0) {
          newAngleLength += 2 * Math.PI;
        }

        onUpdate({ startAngle: newAngle, angleLength: newAngleLength % (2 * Math.PI) });
      },
    });

    this._wakePanResponder = PanResponder.create({
      onMoveShouldSetPanResponder: (evt, gestureState) => true,
      onMoveShouldSetPanResponderCapture: (evt, gestureState) => true,
      onPanResponderGrant: (evt, gestureState) => this.setCircleCenter(),
      onPanResponderMove: (evt, { moveX, moveY }) => {
        // console.log("dsadas")

        const { circleCenterX, circleCenterY } = this.state;
        const { angleLength, startAngle, onUpdate } = this.props;

        let newAngle = Math.atan2(moveY - circleCenterY, moveX - circleCenterX) + Math.PI/2;
        let newAngleLength = (newAngle - startAngle) % (2 * Math.PI);

        if (newAngleLength < 0) {
          newAngleLength += 2 * Math.PI;
        }

        // console.log(circleCenterX, circleCenterY)

        onUpdate({ startAngle, angleLength: newAngleLength });
      },
      onPanResponderRelease: (evt, gestureState) => {
        this.props.onRelease();
      }
    });
  }

  onLayout = () => {
    this.setCircleCenter();
  }

  setCircleCenter = () => {
    const rect = this._circle._touchableNode.getBoundingClientRect();

    let px = rect.left + window.scrollX;
    let py = rect.top + window.scrollY;

    if (window.innerWidth < 1400) {
      px *= ZOOM_LEVEL_MD;
      py *= ZOOM_LEVEL_MD;
    }

    console.log(px, py)

    //this._circle.measure((x, y, w, h, px , py) => {
      const halfOfContainer = this.getContainerWidth() / 2;
      this.setState({ circleCenterX: px + halfOfContainer, circleCenterY: py + halfOfContainer });
    //});
  }

  getContainerWidth() {
    const { strokeWidth, radius } = this.props;
    return strokeWidth + radius * 2 + 2;
  }

  render() {
    const { startAngle, angleLength, segments, strokeWidth, radius, gradientColorFrom, gradientColorTo, bgCircleColor, bgColor,
      showClockFace, clockFaceColor, startIcon, stopIcon, temp, mode } = this.props;

    const containerWidth = this.getContainerWidth();

    const start = calculateArcCircle(0, segments, radius, startAngle, angleLength);
    const stop = calculateArcCircle(segments - 1, segments, radius, startAngle, angleLength);

    return (
      <WebDiv className="no-zoom" onTouchMove={e => e.stopPropagation()}>
        <View style={{ width: containerWidth, height: containerWidth }} onLayout={this.onLayout} ref={view => this._view = view}>
          <Svg
            height={containerWidth}
            width={containerWidth}
            ref={circle => this._circle = circle}
          >
            <Defs>
              {
                range(segments).map(i => {
                  const { fromX, fromY, toX, toY } = calculateArcCircle(i, segments, radius, startAngle, angleLength);
                  const { fromColor, toColor } = calculateArcColor(i, segments, gradientColorFrom, gradientColorTo)
                  return (
                    <LinearGradient key={i} id={getGradientId(i)} x1={fromX.toFixed(2)} y1={fromY.toFixed(2)} x2={toX.toFixed(2)} y2={toY.toFixed(2)}>
                      <Stop offset="0%" stopColor={fromColor} />
                      <Stop offset="1" stopColor={toColor} />
                    </LinearGradient>
                  )
                })
              }
            </Defs>

            {/*
              ##### Circle (Core)
            */}

            <G transform={ gTransform({translate: `${strokeWidth/2 + radius + 1}, ${strokeWidth/2 + radius + 1}`}) }>
              <Circle
                r={radius}
                strokeWidth={strokeWidth}
                fill="transparent"
                stroke={bgColor}
              />
              {/* {
                showClockFace && (
                  <ClockFace
                    r={radius - strokeWidth / 2}
                    stroke={clockFaceColor}
                  />
                )
              } */}

              {/* Center text */}

              <Text textAnchor="middle" fontSize={SIZE_MUL * 108} x={SIZE_MUL * (-10)} fill="#605b5c">{temp}</Text>
              <Text textAnchor="middle" fontSize={SIZE_MUL * 16} x={SIZE_MUL * 60} y={SIZE_MUL * (-13)} fill="#605b5c">o</Text>
              <Text textAnchor="middle" fontSize={SIZE_MUL * 40} x={SIZE_MUL * 73} fill="#605b5c">c</Text>

              <Text textAnchor="middle" fontSize={SIZE_MUL * 40} x={SIZE_MUL * (-10)} y={SIZE_MUL * (55)} fill="#605b5c">{mode}</Text>

              {/* segment from start to circle (segments >= 2, segments = 1 has bug) */}
              {
                range(segments).map(i => {
                  const { fromX, fromY, toX, toY } = calculateArcCircle(i, segments, radius, startAngle, angleLength);
                  const d = `M ${fromX.toFixed(2)} ${fromY.toFixed(2)} A ${radius} ${radius} 0 0 1 ${toX.toFixed(2)} ${toY.toFixed(2)}`;

                  return (
                    <Path
                      d={d}
                      key={i}
                      strokeWidth={strokeWidth}
                      stroke={`url(#${getGradientId(i)})`}
                      fill="transparent"
                    />
                  )
                })
              }

              {/* Gray control button */}
              {
                [Math.PI, Math.PI - BUTTON_ANGLE_RAD].map((startAngle, i) => {
                  const { fromX, fromY, toX, toY } = calculateArcCircle(0, 1, radius, startAngle, BUTTON_ANGLE_RAD);
                  const d = `M ${fromX.toFixed(2)} ${fromY.toFixed(2)} A ${radius} ${radius} 0 0 1 ${toX.toFixed(2)} ${toY.toFixed(2)}`;

                  return (
                    <Path
                      d={d}
                      key={startAngle}
                      strokeWidth={strokeWidth}
                      stroke="#605b5c"
                      fill="transparent"
                      onPress={()=>{
                        if (i==0) {
                          this.props.onChangeTemp(temp-1)
                          setTimeout(()=>this.props.onRelease(), 100);
                        } else {
                          this.props.onChangeTemp(temp+1)
                          setTimeout(()=>this.props.onRelease(), 100);
                        }
                      }}
                    />
                  )
                })
              }

              {/* Control button border */}
              {
                [Math.PI, Math.PI - BUTTON_ANGLE_RAD, BUTTON_ANGLE_RAD - Math.PI].map(startAngle => {
                  const { fromX, fromY, toX, toY } = calculateArcCircle(0, 1, radius, startAngle, 0.005);
                  const d = `M ${fromX.toFixed(2)} ${fromY.toFixed(2)} A ${radius} ${radius} 0 0 1 ${toX.toFixed(2)} ${toY.toFixed(2)}`;

                  return (
                    <Path
                      d={d}
                      key={startAngle}
                      strokeWidth={strokeWidth}
                      stroke="#cccccc"
                      fill="transparent"
                    />
                  )
                })
              }

              {/* - + Text */}
              <Text textAnchor="middle" fontSize={SIZE_MUL * 80} x={SIZE_MUL * (-50)} y={SIZE_MUL * 188} fill="#ffffff" pointerEvents="none">-</Text>
              <Text textAnchor="middle" fontSize={SIZE_MUL * 80} x={SIZE_MUL * 50} y={SIZE_MUL * 188} fill="#ffffff" pointerEvents="none">+</Text>

              {/*
                ##### Stop Icon
              */}

              <G
                fill={gradientColorTo}
                transform={ gTransform({ translate: `${stop.toX}, ${stop.toY}` }) }
                onPressIn={() => this.setState({ angleLength: angleLength + Math.PI / 2 })}
                {...this._wakePanResponder.panHandlers}
              >
                <Circle
                  r={(strokeWidth - 1) / 2}
                  fill={bgCircleColor}
                  stroke={gradientColorTo}
                  strokeWidth="1"
                  
                />
                {
                  stopIcon
                }
              </G>

              {/*
                ##### Start Icon
              */}

              {/* <G
                fill={gradientColorFrom}
                transform={ gTransform({ translate: `${start.fromX}, ${start.fromY}` }) }
                onPressIn={() => this.setState({ startAngle: startAngle - Math.PI / 2, angleLength: angleLength + Math.PI / 2 })}
                {...this._sleepPanResponder.panHandlers}
              >
                <Circle
                  r={(strokeWidth - 1) / 2}
                  fill={bgCircleColor}
                  stroke={gradientColorFrom}
                  strokeWidth="1"
                />
                {
                  startIcon
                }
              </G> */}
            </G>
          </Svg>
        </View>
      </WebDiv>
    );
  }
}

function temp2angleLength(temp) {
  let tempLength = temp - TEMP_MIN_PROG

  return TEMP_MAX_ANGLE_LENGTH / TEMP_MAX_LENGTH_PROG * tempLength;
}

function angleLength2temp(angleLength) {
  let tempLength = TEMP_MAX_LENGTH_PROG / TEMP_MAX_ANGLE_LENGTH * angleLength

  return Math.round(tempLength + TEMP_MIN_PROG);
}

const patchTemp = debounce(async (temp, storeMode) => {
  if (storeMode == "air_manual") {
    try {
      let response = await api.patch("/devices/device_control_air?scope_type="+ScopeStore.controlActiveType+"&scope_id="+ScopeStore.controlActiveId, {device_temp: temp})
      applySnapshotAuto(AirStore, response.data);
    } catch (err) {
      alertErrorComplete(err)
      throw err;
    }
  } else if (storeMode.startsWith("schedule")) {
    let controlKey = storeMode.split(':')[1];
    ScheduleStore.controls.get(controlKey)?.setControlDataByField("device_temp", temp)
  }
}, 500)

export default observer<any>(function ControlTempSlider({defaultTemp, mode="auto", refreshKey, storeMode="air_manual", ...props}) {
  const [angleLengthOld, setAngleLength] = useState(temp2angleLength(defaultTemp))
  const [temp, setTemp] = useState(defaultTemp >= TEMP_MIN && defaultTemp <= TEMP_MAX ? defaultTemp : 25);
  const [firstTime, setFirstTime] = useState(true);

  useEffect(() => {
    if (!temp) return;

    if (firstTime) {
      setFirstTime(false)
      return;
    }
  }, [temp])

  useEffect(()=>{
    setAngleLength(temp2angleLength(temp))
  }, [temp])

  useEffect(()=>{
    setTemp(defaultTemp >= TEMP_MIN && defaultTemp <= TEMP_MAX ? defaultTemp : 25)
  }, [refreshKey])

  const insistDisabled = useContext(InsistDisabledContext);
  const disabled = insistDisabled || getAirButtonDisabled(storeMode);

  return (
    <DisabledOpacity value={disabled}>
      <CircularSlider
        startAngle={BUTTON_ANGLE_RAD - Math.PI}
        angleLength={angleLengthOld}
        onUpdate={({ startAngle, angleLength }) => {
          if (
            false || (
            Math.abs(angleLength - angleLengthOld) < Math.PI/4 && 
            angleLength > 0 &&
            angleLength < TEMP_MAX_ANGLE_LENGTH)
          ) {
            setAngleLength(angleLength)
            setTemp(angleLength2temp(angleLength))
          }
        }}

        onChangeTemp={(temp) => {
          if (temp >= TEMP_MIN && temp <= TEMP_MAX) {
            setTemp(temp)
            setAngleLength(temp2angleLength(temp))
          }
        }}

        onRelease={() => patchTemp(temp, storeMode)}
        
        segments={2}
        strokeWidth={SIZE_MUL * 65}
        radius={SIZE_MUL * 175}
        gradientColorFrom="#27afd9"
        gradientColorTo="#27afd9"
        clockFaceColor="#27afd9"
        bgCircleColor="#ffffff"
        bgColor="#27afd9"
        semicircle
        
        temp={temp}
        mode={mode}
      />
    </DisabledOpacity>

  )
})