import React, { useEffect } from "react";
import Dialog from "../../../../components/dialog/Dialog";
import {
  View,
  Image,
  TextMD,
  Text,
  TextL,
} from "../../../../seperated/react-native";
import {
  FLEX_ROW_CENTER_CENTER,
  FLEX_COLUMN,
  FLEX_ROW_CENTER,
  FLEX_COLUMN_CENTER_CENTER,
} from "../../../../components/constants/style";
import CardButton from "../../../../components/buttons/CardButton";
import HoriTwo from "../../../../components/HoriTwo";
import TextFieldComplete from "../../../../components/input/TextFieldComplete";
import IconSelectComplete from "../../../../components/input/IconSelectComplete";
import SelectCustom from "../../../../seperated/components/SelectCustom";
import { IS_MOBILE } from "../../../../seperated/screen-mode";
import CommonButton from "../../../../components/buttons/CommonButton";
import AirAdderStore from "../../../../stores/AirAdderStore";
import { observer } from "mobx-react-lite";
import MobileLayout from "../../../../components/MobileLayout";
import { navigate, getRouteParam } from "../../../../seperated/components/Router";
import { useFormState } from "../../../../hooks/FormState";
import {
  ICON_BUILDING_BASE,
  DEVICE_TYPES_OPTIONS,
  DEVICE_AIR_TYPES_OPTIONS,
  ELECTRICITY_FORMULA_OPTIONS,
  DEVICE_LIGHT_TYPES_OPTIONS,
} from "../../../../components/constants/options";
import SelectComplete from "../../../../components/input/SelectComplete";
import {
  applySnapshotAuto,
  createFromModel,
  applySnapshotPatch,
} from "../../../../utils/mobx";
import { getType } from "mobx-state-tree";
import { globalLoadingFn } from "../../../../utils/loading";
import { api } from "../../../../config";
import ScopeStore from "../../../../stores/ScopeStore";
import DisabledContext from "../../../../components/input/DisabledContext";
import { DEVICE_TYPE_AIR } from "../../../../components/constants/constants";
import { alertErrorComplete, alert_success, alert_error } from "../../../../seperated/toast";
import { $VAL_REQUIRED } from "../../../../utils/validation";
import { controlAddActiveFirstPage } from "./controlAddUtils";
import axios from "axios";

const FORM_VALIDATION = addBuildingOnly => ({
  name: val => $VAL_REQUIRED(val, "จำเป็นต้องใส่ชื่ออาคาร"),
  avatar: val => $VAL_REQUIRED(val, "จำเป็นต้องเลือกรูปภาพ"),
  device_type_id: addBuildingOnly ? val => false : val => !IS_MOBILE && $VAL_REQUIRED(val, "จำเป็นต้องเลือกประเภทอุปกรณ์"),
  device_air_type_id(val, data) {
    if (IS_MOBILE) return false;
    if (addBuildingOnly) return false;

    if (data.device_type_id == DEVICE_TYPE_AIR || data.device_type_id == 3) {
      return $VAL_REQUIRED(val, "จำเป็นต้องเลือกประเภทแอร์")
    }

    return false;
  },
  district: val => $VAL_REQUIRED(val, "จำเป็นต้องใส่เขตที่อยู่"),
})

async function performAddBuilding(is_two_level) {
  // let password = window.prompt('กรุณาป้อนรหัสผ่าน');

  // if (password != "Bruno2021") return;
  
  let building = {
    ...AirAdderStore.building,
    is_two_level
  }

  let district = building.district;

  let districtResponse = await globalLoadingFn(()=>axios.get("https://openweathermap.org/data/2.5/find?q=" + encodeURIComponent(district) + "&type=like&sort=population&cnt=30&appid=439d4b804bc8187953eb36d2a8c26a02"));
  districtResponse = districtResponse.data;

  if (districtResponse.count > 0) {
    district = districtResponse.list[0].name;
  } else {
    alert_error('ไม่พบเขต/อำเภอ "' + district + '" กรุณาตรวจสอบว่าคุณใส่เขตถูกต้องหรือไม่ (เขต/อำเภอ ไม่ใช่ แขวง/ตำบล)')
    return;
  }

  building.district = district

  let response = await globalLoadingFn(()=>api.post("/buildings", building))
  ScopeStore.setActiveBuilding(response.data.id)

  alert_success("เพิ่มอาคารสำเร็จ");

  navigate("dashboard_after_add_air")
  
  setTimeout(() => window.location.reload(), 250);
}

function updateMobx(model, addBuildingOnly = false) {
  let data = model.getAndValidate();

  if (!IS_MOBILE && typeof AirAdderStore.building.is_two_level === "undefined") {
    throw new Error("จำเป็นต้องเลือกรูปแบบการแสดงผลด้วย")
  }

  if (!addBuildingOnly) {
    AirAdderStore.setDeviceType(data.device_type_id);
    AirAdderStore.setDeviceAirType(data.device_air_type_id);
  }

  applySnapshotPatch(AirAdderStore.building, {
    name: data.name,
    avatar: data.avatar,
    district: data.district,
    electricity_formula_id: data.electricity_formula_id,
    is_two_level: AirAdderStore.building.is_two_level,
  });
}

let ControlAirAddBuildingInnerTop = observer<any>(
  ({ model, addBuildingOnly = false }) => {
    useEffect(() => {
      model.dataState.set({
        ...model.dataState.get(),
        name: AirAdderStore.building.name,
        avatar: AirAdderStore.building.avatar,
        device_type_id: AirAdderStore.device.device_detail.device_type_id,
        district: AirAdderStore.building.district,
        electricity_formula_id: "mockup" || AirAdderStore.building.electricity_formula_id,
      });
    }, [
      AirAdderStore.building.name,
      AirAdderStore.device.device_detail.device_type_id,
    ]);

    return (
      <DisabledContext.Provider value={AirAdderStore.building.id != 0}>
        <View>
          <HoriTwo
            left={<Text>ชื่ออาคาร</Text>}
            style={{ marginBottom: 36 }}
            mobilecol
          >
            <TextFieldComplete
              variant="material"
              model={model.$("name")}
            ></TextFieldComplete>
          </HoriTwo>
  
          <Text style={{ marginBottom: 16 }}>รูปภาพ</Text>
  
          <View style={{ marginBottom: 36 }}>
            <IconSelectComplete
              options={[...ICON_BUILDING_BASE]}
              model={model.$("avatar")}
            ></IconSelectComplete>
          </View>

          <HoriTwo
            left={<Text>เขต / อำเภอ</Text>}
            style={{ marginBottom: 36 }}
            mobilecol
          >
            <TextFieldComplete
              variant="material"
              model={model.$("district")}
            ></TextFieldComplete>
          </HoriTwo>

          {/* <HoriTwo
            left={<Text>การคำนวณค่าไฟ</Text>}
            style={{ marginBottom: 36 }}
          >
            <SelectComplete
              variant="material"
              options={ELECTRICITY_FORMULA_OPTIONS}
              model={model.$("electricity_formula_id")}
            ></SelectComplete>
          </HoriTwo> */}
  
          {!addBuildingOnly && !IS_MOBILE && (<DisabledContext.Provider value={false}>
            <HoriTwo
              left={<Text>ประเภทอุปกรณ์</Text>}
              style={{ marginBottom: 36 }}
            >
              <SelectComplete
                variant="material"
                options={DEVICE_TYPES_OPTIONS}
                model={model.$("device_type_id")}
              ></SelectComplete>
            </HoriTwo>
  
            {model.$("device_type_id").get() == 1 && (
              <HoriTwo
                left={<Text>ประเภทแอร์</Text>}
                style={{ marginBottom: 36 }}
              >
                <SelectComplete
                  variant="material"
                  options={DEVICE_AIR_TYPES_OPTIONS}
                  model={model.$("device_air_type_id")}
                ></SelectComplete>
              </HoriTwo>
            )}

            {model.$("device_type_id").get() == 3 && (
              <HoriTwo
                left={<Text>ประเภทหลอดไฟ</Text>}
                style={{ marginBottom: 36 }}
              >
                <SelectComplete
                  variant="material"
                  options={DEVICE_LIGHT_TYPES_OPTIONS}
                  model={model.$("device_air_type_id")}
                ></SelectComplete>
              </HoriTwo>
            )}
          </DisabledContext.Provider>)}
        </View>
      </DisabledContext.Provider>
    );
  }
);

const ControlAirAddBuildingInnerBottom = observer<any>(
  ({ model, addBuildingOnly }) => {
    return (
      <DisabledContext.Provider value={AirAdderStore.building.id != 0}>
        <View>
          <View
            style={{
              ...(IS_MOBILE ? FLEX_COLUMN_CENTER_CENTER : FLEX_ROW_CENTER_CENTER),
            }}
          >
            <CardButton
              icon={
                <Image
                  src={require("../../../../img/icons/home.png")}
                  height="125"
                />
              }
              title="อาคาร > ห้อง"
              subtitle="ภาพรวมอาคารและรายละเอียดชั้น"
              active={AirAdderStore.building.is_two_level === true}
              onPress={() => {
                AirAdderStore.setDisplayTwoLevel(true);
  
                if (IS_MOBILE) {
                  if (!addBuildingOnly) {
                    navigate("control_air_add_type");
                  } else {
                    performAddBuilding(true);
                  }
                }
              }}
              style={{ margin: 8 }}
              hasRightArrow={IS_MOBILE}
            ></CardButton>
  
            <CardButton
              icon={
                <Image
                  src={require("../../../../img/icons/home.png")}
                  height="125"
                />
              }
              title="อาคาร > ชั้น > ห้อง"
              subtitle="ภาพรวมอาคาร, รายละเอียดชั้น และ ห้อง"
              active={AirAdderStore.building.is_two_level === false}
              onPress={() => {
                AirAdderStore.setDisplayTwoLevel(false);
  
                if (IS_MOBILE) {
                  if (!addBuildingOnly) {
                    navigate("control_air_add_type");
                  } else {
                    performAddBuilding(true);
                  }
                }
              }}
              style={{ margin: 8 }}
              hasRightArrow={IS_MOBILE}
            ></CardButton>
          </View>
        </View>
      </DisabledContext.Provider>
    );
  }
);

export function ControlAirAddBuildingTopPage(props) {
  const model = useFormState({}, FORM_VALIDATION(props.addBuildingOnly));

  const addBuildingOnly = getRouteParam(props, "add_building_only") == "1"

  return (
    <MobileLayout
      title={addBuildingOnly ? "สร้างอาคารใหม่" : "เพิ่มอุปกรณ์"}
      bottom={
        <CommonButton
          variant="primary"
          onPress={() => {
            try {
              updateMobx(model, props.addBuildingOnly || false)
              navigate(AirAdderStore.building.id ? "control_air_add_type" : "control_air_add_building_bottom", {...(addBuildingOnly ? {add_building_only: 1} : {})});
            } catch (err) {
              alertErrorComplete(err);
            }
          }}
        >
          บันทึก
        </CommonButton>
      }
    >
      {AirAdderStore.building.id == 0 && !addBuildingOnly && (
        <TextL style={{ marginBottom: 18 }}>สร้างอาคารใหม่</TextL>
      )}

      <View style={{ padding: 8 }}>
        <ControlAirAddBuildingInnerTop
          {...props}
          addBuildingOnly={addBuildingOnly}
          model={model}
        ></ControlAirAddBuildingInnerTop>
      </View>
    </MobileLayout>
  );
}

export function ControlAirAddBuildingฺBottomPage(props) {
  const model = useFormState({}, FORM_VALIDATION(props.addBuildingOnly));

  const addBuildingOnly = getRouteParam(props, "add_building_only") == "1"

  console.log(addBuildingOnly)

  return (
    <MobileLayout title={addBuildingOnly ? "สร้างอาคารใหม่" : "เพิ่มอุปกรณ์"}>
      <ControlAirAddBuildingInnerBottom
        {...props}
        addBuildingOnly={addBuildingOnly}
        model={model}
      ></ControlAirAddBuildingInnerBottom>
    </MobileLayout>
  );
}

export default function ControlAirAddBuilding(props) {
  const model = useFormState({}, FORM_VALIDATION(props.addBuildingOnly));

  useEffect(()=>{
    if (props.addBuildingOnly) {
      applySnapshotAuto(AirAdderStore.building, createFromModel(getType(AirAdderStore.building)))
    }
  }, [props.addBuildingOnly])

  return (
    <Dialog
      show={props.show}
      title={
        props.addBuildingOnly ? "สร้างอาคารใหม่" : (
          "เพิ่มอุปกรณ์ในอาคาร" +
          (AirAdderStore.building.id == 0 ? "ใหม่" : "เดิม")
        )
      }
      hasClose
      onClose={() => {
        if (props.addBuildingOnly) props.onClose();
        else AirAdderStore.setActivePage("")
      }}
    >
      <View style={{ padding: 36, paddingTop: 48 }}>
        <ControlAirAddBuildingInnerTop
          {...props}
          model={model}
        ></ControlAirAddBuildingInnerTop>

        <View style={{ marginBottom: 16 }}>
          <Text>รูปแบบการแสดงผล</Text>
        </View>

        <ControlAirAddBuildingInnerBottom
          {...props}
          model={model}
        ></ControlAirAddBuildingInnerBottom>

        <View style={{ ...FLEX_ROW_CENTER, marginTop: 36 }}>
          <CommonButton
            variant="default"
            style={{ marginRight: 16, flexGrow: 1 }}
            onPress={() => {
              if (props.addBuildingOnly) props.onClose(); 
              else AirAdderStore.goToPreviousPage();
            }}
          >
            ย้อนกลับ
          </CommonButton>
          <CommonButton
            variant="primary"
            style={{ marginLeft: 16, flexGrow: 1 }}
            onPress={async () => {
              try {
                updateMobx(model, props.addBuildingOnly || false);

                if (props.addBuildingOnly) {
                  performAddBuilding(AirAdderStore.building.is_two_level);
                } else {
                  controlAddActiveFirstPage(AirAdderStore.device.device_detail.device_type_id);
                }
              } catch (err) {
                alertErrorComplete(err);
              }
            }}
          >
            {props.addBuildingOnly ? "สร้าง" : "ถัดไป"}
          </CommonButton>
        </View>
      </View>
    </Dialog>
  );
}
