import { types, getType, applySnapshot, getSnapshot } from "mobx-state-tree"
import { api } from "../config"
import { applySnapshotAuto, DateType, createFromModel, AnyType, fitToModel } from "../utils/mobx"
import { globalLoadingFn } from "../utils/loading"
import { timeAdd, getTimezone, date2time, toDateString } from "../utils/date"
import ScopeStore from "./ScopeStore"
import { alertErrorComplete } from "../seperated/toast"

export const ScheduleScope = types.model("ScheduleScope", {
  scope_type: "",
  scope_id: 0,
  type: "",
  id: 0,
  name: "",
  total_count: 0
})

export const ScheduleControl = types.model("ScheduleControl", {
  control_type: "",
  every_time: "00:00",
  control_data: AnyType
}).actions(self => ({
  setControlDataByField(field, value) {
    self.control_data = {...self.control_data, [field]: value};
  },
  setControlType(type) {
    self.control_type = type;
  },
  setEveryTime(time) {
    console.log(time)
    self.every_time = time;
  }
}))

export const Schedule = types.model("Schedule", {
  name: "",
  request_reason: "",
  control_entity: "",
  controls: types.map(ScheduleControl),
  repeat_day: types.array(types.integer),
  repeat_date: types.array(types.string),
  include_date: types.array(types.string),
  except_date: types.array(types.string),
  scopes: types.array(ScheduleScope),
  building_id: 0,
  timezone: "+07:00",
  status: "",
  id: 0
}).actions(self => ({
  toggleRepeatDay(day) {
    let index = self.repeat_day.indexOf(day);
    if (index == -1) {
      self.repeat_day.push(day)
    } else {
      self.repeat_day.splice(index, 1);
    }
  },
  toggleRepeatDate(date) {
    let index = self.repeat_date.indexOf(date);
    if (index == -1) {
      self.repeat_date.push(date)
    } else {
      self.repeat_date.splice(index, 1);
    }
  },
  toggleIncludeDate(date) {
    let index = self.include_date.indexOf(date);
    if (index == -1) {
      self.include_date.push(date)
    } else {
      self.include_date.splice(index, 1);
    }
  },
  setRepeatDate(dates) {
    self.repeat_date = dates.map(x => toDateString(x));
  },
  setIncludeDate(dates) {
    self.include_date = dates.map(x => toDateString(x));
  },
  toggleExceptDate(date) {
    let index = self.except_date.indexOf(date);
    if (index == -1) {
      self.except_date.push(date)
    } else {
      self.except_date.splice(index, 1);
    }
  },
  setExceptDate(dates) {
    self.except_date = dates.map(x => toDateString(x));
  },
  // setScopes(scopes) {
  //   api.post("/places/resolve_scopes", {scopes}).then(response => this._setScopes(response.data))
  // },
  setScopes(scopes) {
    console.log(scopes)
    for (let i = 0; i < scopes.length; i++) {
      scopes[i].scope_type = scopes[i].scope_type || scopes[i].type;
      scopes[i].scope_id = scopes[i].scope_id || scopes[i].id;
      scopes[i] = fitToModel(ScheduleScope, scopes[i])
    }
    self.scopes = scopes;
  },
  removeScopes(scope_type, scope_id) {
    const index = self.scopes.findIndex(x => x.scope_type == scope_type && x.scope_id == scope_id);
    if (index != -1) {
      self.scopes.splice(index, 1);
    }  
  },
  setId(id) {
    self.id = parseInt(id);
  },
  setName(name) {
    self.name = name;
  },
  setRequestReason(request_reason) {
    self.request_reason = request_reason;
  },
  removeControl(name) {
    self.controls.delete(name);
    console.log(self.controls)
  },

  airAddOffControl(prefix) {
    self.controls.set(prefix + "_off", {
      control_type: "fixed",
      every_time: date2time(new Date()),
      control_data: {
        device_status: "0"
      }
    })
  },
  airNew(defaultControlData) {
    applySnapshotAuto(self, createFromModel(Schedule));

    self.control_entity = "device_control_air"

    self.controls.set("common_on", {
      control_type: "fixed",
      every_time: date2time(new Date()),
      control_data: {
        ...defaultControlData,
        device_status: "1"
      }
    })

    self.controls.set("common_off", {
      control_type: "fixed",
      every_time: date2time(new Date()),
      control_data: {
        device_status: "0"
      }
    })

    self.building_id = ScopeStore.activeBuilding;
    self.timezone = getTimezone()
  },
  airEnablePrecool() {
    let common_on = self.controls.get("common_on")

    self.controls.set("precool_on", {
      control_type: "fixed",
      every_time: timeAdd(common_on?.every_time || "00:00", -1),
      control_data: {
        ...common_on?.control_data,
        device_temp: Math.min(common_on?.control_data.device_temp + 2, 30)
      }
    })

    self.controls.set("precool_off", {
      control_type: "fixed",
      every_time: common_on?.every_time,
      control_data: {
        device_status: "0"
      }
    })
  },
  airDisablePrecool() {
    self.controls.delete("precool_on")
    self.controls.delete("precool_off")
  }
})).views(self => ({
  isAirEnablePrecool() {
    return self.controls.has("precool_on") 
  }
}))

const ScheduleStore = Schedule.create(createFromModel(Schedule));


// Use low level validation
function submitScheduleValidate(data, control_entity) {
  if (!data.name) throw new Error("จำเป็นต้องใส่ชื่อตารางการทำงานด้วย")
  if (!data.request_reason) throw new Error("จำเป็นต้องใส่สาเหตุ / คำอธิบายด้วย")
  if (data.scopes.length < 1) throw new Error("จำเป็นต้องเลือกอุปกรณ์อย่างน้อย 1 อย่าง")

  return false;
}

export function scheduleShouldRunOn(schedule, date) {
  if (!date) return true;

  date = new Date(date)
  let day = date.getDay();
  let dateStr = toDateString(date);

  for (let except of schedule.device_schedule_date_excepts) {
    if (except.except_date == dateStr) {
      return false;
    }
  }

  for (let timer of schedule.device_schedule_timers) {
    if (timer.every_day === day) return true;
    if (timer.every_date === dateStr || (timer.every_date === null && dateStr === toDateString(new Date()))) return true;
  }

  return false;
}

export async function submitSchedule({control_entity, successText}) {
  let snapshot = getSnapshot(ScheduleStore);
  submitScheduleValidate(snapshot, control_entity)

  if (snapshot.id == 0) {
    return await globalLoadingFn(()=>api.post("/schedules", snapshot), successText)
  } else {
    return await globalLoadingFn(()=>api.patch("/schedules/" + snapshot.id, snapshot), successText)
  }
}

export async function loadSchedule(schedule_id, control_entity) {
  let response = await api.get("/schedules/" + schedule_id);

  console.log(response.data)

  applySnapshotAuto(ScheduleStore, response.data);
}

export default ScheduleStore;