import React, { useState, useEffect } from "react";
import PanelInner from "../PanelInner";
import {
  FLEX_ROW_CENTER,
  FLEX_LR_CENTER,
  FLEX_COLUMN,
  FLEX_COLUMN_CENTER_CENTER,
} from "../constants/style";
import { TextMD, TextSM, View, TouchableOpacity, Text } from "../../seperated/react-native";
import EnergyConsumptionChart from "./EnergyConsumptionChart";
import { navigateSameParams } from "../../seperated/components/Router";
import { globalLoadingFn } from "../../utils/loading";
import { api } from "../../config";
import { IS_MOBILE } from "../../seperated/screen-mode";

export default function EnergyConsumptionSummary(props) {
  const ALL_TAB = props.controlEntity ? [] : [
    {title: 'Air', controlEntity: 'device_control_air'},
    {title: 'Total', controlEntity: 'total'},
  ]

  const TAB_INACTIVE_STYLE = {
    borderRadius: 12,
    borderColor: "#49c5f1",
    borderWidth: 1,
    paddingHorizontal: 24,
    paddingVertical: 4,
    marginHorizontal: 6
  };

  const TAB_ACTIVE_STYLE = {
    borderRadius: 12,
    backgroundColor: "#49c5f1",
    paddingHorizontal: 24,
    paddingVertical: 4,
    marginHorizontal: 6
  };

  const [controlEntity, setControlEntity] = useState("total");
  const [chartData, setChartData] = useState<any>({});

  // const averageKW = chartData.data && chartData.data[0] ? chartData.data[0].y.reduce((acc, curr) => acc + curr, 0) / chartData.data[0].y.length : 0;
  const averageKW = props.averageKW; // Temporary
  const lastKW = chartData.data && chartData.data[0] ? chartData.data[0].y[chartData.data[0].y.length - 1] : 0;

  useEffect(() => {
    async function f() {
      let response = await globalLoadingFn(() => api.get("/sensors/chart", {
        params: {
          scope_type: props.scopeType,
          scope_id: props.scopeId,
          interval: "d",
          date: new Date(),
          chart_type: "energy_consumption",
          split_control_entity: JSON.stringify(props.controlEntity || [controlEntity]),
        }
      }))

      console.log(response.data)

      setChartData(response.data)
    }
    f();
  }, [controlEntity])

  if (props.chartOnly) {
    return <EnergyConsumptionChart data={chartData.data}></EnergyConsumptionChart>
  }

  return (
    <PanelInner>
      <View style={{ ...FLEX_ROW_CENTER }}>
        <View style={{ flexGrow: 1 }}>
          <View style={{ ...(IS_MOBILE ? FLEX_COLUMN : FLEX_LR_CENTER), marginBottom: IS_MOBILE ? 12 : 0 }}>
            <TextMD style={{ marginBottom: IS_MOBILE ? 8 : 0 }}>Energy Consumption</TextMD>

            <View style={{paddingRight: 18, ...FLEX_ROW_CENTER}}>
              {ALL_TAB.map(tab => (
                <TouchableOpacity 
                  style={tab.controlEntity == controlEntity ? TAB_ACTIVE_STYLE : TAB_INACTIVE_STYLE}
                  onPress={() => setControlEntity(tab.controlEntity)}
                >
                  <TextSM style={{color: tab.controlEntity == controlEntity ? "white" : "black"}}>{tab.title}</TextSM>
                </TouchableOpacity>
              ))}
            </View>
          </View>

          <EnergyConsumptionChart data={chartData.data}></EnergyConsumptionChart>
        </View>

        <View style={{ ...FLEX_COLUMN, alignItems: "center" }}>
          <TouchableOpacity
            onPress={() => props.moreClick()}
          >
            <TextSM
              style={{ marginBottom: 8, paddingTop: 4, textDecorationLine: "underline" }}
            >
              See more &gt;
            </TextSM>
          </TouchableOpacity>

          <View
            style={{
              flexBasis: 128,
              ...FLEX_COLUMN_CENTER_CENTER,
              flexGrow: 1,
            }}
          >
            <View style={{ marginVertical: 8, ...FLEX_COLUMN_CENTER_CENTER }}>
              <TextSM>Average</TextSM>
              <Text style={{ fontWeight: "bold" }}>{averageKW.toFixed(2)} kW</Text>
            </View>

            <View style={{ marginVertical: 8, ...FLEX_COLUMN_CENTER_CENTER }}>
              <TextSM>Current Load</TextSM>
              <Text style={{ fontWeight: "bold" }}>{lastKW.toFixed(2)} kW</Text>
            </View>
          </View>
        </View>
      </View>
    </PanelInner>
  );
}
