import { types } from "mobx-state-tree"
import { DeviceDetail_Form, DeviceControlAir_Form, Position } from "./AirStore"
import { createFromModel, applySnapshotAuto } from "../utils/mobx"
import makeInspectable from 'mobx-devtools-mst';

const Building_Form = types.model("AirAdderStoreBuilding_Form", {
  id: 0,
  name: "",
  avatar: "",
  picture: "/img/graphics/empty.png",
  district: "",
  electricity_formula_id: "",
  is_two_level: types.maybe(types.boolean),
  position: Position,
})

const AirForm = types.model("AirForm", {
  // Sent on add air
  device_detail: DeviceDetail_Form,
  device_control_air: DeviceControlAir_Form,
})

const Model = types
  .model("AirAdderStore", {
    activePage: "",
    activePages: types.array(types.string),
    building: Building_Form,
    device: AirForm,
  })
  .actions(self => ({
    setActivePage(page) {
      console.log(page)

      if (page) {
        self.activePages.push(page);
      } else {
        self.activePages.clear();
      }
      
      self.activePage = page;
    },
    goToPreviousPage() {
      if (self.activePages.length > 0) self.activePages.pop();

      if (self.activePages.length > 0) {
        this.setActivePage(self.activePages[self.activePages.length - 1]);
      } else {
        this.setActivePage("");
      }
    },
    setDisplayTwoLevel(is_two_level) {
      self.building.is_two_level = is_two_level;
    },
    setDeviceName(name) {
      self.device.device_detail.name = name;
    },
    setDeviceType(id) {
      self.device.device_detail.device_type_id = parseInt(id);
    },
    setDeviceAirType(id) {
      self.device.device_control_air.device_air_type_id = parseInt(id);
    },
    fillFromInsuranceForm(data) {
      self.device.device_control_air.delivery_date = new Date(data.delivery_date)
      self.device.device_control_air.warranty_all_part_month = parseInt(data.warranty_all_part_month)
      self.device.device_control_air.warranty_compressor_month = parseInt(data.warranty_compressor_month)
      self.device.device_control_air.warranty_installation_month = parseInt(data.warranty_installation_month)
      self.device.device_control_air.device_air_buy_form = data.technician_under_shop_name

      applySnapshotAuto(self.device.device_control_air.technician, data)
    }
  }))

let AirAdderStore = Model.create(createFromModel(Model));

makeInspectable(AirAdderStore)

export default AirAdderStore;