interface ValueLabelPair {
  value: string,
  label: string
}

export const ICON_BUILDING_BASE = [
  {value: "/img/graphics/building_tall.png",  icon: "/img/graphics/building_tall.png",},
  {value: "/img/graphics/building.png",       icon: "/img/graphics/building.png",     },
  {value: "/img/graphics/home.png",           icon: "/img/graphics/home.png",         },
  {value: "/img/graphics/home_tall.png",      icon: "/img/graphics/home_tall.png",    },
  {value: "/img/graphics/home_medium.png",    icon: "/img/graphics/home_medium.png",  },
];

export const ICON_FLOOR_BASE = [
  {value: "/img/graphics/home_inner.png",   icon: "/img/graphics/home_inner.png",  },
  {value: "/img/graphics/working1.png",     icon: "/img/graphics/working1.png",    },
  {value: "/img/graphics/communicate.png",  icon: "/img/graphics/communicate.png", },
  {value: "/img/graphics/presentation.png", icon: "/img/graphics/presentation.png",},
  {value: "/img/graphics/bed.png",          icon: "/img/graphics/bed.png",         },
];

export const ICON_ROOM_BASE = [
  {value: "/img/graphics/working1.png",     icon: "/img/graphics/working1.png",    },
  {value: "/img/graphics/communicate.png",  icon: "/img/graphics/communicate.png", },
  {value: "/img/graphics/presentation.png", icon: "/img/graphics/presentation.png",},
  {value: "/img/graphics/bed.png",          icon: "/img/graphics/bed.png",         },
  {value: "/img/graphics/counter.png",      icon: "/img/graphics/counter.png",     },
];

export const DEFAULT_AVATAR = {
  building: "/img/graphics/building.png",
  floor: "/img/graphics/home_inner.png",
  room: "/img/graphics/working1.png"
}

export const MOCK_OPTIONS = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

export const ELECTRICITY_FORMULA_OPTIONS: ValueLabelPair[] = [
  { value: "mockup", label: "Mock Up" },
];

export const DEVICE_TYPES_OPTIONS: ValueLabelPair[] = [
  { value: "1", label: "Air" },
  // { value: "???", label: "Heat Exchanger" },
  { value: "2", label: "Sensor" },
  { value: "3", label: "Light" },
  { value: "4", label: "Curtain" },
  { value: "5", label: "Door" },
  { value: "6", label: "Breaker" },
];

export const BRAND_OPTIONS: ValueLabelPair[] = [
  { value: "1", label: "Daikin" },
  // { value: "2", label: "Toshiba" },
  // { value: "3", label: "Carrier" },
  // { value: "4", label: "Hisense" },
  // { value: "vanilla", label: "Vanilla" },
  { value: "5", label: "Airconet" },
];

export const SENSOR_TYPE_OPTIONS: ValueLabelPair[] = [
  { value: "2", label: "Power meter & Solar cell" },
  { value: "5", label: "All in one sensor" },
  { value: "6", label: "Daikin sensor" },
  { value: "1", label: "Temperature & Humidity" },
  { value: "3", label: "CO2" },
  { value: "4", label: "PM2.5 - PM10" },
  // { value: "vanilla", label: "Vanilla" },
];

export const DEVICE_LOCK_REMOTE_OPTIONS = [
  { value: "U", label: "ไม่ล็อค" },
  { value: "S", label: "ยกเว้นปุ่ม เปิด/ปิด" },
  { value: "L", label: "ล็อค" },
];

// Air
export const DEVICE_AIR_TYPES_OPTIONS: ValueLabelPair[] = [
  { value: "1", label: "Split Type" },
  { value: "2", label: "VRV" },
  // { value: "3", label: "Chiller" },
  // { value: "4", label: "Manual" },
  { value: "5", label: "Airconet" },
];

// Light
export const DEVICE_LIGHT_TYPES_OPTIONS: ValueLabelPair[] = [
  { value: "1", label: "On Off only" },
  { value: "2", label: "Level" },
];

// Permission
export const PERMISSION_OPTIONS: ValueLabelPair[] = [
  { value: "50", label: "User" },
  { value: "70", label: "Controller" },
  { value: "90", label: "Admin" },
  { value: "110", label: "Advance" },
];

export const DEIVCE_AIR_LOCK_REMOTE_OPTIONS = DEVICE_LOCK_REMOTE_OPTIONS;

export const DEFAULT_POSITION = {
  "top":0.3,
  "left":0.2,
  "width":0.5,
  "height":0.2
}