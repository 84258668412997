import React, { useEffect } from "react"
import { View } from "../seperated/react-native"
import { getRouteParam, navigate } from "../seperated/components/Router";
import { globalLoadingFn } from "../utils/loading";
import { api } from "../config";
import { alert_success } from "../seperated/toast";
import ScopeStore from "../stores/ScopeStore";

export async function acceptRoleQr(secret) {
  try {
    let response = await globalLoadingFn(()=>api.post("/roles/accept_qr/" + secret));

    ScopeStore.setActiveBuilding(response.data.building_id);
  
    alert_success("เพิ่มสิทธิ์สำเร็จ")
  } finally {
    // navigate("control_air_list", {type: response.data.reference_type.toLowerCase(), id: response.data.reference_id})
    navigate("dashboard")
  }
}

export default function RoleAcceptQr(props) {
  const secret = getRouteParam(props, "s");

  useEffect(()=>{
    acceptRoleQr(secret);
  }, []);

  return <View></View>
}