import * as React from "react";

import { FormState, useFormStateAgain } from "../../hooks/FormState";
import classNames from "classnames";
import { DEV } from "./../../config";
import { debounce } from "lodash";
import { inputOnChange } from "./core_logic/onChange";
import { TextInput } from "../../seperated/react-native";
import InputCompleteBase from "./core_logic/InputCompleteBase";

import { TextField } from "@material-ui/core";
import SelectCustom from "../../seperated/components/SelectCustom";
import DisabledContext from "./DisabledContext";

export default function SelectComplete({variant, ...props}) {
  let model = useFormStateAgain(props.model, props.defaultValue || "");
  const disabled = React.useContext(DisabledContext)

  return (
    <InputCompleteBase {...props} model={model}>
      <SelectCustom
        value={model.dataState.get()}
        variant={variant}
        disabled={disabled}
        {...props}
        //className={classNames([ "form-control form-control-solid " + (model.errorState.get() ? 'is-invalid' : ''), props.className||"" ])}
        onChange={(value, ...args) =>
          inputOnChange(props, model, value, value, ...args)
        }
      />
    </InputCompleteBase>
  );
}
