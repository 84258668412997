import React from "react"
import Dialog from "../../../../components/dialog/Dialog"
import { View, Image, TextL } from "../../../../seperated/react-native"
import { FLEX_ROW_CENTER_CENTER, FLEX_COLUMN } from "../../../../components/constants/style"
import CardButton from "../../../../components/buttons/CardButton"
import AirAdderStore from "../../../../stores/AirAdderStore"
import MobileLayout from "../../../../components/MobileLayout"
import CardButtonMini from "../../../../components/buttons/CardButtonMini"
import { navigate } from "../../../../seperated/components/Router"
import { applySnapshotAuto, createFromModel } from "../../../../utils/mobx"
import { api } from "../../../../config"
import ScopeStore from "../../../../stores/ScopeStore"
import { globalLoadingFn } from "../../../../utils/loading"
import { getType } from "mobx-state-tree"

async function loadActiveBuildingToStore() {
  let response = await globalLoadingFn(()=>api.get("/buildings/" + ScopeStore.activeBuilding));
  applySnapshotAuto(AirAdderStore.building, response.data)
}

export function ControlAirAddBuildingTypePage(props) {
  return (
    <MobileLayout title="เพิ่มอุปกรณ์">
      <View style={{ ...FLEX_COLUMN }}>
        <TextL style={{marginBottom: 16}}>ระบุอาคาร</TextL>

        <CardButtonMini
          icon={require("../../../../img/icons/home-large.png")}
          title="เพิ่มอุปกรณ์ในอาคารเดิม"
          subtitle="เพิ่มอุปกรณ์ในอาคารเดิมของฉัน"
          style={{marginBottom: 16}}
          onPress={async ()=>{
            await loadActiveBuildingToStore();
            navigate("control_air_add_building_top")
          }}
        ></CardButtonMini>

        <CardButtonMini
          icon={require("../../../../img/icons/home-star.png")}
          title="เพิ่มอุปกรณ์ในอาคารใหม่"
          subtitle="เพิ่มอุปกรณ์ และสร้างอาคารใหม่"
          style={{marginBottom: 16}}
          onPress={()=>{
            navigate("control_air_add_building_top")
          }}
        ></CardButtonMini>
      </View>
    </MobileLayout>
  )
}

export default function ControlAirAddBuildingType(props) {
  return (
    <Dialog show={props.show} title="ระบุอาคาร" hasClose onClose={()=>AirAdderStore.setActivePage("")}>
      <View style={{ ...FLEX_ROW_CENTER_CENTER, padding: 24 }}>
        <CardButton
          icon={<Image src={require("../../../../img/icons/home-large.png")} height="125" />}
          title="เพิ่มอุปกรณ์ในอาคารเดิม"
          subtitle="เพิ่มอุปกรณ์ในอาคารเดิมของฉัน"
          style={{margin: 16}}
          hasRightArrow
          onPress={async ()=>{
            await loadActiveBuildingToStore();
            AirAdderStore.setActivePage("building")
          }}
        ></CardButton>

        <CardButton
          icon={<Image src={require("../../../../img/icons/home-star.png")} height="125" />}
          title="เพิ่มอุปกรณ์ในอาคารใหม่"
          subtitle="เพิ่มอุปกรณ์ และสร้างอาคารใหม่"
          style={{margin: 16}}
          hasRightArrow
          onPress={()=>{
            applySnapshotAuto(AirAdderStore.building, createFromModel(getType(AirAdderStore.building)))
            AirAdderStore.setActivePage("building")
          }}
        ></CardButton>
      </View>
    </Dialog>
  )
}